/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageAudit } from '../models';
// @ts-ignore
import { PageStageDefinitionDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
// @ts-ignore
import { StageDefinitionCreateRequest } from '../models';
// @ts-ignore
import { StageDefinitionCreateResponse } from '../models';
// @ts-ignore
import { StageDefinitionDto } from '../models';
// @ts-ignore
import { StageDefinitionUpdateRequest } from '../models';
// @ts-ignore
import { StageDefinitionUpdateResponse } from '../models';
/**
 * StageDefinitionsAdminControllerApi - axios parameter creator
 * @export
 */
export const StageDefinitionsAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<StageDefinitionCreateRequest>} stageDefinitionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate14: async (stageDefinitionCreateRequest: Array<StageDefinitionCreateRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stageDefinitionCreateRequest' is not null or undefined
            assertParamExists('bulkCreate14', 'stageDefinitionCreateRequest', stageDefinitionCreateRequest)
            const localVarPath = `/v1/admin/stage-definitions/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stageDefinitionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StageDefinitionCreateRequest} stageDefinitionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create17: async (stageDefinitionCreateRequest: StageDefinitionCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stageDefinitionCreateRequest' is not null or undefined
            assertParamExists('create17', 'stageDefinitionCreateRequest', stageDefinitionCreateRequest)
            const localVarPath = `/v1/admin/stage-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stageDefinitionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById15: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById15', 'id', id)
            const localVarPath = `/v1/admin/stage-definitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find49: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find49', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/stage-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended14: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findExtended14', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/stage-definitions/extended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById35: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById35', 'id', id)
            const localVarPath = `/v1/admin/stage-definitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount41: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/stage-definitions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit14: async (pageable: Pageable, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getEntityAudit14', 'pageable', pageable)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit14', 'id', id)
            const localVarPath = `/v1/admin/stage-definitions/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties14: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/stage-definitions/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessTranslations14ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations14FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations14: async (toLanguage: ProcessTranslations14ToLanguageEnum, fromLanguage?: ProcessTranslations14FromLanguageEnum, forceTranslate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toLanguage' is not null or undefined
            assertParamExists('processTranslations14', 'toLanguage', toLanguage)
            const localVarPath = `/v1/admin/stage-definitions/i18n/from/{fromLanguage}/to/{toLanguage}`
                .replace(`{${"toLanguage"}}`, encodeURIComponent(String(toLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromLanguage !== undefined) {
                localVarQueryParameter['fromLanguage'] = fromLanguage;
            }

            if (forceTranslate !== undefined) {
                localVarQueryParameter['forceTranslate'] = forceTranslate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull14: async (id: number, properties: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPropertiesToNull14', 'id', id)
            // verify required parameter 'properties' is not null or undefined
            assertParamExists('setPropertiesToNull14', 'properties', properties)
            const localVarPath = `/v1/admin/stage-definitions/{id}/property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (properties) {
                localVarQueryParameter['properties'] = Array.from(properties);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StageDefinitionUpdateRequest} stageDefinitionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update14: async (id: number, stageDefinitionUpdateRequest: StageDefinitionUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update14', 'id', id)
            // verify required parameter 'stageDefinitionUpdateRequest' is not null or undefined
            assertParamExists('update14', 'stageDefinitionUpdateRequest', stageDefinitionUpdateRequest)
            const localVarPath = `/v1/admin/stage-definitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stageDefinitionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StageDefinitionsAdminControllerApi - functional programming interface
 * @export
 */
export const StageDefinitionsAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StageDefinitionsAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<StageDefinitionCreateRequest>} stageDefinitionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreate14(stageDefinitionCreateRequest: Array<StageDefinitionCreateRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StageDefinitionCreateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreate14(stageDefinitionCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StageDefinitionCreateRequest} stageDefinitionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create17(stageDefinitionCreateRequest: StageDefinitionCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StageDefinitionCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create17(stageDefinitionCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById15(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById15(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find49(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageStageDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find49(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExtended14(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageStageDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExtended14(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById35(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StageDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById35(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount41(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount41(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit14(pageable: Pageable, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit14(pageable, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nSupportedProperties14(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nSupportedProperties14(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessTranslations14ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations14FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTranslations14(toLanguage: ProcessTranslations14ToLanguageEnum, fromLanguage?: ProcessTranslations14FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTranslations14(toLanguage, fromLanguage, forceTranslate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPropertiesToNull14(id: number, properties: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPropertiesToNull14(id, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StageDefinitionUpdateRequest} stageDefinitionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update14(id: number, stageDefinitionUpdateRequest: StageDefinitionUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StageDefinitionUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update14(id, stageDefinitionUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StageDefinitionsAdminControllerApi - factory interface
 * @export
 */
export const StageDefinitionsAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StageDefinitionsAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<StageDefinitionCreateRequest>} stageDefinitionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate14(stageDefinitionCreateRequest: Array<StageDefinitionCreateRequest>, options?: any): AxiosPromise<Array<StageDefinitionCreateResponse>> {
            return localVarFp.bulkCreate14(stageDefinitionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StageDefinitionCreateRequest} stageDefinitionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create17(stageDefinitionCreateRequest: StageDefinitionCreateRequest, options?: any): AxiosPromise<StageDefinitionCreateResponse> {
            return localVarFp.create17(stageDefinitionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById15(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById15(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find49(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageStageDefinitionDto> {
            return localVarFp.find49(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended14(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageStageDefinitionDto> {
            return localVarFp.findExtended14(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById35(id: number, options?: any): AxiosPromise<StageDefinitionDto> {
            return localVarFp.getById35(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount41(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount41(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit14(pageable: Pageable, id: number, options?: any): AxiosPromise<PageAudit> {
            return localVarFp.getEntityAudit14(pageable, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties14(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getI18nSupportedProperties14(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessTranslations14ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations14FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations14(toLanguage: ProcessTranslations14ToLanguageEnum, fromLanguage?: ProcessTranslations14FromLanguageEnum, forceTranslate?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processTranslations14(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull14(id: number, properties: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPropertiesToNull14(id, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StageDefinitionUpdateRequest} stageDefinitionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update14(id: number, stageDefinitionUpdateRequest: StageDefinitionUpdateRequest, options?: any): AxiosPromise<StageDefinitionUpdateResponse> {
            return localVarFp.update14(id, stageDefinitionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StageDefinitionsAdminControllerApi - object-oriented interface
 * @export
 * @class StageDefinitionsAdminControllerApi
 * @extends {BaseAPI}
 */
export class StageDefinitionsAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<StageDefinitionCreateRequest>} stageDefinitionCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public bulkCreate14(stageDefinitionCreateRequest: Array<StageDefinitionCreateRequest>, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).bulkCreate14(stageDefinitionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StageDefinitionCreateRequest} stageDefinitionCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public create17(stageDefinitionCreateRequest: StageDefinitionCreateRequest, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).create17(stageDefinitionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public deleteById15(id: number, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).deleteById15(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public find49(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).find49(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public findExtended14(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).findExtended14(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public getById35(id: number, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).getById35(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public getCount41(query?: string, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).getCount41(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public getEntityAudit14(pageable: Pageable, id: number, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).getEntityAudit14(pageable, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public getI18nSupportedProperties14(options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).getI18nSupportedProperties14(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessTranslations14ToLanguageEnum} toLanguage 
     * @param {ProcessTranslations14FromLanguageEnum} [fromLanguage] 
     * @param {boolean} [forceTranslate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public processTranslations14(toLanguage: ProcessTranslations14ToLanguageEnum, fromLanguage?: ProcessTranslations14FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).processTranslations14(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} properties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public setPropertiesToNull14(id: number, properties: Array<string>, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).setPropertiesToNull14(id, properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StageDefinitionUpdateRequest} stageDefinitionUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageDefinitionsAdminControllerApi
     */
    public update14(id: number, stageDefinitionUpdateRequest: StageDefinitionUpdateRequest, options?: AxiosRequestConfig) {
        return StageDefinitionsAdminControllerApiFp(this.configuration).update14(id, stageDefinitionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations14ToLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations14FromLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
