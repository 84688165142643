/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BookTicketRequest } from '../models';
// @ts-ignore
import { PageTicketUserDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
// @ts-ignore
import { StartPurchaseRequest } from '../models';
// @ts-ignore
import { TicketUserDto } from '../models';
// @ts-ignore
import { TicketsBookedDto } from '../models';
/**
 * TicketsUserControllerApi - axios parameter creator
 * @export
 */
export const TicketsUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BookTicketRequest} bookTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookTickets: async (bookTicketRequest: BookTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookTicketRequest' is not null or undefined
            assertParamExists('bookTickets', 'bookTicketRequest', bookTicketRequest)
            const localVarPath = `/v1/events/tickets/book`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('deleteTicket', 'ticketId', ticketId)
            const localVarPath = `/v1/events/tickets/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadApplePass: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadApplePass', 'id', id)
            const localVarPath = `/v1/events/tickets/{id}/download/pass`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGoogleWalletTicket: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadGoogleWalletTicket', 'id', id)
            const localVarPath = `/v1/events/tickets/{id}/download/wallet`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DownloadTicketPdfDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTicketPdf: async (id: number, device?: DownloadTicketPdfDeviceEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadTicketPdf', 'id', id)
            const localVarPath = `/v1/events/tickets/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find5: async (pageable: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('find5', 'pageable', pageable)
            const localVarPath = `/v1/events/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActive: async (pageable: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('findActive', 'pageable', pageable)
            const localVarPath = `/v1/events/tickets/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArchive: async (pageable: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('findArchive', 'pageable', pageable)
            const localVarPath = `/v1/events/tickets/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} purchaseOrderId 
         * @param {FinishPurchaseBillingSystemTypeEnum} billingSystemType 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPurchase: async (purchaseOrderId: string, billingSystemType: FinishPurchaseBillingSystemTypeEnum, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseOrderId' is not null or undefined
            assertParamExists('finishPurchase', 'purchaseOrderId', purchaseOrderId)
            // verify required parameter 'billingSystemType' is not null or undefined
            assertParamExists('finishPurchase', 'billingSystemType', billingSystemType)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('finishPurchase', 'body', body)
            const localVarPath = `/v1/events/tickets/finish-purchase/{billingSystemType}/{purchaseOrderId}`
                .replace(`{${"purchaseOrderId"}}`, encodeURIComponent(String(purchaseOrderId)))
                .replace(`{${"billingSystemType"}}`, encodeURIComponent(String(billingSystemType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPurchase1: async (stripeSignature: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('finishPurchase1', 'stripeSignature', stripeSignature)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('finishPurchase1', 'body', body)
            const localVarPath = `/v1/events/tickets/finish-purchase/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookedTicketsDataForEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getBookedTicketsDataForEvent', 'eventId', eventId)
            const localVarPath = `/v1/events/tickets/sold/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartPurchaseRequest} startPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPurchase: async (startPurchaseRequest: StartPurchaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startPurchaseRequest' is not null or undefined
            assertParamExists('startPurchase', 'startPurchaseRequest', startPurchaseRequest)
            const localVarPath = `/v1/events/tickets/start-purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startPurchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsUserControllerApi - functional programming interface
 * @export
 */
export const TicketsUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BookTicketRequest} bookTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookTickets(bookTicketRequest: BookTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookTickets(bookTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicket(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicket(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadApplePass(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadApplePass(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadGoogleWalletTicket(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadGoogleWalletTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DownloadTicketPdfDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTicketPdf(id: number, device?: DownloadTicketPdfDeviceEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTicketPdf(id, device, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find5(pageable: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTicketUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find5(pageable, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findActive(pageable: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTicketUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findActive(pageable, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findArchive(pageable: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTicketUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findArchive(pageable, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} purchaseOrderId 
         * @param {FinishPurchaseBillingSystemTypeEnum} billingSystemType 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishPurchase(purchaseOrderId: string, billingSystemType: FinishPurchaseBillingSystemTypeEnum, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishPurchase(purchaseOrderId, billingSystemType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishPurchase1(stripeSignature: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishPurchase1(stripeSignature, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookedTicketsDataForEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketsBookedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookedTicketsDataForEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartPurchaseRequest} startPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startPurchase(startPurchaseRequest: StartPurchaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startPurchase(startPurchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketsUserControllerApi - factory interface
 * @export
 */
export const TicketsUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {BookTicketRequest} bookTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookTickets(bookTicketRequest: BookTicketRequest, options?: any): AxiosPromise<TicketUserDto> {
            return localVarFp.bookTickets(bookTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket(ticketId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicket(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadApplePass(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadApplePass(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGoogleWalletTicket(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.downloadGoogleWalletTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DownloadTicketPdfDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTicketPdf(id: number, device?: DownloadTicketPdfDeviceEnum, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadTicketPdf(id, device, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find5(pageable: Pageable, query?: string, options?: any): AxiosPromise<PageTicketUserDto> {
            return localVarFp.find5(pageable, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActive(pageable: Pageable, query?: string, options?: any): AxiosPromise<PageTicketUserDto> {
            return localVarFp.findActive(pageable, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArchive(pageable: Pageable, query?: string, options?: any): AxiosPromise<PageTicketUserDto> {
            return localVarFp.findArchive(pageable, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} purchaseOrderId 
         * @param {FinishPurchaseBillingSystemTypeEnum} billingSystemType 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPurchase(purchaseOrderId: string, billingSystemType: FinishPurchaseBillingSystemTypeEnum, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.finishPurchase(purchaseOrderId, billingSystemType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPurchase1(stripeSignature: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.finishPurchase1(stripeSignature, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookedTicketsDataForEvent(eventId: number, options?: any): AxiosPromise<TicketsBookedDto> {
            return localVarFp.getBookedTicketsDataForEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartPurchaseRequest} startPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPurchase(startPurchaseRequest: StartPurchaseRequest, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.startPurchase(startPurchaseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketsUserControllerApi - object-oriented interface
 * @export
 * @class TicketsUserControllerApi
 * @extends {BaseAPI}
 */
export class TicketsUserControllerApi extends BaseAPI {
    /**
     * 
     * @param {BookTicketRequest} bookTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public bookTickets(bookTicketRequest: BookTicketRequest, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).bookTickets(bookTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public deleteTicket(ticketId: number, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).deleteTicket(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public downloadApplePass(id: number, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).downloadApplePass(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public downloadGoogleWalletTicket(id: number, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).downloadGoogleWalletTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DownloadTicketPdfDeviceEnum} [device] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public downloadTicketPdf(id: number, device?: DownloadTicketPdfDeviceEnum, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).downloadTicketPdf(id, device, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public find5(pageable: Pageable, query?: string, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).find5(pageable, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public findActive(pageable: Pageable, query?: string, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).findActive(pageable, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public findArchive(pageable: Pageable, query?: string, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).findArchive(pageable, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} purchaseOrderId 
     * @param {FinishPurchaseBillingSystemTypeEnum} billingSystemType 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public finishPurchase(purchaseOrderId: string, billingSystemType: FinishPurchaseBillingSystemTypeEnum, body: string, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).finishPurchase(purchaseOrderId, billingSystemType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripeSignature 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public finishPurchase1(stripeSignature: string, body: string, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).finishPurchase1(stripeSignature, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public getBookedTicketsDataForEvent(eventId: number, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).getBookedTicketsDataForEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartPurchaseRequest} startPurchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsUserControllerApi
     */
    public startPurchase(startPurchaseRequest: StartPurchaseRequest, options?: AxiosRequestConfig) {
        return TicketsUserControllerApiFp(this.configuration).startPurchase(startPurchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum DownloadTicketPdfDeviceEnum {
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}
/**
  * @export
  * @enum {string}
  */
export enum FinishPurchaseBillingSystemTypeEnum {
    PAY_U = 'PAY_U',
    PRZELEWY_24 = 'PRZELEWY_24',
    STRIPE = 'STRIPE'
}
