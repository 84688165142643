/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorUserDto } from './actor-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyUserDto } from './agency-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CityUserDto } from './city-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CurrencyUserDto } from './currency-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeUserDto } from './event-type-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StageUserDto } from './stage-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { TourUserDto } from './tour-user-dto';

/**
 * 
 * @export
 * @interface EventLandingDto
 */
export interface EventLandingDto {
    /**
     * 
     * @type {number}
     * @memberof EventLandingDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'admissionStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'admissionEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'saleStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'saleEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLandingDto
     */
    'eventStatus'?: EventLandingDtoEventStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EventLandingDto
     */
    'includeVat'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventLandingDto
     */
    'vat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventLandingDto
     */
    'includeServiceFee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventLandingDto
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventLandingDto
     */
    'commissionRate'?: number;
    /**
     * 
     * @type {Array<ActorUserDto>}
     * @memberof EventLandingDto
     */
    'actors'?: Array<ActorUserDto>;
    /**
     * 
     * @type {AgencyUserDto}
     * @memberof EventLandingDto
     */
    'agency'?: AgencyUserDto;
    /**
     * 
     * @type {EventTypeUserDto}
     * @memberof EventLandingDto
     */
    'eventType'?: EventTypeUserDto;
    /**
     * 
     * @type {CityUserDto}
     * @memberof EventLandingDto
     */
    'city'?: CityUserDto;
    /**
     * 
     * @type {StageUserDto}
     * @memberof EventLandingDto
     */
    'stage'?: StageUserDto;
    /**
     * 
     * @type {CurrencyUserDto}
     * @memberof EventLandingDto
     */
    'currency'?: CurrencyUserDto;
    /**
     * 
     * @type {TourUserDto}
     * @memberof EventLandingDto
     */
    'tour'?: TourUserDto;
    /**
     * 
     * @type {number}
     * @memberof EventLandingDto
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventLandingDto
     */
    'minPriceSeatsLeft'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventLandingDto
     */
    'soldOut'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventLandingDto
     */
    'eventStageDefinitionId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EventLandingDtoEventStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}


