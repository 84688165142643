/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TicketsSalesStatisticsView
 */
export interface TicketsSalesStatisticsView {
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'ticketStatus'?: TicketsSalesStatisticsViewTicketStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'eventId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'eventName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'eventSeatId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'placeDefinition'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'promocode'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'billingSystem'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'actorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'actorName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'actorTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'actorTypeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'agencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'agencyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'stageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'stageName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'cityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'cityName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketsSalesStatisticsView
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketsSalesStatisticsView
     */
    'countryName'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketsSalesStatisticsViewTicketStatusEnum {
    RESERVED = 'reserved',
    PURCHASED = 'purchased',
    RETURN_REQUESTED = 'return_requested',
    RETURNED = 'returned',
    EXPIRED = 'expired'
}


