import { Button, Divider, Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import FloatDateTime from '@components/FloatDateTime'
import FormItem from '@components/FormItem'
import { TicketControlPersonDto } from '@/openapi'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { useParams } from 'react-router-dom'
import FloatSelect from '@components/FloatSelect'
import { dateToString } from '../../helpers'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

type MLFormProps = {
  onSuccess: () => void
  id?: number
}

const TCAForm = ({ onSuccess, id }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/ticket-control-activities/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            controlPersonId: {
              value: String(data.controlPerson!.id),
              label: data.controlPerson!.phoneNumber!,
            },
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    const dates = {
      dateStart: dateToString(values.dateStart),
      dateEnd: dateToString(values.dateEnd),
    }
    if (id) {
      await axiosInstance.put(`admin/ticket-control-activities/${id}`, {
        status: values.status,
        ...dates,
      })
    } else {
      await axiosInstance.post('admin/ticket-control-activities', {
        ...values,
        ...dates,
        eventId,
      })
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      {!!id && (
        <FormItem name="status">
          <FloatSelect label={t`labels.Status`} labelInValue={false}>
            <Option value="requested">{'Requested'}</Option>
            <Option value="verified">{'Verified'}</Option>
            <Option value="failed">{'Failed'}</Option>
            <Option value="canceled">{'Canceled'}</Option>
          </FloatSelect>
        </FormItem>
      )}
      {!id && (
        <GenericAsyncSelect<TicketControlPersonDto>
          name="controlPersonId"
          path="admin/ticket-control-persons/search"
          label={t`labels.Control Person`}
          withAll={false}
          width="100%"
          searchParam="searchWord"
          customPrepareOption={({ phoneNumber, id, name }) => ({
            value: String(id),
            label: (name ? `${name} - ` : '') + phoneNumber!,
          })}
        />
      )}
      <FormItem name="dateStart" hasFeedback rules={[{ required: true }]}>
        <FloatDateTime label={t`labels.Start Date`} required />
      </FormItem>
      <FormItem name="dateEnd" hasFeedback rules={[{ required: true }]}>
        <FloatDateTime label={t`labels.End Date`} required />
      </FormItem>
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TCAForm
