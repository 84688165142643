/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { TicketControlActivityCreateResponse } from './ticket-control-activity-create-response';
// May contain unused imports in some cases
// @ts-ignore
import { TicketCreateResponse } from './ticket-create-response';

/**
 * 
 * @export
 * @interface TicketControlVerificationCreateResponse
 */
export interface TicketControlVerificationCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationCreateResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationCreateResponse
     */
    'verificationResult'?: TicketControlVerificationCreateResponseVerificationResultEnum;
    /**
     * 
     * @type {TicketControlActivityCreateResponse}
     * @memberof TicketControlVerificationCreateResponse
     */
    'controlActivity'?: TicketControlActivityCreateResponse;
    /**
     * 
     * @type {TicketCreateResponse}
     * @memberof TicketControlVerificationCreateResponse
     */
    'ticket'?: TicketCreateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlVerificationCreateResponseVerificationResultEnum {
    SUCCESS = 'success',
    DUPLICATE = 'duplicate',
    FAILED = 'failed'
}


