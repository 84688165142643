import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Modal,
  Select,
  Switch,
  message,
} from 'antd'
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { EventStep4Form, StepProps } from './types'
import { axiosInstance } from 'api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.less'
import cn from 'classnames'
import { GenericAsyncSelect } from 'components/AsyncSelect/GenericAsyncSelect'
import { getValue, prepareEABOption } from './helpers'
import MultiLanguageInputBase from 'components/MultiLanguageInputBase'
import MultiLanguageTextareaBase from 'components/MultiLanguageTextareaBase'
import {
  BillingSystemDto,
  EventBillingSystemDto,
  EventCreateRequestLanguagesEnum,
  EventCreateResponseLanguagesEnum,
  EventExtendedDto,
  EventExtendedDtoEventStatusEnum,
  EventPriceDto,
  PageBillingSystemDto,
  PageEventBillingSystemDto,
  PageEventPriceDto,
} from 'openapi'

import {
  handleEventBillingChange,
  handleEventsPricesChange,
} from './eventPricesHelpers'
import FloatNumberInput from '../../components/FloatNumberInput'
import FormItem from 'components/FormItem'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '@/contexts/AuthContext'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { prepareOption } from '@components/AsyncSelect/types'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'
import FloatInput from '@components/FloatInput'

const { Option } = Select

const getCurrencyItem = (
  item: EventExtendedDto,
  appLang: EventCreateRequestLanguagesEnum,
) => {
  if (item.currency) {
    return prepareOption(item.currency, appLang)
  }
  if (item.city?.country?.currency) {
    return prepareOption(item.city.country.currency, appLang)
  }
}

const StepTickets = ({
  onSuccess,
  item,
  setForm,
  disabled,
  errors,
  canEdit,
}: StepProps) => {
  const [form] = Form.useForm<EventStep4Form>()
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const [hiddenDescriptions, setHiddenDescription] = useState<boolean[]>([])
  const { isPartner } = useAuthContext()
  const [eventPrices, setEventPrices] = useState<EventPriceDto[]>([])
  const { id } = useParams<{ id: string }>()
  const { appLang } = useLanguageContext()
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()

  const itemCurrency = useMemo(
    () => (item.currency ? item.currency : item?.city?.country?.currency),
    [item],
  )

  const isPublished = useMemo(
    () => item.eventStatus === EventExtendedDtoEventStatusEnum.PUBLISHED,
    [item],
  )

  const [eventBillingSystems, setEventBillingSystems] = useState<
    EventBillingSystemDto[]
  >([])

  const [billingSystems, setBillingSystems] = useState<BillingSystemDto[]>([])

  const formattedItem = useMemo(
    () => ({
      ...item,
      vat: item.vat !== null ? item.vat : item.city?.country?.vatRate,
      serviceFee: item.serviceFee !== null ? item.serviceFee : 7,
      includeServiceFee: item.includeServiceFee || false,
      includeVat: item.includeVat || true,
      commissionRate:
        item.commissionRate !== null
          ? item.commissionRate
          : item.agency?.commissionRate,
      currencyId: getCurrencyItem(item, appLang),
      eventPrices,
      billingSystemId: eventBillingSystems.map((a) => prepareEABOption(a)),
    }),
    [item, eventBillingSystems, eventPrices, appLang],
  )

  useEffect(() => {
    const getData = async () => {
      const {
        data: { content: epContent },
      } = await axiosInstance.get<PageEventPriceDto>(
        `admin/event-prices/extended?query=event.id==${id}&size=100`,
      )
      setEventPrices(epContent || [])
      const {
        data: { content: ebsContent },
      } = await axiosInstance.get<PageEventBillingSystemDto>(
        `admin/event-billing-systems?query=event.id==${id}`,
      )
      setEventBillingSystems(ebsContent || [])

      const {
        data: { content: bsContent },
      } = await axiosInstance.get<PageBillingSystemDto>(`admin/billing-systems`)

      if (!bsContent?.length) {
        const {
          data: { content: bs },
        } = await axiosInstance.get<PageBillingSystemDto>(
          `admin/billing-systems`,
        )
        setBillingSystems(bs || [])
      } else {
        setBillingSystems(bsContent || [])
      }
      setLoading(false)
    }

    getData()
  }, [id, item])

  useEffect(() => {
    setForm(form)
  }, [form])

  const onFinish = useCallback(
    async (values: EventStep4Form) => {
      setSending(true)
      if (disabled && !canEdit) {
        onSuccess({})
        setSending(false)
        return
      }
      const dataToSend = {
        ...values,
        eventPrices: undefined,
        billingSystemId: undefined,
        currencyId: getValue(values.currencyId, true),
      }
      const { data } = await axiosInstance.put<EventExtendedDto>(
        `admin/events/step4/${id}`,
        dataToSend,
      )
      await handleEventsPricesChange(
        eventPrices,
        values.eventPrices,
        Number(id),
      )
      if (!isPartner) {
        await handleEventBillingChange(
          eventBillingSystems,
          values.billingSystemId,
          Number(id),
        )
      }

      onSuccess(data)
      setSending(false)
    },
    [eventPrices, eventBillingSystems, id, canEdit],
  )

  const handleEventPriceInactivateClick = useCallback(
    async (eventPrice: EventPriceDto, index: number) => {
      const isActive = eventPrice.active
      if (!isActive) {
        Modal.confirm({
          title: t`messages.activateCategory`,
          onOk: async () => {
            await axiosInstance.put(`admin/event-prices/${eventPrice.id}`, {
              active: true,
            })
            setEventPrices((old) => [
              ...old.map((ep) => {
                if (ep.id === eventPrice.id) {
                  return {
                    ...ep,
                    active: true,
                  }
                }
                return ep
              }),
            ])
            form.setFieldValue(`eventPrices[${index}].active`, true)
          },
        })
      } else {
        Modal.confirm({
          title: t`messages.inactivateCategory`,
          onOk: async () => {
            await axiosInstance.put(`admin/event-prices/${eventPrice.id}`, {
              active: false,
            })
            setEventPrices((old) => [
              ...old.map((ep) => {
                if (ep.id === eventPrice.id) {
                  return {
                    ...ep,
                    active: false,
                  }
                }
                return ep
              }),
            ])
            form.setFieldValue(`eventPrices[${index}].active`, false)
          },
        })
      }
    },
    [t],
  )

  const prices = Form.useWatch('eventPrices', form)

  const handleDonateChange = useCallback(async () => {
    if (!prices[0]?.donate) {
      const currencyName = itemCurrency && itemCurrency[`name${appLang}`]
      const textMessage = `${t`messages.minDonateMessage`} ${itemCurrency?.minimumValue} ${currencyName}`
      messageApi.open({
        type: 'info',
        content: textMessage,
        style: {
          marginTop: '40vh',
        },
      })
    }

    await form.validateFields()
  }, [prices])

  const getMinPriceValue = useMemo(() => {
    if (
      prices &&
      prices.length > 0 &&
      prices[0] !== undefined &&
      prices[0]?.donate
    ) {
      return itemCurrency?.minimumValue
    }

    return undefined
  }, [prices])

  return (
    <Card style={{ marginTop: '15px' }} loading={loading}>
      {contextHolder}
      {errors?.length && (
        <Alert
          message={
            <>
              {errors.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </>
          }
          type="error"
          showIcon
          className={cn(styles.fullWidth, styles.alert)}
        />
      )}
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={formattedItem}
        className={cn(styles.form, styles.formTriple)}
      >
        <GenericAsyncSelect
          path="admin/currencies"
          label={t`labels.Currency`}
          name="currencyId"
          withAll={false}
          required
          width="100%"
          controlProps={{ allowClear: true, disabled: disabled || isPartner }}
        />

        <div />
        <div />
        {!isPartner && (
          <>
            <FormItem
              label={t`messages.Price incl VAT`}
              name="includeVat"
              disabled={disabled}
              valuePropName="checked"
            >
              <Switch defaultChecked={item.includeVat} />
            </FormItem>
            <FormItem
              label={t`labels.Price incl service fee`}
              name="includeServiceFee"
              disabled={disabled}
              valuePropName="checked"
            >
              <Switch defaultChecked={item.includeServiceFee} />
            </FormItem>
            <div />
            <FormItem name="vat" disabled={disabled}>
              <FloatNumberInput label={t`labels.VAT rate`} min={0} required />
            </FormItem>
            <FormItem name="serviceFee" disabled={disabled}>
              <FloatNumberInput
                label={t`labels.Service fee`}
                min={0}
                required
              />
            </FormItem>
            {!isPartner && (
              <FormItem name="commissionRate" disabled={disabled}>
                <FloatNumberInput
                  label={t`labels.Commission Rate`}
                  min={0}
                  required
                />
              </FormItem>
            )}
            <FormItem
              disabled={disabled}
              name="billingSystemId"
              className={styles.fullWidth}
            >
              <Select mode="multiple" labelInValue>
                {billingSystems
                  .filter(
                    (bs) =>
                      !eventBillingSystems
                        .map((ebs) => ebs?.billingSystem?.id)
                        .includes(bs.id),
                  )
                  .map((bs) => (
                    <Option value={String(bs.id)} key={`option-${bs.id}`}>
                      {`${bs?.code}`}
                    </Option>
                  ))}
              </Select>
            </FormItem>
          </>
        )}
        <Divider className={styles.fullWidthTriple} />
        <div className={styles.fullWidthDouble}>
          <Form.List name="eventPrices">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key}>
                    <div className={styles.categoryWrapper}>
                      <div>
                        {isPublished && eventPrices[index] && (
                          <>
                            <div className={styles.activateCategory}>
                              <div className={styles.activateCategoryTopRow}>
                                <h4>
                                  {eventPrices[index].active
                                    ? t`labels.activeCategory`
                                    : t`labels.inactiveCategory`}
                                </h4>
                                <FormItem disabled={!canEdit} {...restField}>
                                  <Switch
                                    checked={eventPrices[index].active}
                                    onClick={() =>
                                      handleEventPriceInactivateClick(
                                        eventPrices[index],
                                        index,
                                      )
                                    }
                                  />
                                </FormItem>
                              </div>
                              <div
                                className={styles.inactivateDescription}
                              >{t`labels.Inactive category description`}</div>
                            </div>
                          </>
                        )}
                        <div
                          key={key}
                          className={cn(
                            styles.formCategory,
                            styles.fullWidthTriple,
                          )}
                        >
                          <MultiLanguageInputBase
                            itemProps={{
                              name: [name, 'name'],
                              hasFeedback: true,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    t`form.validation.required` as string,
                                },
                              ],
                              disabled: !canEdit,
                              className: styles.fullWidthDouble,
                              ...restField,
                            }}
                            inputProps={{
                              label: t`labels.Ticket category name`,
                              required: true,
                            }}
                            languages={castType<
                              EventCreateResponseLanguagesEnum[]
                            >(item.languages)}
                            form={form}
                            listName="eventPrices"
                          />
                          <div
                            className={styles.fullWidthDivider}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '200px 200px 1fr',
                              width: '100%',
                            }}
                          >
                            <FormItem
                              label={t`labels.External`}
                              name={[name, 'external']}
                              disabled={prices?.[index]?.online}
                              valuePropName="checked"
                            >
                              <Switch
                                defaultChecked={eventPrices[index]?.external}
                              />
                            </FormItem>
                            <FormItem
                              name={[name, 'online']}
                              valuePropName="checked"
                              label={t`labels.isOnlineCategory`}
                              disabled={prices?.[index]?.external}
                            >
                              <Switch
                                defaultChecked={eventPrices[index]?.online}
                              />
                            </FormItem>
                            <FormItem
                              {...restField}
                              name={[name, 'link']}
                              rules={[
                                {
                                  required:
                                    prices?.[index]?.online ||
                                    prices?.[index]?.external,
                                  message:
                                    t`form.validation.required` as string,
                                },
                                {
                                  type: 'url',
                                  message: 'This field must be a valid url.',
                                },
                              ]}
                              disabled={
                                !prices?.[index]?.online &&
                                !prices?.[index]?.external
                              }
                            >
                              <FloatInput label={t`labels.translationLink`} />
                            </FormItem>
                          </div>
                          <div
                            className={styles.fullWidthDivider}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '120px 120px 120px 1fr',
                              gap: '16px',
                              width: '100%',
                            }}
                          >
                            <FormItem
                              {...restField}
                              name={[name, 'quantity']}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    t`form.validation.required` as string,
                                },
                              ]}
                              disabled={!canEdit}
                            >
                              <FloatNumberInput
                                label={t`labels.Quantity`}
                                min={1}
                              />
                            </FormItem>
                            <FormItem
                              {...restField}
                              name={[name, 'price']}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    t`form.validation.required` as string,
                                },
                                {
                                  type: 'number',
                                  min: getMinPriceValue,
                                  message: `${t`messages.minDonateValidation`} ${itemCurrency?.minimumValue}`,
                                },
                              ]}
                              disabled={!canEdit}
                            >
                              <FloatNumberInput
                                label={t`labels.Price`}
                                min={0}
                              />
                            </FormItem>
                            <FormItem
                              label={t`labels.Donation`}
                              name={[name, 'donate']}
                              valuePropName="checked"
                              disabled={prices?.[index]?.external}
                            >
                              <Switch
                                defaultChecked={eventPrices[index]?.donate}
                                onChange={handleDonateChange}
                              />
                            </FormItem>
                            {(eventPrices[index]?.donate ||
                              prices?.[0]?.donate) && (
                              <Alert
                                message={t`messages.donateInfoMessage`}
                                type="info"
                                showIcon
                                style={{ height: '45px' }}
                              />
                            )}
                          </div>
                          <div className={styles.fullWidthDivider} />
                          <h4
                            className={styles.fullWidthDivider}
                            style={{ marginBottom: '15px' }}
                          >
                            {t`labels.Description`}
                            {hiddenDescriptions[index] ? (
                              <PlusCircleOutlined
                                onClick={() => {
                                  setHiddenDescription((old) => {
                                    let oldVal = [...old]
                                    oldVal[index] = !oldVal[index]
                                    return oldVal
                                  })
                                }}
                                className={cn(styles.deleteCategory)}
                                style={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <MinusCircleOutlined
                                onClick={() => {
                                  setHiddenDescription((old) => {
                                    let oldVal = [...old]
                                    oldVal[index] = !oldVal[index]
                                    return oldVal
                                  })
                                }}
                                className={cn(styles.deleteCategory)}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          </h4>
                          {!hiddenDescriptions[index] && (
                            <MultiLanguageTextareaBase
                              itemProps={{
                                name: [name, 'description'],
                                hasFeedback: true,
                                className: styles.fullWidthTriple,
                                disabled: !canEdit,
                                rules: [
                                  {
                                    max: 256,
                                    message: t('form.validation.max', {
                                      number: 256,
                                    }) as string,
                                  },
                                ],
                                ...restField,
                              }}
                              inputProps={{
                                label: t`labels.Category Description`,
                              }}
                              form={form}
                              languages={castType<
                                EventCreateRequestLanguagesEnum[]
                              >(item.languages)}
                              listName="eventPrices"
                            />
                          )}
                        </div>
                      </div>
                      <Button
                        type="primary"
                        onClick={() => remove(name)}
                        shape="circle"
                        icon={<DeleteOutlined />}
                        danger
                        disabled={disabled}
                      />
                    </div>
                    <Divider className={styles.fullWidthDivider} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    onClick={() => add()}
                    type="primary"
                    icon={<PlusOutlined />}
                    disabled={!canEdit}
                  >
                    {t`labels.Category`}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <div className={styles.fullWidthTriple}>
          <Divider />
          <div className={styles.buttonBlock}>
            <Button
              htmlType="button"
              onClick={() => form.resetFields()}
              disabled={sending}
            >
              {t`cancel`}
            </Button>
            <Form.Item
              style={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Button type="primary" htmlType="submit" disabled={sending}>
                {t`labels.Next`}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Card>
  )
}

export default StepTickets
