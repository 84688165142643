/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AgencyCreateRequest } from '../models';
// @ts-ignore
import { AgencyCreateResponse } from '../models';
// @ts-ignore
import { AgencyExtendedDto } from '../models';
// @ts-ignore
import { AgencyUpdateRequest } from '../models';
// @ts-ignore
import { AgencyUpdateResponse } from '../models';
// @ts-ignore
import { MediaFileCreateRequest } from '../models';
// @ts-ignore
import { MediaFileCreateResponse } from '../models';
// @ts-ignore
import { PageAgencyDto } from '../models';
// @ts-ignore
import { PageAgencyExtendedDto } from '../models';
// @ts-ignore
import { PageAudit } from '../models';
// @ts-ignore
import { PageMediaFileDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * AgenciesAdminControllerApi - axios parameter creator
 * @export
 */
export const AgenciesAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<AgencyCreateRequest>} agencyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate39: async (agencyCreateRequest: Array<AgencyCreateRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyCreateRequest' is not null or undefined
            assertParamExists('bulkCreate39', 'agencyCreateRequest', agencyCreateRequest)
            const localVarPath = `/v1/admin/agencies/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agencyCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgencyCreateRequest} agencyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create46: async (agencyCreateRequest: AgencyCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyCreateRequest' is not null or undefined
            assertParamExists('create46', 'agencyCreateRequest', agencyCreateRequest)
            const localVarPath = `/v1/admin/agencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agencyCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} entityId 
         * @param {MediaFileCreateRequest} mediaFileCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create47: async (entityId: number, mediaFileCreateRequest: MediaFileCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('create47', 'entityId', entityId)
            // verify required parameter 'mediaFileCreateRequest' is not null or undefined
            assertParamExists('create47', 'mediaFileCreateRequest', mediaFileCreateRequest)
            const localVarPath = `/v1/admin/agencies/{entityId}/media`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediaFileCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} entityId 
         * @param {number} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete6: async (entityId: number, mediaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('delete6', 'entityId', entityId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('delete6', 'mediaId', mediaId)
            const localVarPath = `/v1/admin/agencies/{entityId}/media/{mediaId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"mediaId"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById40: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById40', 'id', id)
            const localVarPath = `/v1/admin/agencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find79: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find79', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/agencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find80: async (pageRequest: Pageable, entityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find80', 'pageRequest', pageRequest)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('find80', 'entityId', entityId)
            const localVarPath = `/v1/admin/agencies/{entityId}/media`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended39: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findExtended39', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/agencies/extended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById61: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById61', 'id', id)
            const localVarPath = `/v1/admin/agencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount66: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/agencies/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit39: async (pageable: Pageable, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getEntityAudit39', 'pageable', pageable)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit39', 'id', id)
            const localVarPath = `/v1/admin/agencies/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties39: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/agencies/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessTranslations39ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations39FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations39: async (toLanguage: ProcessTranslations39ToLanguageEnum, fromLanguage?: ProcessTranslations39FromLanguageEnum, forceTranslate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toLanguage' is not null or undefined
            assertParamExists('processTranslations39', 'toLanguage', toLanguage)
            const localVarPath = `/v1/admin/agencies/i18n/from/{fromLanguage}/to/{toLanguage}`
                .replace(`{${"toLanguage"}}`, encodeURIComponent(String(toLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromLanguage !== undefined) {
                localVarQueryParameter['fromLanguage'] = fromLanguage;
            }

            if (forceTranslate !== undefined) {
                localVarQueryParameter['forceTranslate'] = forceTranslate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull39: async (id: number, properties: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPropertiesToNull39', 'id', id)
            // verify required parameter 'properties' is not null or undefined
            assertParamExists('setPropertiesToNull39', 'properties', properties)
            const localVarPath = `/v1/admin/agencies/{id}/property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (properties) {
                localVarQueryParameter['properties'] = Array.from(properties);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AgencyUpdateRequest} agencyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update39: async (id: number, agencyUpdateRequest: AgencyUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update39', 'id', id)
            // verify required parameter 'agencyUpdateRequest' is not null or undefined
            assertParamExists('update39', 'agencyUpdateRequest', agencyUpdateRequest)
            const localVarPath = `/v1/admin/agencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agencyUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgenciesAdminControllerApi - functional programming interface
 * @export
 */
export const AgenciesAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgenciesAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<AgencyCreateRequest>} agencyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreate39(agencyCreateRequest: Array<AgencyCreateRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgencyCreateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreate39(agencyCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AgencyCreateRequest} agencyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create46(agencyCreateRequest: AgencyCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create46(agencyCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} entityId 
         * @param {MediaFileCreateRequest} mediaFileCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create47(entityId: number, mediaFileCreateRequest: MediaFileCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaFileCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create47(entityId, mediaFileCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} entityId 
         * @param {number} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete6(entityId: number, mediaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete6(entityId, mediaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById40(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById40(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find79(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAgencyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find79(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find80(pageRequest: Pageable, entityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMediaFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find80(pageRequest, entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExtended39(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAgencyExtendedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExtended39(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById61(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyExtendedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById61(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount66(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount66(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit39(pageable: Pageable, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit39(pageable, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nSupportedProperties39(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nSupportedProperties39(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessTranslations39ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations39FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTranslations39(toLanguage: ProcessTranslations39ToLanguageEnum, fromLanguage?: ProcessTranslations39FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTranslations39(toLanguage, fromLanguage, forceTranslate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPropertiesToNull39(id: number, properties: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPropertiesToNull39(id, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AgencyUpdateRequest} agencyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update39(id: number, agencyUpdateRequest: AgencyUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update39(id, agencyUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgenciesAdminControllerApi - factory interface
 * @export
 */
export const AgenciesAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgenciesAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<AgencyCreateRequest>} agencyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate39(agencyCreateRequest: Array<AgencyCreateRequest>, options?: any): AxiosPromise<Array<AgencyCreateResponse>> {
            return localVarFp.bulkCreate39(agencyCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AgencyCreateRequest} agencyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create46(agencyCreateRequest: AgencyCreateRequest, options?: any): AxiosPromise<AgencyCreateResponse> {
            return localVarFp.create46(agencyCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} entityId 
         * @param {MediaFileCreateRequest} mediaFileCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create47(entityId: number, mediaFileCreateRequest: MediaFileCreateRequest, options?: any): AxiosPromise<MediaFileCreateResponse> {
            return localVarFp.create47(entityId, mediaFileCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} entityId 
         * @param {number} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete6(entityId: number, mediaId: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete6(entityId, mediaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById40(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById40(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find79(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageAgencyDto> {
            return localVarFp.find79(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find80(pageRequest: Pageable, entityId: number, options?: any): AxiosPromise<PageMediaFileDto> {
            return localVarFp.find80(pageRequest, entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended39(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageAgencyExtendedDto> {
            return localVarFp.findExtended39(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById61(id: number, options?: any): AxiosPromise<AgencyExtendedDto> {
            return localVarFp.getById61(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount66(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount66(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit39(pageable: Pageable, id: number, options?: any): AxiosPromise<PageAudit> {
            return localVarFp.getEntityAudit39(pageable, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties39(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getI18nSupportedProperties39(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessTranslations39ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations39FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations39(toLanguage: ProcessTranslations39ToLanguageEnum, fromLanguage?: ProcessTranslations39FromLanguageEnum, forceTranslate?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processTranslations39(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull39(id: number, properties: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPropertiesToNull39(id, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AgencyUpdateRequest} agencyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update39(id: number, agencyUpdateRequest: AgencyUpdateRequest, options?: any): AxiosPromise<AgencyUpdateResponse> {
            return localVarFp.update39(id, agencyUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgenciesAdminControllerApi - object-oriented interface
 * @export
 * @class AgenciesAdminControllerApi
 * @extends {BaseAPI}
 */
export class AgenciesAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<AgencyCreateRequest>} agencyCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public bulkCreate39(agencyCreateRequest: Array<AgencyCreateRequest>, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).bulkCreate39(agencyCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AgencyCreateRequest} agencyCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public create46(agencyCreateRequest: AgencyCreateRequest, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).create46(agencyCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} entityId 
     * @param {MediaFileCreateRequest} mediaFileCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public create47(entityId: number, mediaFileCreateRequest: MediaFileCreateRequest, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).create47(entityId, mediaFileCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} entityId 
     * @param {number} mediaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public delete6(entityId: number, mediaId: number, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).delete6(entityId, mediaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public deleteById40(id: number, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).deleteById40(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public find79(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).find79(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {number} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public find80(pageRequest: Pageable, entityId: number, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).find80(pageRequest, entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public findExtended39(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).findExtended39(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public getById61(id: number, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).getById61(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public getCount66(query?: string, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).getCount66(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public getEntityAudit39(pageable: Pageable, id: number, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).getEntityAudit39(pageable, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public getI18nSupportedProperties39(options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).getI18nSupportedProperties39(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessTranslations39ToLanguageEnum} toLanguage 
     * @param {ProcessTranslations39FromLanguageEnum} [fromLanguage] 
     * @param {boolean} [forceTranslate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public processTranslations39(toLanguage: ProcessTranslations39ToLanguageEnum, fromLanguage?: ProcessTranslations39FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).processTranslations39(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} properties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public setPropertiesToNull39(id: number, properties: Array<string>, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).setPropertiesToNull39(id, properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AgencyUpdateRequest} agencyUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgenciesAdminControllerApi
     */
    public update39(id: number, agencyUpdateRequest: AgencyUpdateRequest, options?: AxiosRequestConfig) {
        return AgenciesAdminControllerApiFp(this.configuration).update39(id, agencyUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations39ToLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations39FromLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
