import { Card, Tabs } from 'antd'
import { useMemo, useState } from 'react'
import { StepProps } from './types'
import useAsyncGet from '@/hooks/useAsyncGet'
import { useParams, useSearchParams } from 'react-router-dom'
import { EventTourViewDto } from '@/openapi'
import CalendarTab from '@pages/tour/CalendarTab'
import TableTab from '@pages/tour/TableTab'
import { useTranslation } from 'react-i18next'

const StepSchedule = ({ item }: StepProps) => {
  const { id } = useParams<{ id: string }>()
  const [search, setSearch] = useSearchParams()
  const [view, setView] = useState<string>(search.get('view') || 'table-view')
  const { t } = useTranslation()
  const {
    data: events,
    refetch,
    loaded,
  } = useAsyncGet<EventTourViewDto[]>(`admin/tours/${id}/events`)

  const items = useMemo(
    () => [
      {
        label: t`labels.Calendar View`,
        key: 'calendar-view',
        children: <CalendarTab item={item} refetch={refetch} events={events} />,
      },
      {
        label: t`labels.Table View`,
        key: 'table-view',
        children: <TableTab item={item} refetch={refetch} events={events} />,
      },
    ],
    [events, refetch, item, t],
  )

  return (
    <Card style={{ marginTop: '15px' }} loading={!loaded}>
      <Tabs
        type="card"
        destroyInactiveTabPane={true}
        items={items}
        tabBarStyle={{ margin: 0 }}
        activeKey={view}
        onChange={(k) => {
          search.set('view', k)
          setSearch(search, { replace: true })
          setView(k)
        }}
      />
    </Card>
  )
}

export default StepSchedule
