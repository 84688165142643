/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MarketingLinkUpdateRequest
 */
export interface MarketingLinkUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof MarketingLinkUpdateRequest
     */
    'eventId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'lang'?: MarketingLinkUpdateRequestLangEnum;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'device'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'utmCampaign'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'utmMedium'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'utmSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'utmTerm'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'utmContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingLinkUpdateRequest
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingLinkUpdateRequest
     */
    'budget'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketingLinkUpdateRequest
     */
    'budgetCurrencyId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MarketingLinkUpdateRequestLangEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}


