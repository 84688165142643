import { useCallback, useState } from 'react'
import FloatInput, { FloatInputProps } from '../FloatInput'
import { EventCreateResponseLanguagesEnum } from '@/openapi'
import LanguagesEnum = EventCreateResponseLanguagesEnum
import { NamePath } from 'antd/es/form/interface'
import FormItem, { CustomFormItemProps } from 'components/FormItem'
import { FormInstance, Spin } from 'antd'
import { TranslationOutlined } from '@ant-design/icons'
import { axiosInstance } from '@/api'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
type MultiLanguageInputProps<T> = {
  itemProps: CustomFormItemProps
  inputProps: FloatInputProps
  languages: LanguagesEnum[]
  form: FormInstance<T>
  listName?: string
}

export const generateName = (name: NamePath, lang: LanguagesEnum) => {
  if (Array.isArray(name)) {
    const [index, realName] = name
    return [index, `${realName}${lang}`]
  } else {
    return `${name}${lang}`
  }
}

export const generateKey = (name: NamePath, lang: LanguagesEnum) => {
  if (Array.isArray(name)) {
    const [_, realName] = name
    return `${realName}${lang}`
  } else {
    return `${name}${lang}`
  }
}

const MultiLanguageInputBase = <T,>({
  itemProps,
  inputProps,
  languages = [],
  form,
  listName,
}: MultiLanguageInputProps<T>) => {
  const name = itemProps.name!
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const handleTranslateClick = useCallback(
    async (lang: LanguagesEnum, fieldName: string) => {
      if (!form) return
      let value: string | undefined
      let selector: string | Array<any> = fieldName
      if (Array.isArray(fieldName)) {
        selector = [listName, fieldName[0], fieldName[1]]
        value = form.getFieldValue(selector)
      } else {
        value = form.getFieldValue(selector)
      }
      setLoading(true)
      try {
        const { data } = await axiosInstance.post<
          Record<LanguagesEnum, string>
        >(`${lang}/from`, value, {
          headers: {
            'Content-Type': 'text/plain',
          },
        })

        const updatedFields = Object.entries(data).map(([key, value]) => {
          let fullName = generateName(name, key as never as LanguagesEnum)
          if (Array.isArray(fullName) && listName) {
            fullName.unshift(listName)
          }

          return { name: fullName, value, errors: [] }
        })
        form.setFields(updatedFields)
      } catch {
        toast.error(t`messages.cantTranslate`)
      }

      setLoading(false)
    },
    [form, name],
  )

  return (
    <>
      {languages.map((lang) => (
        <Spin
          spinning={loading}
          delay={500}
          key={generateKey(name, lang)}
          wrapperClassName={itemProps.className}
        >
          <FormItem
            {...itemProps}
            name={generateName(name, lang)}
            key={generateKey(name, lang)}
          >
            <FloatInput
              {...inputProps}
              label={`${inputProps.label} (${lang})`}
              addonAfter={
                form &&
                languages.length > 1 && (
                  <TranslationOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleTranslateClick(
                        lang,
                        generateName(name, lang) as string,
                      )
                    }}
                  />
                )
              }
            />
          </FormItem>
        </Spin>
      ))}
    </>
  )
}

export default MultiLanguageInputBase
