import { Form } from 'antd'
import Page from 'components/Page'
import {
  CountryDto,
  ChannelsSalesStatisticsView,
  PageImplChannelsSalesStatisticsView,
  EventDto,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import commonStyles from '../../commonStyles.module.less'
import { useAntdTable } from 'ahooks'
import { Params } from '@/types/Params'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { SelectOption } from '@/types/Option'
import {
  DownloadReportButton,
  useLastParams,
} from '@pages/reports/shared/DownloadReportButton'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'
import useIsMobile from '@/hooks/useIsMobile'

const renderEventLabel = (event: EventDto) => {
  let label = String(event.id)
  if ((event.name?.length || 0) > 15) {
    label += ` - ${event.name?.slice(0, 15)}...`
  } else {
    label += ` - ${event.name}`
  }
  if (event.city?.name) {
    label += ` - ${event.city.name}`
  }
  return label
}

const GenericReportByChannels = ({
  externalEventId,
}: {
  externalEventId?: string
}) => {
  const [form] = Form.useForm()
  const { getSort, getQuery, setLastSort, setLastQuery } = useLastParams()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<CountryDto>
    },
    filters: { eventId?: SelectOption[] },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (externalEventId) query.push(`eventId==${externalEventId}`)
    if (filters.eventId?.length) {
      query.push(
        `eventId~in~${filters.eventId.map(({ value }) => value).toString()}`,
      )
    }

    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
      setLastSort({ sort: params.sort })
    } else {
      setLastSort(null)
    }
    params.query = query.join(';')
    setLastQuery(query.join(';'))
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageImplChannelsSalesStatisticsView>(
      'admin/statistics/channels/view',
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const columns: ExtendedColumns<ChannelsSalesStatisticsView>[] = [
    ...(!externalEventId
      ? [
          {
            title: t`labels.Event`,
            dataIndex: 'eventId',
            key: 'eventId',
            sorter: true,
            fixed: 'left',
          } as ExtendedColumns<ChannelsSalesStatisticsView>,
        ]
      : []),
    {
      title: t`labels.Channel`,
      dataIndex: 'channel',
      key: 'channel',
      sorter: true,
    },
    {
      title: t`labels.Purchased Tickets`,
      dataIndex: 'soldTicketsCount',
      key: 'soldTicketsCount',
      sorter: true,
    },
    {
      title: t`labels.Sales amount excluding sf`,
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      render: (_, record) => (record.salesNet || 0) + (record.salesVat || 0),
    },
    {
      title: t`labels.Billing Service Fee`,
      dataIndex: 'billingServiceFee',
      key: 'billingServiceFee',
      sorter: true,
    },
    {
      title: t`labels.Sales Service Fee`,
      dataIndex: 'salesServiceFee',
      key: 'salesServiceFee',
      sorter: true,
    },
    {
      title: t`labels.Returned Tickets Count`,
      dataIndex: 'returnedTicketsCount',
      key: 'returnedTicketsCount',
      sorter: true,
    },
    {
      title: t`labels.Returns Net`,
      dataIndex: 'returnsNet',
      key: 'returnsNet',
      sorter: true,
    },
    {
      title: t`labels.Returns Vat`,
      dataIndex: 'returnsVat',
      key: 'returnsVat',
      sorter: true,
    },
    {
      title: t`labels.Returns Service Fee`,
      dataIndex: 'returnsServiceFee',
      key: 'returnsServiceFee',
      sorter: true,
    },
  ]

  return (
    <Page inner title={t`labels.Sales channels report`}>
      <Form form={form}>
        <div
          style={{
            justifyContent: externalEventId ? 'flex-end' : 'space-between',
            display: 'flex',
            marginBottom: '20px',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {!externalEventId && (
            <GenericAsyncSelect<EventDto>
              path="admin/events"
              label=""
              name="eventId"
              withAll={false}
              width={isMobile ? '100%' : '350px'}
              submit={submit}
              controlProps={{ allowClear: true, mode: 'multiple' }}
              customPrepareOption={(event) => ({
                value: String(event.id),
                label: renderEventLabel(event),
              })}
            />
          )}
          <DownloadReportButton
            entity="channels"
            getQuery={getQuery}
            getParams={getSort}
            style={{ width: isMobile ? '100%' : 'auto' }}
          />
        </div>
        <DragTable<ChannelsSalesStatisticsView>
          rowKey={(record) => String(record.eventId + (record.channel || ''))}
          bordered
          actionsMobile
          columns={columns}
          className={commonStyles.table}
          rowClassName={commonStyles.row}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default GenericReportByChannels
