import { ChangeEventHandler, useState } from 'react'
import { Input, InputProps } from 'antd'
import cn from 'classnames'
import styles from './index.module.less'

export type FloatInputProps = {
  onChange?: ChangeEventHandler<HTMLInputElement>
  label: string
  alwaysFloat?: boolean
} & Partial<InputProps>

const FloatInput = (props: FloatInputProps) => {
  const [focus, setFocus] = useState(false)
  let {
    label,
    value,
    placeholder,
    type,
    required,
    alwaysFloat = false,
    ...rest
  } = props

  if (!placeholder) placeholder = label

  const isOccupied =
    alwaysFloat || focus || (value && (value as string).length !== 0)

  const labelClass = cn(
    styles.label,
    isOccupied ? styles.asLabel : styles.asPlaceholder,
  )

  const requiredMark = required ? (
    <span className="text-danger">{'*'}</span>
  ) : null

  return (
    <div
      className={styles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <Input
        onChange={props.onChange}
        type={type}
        value={value}
        {...rest}
        className={cn(styles.inputAnimation, rest.className)}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatInput
