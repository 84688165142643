import React from 'react'
import AuthProvider from './AuthContext'
import LanguageProvider from './LanguageProvider'
import FormProvider from '@/contexts/FormProvider'

interface ComposeProps {
  components: React.JSXElementConstructor<React.PropsWithChildren<any>>[]
  children: React.ReactNode
}

const Compose: React.FC<ComposeProps> = (props) => {
  const { components = [], children } = props

  return (
    <>
      {components.reduceRight((acc, ComponentItem) => {
        return <ComponentItem>{acc}</ComponentItem>
      }, children)}
    </>
  )
}

const SingletonProviders = ({ children }: React.PropsWithChildren) => {
  return (
    <Compose components={[AuthProvider, LanguageProvider, FormProvider]}>
      {children}
    </Compose>
  )
}

export default SingletonProviders
