import React, { useEffect, useMemo } from 'react'
import './App.less'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useLanguageContext } from './contexts/LanguageProvider'
import Login from './pages/login'
import Error from './pages/404/404'
import { MainLayout } from '@components/Layout/MainLayout'
import Countries from './pages/countries'
import Cities from './pages/cities'
import User from './pages/users'
import Stage from 'pages/stages'
import Billing from './pages/billing'
import Agencies from './pages/agencies'
import ActorTypes from './pages/actorTypes'
import EventTypes from './pages/eventTypes'
import Actors from './pages/actors'
import Currencies from './pages/currencies'
import Event from './pages/event'
import EventsList from './pages/eventList'
import TicketOperators from './pages/ticketOperators'
import LoginCredentialsPage from './pages/loginCredentials'
import { ConfigProvider } from 'antd'
import EN from 'antd/es/locale/en_US'
import RU from 'antd/es/locale/ru_RU'
import PL from 'antd/es/locale/pl_PL'
import UA from 'antd/es/locale/uk_UA'
import BY from 'antd/es/locale/by_BY'
import { StageDefinitions } from '@pages/stageDefinitions'
import StageDefinitionsList from './pages/stageDefinitions/StageDefinitionsList'
import { isLoggedIn } from 'axios-jwt'
import TicketControlPerson from '@pages/ticketControlPersons'
import ManageEvent from '@pages/event/manage'
import EventProvider from '@/contexts/EventContext'
import { useAuthContext } from '@/contexts/AuthContext'
import { AdminOutlet } from '@components/Auth/AdminOutlet'
import Dashboard from './pages/dashboard/Dashboard'
import { Locale } from 'antd/es/locale'
import ManageUsers from '@pages/users/manage'
import TourList from '@pages/tourList'
import Tour from '@pages/tour'
import SalesReportByCountries from '@pages/reports/salesReportByCountries'
import AgencyReport from '@pages/reports/agencyReport'
import SalesReportByChannels from '@pages/reports/salesReportByChannels'
import PictureTypes from '@pages/pictureTypes'
import SalesByDates from '@pages/reports/salesByDates'
import SocialMedia from '@pages/socialMedia'
import { themeConfig } from './theme'
import TransferEvent from '@pages/transferEvent'
import EventGroups from '@pages/eventGroups'
import ManageEventsGroup from '@pages/eventGroups/manage'
import { resetSessionSetting } from '@/utils/sessionSettings'

function RequireAuth({ children }: { children: React.JSX.Element }) {
  const location = useLocation()
  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

const languageMapping: { [key: string]: Locale } = {
  EN,
  RU,
  PL,
  BY,
  UA,
}

const App = () => {
  const { appLang } = useLanguageContext()
  const { allLoaded } = useAuthContext()
  const providerLanguage = useMemo(() => languageMapping[appLang], [appLang])
  useEffect(() => {
    resetSessionSetting()
  }, [])

  if (!allLoaded) return null

  return (
    <ConfigProvider locale={providerLanguage} theme={themeConfig}>
      <Routes>
        <Route path="/" element={<Navigate to="/app" replace />} />
        <Route path="/app" element={<Navigate to="/app/dashboard" replace />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="app"
          element={
            <RequireAuth>
              <MainLayout />
            </RequireAuth>
          }
        >
          <Route element={<AdminOutlet />}>
            <Route path="currency" element={<Currencies />} />
            <Route path="agencies" element={<Agencies />} />
            <Route path="billing" element={<Billing />} />
            <Route path="users" element={<User />} />
            <Route
              path="login-credentials"
              element={<LoginCredentialsPage />}
            />
          </Route>
          <Route path="countries" element={<Countries />} />
          <Route path="cities" element={<Cities />} />
          <Route path="stage-definitions">
            <Route path="" element={<StageDefinitionsList />} />
            <Route path="create" element={<StageDefinitions />} />
            <Route path=":id" element={<StageDefinitions />} />
          </Route>
          <Route path="users/:id/manage" element={<ManageUsers />} />
          <Route path="stages" element={<Stage />} />
          <Route path="eventTypes" element={<EventTypes />} />
          <Route path="events_groups" element={<EventGroups />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="actorTypes" element={<ActorTypes />} />
          <Route path="actors" element={<Actors />} />
          <Route
            path="ticketControlPersons"
            element={<TicketControlPerson />}
          />
          <Route path="ticketOperators" element={<TicketOperators />} />
          <Route path="event/:id/manage" element={<ManageEvent />} />
          <Route path="eventGroup/:id/manage" element={<ManageEventsGroup />} />
          <Route
            path="event/:id"
            element={
              <EventProvider>
                <Event />
              </EventProvider>
            }
          />
          <Route path="tour/:id" element={<Tour />} />
          <Route path="tours_list" element={<TourList />} />
          <Route
            path="sales_report_by_countries"
            element={<SalesReportByCountries />}
          />
          <Route
            path="sales_report_by_channels"
            element={<SalesReportByChannels />}
          />
          <Route path="agency_report" element={<AgencyReport />} />
          <Route path="sales_by_dates" element={<SalesByDates />} />
          <Route path="picture-types" element={<PictureTypes />} />
          <Route path="social-medias" element={<SocialMedia />} />
          <Route
            path="events_published"
            element={<EventsList value="published" />}
          />
          <Route path="event_transfer" element={<TransferEvent />} />
          <Route
            path="events_announced"
            element={<EventsList value="announced" />}
          />
          <Route
            path="events_unpublished"
            element={<EventsList value="unpublished" />}
          />
          <Route
            path="events_archived"
            element={<EventsList value="archived" />}
          />
          <Route
            path="events_canceled"
            element={<EventsList value="canceled" />}
          />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </ConfigProvider>
  )
}

export default App
