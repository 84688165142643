import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import FormItem from '@components/FormItem'
import { useParams } from 'react-router-dom'
import FloatInput from '@components/FloatInput'
import { CurrencyDto, LanguageDto } from '@/openapi'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { useTranslation } from 'react-i18next'
import FloatNumberInput from '@components/FloatNumberInput'
import { prepareOption } from '@components/AsyncSelect/types'
import { useLanguageContext } from '@/contexts/LanguageProvider'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

type MLFormProps = {
  onSuccess: () => void
  id?: number
  entity: 'event' | 'group'
}

const field = {
  event: 'eventId',
  group: 'eventGroupId',
}

const PromoLinkForm = ({ onSuccess, id, entity }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { id: entityId } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { appLang } = useLanguageContext()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/links/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            ...(data.budgetCurrency
              ? {
                  budgetCurrencyId: prepareOption(data.budgetCurrency, appLang),
                }
              : {}),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    try {
      if (id) {
        await axiosInstance.put(`admin/links/${id}`, {
          ...values,
          budgetCurrencyId:
            values.budgetCurrencyId.value || values.budgetCurrencyId,
        })
      } else {
        await axiosInstance.post('admin/links', {
          ...values,
          [field[entity]]: entityId,
        })
      }
      onSuccess()
    } catch (e) {
      console.log(e)
    }
  }

  if (loading) return null

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <GenericAsyncSelect<LanguageDto>
        name="lang"
        path="admin/languages"
        label={t`labels.Language`}
        withAll={false}
        searchField="name"
        customPrepareOption={({ code, name }) => ({
          value: code!,
          label: name!,
        })}
      />
      <FormItem name="source">
        <FloatInput label={t`labels.source`} />
      </FormItem>
      <FormItem name="device">
        <FloatInput label={t`labels.Device`} />
      </FormItem>
      <FormItem name="utmCampaign" rules={[{ required: true }]}>
        <FloatInput label="Utm Campaign" required />
      </FormItem>
      <FormItem name="utmMedium" rules={[{ required: true }]}>
        <FloatInput label="Utm Medium" required />
      </FormItem>
      <FormItem name="utmSource" rules={[{ required: true }]}>
        <FloatInput label="Utm Source" required />
      </FormItem>
      <FormItem name="utmTerm">
        <FloatInput label="Utm Term" />
      </FormItem>
      <FormItem name="utmContent">
        <FloatInput label="Utm Content" />
      </FormItem>
      <FormItem name="budget">
        <FloatNumberInput label={t`labels.budget`} />
      </FormItem>
      <GenericAsyncSelect<CurrencyDto>
        path="admin/currencies"
        label={t`labels.Currency`}
        withAll={false}
        width="100%"
        name="budgetCurrencyId"
        rules={[{ required: true }]}
        required
      />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PromoLinkForm
