import { Button, Divider, Form, TimePicker } from 'antd'
import { EventTourViewDto } from '@/openapi'
import FormItem from '@components/FormItem'
import { axiosInstance } from '@/api'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

type FormProps = {
  onSuccess: () => void
  event: EventTourViewDto
}

type CustomFormData = {
  time: Dayjs
}

const AddTimeForm = ({ onSuccess, event }: FormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const { t } = useTranslation()
  const onFinish = async (values: CustomFormData) => {
    const date = dayjs(event.dateStart).format('YYYY-MM-DD')
    const time = values.time.format('HH:mm:ss')
    const dataToSend = {
      id: event.id,
      dateStart: date + 'T' + time,
    }
    await axiosInstance.put(`admin/events/step2/${event.id}`, dataToSend)
    onSuccess()
  }

  return (
    <div>
      {event.city && <h3>{`${t`labels.City`} - ${event.city.name}`}</h3>}
      <Form form={form} onFinish={onFinish} style={{ marginTop: '20px' }}>
        <FormItem name="time" rules={[{ required: true }]}>
          <TimePicker showSecond={false} />
        </FormItem>
        <Divider />
        <Form.Item
          style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
        >
          <Button type="primary" htmlType="submit">
            {t`Submit`}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AddTimeForm
