import { useCallback, useState } from 'react'
import { Modal, Form, Button, Tag, Space } from 'antd'
import Page from 'components/Page'
import {
  TicketControlActivityDto,
  PageTicketControlActivityDto,
  TicketControlActivityDtoStatusEnum,
  EventExtendedDto,
  EventExtendedDtoEventStatusEnum,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@/components/DropOption/DropOption'
import { axiosInstance } from 'api'
import CountryFlag from '@components/CountryFlag/CountryFlag'
import { useAntdTable } from 'ahooks'
import TCAForm from './Form'

import { Params } from '@/types/Params'
import { SelectOption } from '@/types/Option'
import commonStyles from '@/pages/commonStyles.module.less'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import FloatInput from '@components/FloatInput'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = TicketControlActivityDto & {
  controlPersonId: SelectOption
  controlPersonName?: string
}

const statusToTag = (status: TicketControlActivityDtoStatusEnum) => {
  switch (status) {
    case TicketControlActivityDtoStatusEnum.VERIFIED:
      return <Tag color="green">{'VERIFIED'}</Tag>
    case TicketControlActivityDtoStatusEnum.FAILED:
      return <Tag color="red">{'FAILED'}</Tag>
    case TicketControlActivityDtoStatusEnum.REQUESTED:
      return <Tag color="geekblue">{'REQUESTED'}</Tag>
    case TicketControlActivityDtoStatusEnum.CANCELED:
      return <Tag color="orange">{'CANCELED'}</Tag>
    default:
      return null
  }
}

const TicketControlActivity = ({ event }: { event?: EventExtendedDto }) => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [qrLoading, setQrLoading] = useState(false)
  const [activeItem, setActiveItem] = useState<TicketControlActivityDto>()
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<TicketControlActivityDto>
    },
    filters: { [key in keyof CustomFormData]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [`event.id==${eventId}`]
    if (filters.controlPersonId && filters.controlPersonId !== 'All') {
      query.push(`controlPerson.id==${filters.controlPersonId}`)
    }
    if (filters.controlPersonName) {
      query.push(`controlPerson.name==${filters.controlPersonName}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageTicketControlActivityDto>(
      `admin/ticket-control-activities`,
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/ticket-control-activities/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback(
    (record: TicketControlActivityDto, e: any) => {
      if (e.key === '1') {
        setActiveItem(record)
        setIsOpenModal(true)
      } else if (e.key === '2') {
        Modal.confirm({
          title: t`Are you sure delete this record?`,
          onOk() {
            deleteItem(record.id!)
          },
        })
      }
    },
    [],
  )

  const columns: ExtendedColumns<TicketControlActivityDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: t`labels.Country`,
      dataIndex: 'country',
      key: 'country',
      render: (_, r) => (
        <div>
          {`${r.controlPerson?.country?.name} (+
          ${r.controlPerson?.country?.phoneCode})  `}
          <CountryFlag countryCode={r.controlPerson?.country?.code || ''} />
        </div>
      ),
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Controller phone number`}</div>
          <Form.Item name="controlPersonName">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'controlPerson',
      key: 'controlPerson',
      shortTitle: t`labels.Controller name`,
      render: (t) => <div>{t.phoneNumber}</div>,
    },
    {
      title: t`labels.Status`,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: statusToTag,
      noTooltip: true,
    },
    {
      title: t`labels.Start Date`,
      dataIndex: 'dateStart',
      key: 'dateStart',
      sorter: true,
      render: (val) => (
        <>
          <div>{dayjs(val).format('YYYY-MM-DD')}</div>
          <div style={{ fontSize: '12px' }}>
            {dayjs(val).format('HH:mm:ss')}
          </div>
        </>
      ),
    },
    {
      title: t`labels.End Date`,
      dataIndex: 'dateEnd',
      key: 'dateEnd',
      sorter: true,
      render: (val) => (
        <>
          <div>{dayjs(val).format('YYYY-MM-DD')}</div>
          <div style={{ fontSize: '12px' }}>
            {dayjs(val).format('HH:mm:ss')}
          </div>
        </>
      ),
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ]

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  const showQr = useCallback(async () => {
    setQrLoading(true)
    const { data } = await axiosInstance.get<Blob>(
      `/admin/ticket-control-persons/event-registration?eventId=${eventId}`,
      {
        responseType: 'blob',
      },
    )
    const src = URL.createObjectURL(data)

    setQrLoading(false)
    Modal.info({
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <img src={src} style={{ width: '100%' }} />
          <h3>{`EventId: ${event?.id}, PinCode: ${event?.pinCode}`}</h3>
        </div>
      ),
      icon: null,
    })
  }, [eventId, event])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<TicketControlActivityDto>
          bordered
          actions={
            <div
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Space wrap>
                <Button type="primary" onClick={() => setIsOpenModal(true)}>
                  {t`add`}
                </Button>
                <Button type="primary" onClick={refresh}>
                  {t`labels.Refresh`}
                </Button>
                {event?.eventStatus ===
                  EventExtendedDtoEventStatusEnum.UNPUBLISHED && (
                  <Link to={`/app/event/${eventId}`}>
                    <Button type="primary" onClick={refresh}>
                      {t`labels.Edit Event`}
                    </Button>
                  </Link>
                )}
              </Space>
              <Button
                type="primary"
                onClick={showQr}
                style={{ marginLeft: '10px' }}
                loading={qrLoading}
              >
                {t`labels.showQrCode`}
              </Button>
            </div>
          }
          actionsMobile
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={activeItem ? t`labels.Update entry` : t`labels.Add entry`}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
      >
        <TCAForm onSuccess={handleSuccess} id={activeItem?.id} />
      </Modal>
    </Page>
  )
}

export default TicketControlActivity
