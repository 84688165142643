import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { ActorTypeAsyncSelect } from '@components/AsyncSelect/ActorTypeAsyncSelect'
import MultiLanguageInput from '../../components/MultiLanguageInput'
import { prepareOption } from '@components/AsyncSelect/types'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { MLFormProps } from '../types'
import MultiLanguageTextEditor from '@/components/MultiLanguageTextEditor'
import { AgencyAsyncSelect } from '@components/AsyncSelect/AgencyAsyncSelect'
import { useAuthContext } from '@/contexts/AuthContext'
import { useTranslation } from 'react-i18next'

const ActorForm = ({ onSuccess, id, langFields }: MLFormProps) => {
  const { isAdmin, isPartner, me } = useAuthContext()
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { appLang } = useLanguageContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/actors/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            actorTypeId: prepareOption(data.actorType!, appLang),
            ...(data.agency
              ? { agencyId: prepareOption(data.agency, appLang) }
              : {}),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const onFinish = async (values: CustomFormData) => {
    const valuesCopy = { ...values }

    if (id) {
      delete valuesCopy.agencyId
      await axiosInstance.put(`admin/actors/${id}`, {
        ...valuesCopy,
        actorTypeId: values.actorTypeId.value || values.actorTypeId,
      })
    } else {
      if (isPartner) {
        valuesCopy.agencyId = me?.agency?.id
      }
      await axiosInstance.post('admin/actors', valuesCopy)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <MultiLanguageInput
        itemProps={{
          name: 'name',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`name`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <ActorTypeAsyncSelect
        name="actorTypeId"
        rules={[{ required: true }]}
        withAll={false}
      />
      <MultiLanguageTextEditor
        form={form}
        itemProps={{
          name: 'description',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{
          label: t`labels.Description`,
          required: true,
        }}
        languageFields={langFields}
      />
      {isAdmin && (
        <AgencyAsyncSelect
          name="agencyId"
          disabled={!!id}
          rules={[{ required: true }]}
          withAll={false}
        />
      )}
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ActorForm
