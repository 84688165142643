/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AgencyExtendedDto } from './agency-extended-dto';

/**
 * 
 * @export
 * @interface LoginCredentialsExtendedDto
 */
export interface LoginCredentialsExtendedDto {
    /**
     * 
     * @type {number}
     * @memberof LoginCredentialsExtendedDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsExtendedDto
     */
    'username'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginCredentialsExtendedDto
     */
    'roles'?: Array<LoginCredentialsExtendedDtoRolesEnum>;
    /**
     * 
     * @type {AgencyExtendedDto}
     * @memberof LoginCredentialsExtendedDto
     */
    'agency'?: AgencyExtendedDto;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsExtendedDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginCredentialsExtendedDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsExtendedDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginCredentialsExtendedDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginCredentialsExtendedDtoRolesEnum {
    USER = 'user',
    GUEST = 'guest',
    ADMIN = 'admin',
    PARTNER = 'partner'
}


