import { FrownOutlined } from '@ant-design/icons'
import styles from './404.module.less'
import Page from 'components/Page'
import { useTranslation } from 'react-i18next'

const Error = () => {
  const { t } = useTranslation()
  return (
    <Page inner>
      <div className={styles.error}>
        <FrownOutlined />
        <h1>{t`labels.404 Not Found`}</h1>
      </div>
    </Page>
  )
}

export default Error
