import { Form } from 'antd'
import Page from 'components/Page'
import { UserDto, UserVisitLogDto, PageUserVisitLogDto } from '@/openapi'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import { Params } from 'types/Params'
import { SelectOption } from 'types/Option'
import commonStyles from '@pages/commonStyles.module.less'
import { SorterResult } from 'antd/es/table/interface'
import { twoColumnsDateFormat } from '@pages/helpers'
import FloatInput from '@components/FloatInput'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = UserDto & { countryId: SelectOption }

type Props = {
  filter: string
  showUser?: boolean
}

const VisitsHistory = ({ filter, showUser }: Props) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<UserVisitLogDto>
    },
    filters: { [key in keyof UserVisitLogDto]: string } & {
      eventName: string
      stage: string
      city: string
    },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = [filter]
    if (filters.source) {
      query.push(`source~~${filters.source}`)
    }
    if (filters.utmCampaign) {
      query.push(`utmCampaign~~${filters.utmCampaign}`)
    }
    if (filters.utmContent) {
      query.push(`utmContent~~${filters.utmContent}`)
    }
    if (filters.utmTerm) {
      query.push(`utmTerm~~${filters.utmTerm}`)
    }
    if (filters.utmSource) {
      query.push(`utmSource~~${filters.utmSource}`)
    }
    if (filters.utmMedium) {
      query.push(`utmMedium~~${filters.utmMedium}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }

    params.query = query.join(';')

    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageUserVisitLogDto>('admin/user-visit-logs', {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const columns: ExtendedColumns<UserVisitLogDto>[] = [
    {
      title: t`labels.Event ID`,
      dataIndex: 'eventId',
      key: 'eventId',
      sorter: true,
      fixed: 'left',
    },
    ...(showUser
      ? [
          {
            title: t`labels.User ID`,
            dataIndex: 'userId',
            key: 'userId',
          } as ExtendedColumns<UserVisitLogDto>,
        ]
      : []),
    {
      title: t`labels.Count`,
      dataIndex: 'visitCount',
      key: 'visitCount',
      sorter: true,
    },
    {
      title: t`labels.First_Visit_Date`,
      dataIndex: 'firstVisitDate',
      key: 'firstVisitDate',
      render: twoColumnsDateFormat,
      sorter: true,
    },
    {
      title: t`labels.Last_Visit_Date`,
      dataIndex: 'lastVisitDate',
      key: 'lastVisitDate',
      render: twoColumnsDateFormat,
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.source`}</div>
          <Form.Item name="source">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'source',
      key: 'source',
      shortTitle: t`labels.source`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.UTM_Campaign`}</div>
          <Form.Item name="utmCampaign">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmCampaign',
      key: 'utmCampaign',
      shortTitle: t`labels.UTM_Campaign`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.UTM_Medium`}</div>
          <Form.Item name="utmMedium">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmMedium',
      key: 'utmMedium',
      shortTitle: t`labels.UTM_Medium`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.UTM_Source`}</div>
          <Form.Item name="utmSource">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmSource',
      key: 'utmSource',
      shortTitle: t`labels.UTM_Source`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.UTM_Term`}</div>
          <Form.Item name="utmTerm">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmTerm',
      key: 'utmTerm',
      shortTitle: t`labels.UTM_Term`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.UTM_Content`}</div>
          <Form.Item name="utmContent">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmContent',
      key: 'utmContent',
      shortTitle: t`labels.UTM_Content`,
    },
  ]

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<UserDto>
          bordered
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default VisitsHistory
