import {
  EventDto,
  EventExtendedDto,
  EventExtendedDtoEventStatusEnum,
  EventTourViewDto,
} from '@/openapi'

type EventTypes = EventDto | EventExtendedDto | EventTourViewDto

export const isArchivedEvent = (event?: EventTypes) =>
  event?.eventStatus === EventExtendedDtoEventStatusEnum.ARCHIVED

export const isPublishedEvent = (event?: EventTypes) =>
  event?.eventStatus === EventExtendedDtoEventStatusEnum.PUBLISHED

export const isCancelledEvent = (event?: EventTypes) =>
  event?.eventStatus === EventExtendedDtoEventStatusEnum.CANCELED

export const isAnnouncedEvent = (event?: EventTypes) =>
  event?.eventStatus === EventExtendedDtoEventStatusEnum.ANNOUNCED

export const isUnpublishedEvent = (event?: EventTypes) =>
  event?.eventStatus === EventExtendedDtoEventStatusEnum.UNPUBLISHED

export const isNotCanceledOrArchivedEvent = (event?: EventTypes) =>
  event?.eventStatus !== EventExtendedDtoEventStatusEnum.CANCELED &&
  event?.eventStatus !== EventExtendedDtoEventStatusEnum.ARCHIVED
