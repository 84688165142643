import { EventCreateResponseLanguagesEnum } from '@/openapi'
import { FC, useMemo } from 'react'
import { Button, Radio } from 'antd'
import cn from 'classnames'
import styles from './index.module.less'
import { ReactComponent as AiSvg } from '@/assets/ai.svg'
import { ReactComponent as RotateLeftSvg } from '@/assets/rotate-left.svg'
import { FieldError } from 'rc-field-form/es/interface'
import { AiButtonStatus } from '@components/formElements/types'

type Props = {
  languages: EventCreateResponseLanguagesEnum[]
  currentLanguage: EventCreateResponseLanguagesEnum
  setCurrentLanguage: (l: EventCreateResponseLanguagesEnum) => void
  handleTranslateClick: () => void
  loading: boolean
  errors: FieldError[]
  marginBottom?: string
  aiButtonStatus: AiButtonStatus
}
export const LanguageButtons: FC<Props> = ({
  languages,
  setCurrentLanguage,
  currentLanguage,
  loading,
  errors,
  handleTranslateClick,
  marginBottom = '24px',
  aiButtonStatus,
}) => {
  const actionButton = useMemo(() => {
    switch (aiButtonStatus) {
      case 'ready':
        return (
          <Button
            onClick={handleTranslateClick}
            className={styles.aiButton}
            icon={<AiSvg />}
            loading={loading}
          >
            {'AI translate'}
          </Button>
        )
      case 'done':
        return (
          <Button
            className={cn(styles.aiButton, styles.greenAiButton)}
            icon={<AiSvg style={{ color: '#00AC78' }} />}
            loading={loading}
          >
            {'Done'}
          </Button>
        )
      case 'error':
        return (
          <Button
            onClick={handleTranslateClick}
            danger
            icon={<RotateLeftSvg style={{ color: '#00AC78' }} />}
            loading={loading}
            className={styles.aiButton}
            style={{ backgroundColor: '#FFF0F0' }}
          >
            {'Fail'}
          </Button>
        )
    }
  }, [aiButtonStatus, loading, handleTranslateClick])

  if (languages.length < 2) return null

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: '8px',
        marginBottom,
      }}
    >
      <Radio.Group
        value={currentLanguage}
        onChange={(e) => setCurrentLanguage(e.target.value)}
      >
        {languages.map((l, i) => (
          <Radio.Button
            key={l}
            value={l}
            className={cn(!!errors[i]?.errors.length && styles.error)}
          >
            {l}
          </Radio.Button>
        ))}
      </Radio.Group>
      {actionButton}
    </div>
  )
}
