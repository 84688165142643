import { Button, Divider, Form, Input } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { useTranslation } from 'react-i18next'
import {
  TicketOperatorCreateRequest,
  TicketOperatorCreateResponse,
  TicketOperatorDto,
  TicketOperatorUpdateResponse,
} from '@/openapi'
import { toast } from 'react-toastify'

const TicketOperatorForm = ({
  onSuccess,
  id,
}: {
  onSuccess: () => void
  id?: number
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<TicketOperatorCreateRequest>()
  const [fullItem, setFullItem] = useState<TicketOperatorDto>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<TicketOperatorDto>(`admin/ticket-operators/${id}`)
        .then(({ data }) => {
          setFullItem(data)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const isChangesWereSuccessful = (code: number, id?: number) => {
    return code === 200 && !!id
  }

  const onFinish = async (values: TicketOperatorCreateRequest) => {
    if (id) {
      try {
        const response = await axiosInstance.put<TicketOperatorUpdateResponse>(
          `admin/ticket-operators/${id}`,
          {
            ...values,
            id,
          },
        )
        if (isChangesWereSuccessful(response.status, response.data.id)) {
          toast.success(t`changesSavedSuccessfully`)
        } else {
          toast.error(t`somethingWentWrong`)
        }
      } catch {
        toast.error(t`somethingWentWrong`)
      }
    } else {
      try {
        const response = await axiosInstance.post<TicketOperatorCreateResponse>(
          'admin/ticket-operators',
          values,
        )
        if (isChangesWereSuccessful(response.status, response.data.id)) {
          toast.success(t`changesSavedSuccessfully`)
        } else {
          toast.error(t`somethingWentWrong`)
        }
      } catch {
        toast.error(t`somethingWentWrong`)
      }
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      name="ticket-operator"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <Form.Item
        name="name"
        label={t`name`}
        rules={[
          {
            required: true,
            message: t`TicketOperators.nameInputRequiredMessage` ?? '',
          },
          {
            max: 128,
            message: t`TicketOperators.nameInputLengthMessage` ?? '',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="publicUrl"
        label={t`link`}
        rules={[
          {
            required: true,
            message: t`TicketOperators.publicUrlInputRequiredMessage` ?? '',
          },
          {
            max: 1024,
            message: t`TicketOperators.publicUrlInputLengthMessage` ?? '',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TicketOperatorForm
