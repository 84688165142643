/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventTicketControlData } from '../models';
// @ts-ignore
import { TicketControlAuthRequest } from '../models';
// @ts-ignore
import { TicketControlEventRequest } from '../models';
// @ts-ignore
import { TicketControlEventResponse } from '../models';
// @ts-ignore
import { TicketControlRegisterRequest } from '../models';
// @ts-ignore
import { TicketControlRegisterResponse } from '../models';
// @ts-ignore
import { TicketControlVerificationCreateRequest } from '../models';
// @ts-ignore
import { TicketControlVerificationCreateResponse } from '../models';
/**
 * TicketControlRegistrationControllerApi - axios parameter creator
 * @export
 */
export const TicketControlRegistrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TicketControlEventRequest} ticketControlEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForEventControl: async (ticketControlEventRequest: TicketControlEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketControlEventRequest' is not null or undefined
            assertParamExists('applyForEventControl', 'ticketControlEventRequest', ticketControlEventRequest)
            const localVarPath = `/v1/ticket-control/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketControlEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} pinCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForEventControl1: async (eventId: number, pinCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('applyForEventControl1', 'eventId', eventId)
            // verify required parameter 'pinCode' is not null or undefined
            assertParamExists('applyForEventControl1', 'pinCode', pinCode)
            const localVarPath = `/v1/ticket-control/event/quick`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }

            if (pinCode !== undefined) {
                localVarQueryParameter['pinCode'] = pinCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TicketControlAuthRequest} ticketControlAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTicketControlPerson: async (ticketControlAuthRequest: TicketControlAuthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketControlAuthRequest' is not null or undefined
            assertParamExists('authTicketControlPerson', 'ticketControlAuthRequest', ticketControlAuthRequest)
            const localVarPath = `/v1/ticket-control/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketControlAuthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventControlData: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getEventControlData', 'authorization', authorization)
            const localVarPath = `/v1/ticket-control/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TicketControlRegisterRequest} ticketControlRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTicketControlPerson: async (ticketControlRegisterRequest: TicketControlRegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketControlRegisterRequest' is not null or undefined
            assertParamExists('registerTicketControlPerson', 'ticketControlRegisterRequest', ticketControlRegisterRequest)
            const localVarPath = `/v1/ticket-control/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketControlRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTicket: async (authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('verifyTicket', 'authorization', authorization)
            // verify required parameter 'ticketControlVerificationCreateRequest' is not null or undefined
            assertParamExists('verifyTicket', 'ticketControlVerificationCreateRequest', ticketControlVerificationCreateRequest)
            const localVarPath = `/v1/ticket-control/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketControlVerificationCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTicketQuick: async (authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('verifyTicketQuick', 'authorization', authorization)
            // verify required parameter 'ticketControlVerificationCreateRequest' is not null or undefined
            assertParamExists('verifyTicketQuick', 'ticketControlVerificationCreateRequest', ticketControlVerificationCreateRequest)
            const localVarPath = `/v1/ticket-control/verify/quick`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketControlVerificationCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketControlRegistrationControllerApi - functional programming interface
 * @export
 */
export const TicketControlRegistrationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketControlRegistrationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TicketControlEventRequest} ticketControlEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyForEventControl(ticketControlEventRequest: TicketControlEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControlEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyForEventControl(ticketControlEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} pinCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyForEventControl1(eventId: number, pinCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControlEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyForEventControl1(eventId, pinCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TicketControlAuthRequest} ticketControlAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTicketControlPerson(ticketControlAuthRequest: TicketControlAuthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTicketControlPerson(ticketControlAuthRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventControlData(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventTicketControlData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventControlData(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TicketControlRegisterRequest} ticketControlRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerTicketControlPerson(ticketControlRegisterRequest: TicketControlRegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControlRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerTicketControlPerson(ticketControlRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyTicket(authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControlVerificationCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyTicket(authorization, ticketControlVerificationCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyTicketQuick(authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControlVerificationCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyTicketQuick(authorization, ticketControlVerificationCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketControlRegistrationControllerApi - factory interface
 * @export
 */
export const TicketControlRegistrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketControlRegistrationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {TicketControlEventRequest} ticketControlEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForEventControl(ticketControlEventRequest: TicketControlEventRequest, options?: any): AxiosPromise<TicketControlEventResponse> {
            return localVarFp.applyForEventControl(ticketControlEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} pinCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForEventControl1(eventId: number, pinCode: string, options?: any): AxiosPromise<TicketControlEventResponse> {
            return localVarFp.applyForEventControl1(eventId, pinCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketControlAuthRequest} ticketControlAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTicketControlPerson(ticketControlAuthRequest: TicketControlAuthRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authTicketControlPerson(ticketControlAuthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventControlData(authorization: string, options?: any): AxiosPromise<EventTicketControlData> {
            return localVarFp.getEventControlData(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketControlRegisterRequest} ticketControlRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTicketControlPerson(ticketControlRegisterRequest: TicketControlRegisterRequest, options?: any): AxiosPromise<TicketControlRegisterResponse> {
            return localVarFp.registerTicketControlPerson(ticketControlRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTicket(authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options?: any): AxiosPromise<TicketControlVerificationCreateResponse> {
            return localVarFp.verifyTicket(authorization, ticketControlVerificationCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTicketQuick(authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options?: any): AxiosPromise<TicketControlVerificationCreateResponse> {
            return localVarFp.verifyTicketQuick(authorization, ticketControlVerificationCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketControlRegistrationControllerApi - object-oriented interface
 * @export
 * @class TicketControlRegistrationControllerApi
 * @extends {BaseAPI}
 */
export class TicketControlRegistrationControllerApi extends BaseAPI {
    /**
     * 
     * @param {TicketControlEventRequest} ticketControlEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControlRegistrationControllerApi
     */
    public applyForEventControl(ticketControlEventRequest: TicketControlEventRequest, options?: AxiosRequestConfig) {
        return TicketControlRegistrationControllerApiFp(this.configuration).applyForEventControl(ticketControlEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {string} pinCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControlRegistrationControllerApi
     */
    public applyForEventControl1(eventId: number, pinCode: string, options?: AxiosRequestConfig) {
        return TicketControlRegistrationControllerApiFp(this.configuration).applyForEventControl1(eventId, pinCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketControlAuthRequest} ticketControlAuthRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControlRegistrationControllerApi
     */
    public authTicketControlPerson(ticketControlAuthRequest: TicketControlAuthRequest, options?: AxiosRequestConfig) {
        return TicketControlRegistrationControllerApiFp(this.configuration).authTicketControlPerson(ticketControlAuthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControlRegistrationControllerApi
     */
    public getEventControlData(authorization: string, options?: AxiosRequestConfig) {
        return TicketControlRegistrationControllerApiFp(this.configuration).getEventControlData(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketControlRegisterRequest} ticketControlRegisterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControlRegistrationControllerApi
     */
    public registerTicketControlPerson(ticketControlRegisterRequest: TicketControlRegisterRequest, options?: AxiosRequestConfig) {
        return TicketControlRegistrationControllerApiFp(this.configuration).registerTicketControlPerson(ticketControlRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControlRegistrationControllerApi
     */
    public verifyTicket(authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options?: AxiosRequestConfig) {
        return TicketControlRegistrationControllerApiFp(this.configuration).verifyTicket(authorization, ticketControlVerificationCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {TicketControlVerificationCreateRequest} ticketControlVerificationCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControlRegistrationControllerApi
     */
    public verifyTicketQuick(authorization: string, ticketControlVerificationCreateRequest: TicketControlVerificationCreateRequest, options?: AxiosRequestConfig) {
        return TicketControlRegistrationControllerApiFp(this.configuration).verifyTicketQuick(authorization, ticketControlVerificationCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

