/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CityDto } from './city-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTourAnnounceReadinessViewDto } from './event-tour-announce-readiness-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTourPublishReadinessViewDto } from './event-tour-publish-readiness-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StageDto } from './stage-dto';

/**
 * 
 * @export
 * @interface EventTourViewDto
 */
export interface EventTourViewDto {
    /**
     * 
     * @type {number}
     * @memberof EventTourViewDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTourViewDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventTourViewDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTourViewDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventTourViewDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTourViewDto
     */
    'name'?: string;
    /**
     * 
     * @type {CityDto}
     * @memberof EventTourViewDto
     */
    'city'?: CityDto;
    /**
     * 
     * @type {StageDto}
     * @memberof EventTourViewDto
     */
    'stage'?: StageDto;
    /**
     * 
     * @type {string}
     * @memberof EventTourViewDto
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventTourViewDto
     */
    'eventStatus'?: EventTourViewDtoEventStatusEnum;
    /**
     * 
     * @type {EventTourAnnounceReadinessViewDto}
     * @memberof EventTourViewDto
     */
    'announceReadiness'?: EventTourAnnounceReadinessViewDto;
    /**
     * 
     * @type {EventTourPublishReadinessViewDto}
     * @memberof EventTourViewDto
     */
    'publishReadiness'?: EventTourPublishReadinessViewDto;
}

/**
    * @export
    * @enum {string}
    */
export enum EventTourViewDtoEventStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}


