import { useCallback, useMemo, useState } from 'react'
import { Button, Table } from 'antd'
import Page from 'components/Page'
import {
  EventExtendedDto,
  EventPriceDenominationStatisticsView,
} from '@/openapi'
import { ColumnsType } from 'antd/es/table/interface'
import { Pie } from 'react-chartjs-2'

import { useParams } from 'react-router-dom'
import useAsyncGet from '@/hooks/useAsyncGet'
import styles from '@pages/event/styles.module.less'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { axiosInstance } from '@/api'
import { backgroundColor, borderColor } from '@/utils/chartColors'
import { useTranslation } from 'react-i18next'
import { getNameFromHeaders } from '@/utils/getNameFromHeaders'

ChartJS.register(ArcElement, Tooltip, Legend)

const DenominationReport = ({ event }: { event?: EventExtendedDto }) => {
  const { id: eventId } = useParams<{ id: string }>()
  const { data, loaded } = useAsyncGet<EventPriceDenominationStatisticsView[]>(
    `admin/statistics/price-categories/${eventId}?size=200`,
  )
  const { data: currentEvent } = useAsyncGet<EventExtendedDto>(
    `admin/events/${eventId}`,
  )
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const currency = useMemo(() => event?.currency?.code || '', [event])

  const columns: ColumnsType<EventPriceDenominationStatisticsView> = [
    {
      key: 'eventPriceName',
      dataIndex: 'eventPriceName',
      title: t`labels.eventPriceName`,
    },
    {
      key: 'priceDenomination',
      dataIndex: 'priceDenomination',
      title: t`labels.Denomination`,
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: t`labels.Quantity`,
    },
    {
      key: 'discount',
      dataIndex: 'discount',
      title: `${t`labels.Discount`}, ${currency}`,
    },
    {
      key: 'returnsSum',
      dataIndex: 'returnsSum',
      title: `${t`labels.Returns`}, ${currency}`,
      render: (text) => <span style={{ color: 'red' }}>{text}</span>,
    },
    {
      key: 'sum',
      dataIndex: 'sum',
      title: `${t`labels.Sum`}, ${currency}`,
    },
  ]

  const downloadFile = useCallback(
    (type: 'excel' | 'pdf') => async () => {
      setLoading(true)
      const { data, headers } = await axiosInstance.get<Blob>(
        `/admin/statistics/price-categories/${eventId}/download?format=${type}`,
        {
          responseType: 'blob',
        },
      )
      const fileURL = window.URL.createObjectURL(data)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = getNameFromHeaders(headers)
      alink.click()
      setLoading(false)
    },
    [eventId, currentEvent],
  )

  return (
    <Page inner>
      <div style={{ display: 'flex', gap: '15px' }}>
        <Button type="primary" onClick={downloadFile('pdf')} loading={loading}>
          {t`labels.PDF Export`}
        </Button>
        <Button
          type="primary"
          onClick={downloadFile('excel')}
          loading={loading}
        >
          {t`labels.Excel Export`}
        </Button>
      </div>
      {data?.find(({ priceDenomination }) => priceDenomination) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ width: '50%', marginBottom: '10px', maxWidth: '300px' }}
          >
            <Pie
              data={{
                labels: data?.map(
                  ({ priceDenomination, eventPriceName }) =>
                    `${eventPriceName} ${priceDenomination}`,
                ),
                datasets: [
                  {
                    data: data?.map(({ quantity }) => quantity),
                    backgroundColor,
                    borderColor,
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>
      )}

      <Table
        scroll={{ x: 400 }}
        loading={!loaded}
        pagination={false}
        dataSource={data}
        columns={columns}
        style={{ marginTop: '20px' }}
        rowClassName={styles.rowHover}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <b>{t`all`}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} />

              <Table.Summary.Cell index={2}>
                <b>{data?.reduce((a, el) => a + (el.quantity || 0), 0)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{data?.reduce((a, el) => a + (el.discount || 0), 0)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <b style={{ color: 'red' }}>
                  {data?.reduce((a, el) => a + (el.returnsSum || 0), 0)}
                </b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <b>{data?.reduce((a, el) => a + (el.sum || 0), 0)}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </Page>
  )
}

export default DenominationReport
