import { Button, Divider, Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { MLFormProps } from '../types'
import FloatInput from '@components/FloatInput'
import FormItem from '@/components/FormItem'
import FloatSelect from '@/components/FloatSelect'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { CountryDto } from '@/openapi'
import { SelectOption } from '@/types/Option'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

export const formatCountryOption = ({
  id,
  phoneCode,
  name,
}: CountryDto): SelectOption => ({
  value: String(id),
  label: `${name} (+${phoneCode})`,
})

const TicketControlPersonsForm = ({ onSuccess, id }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/ticket-control-persons/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            countryId: formatCountryOption(data.country!),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    if (id) {
      await axiosInstance.put(`admin/ticket-control-persons/${id}`, {
        status: values.status,
        name: values.name,
      })
    } else {
      await axiosInstance.post('admin/ticket-control-persons', values)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      {!!id && (
        <FormItem name="status">
          <FloatSelect label={t`labels.Status`} labelInValue={false}>
            <Option value="created">{'Created'}</Option>
            <Option value="approved">{'Approved'}</Option>
            <Option value="blocked">{'Blocked'}</Option>
          </FloatSelect>
        </FormItem>
      )}
      <FormItem
        name="phoneNumber"
        rules={[{ required: true }]}
        hasFeedback
        disabled={!!id}
      >
        <FloatInput label={t`labels.Phone Number`} />
      </FormItem>
      <FormItem
        name="name"
        rules={[{ required: true }]}
        hasFeedback
        disabled={!!id}
      >
        <FloatInput label={t`name`} />
      </FormItem>
      <GenericAsyncSelect<CountryDto>
        path="admin/countries"
        label={t`labels.Country`}
        name="countryId"
        withAll={false}
        customPrepareOption={formatCountryOption}
        controlProps={{ disabled: !!id }}
        disabled={!!id}
        width="100%"
      />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TicketControlPersonsForm
