/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorExtendedDto } from './actor-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyExtendedDto } from './agency-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CityExtendedDto } from './city-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CityUpdateResponseTimezone } from './city-update-response-timezone';
// May contain unused imports in some cases
// @ts-ignore
import { CurrencyExtendedDto } from './currency-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeExtendedDto } from './event-type-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StageExtendedDto } from './stage-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { TourExtendedDto } from './tour-extended-dto';

/**
 * 
 * @export
 * @interface EventExtendedDto
 */
export interface EventExtendedDto {
    /**
     * 
     * @type {number}
     * @memberof EventExtendedDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'nameBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'descriptionBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'admissionStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'admissionEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'saleStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'saleEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'pinCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'lockId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'eventStatus'?: EventExtendedDtoEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'filterType'?: EventExtendedDtoFilterTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventExtendedDto
     */
    'languages'?: Array<EventExtendedDtoLanguagesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof EventExtendedDto
     */
    'includeVat'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventExtendedDto
     */
    'vat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventExtendedDto
     */
    'includeServiceFee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventExtendedDto
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventExtendedDto
     */
    'commissionRate'?: number;
    /**
     * 
     * @type {AgencyExtendedDto}
     * @memberof EventExtendedDto
     */
    'agency'?: AgencyExtendedDto;
    /**
     * 
     * @type {Array<ActorExtendedDto>}
     * @memberof EventExtendedDto
     */
    'actors'?: Array<ActorExtendedDto>;
    /**
     * 
     * @type {EventTypeExtendedDto}
     * @memberof EventExtendedDto
     */
    'eventType'?: EventTypeExtendedDto;
    /**
     * 
     * @type {CityExtendedDto}
     * @memberof EventExtendedDto
     */
    'city'?: CityExtendedDto;
    /**
     * 
     * @type {StageExtendedDto}
     * @memberof EventExtendedDto
     */
    'stage'?: StageExtendedDto;
    /**
     * 
     * @type {CurrencyExtendedDto}
     * @memberof EventExtendedDto
     */
    'currency'?: CurrencyExtendedDto;
    /**
     * 
     * @type {TourExtendedDto}
     * @memberof EventExtendedDto
     */
    'tour'?: TourExtendedDto;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventExtendedDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventExtendedDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventExtendedDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {CityUpdateResponseTimezone}
     * @memberof EventExtendedDto
     */
    'timezone'?: CityUpdateResponseTimezone;
    /**
     *
     * @type {boolean}
     * @memberof EventExtendedDto
     */
    'external'?: boolean;
    /**
     *
     * @type {string}
     * @memberof EventExtendedDto
     */
    'externalUrl'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EventExtendedDtoEventStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum EventExtendedDtoFilterTypeEnum {
    REGULAR = 'regular',
    PROMOTION = 'promotion',
    HIDDEN = 'hidden'
}
/**
    * @export
    * @enum {string}
    */
export enum EventExtendedDtoLanguagesEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}


