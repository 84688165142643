import { PageEntity } from '@/types/PagiableEntity'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Select } from 'antd'
import { useDebounceEffect } from 'ahooks'
import { uniqBy } from 'lodash'
import { SelectOption } from 'types/Option'
import { SelectProps } from 'antd/es'
import { BaseFilers } from './GenericAsyncSelect'
import { useTranslation } from 'react-i18next'
import FormLabel from '@components/formElements/Layout/FormLabel'
export type TOption = { value: string; label: string }

type AsyncSelectProps<T> = {
  pageSize?: number
  withAll?: boolean
  prepareOption: (item: T) => TOption
  onChange: (value: string | SelectOption[], item?: T) => void
  label?: string | null
  required?: boolean
  baseFilters?: BaseFilers
  controlProps?: Omit<SelectProps, 'onChange'>
  getData: (
    page: number,
    size: number,
    search?: string,
    baseFilters?: BaseFilers,
  ) => Promise<PageEntity<T>>
} & Partial<SelectProps>

const AsyncSelect = <T extends { id?: number }>({
  prepareOption,
  pageSize = 10,
  getData,
  onChange,
  withAll = true,
  label = '',
  required,
  baseFilters,
  controlProps,
  ...rest
}: AsyncSelectProps<T>) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T[]>([])
  const [page, setPage] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const [search, setSearch] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    setData([])
    setPage(0)
    setSearch('')
  }, [baseFilters])

  useDebounceEffect(
    () => {
      const fetchData = async () => {
        setLoading(true)
        const { content = [], last } = await getData(
          page,
          pageSize,
          search,
          baseFilters,
        )
        setIsLastPage(last)
        setData((data) => [...data, ...content])
        setLoading(false)
      }
      fetchData()
    },
    [pageSize, page, search, baseFilters],
    { wait: 200 },
  )

  const onScroll = useCallback(
    async (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (isLastPage) return
      const target = event.target as HTMLDivElement
      if (
        !loading &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        target.scrollTo(0, target.scrollHeight)
        console.log(page)
        setPage(page + 1)
      }
    },
    [page, isLastPage],
  )

  const searchHandler = useCallback((value: string) => {
    setPage(0)
    setData([])
    setSearch(value)
  }, [])

  const options = useMemo(() => {
    const result = []
    if (data.length && withAll) result.push({ label: t`all`, value: 'All' })
    if (data[0]?.id) {
      uniqBy(data, 'id').forEach((item) => result.push(prepareOption(item)))
    } else {
      data.forEach((item) => result.push(prepareOption(item)))
    }
    if (!isLastPage) result.push({ label: t`labels.Loading`, value: 'loading' })
    return result
  }, [isLastPage, data])

  return (
    <div>
      <FormLabel label={label} required={required} />
      <Select
        onChange={(option: SelectOption | SelectOption[]) => {
          if (Array.isArray(option)) {
            onChange(option)
          } else {
            onChange(
              option?.value,
              data.find((item) => +option?.value === item.id),
            )
          }
        }}
        onPopupScroll={onScroll}
        showSearch
        labelInValue
        filterOption={false}
        onSearch={searchHandler}
        loading={loading}
        options={options}
        onSelect={() => setSearch('')}
        onClick={(e) => e.stopPropagation()}
        {...rest}
        {...controlProps}
      />
    </div>
  )
}

export default AsyncSelect
