/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventLandingDto } from '../models';
// @ts-ignore
import { EventUserDto } from '../models';
// @ts-ignore
import { PageEventUserDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * EventsControllerApi - axios parameter creator
 * @export
 */
export const EventsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find10: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find10', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} aLong 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById5: async (aLong: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aLong' is not null or undefined
            assertParamExists('getById5', 'aLong', aLong)
            const localVarPath = `/v1/events/events/{id}`
                .replace(`{${"aLong"}}`, encodeURIComponent(String(aLong)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} [lockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdLanding: async (eventId: number, lockId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getByIdLanding', 'eventId', eventId)
            const localVarPath = `/v1/events/events/{eventId}/landing`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lockId !== undefined) {
                localVarQueryParameter['lockId'] = lockId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount5: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('getCount5', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/events/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} searchWord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (pageRequest: Pageable, searchWord: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('search', 'pageRequest', pageRequest)
            // verify required parameter 'searchWord' is not null or undefined
            assertParamExists('search', 'searchWord', searchWord)
            const localVarPath = `/v1/events/events/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsControllerApi - functional programming interface
 * @export
 */
export const EventsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find10(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEventUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find10(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} aLong 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById5(aLong: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById5(aLong, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} [lockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdLanding(eventId: number, lockId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventLandingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdLanding(eventId, lockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount5(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount5(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} searchWord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(pageRequest: Pageable, searchWord: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEventUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(pageRequest, searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsControllerApi - factory interface
 * @export
 */
export const EventsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find10(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageEventUserDto> {
            return localVarFp.find10(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} aLong 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById5(aLong: number, options?: any): AxiosPromise<EventUserDto> {
            return localVarFp.getById5(aLong, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} [lockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdLanding(eventId: number, lockId?: string, options?: any): AxiosPromise<EventLandingDto> {
            return localVarFp.getByIdLanding(eventId, lockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount5(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount5(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} searchWord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(pageRequest: Pageable, searchWord: string, options?: any): AxiosPromise<PageEventUserDto> {
            return localVarFp.search(pageRequest, searchWord, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsControllerApi - object-oriented interface
 * @export
 * @class EventsControllerApi
 * @extends {BaseAPI}
 */
export class EventsControllerApi extends BaseAPI {
    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsControllerApi
     */
    public find10(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return EventsControllerApiFp(this.configuration).find10(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} aLong 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsControllerApi
     */
    public getById5(aLong: number, options?: AxiosRequestConfig) {
        return EventsControllerApiFp(this.configuration).getById5(aLong, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {string} [lockId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsControllerApi
     */
    public getByIdLanding(eventId: number, lockId?: string, options?: AxiosRequestConfig) {
        return EventsControllerApiFp(this.configuration).getByIdLanding(eventId, lockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsControllerApi
     */
    public getCount5(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return EventsControllerApiFp(this.configuration).getCount5(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} searchWord 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsControllerApi
     */
    public search(pageRequest: Pageable, searchWord: string, options?: AxiosRequestConfig) {
        return EventsControllerApiFp(this.configuration).search(pageRequest, searchWord, options).then((request) => request(this.axios, this.basePath));
    }
}

