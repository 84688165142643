import { Alert, Button, Form, Modal, Select } from 'antd'
import { EventStep1Form, FirstStepProps } from './types'
import { axiosInstance } from 'api'
import { useCallback, useEffect } from 'react'
import { ActorAsyncSelect } from '@components/AsyncSelect/ActorAsyncSelect'
import { EventTypeAsyncSelect } from '@components/AsyncSelect/EventTypesAsyncSelect'
import styles from './styles.module.less'
import { useNavigate } from 'react-router-dom'
import {
  EventCreateResponseLanguagesEnum,
  EventDto,
  EventDtoEventStatusEnum,
  EventExtendedDto,
} from '@/openapi'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import CountryFlag from '@components/CountryFlag/CountryFlag'
import { getValue } from './helpers'
import { toast } from 'react-toastify'
import cn from 'classnames'
import FormItem from 'components/FormItem'
import { SelectOption } from '@/types/Option'
import { AgencyAsyncSelect } from '@components/AsyncSelect/AgencyAsyncSelect'
import { useAuthContext } from '@/contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'
import InputMultiLanguage from '@components/formElements/InputMultiLanguage'
import DoubleFormRow from '@components/Layout/DoubleFormRow'
import FormSelect from '@components/formElements/FormSelect'
import TextEditorMultiLanguage from '@components/formElements/TextEditorMultiLanguage'

const { Option } = Select
const StepBasicInformation = ({
  onSuccess,
  item,
  setForm,
  isCreate,
  errors,
  canEdit,
}: FirstStepProps & { isCreate: boolean }) => {
  const [form] = Form.useForm<EventStep1Form>()
  useEffect(() => {
    setForm(form)
  }, [form])
  const { t } = useTranslation()

  const languages = Form.useWatch('languages', form)
  const navigate = useNavigate()
  const { isAdmin, me } = useAuthContext()
  const { languagesFull } = useLanguageContext()
  const onFinish = async (values: EventStep1Form) => {
    if (!form.isFieldsTouched()) {
      onSuccess({})
      return
    }
    if (item?.id) {
      const dataToSend = {
        ...values,
        actorIds: values.actorIds?.map(
          (actor) => (actor as SelectOption).value,
        ),
        eventTypeId: getValue(values.eventTypeId),
        agencyId: isAdmin ? getValue(values.agencyId) : me?.agency?.id,
        ageCategory: getValue(values.ageCategory, false),
      }
      const { data } = await axiosInstance.put<EventExtendedDto>(
        `admin/events/step1/${item.id}`,
        dataToSend,
      )
      onSuccess(data)
    } else {
      const {
        data: { id },
      } = await axiosInstance.post<EventDto>('admin/events/step1', {
        ...values,
        ageCategory: getValue(values.ageCategory, false),
        actorIds: values.actorIds?.map(
          (actor) => (actor as SelectOption).value,
        ),
        agencyId: isAdmin ? values.agencyId : me?.agency?.id,
      })
      toast.success(t`messages.eventChangesSaved`)
      navigate(`/app/event/${id}`, { state: 'new' })
    }
  }

  const cancelHandle = useCallback(() => {
    if (isCreate) {
      Modal.confirm({
        title: t`messages.cancelEventCreation`,
        onOk: () => navigate(-1),
      })
    } else {
      form.resetFields()
    }
  }, [t, isCreate, form])

  return (
    <div>
      {errors?.length && (
        <Alert
          message={
            <>
              {errors.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </>
          }
          type="error"
          showIcon
          className={cn(styles.fullWidth, styles.alert)}
        />
      )}
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={item}
        className={styles.form}
      >
        <FormSelect
          label="Languages"
          name="languages"
          disabled={!canEdit}
          formItemProps={{ rules: [{ required: true }], initialValue: ['EN'] }}
          mode="multiple"
        >
          <Option value="EN" label={t`labels.English`} disabled>
            <CountryFlag countryCode="us" /> {t`labels.English`}
          </Option>
          {languagesFull
            .filter(({ code }) => code !== 'EN')
            .map(({ code, name }) => (
              <Option value={code} label={name} key={code}>
                <CountryFlag countryCode={code!} /> {name}
              </Option>
            ))}
        </FormSelect>
        <InputMultiLanguage
          form={form}
          itemProps={{
            name: 'name',
            hasFeedback: true,
            rules: [{ required: true, max: 80 }],
          }}
          label={t`labels.Event name`}
          subLabel="to 80 characters max. Example: The Rolling Stones "
          placeholder="The Rolling Stones"
          languages={castType<EventCreateResponseLanguagesEnum[]>(languages)}
        />
        <DoubleFormRow>
          <EventTypeAsyncSelect
            width="100%"
            name="eventTypeId"
            withAll={false}
            disabled={!canEdit}
            rules={[{ required: true }]}
            required
          />
          <FormSelect
            disabled={!canEdit}
            name="ageCategory"
            label={t`labels.Age Category`}
            labelInValue
          >
            <Option value="0+" label="0+">
              {'0 +'}
            </Option>
            <Option value="6+" label="6+">
              {'6 +'}
            </Option>
            <Option value="12+" label="12+">
              {'12 +'}
            </Option>
            <Option value="14+" label="14+">
              {'14 +'}
            </Option>
            <Option value="16+" label="16+">
              {'16 +'}
            </Option>
            <Option value="18+" label="18+">
              {'18 +'}
            </Option>
          </FormSelect>
        </DoubleFormRow>

        <ActorAsyncSelect
          width="100%"
          name="actorIds"
          withAll={false}
          rules={[{ required: true }]}
          disabled={
            !canEdit ||
            (!isCreate &&
              item?.eventStatus === EventDtoEventStatusEnum.ANNOUNCED)
          }
          required
          mode="multiple"
        />
        <TextEditorMultiLanguage
          itemProps={{
            name: 'description',
            hasFeedback: true,
            className: styles.fullWidth,
            rules: [{ required: true }],
          }}
          inputProps={{ label: t`labels.Description`, required: true }}
          languages={castType<EventCreateResponseLanguagesEnum[]>(languages)}
          form={form}
        />
        {isAdmin && (
          <AgencyAsyncSelect
            withAll={false}
            rules={[{ required: true }]}
            disabled={!canEdit}
            required
            name="agencyId"
            width="100%"
          />
        )}
        <div className={styles.fullWidth}>
          <div className={cn(styles.buttonBlock)}>
            <Button htmlType="button" onClick={cancelHandle}>
              {t`cancel`}
            </Button>
            <FormItem
              style={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Button type="primary" htmlType="submit">
                {t`labels.Next`}
              </Button>
            </FormItem>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default StepBasicInformation
