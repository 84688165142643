/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyPromoCodeRequest } from '../models';
// @ts-ignore
import { PagePurchaseOrderUserDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
// @ts-ignore
import { PurchaseOrderUserDto } from '../models';
// @ts-ignore
import { PurchaseOrderWithTicketsDto } from '../models';
// @ts-ignore
import { UserShoppingCartDto } from '../models';
/**
 * PurchaseOrdersControllerApi - axios parameter creator
 * @export
 */
export const PurchaseOrdersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {ApplyPromoCodeRequest} applyPromoCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPromoCodeToPurchaseOrder: async (purchaseOrderId: number, applyPromoCodeRequest: ApplyPromoCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseOrderId' is not null or undefined
            assertParamExists('applyPromoCodeToPurchaseOrder', 'purchaseOrderId', purchaseOrderId)
            // verify required parameter 'applyPromoCodeRequest' is not null or undefined
            assertParamExists('applyPromoCodeToPurchaseOrder', 'applyPromoCodeRequest', applyPromoCodeRequest)
            const localVarPath = `/v1/events/purchase-orders/promo/{purchaseOrderId}`
                .replace(`{${"purchaseOrderId"}}`, encodeURIComponent(String(purchaseOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyPromoCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById: async (purchaseOrderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseOrderId' is not null or undefined
            assertParamExists('deleteById', 'purchaseOrderId', purchaseOrderId)
            const localVarPath = `/v1/events/purchase-orders/{purchaseOrderId}`
                .replace(`{${"purchaseOrderId"}}`, encodeURIComponent(String(purchaseOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DownloadPurchaseOrderPdfDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPurchaseOrderPdf: async (id: number, device?: DownloadPurchaseOrderPdfDeviceEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadPurchaseOrderPdf', 'id', id)
            const localVarPath = `/v1/events/purchase-orders/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find9: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find9', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/purchase-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById4: async (purchaseOrderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseOrderId' is not null or undefined
            assertParamExists('getById4', 'purchaseOrderId', purchaseOrderId)
            const localVarPath = `/v1/events/purchase-orders/{purchaseOrderId}`
                .replace(`{${"purchaseOrderId"}}`, encodeURIComponent(String(purchaseOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount4: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('getCount4', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/purchase-orders/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentPurchaseOrder: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('readCurrentPurchaseOrder', 'eventId', eventId)
            const localVarPath = `/v1/events/purchase-orders/current/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTickets: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderTickets', 'orderId', orderId)
            const localVarPath = `/v1/events/purchase-orders/tickets/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserCurrentOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/events/purchase-orders/shopping-cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseOrdersControllerApi - functional programming interface
 * @export
 */
export const PurchaseOrdersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseOrdersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {ApplyPromoCodeRequest} applyPromoCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyPromoCodeToPurchaseOrder(purchaseOrderId: number, applyPromoCodeRequest: ApplyPromoCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyPromoCodeToPurchaseOrder(purchaseOrderId, applyPromoCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById(purchaseOrderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById(purchaseOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DownloadPurchaseOrderPdfDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPurchaseOrderPdf(id: number, device?: DownloadPurchaseOrderPdfDeviceEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPurchaseOrderPdf(id, device, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find9(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePurchaseOrderUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find9(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById4(purchaseOrderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById4(purchaseOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount4(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount4(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCurrentPurchaseOrder(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderWithTicketsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCurrentPurchaseOrder(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderTickets(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderWithTicketsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderTickets(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserCurrentOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserShoppingCartDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserCurrentOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseOrdersControllerApi - factory interface
 * @export
 */
export const PurchaseOrdersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseOrdersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {ApplyPromoCodeRequest} applyPromoCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPromoCodeToPurchaseOrder(purchaseOrderId: number, applyPromoCodeRequest: ApplyPromoCodeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.applyPromoCodeToPurchaseOrder(purchaseOrderId, applyPromoCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById(purchaseOrderId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById(purchaseOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DownloadPurchaseOrderPdfDeviceEnum} [device] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPurchaseOrderPdf(id: number, device?: DownloadPurchaseOrderPdfDeviceEnum, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadPurchaseOrderPdf(id, device, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find9(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PagePurchaseOrderUserDto> {
            return localVarFp.find9(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById4(purchaseOrderId: number, options?: any): AxiosPromise<PurchaseOrderUserDto> {
            return localVarFp.getById4(purchaseOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount4(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount4(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentPurchaseOrder(eventId: number, options?: any): AxiosPromise<PurchaseOrderWithTicketsDto> {
            return localVarFp.readCurrentPurchaseOrder(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTickets(orderId: string, options?: any): AxiosPromise<PurchaseOrderWithTicketsDto> {
            return localVarFp.readOrderTickets(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserCurrentOrders(options?: any): AxiosPromise<Array<UserShoppingCartDto>> {
            return localVarFp.readUserCurrentOrders(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseOrdersControllerApi - object-oriented interface
 * @export
 * @class PurchaseOrdersControllerApi
 * @extends {BaseAPI}
 */
export class PurchaseOrdersControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} purchaseOrderId 
     * @param {ApplyPromoCodeRequest} applyPromoCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public applyPromoCodeToPurchaseOrder(purchaseOrderId: number, applyPromoCodeRequest: ApplyPromoCodeRequest, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).applyPromoCodeToPurchaseOrder(purchaseOrderId, applyPromoCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} purchaseOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public deleteById(purchaseOrderId: number, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).deleteById(purchaseOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DownloadPurchaseOrderPdfDeviceEnum} [device] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public downloadPurchaseOrderPdf(id: number, device?: DownloadPurchaseOrderPdfDeviceEnum, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).downloadPurchaseOrderPdf(id, device, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public find9(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).find9(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} purchaseOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public getById4(purchaseOrderId: number, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).getById4(purchaseOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public getCount4(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).getCount4(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public readCurrentPurchaseOrder(eventId: number, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).readCurrentPurchaseOrder(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public readOrderTickets(orderId: string, options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).readOrderTickets(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrdersControllerApi
     */
    public readUserCurrentOrders(options?: AxiosRequestConfig) {
        return PurchaseOrdersControllerApiFp(this.configuration).readUserCurrentOrders(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum DownloadPurchaseOrderPdfDeviceEnum {
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}
