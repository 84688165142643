/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageImplCountriesSalesStatisticsView } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * CountriesSalesStatisticsViewControllerApi - axios parameter creator
 * @export
 */
export const CountriesSalesStatisticsViewControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {ExportView3FormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportView3: async (pageable: Pageable, query?: string, format?: ExportView3FormatEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('exportView3', 'pageable', pageable)
            const localVarPath = `/v1/admin/statistics/countries/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findView3: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findView3', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/statistics/countries/view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount37: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/statistics/countries/view/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewMetadata3: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/statistics/countries/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesSalesStatisticsViewControllerApi - functional programming interface
 * @export
 */
export const CountriesSalesStatisticsViewControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesSalesStatisticsViewControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {ExportView3FormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportView3(pageable: Pageable, query?: string, format?: ExportView3FormatEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportView3(pageable, query, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findView3(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageImplCountriesSalesStatisticsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findView3(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount37(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount37(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewMetadata3(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewMetadata3(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesSalesStatisticsViewControllerApi - factory interface
 * @export
 */
export const CountriesSalesStatisticsViewControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesSalesStatisticsViewControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [query] 
         * @param {ExportView3FormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportView3(pageable: Pageable, query?: string, format?: ExportView3FormatEnum, options?: any): AxiosPromise<object> {
            return localVarFp.exportView3(pageable, query, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findView3(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageImplCountriesSalesStatisticsView> {
            return localVarFp.findView3(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount37(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount37(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewMetadata3(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getViewMetadata3(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesSalesStatisticsViewControllerApi - object-oriented interface
 * @export
 * @class CountriesSalesStatisticsViewControllerApi
 * @extends {BaseAPI}
 */
export class CountriesSalesStatisticsViewControllerApi extends BaseAPI {
    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [query] 
     * @param {ExportView3FormatEnum} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesSalesStatisticsViewControllerApi
     */
    public exportView3(pageable: Pageable, query?: string, format?: ExportView3FormatEnum, options?: AxiosRequestConfig) {
        return CountriesSalesStatisticsViewControllerApiFp(this.configuration).exportView3(pageable, query, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesSalesStatisticsViewControllerApi
     */
    public findView3(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return CountriesSalesStatisticsViewControllerApiFp(this.configuration).findView3(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesSalesStatisticsViewControllerApi
     */
    public getCount37(query?: string, options?: AxiosRequestConfig) {
        return CountriesSalesStatisticsViewControllerApiFp(this.configuration).getCount37(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesSalesStatisticsViewControllerApi
     */
    public getViewMetadata3(options?: AxiosRequestConfig) {
        return CountriesSalesStatisticsViewControllerApiFp(this.configuration).getViewMetadata3(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ExportView3FormatEnum {
    EXCEL = 'excel',
    PDF = 'pdf'
}
