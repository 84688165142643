import { EventPriceDto, EventSeatPriceDto, EventSeatUserDto } from '@/openapi'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { Button } from 'antd'
import html2pdf from 'html2pdf.js'
import { COLORS } from '@/constants/colors'

export type Props = {
  seatPrices?: EventSeatPriceDto[] | null
  eventSeats?: EventSeatUserDto[] | null
  eventPrices?: EventPriceDto[] | null
  definition?: string
}

export const ToPdf = ({ seatPrices, eventPrices, definition }: Props) => {
  const { appLang } = useLanguageContext()

  const downloadScheme = () => {
    if (!definition) {
      return
    }

    const temp = document.createElement('div')
    temp.innerHTML = definition

    const svg = temp.querySelector('svg')
    if (!svg) return

    svg.removeAttribute('width')
    svg.removeAttribute('height')

    const svgElements = svg.querySelectorAll('[data-definition]')
    if (svgElements.length > 0) {
      for (let i = 0; i < svgElements.length; i++) {
        const ele = svgElements.item(i) as SVGElement
        const defData = ele.dataset.definition
        if (defData) {
          const definition = JSON.parse(decodeURIComponent(defData))

          const id = ele.getAttribute('id')
          if (id) {
            const linkedEle = svg.querySelector(`[to=${id}]`)
            if (linkedEle) {
              linkedEle.innerHTML = definition.name[`value${appLang}`]
            }
          }
        }
      }
    }

    if (eventPrices && eventPrices.length > 0) {
      for (let i = 1; i < eventPrices.length; i++) {
        const eventPrice = eventPrices[i]
        for (const eventSeatPrice of seatPrices || []) {
          if (eventSeatPrice.eventSeat?.seatId) {
            if (eventSeatPrice.eventPrice?.id === eventPrice.id) {
              const ele = svg.getElementById(eventSeatPrice.eventSeat.seatId)
              if (ele) {
                ele.setAttribute('fill', COLORS[i])
              }
            }
          }
        }
      }
    }
    const div = document.createElement('div')
    const div2 = document.createElement('div')
    div2.innerHTML =
      document.querySelector('#ticketCategories')?.innerHTML || ''
    div.style.padding = '10px'
    div2
      .querySelectorAll('[data-type="delete"]')
      .forEach((button) => button.closest('td')?.remove())
    div.appendChild(svg)
    div.appendChild(div2)
    div2.style.marginTop = '15px'
    document.body.appendChild(div)
    const { width, height } = div.getBoundingClientRect()
    html2pdf(div, { jsPDF: { format: [width, height], unit: 'px' } })
    document.body.removeChild(div)
  }

  return (
    <Button onClick={downloadScheme} style={{ marginBottom: '10px' }}>
      {'Download schema as pdf'}
    </Button>
  )
}
