/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EventUpdateResponse } from './event-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { TicketControlPersonUpdateResponse } from './ticket-control-person-update-response';

/**
 * 
 * @export
 * @interface TicketControlActivityUpdateResponse
 */
export interface TicketControlActivityUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof TicketControlActivityUpdateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityUpdateResponse
     */
    'status'?: TicketControlActivityUpdateResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityUpdateResponse
     */
    'oneTimeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityUpdateResponse
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityUpdateResponse
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {EventUpdateResponse}
     * @memberof TicketControlActivityUpdateResponse
     */
    'event'?: EventUpdateResponse;
    /**
     * 
     * @type {TicketControlPersonUpdateResponse}
     * @memberof TicketControlActivityUpdateResponse
     */
    'controlPerson'?: TicketControlPersonUpdateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlActivityUpdateResponseStatusEnum {
    REQUESTED = 'requested',
    VERIFIED = 'verified',
    FAILED = 'failed',
    CANCELED = 'canceled'
}


