/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CountryCreateRequest
 */
export interface CountryCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'nameEN': string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'nameBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'descriptionBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'phoneCode': string;
    /**
     * 
     * @type {number}
     * @memberof CountryCreateRequest
     */
    'currencyId': number;
    /**
     * 
     * @type {number}
     * @memberof CountryCreateRequest
     */
    'vatRate': number;
    /**
     * 
     * @type {string}
     * @memberof CountryCreateRequest
     */
    'smsSender'?: CountryCreateRequestSmsSenderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CountryCreateRequestSmsSenderEnum {
    AWS = 'aws',
    TWILIO = 'twilio'
}


