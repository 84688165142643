import { Button, Descriptions, Form, Modal } from 'antd'
import { ActorTypeDto, TourDto, PageTourDto } from '@/openapi'
import commonStyles from '../commonStyles.module.less'
import Page from '../../components/Page'
import { useCallback } from 'react'

import { Params } from '@/types/Params'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import { SorterResult } from 'antd/es/table/interface'
import FloatInput from '../../components/FloatInput'
import { ActorAsyncSelect } from '@components/AsyncSelect/ActorAsyncSelect'
import { useNavigate } from 'react-router-dom'
import DropOption from '../../components/DropOption/DropOption'
import { twoColumnsDateFormat, valueOrDash } from '@pages/helpers'
import { PageHeader } from '@ant-design/pro-components'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

const ToursList = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<TourDto>
    },
    filters: { [key in keyof TourDto]: string } & { city: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.actors && filters.actors !== 'All') {
      query.push(`actors.id$$${filters.actors}`)
    }
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageTourDto>('admin/tours', { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/tours/' + id)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: ActorTypeDto, e: any) => {
    if (e.key === '1') {
      navigate(`/app/tour/${record.id}`)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns: ExtendedColumns<TourDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Tour name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Tour name`,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t`labels.Created Date`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      render: twoColumnsDateFormat,
    },
    {
      title: t`labels.Status`,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t`labels.Event Type`,
      dataIndex: 'eventType',
      key: 'eventType',
      render: (_, s) => valueOrDash(s?.eventType?.name),
    },
    {
      title: t`labels.Events number`,
      dataIndex: 'tourCount',
      key: 'tourCount',
      width: '100px',
      render: (s) => valueOrDash(s?.totalEvents),
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Actors`}</div>
          <ActorAsyncSelect
            submit={submit}
            width="100%"
            name="actors"
            noLabel
          />
        </div>
      ),
      shortTitle: t`labels.Actors`,
      dataIndex: 'actors',
      key: 'actors',
      render: (t: ActorTypeDto[]) => t?.map(({ name }) => name).join(', '),
    },

    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <DropOption
              onMenuClick={(e) => {
                handleMenuClick(record, e)
              }}
              menuOptions={[{ key: '1', name: t`View/Edit` }]}
            />
          </div>
        )
      },
    },
  ]

  const expandedRowRender = useCallback((item: TourDto) => {
    return (
      <>
        <Descriptions title={t`labels.Tour Count`}>
          <Descriptions.Item label={t`labels.Total Events`}>
            {valueOrDash(item.tourCount?.totalEvents)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Ready For Announce`}>
            {valueOrDash(item.tourCount?.readyForAnnounce)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Ready For Publish`}>
            {valueOrDash(item.tourCount?.readyForPublish)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Announced`}>
            {valueOrDash(item.tourCount?.announced)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Published`}>
            {valueOrDash(item.tourCount?.published)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Canceled`}>
            {valueOrDash(item.tourCount?.canceled)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Archived`}>
            {valueOrDash(item.tourCount?.archived)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Total Cities`}>
            {valueOrDash(item.tourCount?.totalCities)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Total Countries`}>
            {valueOrDash(item.tourCount?.totalCountries)}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title={t`labels.Tour Sales`}>
          <Descriptions.Item label={t`labels.Total Tickets Sold`}>
            {valueOrDash(item.tourSales?.totalTicketsSold)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Predicted Tickets Sold`}>
            {valueOrDash(item.tourSales?.predictedTicketsSold)}
          </Descriptions.Item>
          <Descriptions.Item label={t`labels.Sold Out Percentage`}>
            {valueOrDash(item?.tourSales?.soldOutPercentage?.toFixed(2), '%')}
          </Descriptions.Item>
        </Descriptions>
      </>
    )
  }, [])

  return (
    <Page inner>
      <PageHeader title={t`labels.Tours List`} />
      <Form form={form}>
        <DragTable<TourDto>
          bordered
          actions={
            <Button key="add-new" onClick={() => navigate('/app/tour/new')}>
              {t`labels.Create Tour`}
            </Button>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          expandable={{ expandedRowRender }}
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(`/app/tour/${record.id}?step=1&view=table-view`),
            }
          }}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default ToursList
