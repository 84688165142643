import { useCallback, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import Page from 'components/Page'
import {
  ActorDto,
  PageActorDto,
  PictureTypeUserDtoEntityTypeEnum,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@components/FloatInput'
import ActorForm from './actorForm'

import { Params } from '@/types/Params'
import { SelectOption } from '@/types/Option'
import commonStyles from '../commonStyles.module.less'
import { ActorTypeAsyncSelect } from '@components/AsyncSelect/ActorTypeAsyncSelect'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { MediaModalExtended } from '@components/MediaModalExtended'
import { AgencyAsyncSelect } from '@components/AsyncSelect/AgencyAsyncSelect'
import ActorSocialLinks from './SocialLinks'
import { useTranslation } from 'react-i18next'
import { DragTable } from '@components/DragTable'
import { useAdminColumns } from '@/hooks/adminColumns'

export type CustomFormData = ActorDto & {
  actorTypeId: SelectOption
  agencyId?: SelectOption | number
}

const Actor = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<ActorDto>()
  const { languageFields } = useCatalogMultiLanguageFields('actors')
  const [mediaId, setMediaId] = useState<number | undefined>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<ActorDto>
    },
    filters: { [key in keyof ActorDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.actorType && filters.actorType !== 'All') {
      query.push(`actorType.id==${filters.actorType}`)
    }
    if (filters.agency && filters.agency !== 'All') {
      query.push(`agency.id==${filters.agency}`)
    }
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageActorDto>('admin/actors', { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/actors/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: ActorDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '3') {
      setMediaId(record.id!)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns = useAdminColumns<ActorDto>(
    [
      {
        title: 'ID',
        fixed: 'left',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        width: '70px',
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`Actor name`}</div>
            <Form.Item name="name">
              <FloatInput label="" onChange={submit} />
            </Form.Item>
          </div>
        ),
        shortTitle: t`Actor name`,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`labels.Actor type`}</div>
            <ActorTypeAsyncSelect fullWidth submit={submit} noLabel />
          </div>
        ),
        shortTitle: t`labels.Actor type`,
        dataIndex: 'actorType',
        key: 'actorType',
        render: (t) => t.name,
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`labels.Agency`}</div>
            <AgencyAsyncSelect width="100%" submit={submit} noLabel />
          </div>
        ),
        shortTitle: t`labels.Agency`,
        dataIndex: 'agency',
        key: 'agency',
        render: (t) => t?.name,
      },
      {
        title: t`actions`,
        key: 'operation',
        noDrag: true,
        noTooltip: true,
        render: (text, record) => {
          return (
            <DropOption
              onMenuClick={(e) => handleMenuClick(record, e)}
              menuOptions={[
                { key: '1', name: t`update` },
                { key: '3', name: t`media` },
                { key: '2', name: t`delete` },
              ]}
            />
          )
        },
      },
    ],
    ['agency'],
  )

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<ActorDto>
          bordered
          actions={
            <Button
              className={commonStyles.addButton}
              onClick={() => setIsOpenModal(true)}
            >
              {t`add`}
            </Button>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={activeItem ? `${t`update`} ${activeItem.name}` : 'Add actor'}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <ActorForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
      {mediaId && (
        <MediaModalExtended
          entity={PictureTypeUserDtoEntityTypeEnum.ACTOR}
          id={mediaId}
          close={() => setMediaId(undefined)}
          footer={<ActorSocialLinks id={mediaId} />}
        />
      )}
    </Page>
  )
}

export default Actor
