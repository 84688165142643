import { useCallback, useState } from 'react'
import { Modal, Form, Button, Space } from 'antd'
import Page from 'components/Page'
import {
  PromoCodeDto,
  PagePromoCodeDto,
  PromoCodeDtoTypeEnum,
  EventExtendedDto,
  EventExtendedDtoEventStatusEnum,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@/components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@/components/FloatInput'
import PromoCodesForm from './Form'

import { Params } from '@/types/Params'
import commonStyles from '@/pages/commonStyles.module.less'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = PromoCodeDto

const PromoCodes = ({ event }: { event?: EventExtendedDto }) => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<PromoCodeDto>()
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<PromoCodeDto>
    },
    filters: { [key in keyof CustomFormData]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [`event.id==${eventId}`]
    if (filters.code) {
      query.push(`code~~${filters.code}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PagePromoCodeDto>(`admin/promo-codes`, {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/promo-codes/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: PromoCodeDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns: ExtendedColumns<PromoCodeDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Code`}</div>
          <Form.Item name="code">
            <FloatInput label={t`labels.Code`} onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Code`,
      dataIndex: 'code',
      key: 'code',
      sorter: true,
    },
    {
      title: t`labels.Value`,
      dataIndex: 'value',
      key: 'value',
      sorter: true,
      render: (val, record) =>
        val + (record.type === PromoCodeDtoTypeEnum.PERCENT ? '%' : ''),
    },
    {
      title: t`labels.Quantity`,
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      render: (val) => val || '∞',
    },
    {
      title: t`labels.Minimum Purchase`,
      dataIndex: 'minPurchase',
      key: 'minPurchase',
      sorter: true,
      render: (val) => val || '',
    },
    {
      title: t`labels.Valid Thru`,
      dataIndex: 'validThru',
      key: 'validThru',
      sorter: true,
      render: (val) =>
        val && (
          <>
            <div>{dayjs(val).format('YYYY-MM-DD')}</div>
            <div style={{ fontSize: '12px' }}>
              {dayjs(val).format('HH:mm:ss')}
            </div>
          </>
        ),
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ]

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<PromoCodeDto>
          bordered
          actions={
            <Space wrap>
              <Button type="primary" onClick={() => setIsOpenModal(true)}>
                {t`add`}
              </Button>
              <Button type="primary" onClick={refresh}>
                {t`labels.Refresh`}
              </Button>
              {event?.eventStatus ===
                EventExtendedDtoEventStatusEnum.UNPUBLISHED && (
                <Link to={`/app/event/${eventId}`}>
                  <Button type="primary" onClick={refresh}>
                    {t`labels.Edit Event`}
                  </Button>
                </Link>
              )}
            </Space>
          }
          actionsMobile
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={activeItem ? t`labels.Update entry` : t`labels.Add entry`}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
      >
        <PromoCodesForm onSuccess={handleSuccess} id={activeItem?.id} />
      </Modal>
    </Page>
  )
}

export default PromoCodes
