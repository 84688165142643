/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PhoneDto } from './phone-dto';

/**
 * 
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
    /**
     * 
     * @type {PhoneDto}
     * @memberof UserLoginRequest
     */
    'phone'?: PhoneDto;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'passcode': string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'loginType': UserLoginRequestLoginTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserLoginRequestLoginTypeEnum {
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    USERNAME = 'USERNAME',
    FACEBOOK = 'FACEBOOK',
    GOOGLE = 'GOOGLE'
}


