/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PurchaseOrderDto } from './purchase-order-dto';
// May contain unused imports in some cases
// @ts-ignore
import { TicketDto } from './ticket-dto';

/**
 * 
 * @export
 * @interface TicketRefundDto
 */
export interface TicketRefundDto {
    /**
     * 
     * @type {number}
     * @memberof TicketRefundDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketRefundDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketRefundDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketRefundDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketRefundDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketRefundDto
     */
    'type'?: TicketRefundDtoTypeEnum;
    /**
     * 
     * @type {PurchaseOrderDto}
     * @memberof TicketRefundDto
     */
    'purchaseOrder'?: PurchaseOrderDto;
    /**
     * 
     * @type {TicketDto}
     * @memberof TicketRefundDto
     */
    'ticket'?: TicketDto;
    /**
     * 
     * @type {string}
     * @memberof TicketRefundDto
     */
    'refundId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketRefundDto
     */
    'amount'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketRefundDtoTypeEnum {
    PARTIAL = 'partial',
    FULL = 'full'
}


