import { Image } from 'antd'

type props = {
  definition: string | undefined
}
const StageDefinitionPreview = ({ definition }: props) => {
  function b64EncodeUnicode(str: string) {
    return window.btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        },
      ),
    )
  }

  const getBase64 = () => {
    if (definition) {
      definition = definition.replace(
        /[^<text]xmlns="http:\/\/www.w3.org\/1999\/xhtml"[^>]/gm,
        ' ',
      )
      return 'data:image/svg+xml;base64,' + b64EncodeUnicode(definition)
    }
  }
  return <Image src={getBase64()} width={200} height={200} />
}

export default StageDefinitionPreview
