/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorUserDto } from './actor-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyUserDto } from './agency-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CityUserDto } from './city-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CurrencyUserDto } from './currency-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventGroupViewDto } from './event-group-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeUserDto } from './event-type-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StageUserDto } from './stage-user-dto';

/**
 * 
 * @export
 * @interface EventUserDto
 */
export interface EventUserDto {
    /**
     * 
     * @type {number}
     * @memberof EventUserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'admissionStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'admissionEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'saleStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'saleEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUserDto
     */
    'eventStatus'?: EventUserDtoEventStatusEnum;
    /**
     * 
     * @type {Array<ActorUserDto>}
     * @memberof EventUserDto
     */
    'actors'?: Array<ActorUserDto>;
    /**
     * 
     * @type {Array<EventGroupViewDto>}
     * @memberof EventUserDto
     */
    'eventGroups'?: Array<EventGroupViewDto>;
    /**
     * 
     * @type {AgencyUserDto}
     * @memberof EventUserDto
     */
    'agency'?: AgencyUserDto;
    /**
     * 
     * @type {EventTypeUserDto}
     * @memberof EventUserDto
     */
    'eventType'?: EventTypeUserDto;
    /**
     * 
     * @type {CityUserDto}
     * @memberof EventUserDto
     */
    'city'?: CityUserDto;
    /**
     * 
     * @type {StageUserDto}
     * @memberof EventUserDto
     */
    'stage'?: StageUserDto;
    /**
     * 
     * @type {CurrencyUserDto}
     * @memberof EventUserDto
     */
    'currency'?: CurrencyUserDto;
    /**
     * 
     * @type {number}
     * @memberof EventUserDto
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventUserDto
     */
    'minPriceSeatsLeft'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventUserDto
     */
    'soldOut'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum EventUserDtoEventStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}


