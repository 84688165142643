import { useCallback, useState } from 'react'
import { Modal, Form, Button, Popover } from 'antd'
import Page from 'components/Page'
import { MarketingLinkDto, PageMarketingLinkDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@/components/FloatInput'
import PromoLinkForm from './Form'

import { Params } from '@/types/Params'
import commonStyles from '@/pages/commonStyles.module.less'
import { useParams } from 'react-router-dom'
import DropOption from '@components/DropOption/DropOption'
import { CopyOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '@/types/Option'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = MarketingLinkDto & {
  budgetCurrencyId: SelectOption
}

type Props = {
  entity?: 'event' | 'group'
  showAdd?: boolean
}

const path = {
  event: 'event.id',
  group: 'eventGroupId',
}

const MarketingLinks = ({ entity = 'event', showAdd = true }: Props) => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<MarketingLinkDto>()
  const { id: entityId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<MarketingLinkDto>
    },
    filters: { [key in keyof CustomFormData]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [`${path[entity]}==${entityId}`]
    if (filters.source) {
      query.push(`source~~${filters.source}`)
    }
    if (filters.utmCampaign) {
      query.push(`utmCampaign~~${filters.utmCampaign}`)
    }
    if (filters.utmContent) {
      query.push(`utmContent~~${filters.utmContent}`)
    }
    if (filters.utmTerm) {
      query.push(`utmTerm~~${filters.utmTerm}`)
    }
    if (filters.utmSource) {
      query.push(`utmSource~~${filters.utmSource}`)
    }
    if (filters.utmMedium) {
      query.push(`utmMedium~~${filters.utmMedium}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageMarketingLinkDto>(`admin/links`, {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete(`admin/links/${id}`)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback(
    (record: MarketingLinkDto, e: any) => {
      if (e.key === '1') {
        setActiveItem(record)
        setIsOpenModal(true)
      } else if (e.key === '2') {
        Modal.confirm({
          title: t`Are you sure delete this record?`,
          onOk: async () => {
            await deleteItem(record.id!)
            refresh()
          },
        })
      }
    },
    [entityId],
  )

  const columns: ExtendedColumns<MarketingLinkDto>[] = [
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.source`}</div>
          <Form.Item name="source">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.source`,
      dataIndex: 'source',
      key: 'source',
      sorter: true,
    },
    {
      title: t`labels.Device`,
      dataIndex: 'device',
      key: 'device',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{'UTM Campaign'}</div>
          <Form.Item name="utmCampaign">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: 'UTM Campaign',
      dataIndex: 'utmCampaign',
      key: 'utmCampaign',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{'UTM Medium'}</div>
          <Form.Item name="utmMedium">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmMedium',
      key: 'utmMedium',
      shortTitle: 'UTM Medium',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{'UTM Source'}</div>
          <Form.Item name="utmSource">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmSource',
      key: 'utmSource',
      shortTitle: 'UTM Source',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{'UTM Term'}</div>
          <Form.Item name="utmTerm">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmTerm',
      key: 'utmTerm',
      shortTitle: 'UTM Term',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{'UTM Content'}</div>
          <Form.Item name="utmContent">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmContent',
      key: 'utmContent',
      shortTitle: 'UTM Content',
    },
    {
      title: t`link`,
      dataIndex: 'shortLink',
      key: 'shortLink',
      width: '70px',
      noTooltip: true,
      render: (text) => (
        <Popover content={text} title={t`labels.Click to copy to clipboard`}>
          <Button
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(text).then(() => {
                toast.success(t`messages.Copied to clipboard`)
              })
            }}
          />
        </Popover>
      ),
    },
    {
      title: t`labels.budget`,
      dataIndex: 'budget',
      key: 'budget',
      width: '90px',
      render: (_, t) => `${t.budget || ''} ${t.budgetCurrency?.code || ''}`,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ]

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<MarketingLinkDto>
          bordered
          actions={
            showAdd && (
              <Button type="primary" onClick={() => setIsOpenModal(true)}>
                {t`add`}
              </Button>
            )
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={activeItem ? t`labels.Update entry` : t`labels.Add entry`}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
      >
        <PromoLinkForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          entity={entity}
        />
      </Modal>
    </Page>
  )
}

export default MarketingLinks
