import { Menu, Space } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { EventGroupDto } from '@/openapi'
import { axiosInstance } from '@/api'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { MenuProps } from 'antd/lib/menu'
import { useTranslation } from 'react-i18next'
import MarketingLinks from '@components/pages/marketingLinks'

const ManageEventsGroup = () => {
  const [search, setSearch] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const [eventGroup, setEventGroup] = useState<EventGroupDto>()
  const { getLocalizedKey } = useLanguageContext()
  const { t } = useTranslation()

  useEffect(() => {
    axiosInstance
      .get<EventGroupDto>(`admin/event-groups/${id}`)
      .then(({ data }) => {
        setEventGroup(data)
      })
  }, [id])

  useEffect(() => {
    if (!search.get('tab')) {
      setSearch({ tab: 'marketing-links' })
    }
  }, [])

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: t`tabs.Marketing`,
        key: 'marketing',
        children: [
          {
            label: t`tabs.Marketing Links`,
            key: 'marketing-links',
          },
        ],
      },
    ],
    [eventGroup, search, id, t],
  )

  const subtitle = useMemo(
    () => <Space wrap>{eventGroup?.name}</Space>,
    [eventGroup],
  )

  return (
    <>
      <PageHeader title={t`labels.Manage Event Groups`} subTitle={subtitle} />
      <Menu
        onClick={(tab) => setSearch({ tab: tab.key })}
        selectedKeys={[search.get('tab') || 'marketing-links']}
        mode="horizontal"
        items={items}
      />

      {search.get('tab') === 'marketing-links' && (
        <MarketingLinks entity="group" />
      )}
    </>
  )
}

export default ManageEventsGroup
