import { axiosInstance } from '@/api'
import {
  PageTicketDto,
  StageDefinitionDto,
  TicketDto,
  TicketsBookedDto,
} from '@/openapi'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import styles from '@pages/event/styles.module.less'
import { LeftPartModal } from '@pages/transferEvent/components/LeftPartModal'

export type Props = {
  eventId: string
  definition: StageDefinitionDto
  onSelect: (ticket?: TicketDto) => void
}

export const LeftPartWithSD = ({ eventId, definition, onSelect }: Props) => {
  const definitionWrapperRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<Element>()
  const selectionFrame = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const [selectedSeat, setSelectedSeat] = useState<string | undefined>()
  const [modalParams, setModalParams] = useState<string[]>()
  const [selectedTicket, setSelectedTicket] = useState<TicketDto | undefined>()
  const { appLang } = useLanguageContext()
  const svgString = useRef('')

  const removeSelection = useCallback(() => {
    setSelectedTicket(undefined)
    onSelect(undefined)
    setSelectedSeat((old) => {
      clearSelection(old)
      return undefined
    })
  }, [])

  const ellipseClickHandler = useCallback(async (e: Event) => {
    e.stopPropagation()
    const target = e.target
    let ellipse

    if (target instanceof SVGTextElement) {
      const toSeat = target.dataset.toSeat || target.getAttribute('to')
      if (toSeat) {
        ellipse = document.getElementById(toSeat)
      }
    } else {
      ellipse = target
    }

    if (ellipse instanceof SVGElement) {
      const id = ellipse.getAttribute('id')
      const {
        data: { content },
      } = await axiosInstance.get<PageTicketDto>(
        `admin/tickets?query=eventSeat.event.id==${eventId};eventSeat.seatId==${id};status==purchased`,
      )
      removeSelection()
      if (!content?.length) return

      const selected = ellipse.dataset.selected
      if (!selected || selected === 'false') {
        if (id) {
          setSelectedSeat(id)
        }
        ellipse.setAttribute('stroke', 'red')
        ellipse.setAttribute('stroke-width', '3')
        ellipse.dataset.selected = 'true'
      } else {
        if (id) {
          setSelectedSeat(id)
        }

        ellipse.setAttribute('stroke', '#000000')
        ellipse.setAttribute('stroke-width', '1')
        ellipse.dataset.selected = 'false'
      }
      if (content.length === 1) {
        setSelectedTicket(content[0])
        onSelect(content[0])
      } else {
        setModalParams([
          `eventSeat.event.id==${eventId}`,
          `eventSeat.seatId==${id}`,
        ])
      }
    }
  }, [])

  const clearSelection = (id?: string): void => {
    if (!id) return
    const element = document.getElementById(id)
    if (element) {
      element.setAttribute('stroke', '#000000')
      element.setAttribute('stroke-width', '1')
      element.dataset.selected = 'false'
    }
  }

  const getData = useCallback(async () => {
    svgString.current! = definition.definition || ''
    const temp = document.createElement('template')
    temp.innerHTML = definition.definition || ''
    if (temp.content.firstChild) {
      definitionWrapperRef.current!.appendChild(temp.content.firstChild)
    }

    const svg = definitionWrapperRef.current!.children[1]
    svgRef.current = svg

    svg?.removeAttribute('width')
    svg?.removeAttribute('height')

    const svgElements = svgRef.current!.querySelectorAll('[data-definition]')
    if (svgElements.length > 0) {
      for (let i = 0; i < svgElements.length; i++) {
        const ele = svgElements.item(i) as SVGElement
        const defData = ele.dataset.definition
        if (defData) {
          const definition = JSON.parse(decodeURIComponent(defData))

          const id = ele.getAttribute('id')
          if (id) {
            const linkedEle = svgRef.current!.querySelector(`[to="${id}"]`)
            if (linkedEle) {
              linkedEle.innerHTML = definition.name[`value${appLang}`]
            }
          }
        }
      }
    }

    const {
      data: { content: eventSeats },
    } = await axiosInstance.get(
      `admin/event-seats?size=50000&query=event.id==${eventId}`,
    )

    const seats: { [key: string]: { id: number } } = {}

    for (const eventSeat of eventSeats) {
      seats[eventSeat.seatId] = {
        id: eventSeat.id,
      }

      const ele = document.getElementById(eventSeat.seatId)
      if (ele) {
        ele.style.cursor = 'default'
      }
      const textEle =
        document.querySelector<SVGElement>(
          `[data-to-seat="${eventSeat.seatId}"]`,
        ) || document.querySelector<SVGElement>(`[to="${eventSeat.seatId}"]`)
      if (textEle) {
        textEle.style.pointerEvents = 'none'
      }
    }

    const {
      data: { purchasedSeatIds },
    } = await axiosInstance.get<TicketsBookedDto>(
      `admin/tickets/sold/${eventId}`,
    )

    for (const purchasedID of purchasedSeatIds || []) {
      const ele = svgRef.current?.querySelector<SVGElement>(`#${purchasedID}`)
      if (ele) {
        ele.setAttribute('fill', 'green')
        ele.dataset.purchased = 'purchased'
        ele.addEventListener('click', ellipseClickHandler)
        ele.style.cursor = 'pointer'
      }
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  return (
    <Card style={{ marginTop: '15px' }}>
      <Card
        style={{ height: '100%' }}
        headStyle={{ textAlign: 'center' }}
        title={t('StepSeatAssignment.mapTitle')}
      >
        {!!selectedSeat && (
          <Button
            size="small"
            style={{
              position: 'absolute',
              top: '62px',
              right: '10px',
              zIndex: '1',
            }}
            onClick={removeSelection}
          >
            {t('StepSeatAssignment.removeSelectionButton')}
          </Button>
        )}
        <div
          ref={definitionWrapperRef}
          style={{ position: 'relative', userSelect: 'none' }}
        >
          <div id={styles.selectionFrame} ref={selectionFrame} />
        </div>
      </Card>
      {!!modalParams?.length && (
        <LeftPartModal
          onOk={(ticket?: TicketDto) => {
            setSelectedTicket(ticket)
            onSelect(ticket)
            setModalParams([])
          }}
          onCancel={() => {
            setModalParams([])
            removeSelection()
          }}
          queryStrings={modalParams}
        />
      )}
    </Card>
  )
}
