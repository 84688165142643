import { Form, Select } from 'antd'
import Page from 'components/Page'
import {
  UserDto,
  PageUserDto,
  NotificationLog,
  NotificationLogTypeEnum,
  NotificationLogChannelEnum,
  NotificationLogStatusEnum,
} from '@/openapi'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import { Params } from 'types/Params'
import { SelectOption } from 'types/Option'
import commonStyles from '@pages/commonStyles.module.less'
import { SorterResult } from 'antd/es/table/interface'
import { twoColumnsDateFormat } from '@pages/helpers'
import { Dayjs } from 'dayjs'
import { CustomDatePicker } from '@components/DatePicker'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'
const { Option } = Select

export const channelOptions = [
  { value: NotificationLogChannelEnum.SMS, label: 'Sms' },
  { value: NotificationLogChannelEnum.EMAIL, label: 'Email' },
]

export const statusOptions = [
  { value: NotificationLogStatusEnum.DELIVERED, label: 'Delivered' },
  { value: NotificationLogStatusEnum.FAILED, label: 'Failed' },
  { value: NotificationLogStatusEnum.IN_PROGRESS, label: 'In Progress' },
  { value: NotificationLogStatusEnum.SENT, label: 'Sent' },
]

export const typeOptions = [
  { value: NotificationLogTypeEnum.GIFT, label: 'Gift' },
  { value: NotificationLogTypeEnum.NOTIFICATION, label: 'Notification' },
  { value: NotificationLogTypeEnum.MARKETING, label: 'Marketing' },
  { value: NotificationLogTypeEnum.REGISTRATION, label: 'Registration' },
  {
    value: NotificationLogTypeEnum.PURCHASE_COMPLETED,
    label: 'Purchase Completed',
  },
  { value: NotificationLogTypeEnum.ALERT, label: 'Alert' },
]

export const prepareItem = (item: string) =>
  item
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ')

export type CustomFormData = UserDto & { countryId: SelectOption }

type Props = {
  user?: UserDto
  baseQuery: string
}
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'
const NotificationLogs = ({ baseQuery }: Props) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<NotificationLog>
    },
    filters: { [key in keyof NotificationLog]: string } & {
      dates: {
        from: Dayjs
        to: Dayjs
      }
    },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = [baseQuery]
    if (filters.dates?.to && filters.dates?.from) {
      query.push(
        `createdDate~DATE_BEFORE~${filters.dates.to.format(DATE_FORMAT)}`,
      )
      query.push(
        `createdDate~DATE_AFTER~${filters.dates.from.format(DATE_FORMAT)}`,
      )
    }
    if (filters.channel) {
      query.push(`channel~~${filters.channel}`)
    }
    if (filters.type) {
      query.push(`type~~${filters.type}`)
    }
    if (filters.status) {
      query.push(`status~~${filters.status}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')

    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageUserDto>('admin/notification_logs', {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const columns: ExtendedColumns<NotificationLog>[] = [
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Channel`}</div>
          <Form.Item name="channel">
            <Select onClick={(e) => e.stopPropagation()} onChange={submit}>
              {channelOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ),
      dataIndex: 'channel',
      key: 'channel',
      width: '140px',
      render: prepareItem,
      fixed: 'left',
      shortTitle: t`labels.Channel`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Type`}</div>
          <Form.Item name="type">
            <Select onClick={(e) => e.stopPropagation()} onChange={submit}>
              {typeOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ),
      dataIndex: 'type',
      key: 'type',
      render: prepareItem,
      width: '230px',
      shortTitle: t`labels.Type`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Status`}</div>
          <Form.Item name="status">
            <Select onClick={(e) => e.stopPropagation()} onChange={submit}>
              {statusOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: prepareItem,
      width: '160px',
      shortTitle: `labels.Status`,
    },
    {
      title: t`labels.Destination`,
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: t`labels.Message Sid`,
      dataIndex: 'messageSid',
      key: 'messageSid',
    },
    {
      title: t`labels.Order Id`,
      dataIndex: 'purchaseOrderId',
      key: 'purchaseOrderId',
    },
    {
      title: t`labels.Failure Reason`,
      dataIndex: 'failureReason',
      key: 'failureReason',
    },
    {
      title: t`labels.Created Date`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: twoColumnsDateFormat,
      sorter: true,
    },
  ]

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<UserDto>
          bordered
          actionsMobile
          actions={
            <Form.Item name="dates" style={{ marginBottom: '-20px' }}>
              <CustomDatePicker onChange={submit} />
            </Form.Item>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default NotificationLogs
