import { Dropdown, MenuProps, Radio, Tag } from 'antd'
import { useLanguageContext } from 'contexts/LanguageProvider'
import { useCallback, useMemo } from 'react'
import { EventCreateRequestLanguagesEnum } from '@/openapi'
import LanguagesEnum = EventCreateRequestLanguagesEnum
import { UserOutlined } from '@ant-design/icons'
import styles from './Header.module.less'
import { useNavigate } from 'react-router-dom'
import CountryFlag from '@/components/CountryFlag/CountryFlag'
import { useAuthContext } from '@/contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import useIsMobile from '@/hooks/useIsMobile'

const HeaderContent = () => {
  const { languages, setApplicationLanguage, appLang } = useLanguageContext()
  const navigator = useNavigate()
  const { me, logout } = useAuthContext()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: t`labels.Logout`,
      },
    ],
    [],
  )

  const onClick = useCallback((e: any) => {
    if (e.key === '1') {
      logout()
      navigator('/login')
    }
  }, [])

  return (
    <div className={cn(styles.container)}>
      <div className={cn(me?.agency?.name && styles.agency)}>
        {me?.agency?.name && <Tag color="#108ee9">{me.agency.name}</Tag>}
        <Radio.Group
          value={appLang}
          onChange={(e) => setApplicationLanguage(e.target.value)}
          size={isMobile ? 'small' : 'middle'}
        >
          {languages.map((lang) => (
            <Radio.Button value={lang} key={lang}>
              <CountryFlag
                countryCode={
                  lang === LanguagesEnum.EN ? 'US' : (lang as never as string)
                }
              />
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <Dropdown
        menu={{ items, onClick }}
        placement="topRight"
        arrow={{ pointAtCenter: true }}
        className={styles.menu}
      >
        <UserOutlined style={{ fontSize: '24px' }} />
      </Dropdown>
    </div>
  )
}

export default HeaderContent
