import { useCallback, useState } from 'react'
import { Table, Form, TableColumnsType } from 'antd'
import Page from 'components/Page'
import {
  EventPriceSales,
  EventsSalesDashboardViewV2,
  EventsSalesStatisticsView,
} from '@/openapi'
import { axiosInstance } from 'api'
import commonStyles from '../commonStyles.module.less'
import { alpabeticalSort, twoColumnsDateFormat } from '@pages/helpers'
import FloatInput from '@components/FloatInput'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '@/contexts/AuthContext'
import DropOption from '@components/DropOption/DropOption'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'
import { CustomDatePicker } from '@components/DatePicker'
import { SorterResult } from 'antd/es/table/interface'
import { Dayjs } from 'dayjs'
import { useAntdTable } from 'ahooks'
import { Params } from '@/types/Params'

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'

const Dashboard = () => {
  const [form] = Form.useForm()
  const [data, setData] = useState<EventsSalesDashboardViewV2>()

  const { isAdmin } = useAuthContext()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const getData = useCallback(
    async (
      {
        current,
        pageSize,
        sorter,
      }: {
        current: number
        pageSize: number
        sorter: SorterResult<EventsSalesStatisticsView>
      },
      filters: { [key in keyof EventsSalesStatisticsView]: string } & {
        dates: {
          from: Dayjs
          to: Dayjs
        }
      },
    ) => {
      let query = []
      const params: Params = { page: current - 1, size: pageSize }
      if (filters.eventName) {
        query.push(`eventName~~${filters.eventName}`)
      }
      if (filters.city) {
        query.push(`city~~${filters.city}`)
      }
      if (filters.agencyName) {
        query.push(`agencyName~~${filters.agencyName}`)
      }

      if (sorter?.columnKey) {
        params.sort = `${sorter.columnKey},${
          sorter.order === 'ascend' ? 'asc' : 'desc'
        }`
      }
      params.query = query.join(';')

      const { data } = await axiosInstance.get<EventsSalesDashboardViewV2>(
        `admin/dashboard?from=${filters.dates?.from?.format(
          DATE_FORMAT,
        )}&to=${filters.dates.to.format(DATE_FORMAT)}`,
        { apiVersion: 2, params },
      )

      setData(data)

      return {
        list: data.salesStatistics?.content || [],
        total: data.salesStatistics?.totalElements || 0,
      }
    },
    [],
  )

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 20,
    form,
  })

  const handleMenuClick = useCallback(
    (record: EventsSalesStatisticsView, e: any) => {
      if (e.key === '1') {
        navigate(`/app/event/${record.id}/manage?tab=sold-tickets`)
      } else if (e.key === '2') {
        navigate(`/app/event/${record.id}/manage?tab=denomination-report`)
      }
    },
    [],
  )

  const columns: ExtendedColumns<EventsSalesStatisticsView>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '70px',
      fixed: 'left',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="eventName">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'eventName',
      shortTitle: t`name`,
      key: 'eventName',
      sorter: (a, b) => alpabeticalSort(a.eventName!, b.eventName!),
    },
    {
      title: t`labels.Event Date`,
      dataIndex: 'eventDate',
      key: 'eventDate',
      defaultSortOrder: 'ascend',
      render: twoColumnsDateFormat,
      sorter: true,
    },
    {
      title: t`labels.Stage Name`,
      dataIndex: 'stageName',
      key: 'stageName',
      sorter: true,
    },
    {
      title: t`labels.Purchased Tickets`,
      dataIndex: 'purchasedTickets',
      key: 'purchasedTickets',
      sorter: true,
    },
    {
      title: t`labels.Sales Amount`,
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      sorter: true,
      render: (_, record) => `${record.salesAmount} ${record.currencyCode}`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.City`}</div>
          <Form.Item name="city">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'city',
      shortTitle: t`labels.City`,
      key: 'city',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Agency`}</div>
          <Form.Item name="agencyName">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Agency`,
      dataIndex: 'agencyName',
      key: 'agencyName',
      sorter: true,
    },
    ...(isAdmin
      ? [
          {
            title: t`labels.Service fee`,
            dataIndex: 'serviceFeeAmount',
            key: 'serviceFeeAmount',
          },
        ]
      : []),
    {
      title: t`actions`,
      key: 'operation',
      width: '90px',
      noTooltip: true,
      noDrag: true,
      render: (text, record) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <DropOption
              onMenuClick={(e) => handleMenuClick(record, e)}
              menuOptions={[
                { key: '1', name: t`labels.View sold tickets` },
                { key: '2', name: t`labels.View denomination report` },
              ]}
            />
          </div>
        )
      },
    },
  ]

  const expandedRowRender = useCallback(
    (item: EventsSalesStatisticsView) => {
      const columns: TableColumnsType<EventPriceSales> = [
        { title: t`name`, dataIndex: 'eventPriceName', key: 'eventPriceName' },
        {
          title: t`labels.Count`,
          dataIndex: 'soldTicketsCount',
          key: 'soldTicketsCount',
        },
        {
          title: t`labels.Amount`,
          dataIndex: 'salesAmount',
          key: 'salesAmount',
        },
        {
          title: t`labels.Service fee`,
          dataIndex: 'serviceFeeAmount',
          key: 'serviceFeeAmount',
        },
      ]

      return (
        <Table
          columns={columns}
          dataSource={item.eventPriceSales}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      )
    },
    [t],
  )

  return (
    <Page inner>
      <Form form={form}>
        <Form.Item name="dates" style={{ marginBottom: '-20px' }}>
          <CustomDatePicker
            onChange={submit}
            saveString="dashboard"
            saveToLocalStorage
          />
        </Form.Item>
        <DragTable<EventsSalesStatisticsView>
          rowKey={(record) => String(record.id)}
          bordered
          expandable={
            isAdmin
              ? {
                  expandedRowRender,
                  rowExpandable: (item) => !!item.eventPriceSales?.length,
                  defaultExpandedRowKeys: ['0'],
                }
              : {}
          }
          columns={columns}
          className={commonStyles.table}
          rowClassName={commonStyles.row}
          onRow={(record) => ({
            onClick: () => navigate(`/app/event/${record.id}`),
          })}
          title={() => (
            <>
              {Object.keys(data?.salesTotals || []).map((key) => (
                <div key={key} style={{ display: 'flex', gap: '20px' }}>
                  <div>
                    {t`labels.Total tickets`}
                    {data?.salesTotals![key].totalSoldTickets}
                  </div>
                  <div>
                    {t`labels.Total sum`}
                    {data?.salesTotals![key].totalSoldAmount} {key}
                  </div>
                </div>
              ))}
            </>
          )}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default Dashboard
