import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { axiosInstance } from '@/api'
import {
  LoginResponse,
  PartnerDetailsDto,
  PartnerDetailsDtoRoleEnum,
} from '@/openapi'
import { clearAuthTokens, setAuthTokens } from 'axios-jwt'

export interface IAuthContext {
  login: (user: string, password: string) => Promise<boolean>
  logout: () => void
  me?: PartnerDetailsDto
  allLoaded: boolean
  isAdmin: boolean
  isPartner: boolean
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined)

const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [me, setMe] = useState<PartnerDetailsDto>()
  const [allLoaded, setAllLoaded] = useState(false)

  const getMe = useCallback(async () => {
    axiosInstance
      .get<PartnerDetailsDto>('admin/me')
      .then((res) => {
        setMe(res?.data)
        setAllLoaded(true)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    getMe()
  }, [])

  const isAdmin = useMemo(
    () => me?.role === PartnerDetailsDtoRoleEnum.ADMIN,
    [me],
  )

  const isPartner = useMemo(
    () => me?.role === PartnerDetailsDtoRoleEnum.PARTNER,
    [me],
  )

  const login = async (username: string, password: string) => {
    const {
      data: { accessToken, refreshToken },
    } = await axiosInstance.post<LoginResponse>('admin-login', {
      username,
      password,
    })
    if (!accessToken || !refreshToken) return false
    setAuthTokens({
      accessToken,
      refreshToken,
    })
    getMe()
    return true
  }

  const logout = () => {
    clearAuthTokens()
    setMe(undefined)
  }

  let value = { login, logout, allLoaded, me, isAdmin, isPartner }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuthContext() {
  const context = useContext(AuthContext)

  if (typeof context === 'undefined') {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }

  return context
}

export default AuthProvider
