const setSelection = (ids: string[]): void => {
  for (const id of ids) {
    const element = document.getElementById(id)
    if (element) {
      element.setAttribute('stroke', 'red')
      element.setAttribute('stroke-width', '3')
      element.dataset.selected = 'true'
    }
  }
}

const clearSelection = (ids: string[]): void => {
  for (const id of ids) {
    const element = document.getElementById(id)
    if (element) {
      element.setAttribute('stroke', '#000000')
      element.setAttribute('stroke-width', '1')
      element.dataset.selected = 'false'
    }
  }
}

const getCoordinates = (
  e: MouseEvent,
  offsetLeft: number,
  offsetTop: number,
): [number, number, number, number] => {
  let posx = 0
  let posy = 0

  const eventPageX = e.pageX
  const eventPageY = e.pageY
  posx = eventPageX - offsetLeft - window.scrollX
  posy = eventPageY - offsetTop - window.scrollY

  return [posx, posy, eventPageX, eventPageY]
}

export { clearSelection, getCoordinates as coords, setSelection }
