import { Alert, Button, Card, Modal, Select } from 'antd'
import { useEffect, useRef, useState } from 'react'
import {
  CityDto,
  EventExtendedDto,
  EventTourViewDto,
  EventTourViewDtoEventStatusEnum,
  StageDto,
  TourExtendedDto,
} from '@/openapi'
import commonStyles from '@pages/commonStyles.module.less'
import { noop, twoColumnsDateFormat } from '../helpers'
import { EditOutlined } from '@ant-design/icons'
import FloatDateTime from '@components/FloatDateTime'
import { axiosInstance } from '@/api'
import { dateToString } from '@pages/event/helpers'
import { prepareOption } from '@components/AsyncSelect/types'
import { fetchPageableEntity } from '@/utils/fechPageableEntity'
import AsyncSelect from '@components/AsyncSelect'
import StepPoster from '@pages/event/StepPoster'
import axios from 'axios'
import {
  AnnouncePercentTooltip,
  PublishPercentTooltip,
} from '@pages/tour/components/PercentTooltip'
import dayjs from 'dayjs'
import { eventReadyOption, eventStatusOption } from '@/utils/options'
import { FILTER_OPERATORS } from '@components/AsyncSelect/GenericAsyncSelect'
import { useTranslation } from 'react-i18next'
import {
  isAnnouncedEvent,
  isArchivedEvent,
  isCancelledEvent,
  isPublishedEvent,
} from '@/utils/eventsStatuses'
import { DragTable, ExtendedColumns } from '@components/DragTable'
import { canEditEventRecord } from '@pages/tour/helpers'

const { Option } = Select

type ChangeStatusError = {
  [key: string]: string[]
}

export type Props = {
  item?: TourExtendedDto
  events?: EventTourViewDto[]
  refetch: () => void
}

const CalendarTab = ({ events, refetch }: Props) => {
  const value = useRef<any>()
  const [eventsForTable, setEventForTable] = useState<EventTourViewDto[]>()
  const { t } = useTranslation()
  const [filters, setFilters] = useState<
    Record<string, string | EventTourViewDtoEventStatusEnum>
  >({})

  useEffect(() => {
    let newEvents = events?.sort((a, b) =>
      dayjs(a.dateStart).isBefore(b.dateStart) ? -1 : 1,
    )
    if (filters.status && filters.status !== 'all') {
      newEvents = newEvents?.filter(
        ({ eventStatus }) => eventStatus === filters.status,
      )
    }
    if (filters.announce && filters.announce !== 'all') {
      if (filters.announce === 'ready') {
        newEvents = newEvents?.filter(
          ({ announceReadiness }) => announceReadiness?.readiness === 100,
        )
      } else {
        newEvents = newEvents?.filter(
          ({ announceReadiness }) => announceReadiness?.readiness !== 100,
        )
      }
    }
    if (filters.publish && filters.publish !== 'all') {
      if (filters.publish === 'ready') {
        newEvents = newEvents?.filter(
          ({ publishReadiness }) => publishReadiness?.readiness === 100,
        )
      } else {
        newEvents = newEvents?.filter(
          ({ publishReadiness }) => publishReadiness?.readiness !== 100,
        )
      }
    }
    setEventForTable(newEvents)
  }, [events, filters])

  const columns: ExtendedColumns<EventTourViewDto>[] = [
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.AnnouncePercent`}</div>
          <Select
            onClick={(e) => e.stopPropagation()}
            onChange={(value) =>
              setFilters((old) => ({ ...old, announce: value }))
            }
            defaultValue="all"
          >
            {eventReadyOption.map(({ label, value }) => (
              <Option value={value} label={label} key={value}>
                {label}
              </Option>
            ))}
          </Select>
        </div>
      ),
      shortTitle: t`labels.AnnouncePercent`,
      dataIndex: 'announcePercents',
      key: 'announcePercents',
      width: '130px',
      noTooltip: true,
      render: (_, record) => <AnnouncePercentTooltip event={record} />,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.PublishPercent`}</div>
          <Select
            onClick={(e) => e.stopPropagation()}
            onChange={(value) =>
              setFilters((old) => ({ ...old, publish: value }))
            }
            defaultValue="all"
          >
            {eventReadyOption.map(({ label, value }) => (
              <Option value={value} label={label} key={value}>
                {label}
              </Option>
            ))}
          </Select>
        </div>
      ),
      shortTitle: t`labels.PublishPercent`,
      dataIndex: 'publishPercents',
      key: 'publishPercents',
      width: '130px',
      noTooltip: true,
      render: (_, record) => <PublishPercentTooltip event={record} />,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Status`}</div>
          <Select
            onClick={(e) => e.stopPropagation()}
            onChange={(value) =>
              setFilters((old) => ({ ...old, status: value }))
            }
            defaultValue="all"
          >
            {eventStatusOption.map(({ label, value }) => (
              <Option value={value} label={label} key={value}>
                {label}
              </Option>
            ))}
          </Select>
        </div>
      ),
      shortTitle: t`labels.Status`,
      dataIndex: 'eventStatus',
      key: 'eventStatus',
      width: '150px',
    },
    {
      title: t`labels.Start Date`,
      dataIndex: 'dateStart',
      key: 'dateStart',
      render: (_, record) => {
        return canEditEventRecord(record) ? (
          <div
            style={{
              display: 'flex',
              gap: '10px',
              cursor: 'pointer',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              Modal.confirm({
                title: t`labels.changeStartDate`,
                onCancel: () => (value.current = undefined),
                content: (
                  <FloatDateTime
                    label=""
                    value={record.dateStart as never as string}
                    onChange={(v) => {
                      value.current = dateToString(v)
                    }}
                  />
                ),
                onOk: async () => {
                  await axiosInstance.put(`admin/events/step2/${record.id}`, {
                    dateStart: value.current,
                  })
                  value.current = undefined
                  refetch()
                },
              })
            }}
          >
            <div>{twoColumnsDateFormat(record.dateStart)}</div>
            <EditOutlined />
          </div>
        ) : (
          twoColumnsDateFormat(record.dateStart)
        )
      },
    },
    {
      title: t`labels.City`,
      dataIndex: 'city',
      key: 'city',
      noTooltip: true,
      render: (_, record) =>
        canEditEventRecord(record) ? (
          <AsyncSelect<CityDto>
            style={{ width: '200px' }}
            value={record.city ? prepareOption(record.city) : undefined}
            prepareOption={prepareOption}
            withAll={false}
            onChange={async (v) => {
              await axiosInstance.put(`admin/events/step3/${record.id}`, {
                cityId: v.cityId?.value || v,
                stageId: null,
              })
              refetch()
            }}
            getData={fetchPageableEntity<CityDto>('admin/cities')}
          />
        ) : (
          record.city?.name
        ),
    },
    {
      title: t`labels.Stage`,
      dataIndex: 'stage',
      key: 'stage',
      noTooltip: true,
      render: (_, record) =>
        canEditEventRecord(record) ? (
          <AsyncSelect<StageDto>
            style={{ width: '200px' }}
            prepareOption={prepareOption}
            value={record.stage ? prepareOption(record.stage) : undefined}
            onChange={async (v) => {
              await axiosInstance.put(`admin/events/step3/${record.id}`, {
                stageId: v.stageId?.value || v,
              })
              refetch()
            }}
            withAll={false}
            getData={fetchPageableEntity<StageDto>('admin/stages')}
            baseFilters={
              record.city?.id
                ? {
                    'city.id': {
                      operator: FILTER_OPERATORS.EQUAL,
                      value: String(record.city.id),
                    },
                  }
                : undefined
            }
            disabled={false}
            controlProps={{ disabled: !record.city }}
          />
        ) : (
          record.stage?.name
        ),
    },
    {
      title: t`labels.Posters`,
      dataIndex: 'poster',
      key: 'poster',
      noTooltip: true,
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            Modal.confirm({
              icon: null,
              onOk: refetch,
              onCancel: refetch,
              width: '70%',
              content: (
                <StepPoster
                  onSuccess={noop}
                  setForm={noop}
                  disabled={false}
                  item={record as EventExtendedDto}
                  eventId={record.id}
                  canEdit={true}
                />
              ),
            })
          }}
        />
      ),
    },
    {
      title: t`actions`,
      dataIndex: 'actions',
      key: 'actions',
      noTooltip: true,
      render: (_, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Button
            size="small"
            type="primary"
            disabled={
              record.announceReadiness?.readiness !== 100 ||
              isArchivedEvent(record) ||
              isAnnouncedEvent(record) ||
              isPublishedEvent(record) ||
              isCancelledEvent(record)
            }
            onClick={async () => {
              try {
                await axiosInstance.post(`admin/events/announce/${record.id}`)
                refetch()
              } catch (e) {
                if (axios.isAxiosError(e)) {
                  const errors = e.response?.data as ChangeStatusError
                  Modal.error({
                    title: t`labels.errorOnEventAnnounce`,
                    content: (
                      <>
                        {Object.entries(errors).map(([key, value]) => (
                          <div key={key} style={{ marginBottom: '10px' }}>
                            <h4>{key}</h4>
                            <Alert
                              message={
                                <>
                                  {value.map((e) => (
                                    <div key={e}>{e}</div>
                                  ))}
                                </>
                              }
                              type="error"
                              showIcon
                            />
                          </div>
                        ))}
                      </>
                    ),
                  })
                }
              }
            }}
          >
            {t`labels.Announce`}
          </Button>
          <Button
            size="small"
            type="primary"
            disabled={
              record.publishReadiness?.readiness !== 100 ||
              isArchivedEvent(record) ||
              isCancelledEvent(record) ||
              isPublishedEvent(record)
            }
            onClick={async () => {
              try {
                await axiosInstance.post(`admin/events/publish/${record.id}`)
                refetch()
              } catch (e) {
                if (axios.isAxiosError(e)) {
                  const errors = e.response?.data as ChangeStatusError
                  Modal.error({
                    title: t`messages.Error on event publish`,
                    content: (
                      <>
                        {Object.entries(errors).map(([key, value]) => (
                          <div key={key} style={{ marginBottom: '10px' }}>
                            <h4>{key}</h4>
                            <Alert
                              message={
                                <>
                                  {value.map((e) => (
                                    <div key={e}>{e}</div>
                                  ))}
                                </>
                              }
                              type="error"
                              showIcon
                            />
                          </div>
                        ))}
                      </>
                    ),
                  })
                }
              }
            }}
          >
            {t`labels.Publish`}
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Card>
      <DragTable<EventTourViewDto>
        bordered
        columns={columns}
        rowKey={(record) => String(record.id)}
        className={commonStyles.table}
        dataSource={eventsForTable}
      />
    </Card>
  )
}

export default CalendarTab
