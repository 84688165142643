import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { MLFormProps } from '../types'
import MultiLanguageInput from '@components/MultiLanguageInput'
import MultiLanguageTextEditor from '@components/MultiLanguageTextEditor'
import FloatNumberInput from '@components/FloatNumberInput'
import FormItem from '@components/FormItem'
import { CurrencyDto } from '@/openapi'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { prepareOption } from '@components/AsyncSelect/types'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { getValue } from '@pages/tour/helpers'
import { useTranslation } from 'react-i18next'

const CountryForm = ({ onSuccess, id, langFields }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { appLang } = useLanguageContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/countries/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            ...(data.currency
              ? { currencyId: prepareOption(data.currency, appLang) }
              : {}),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    const preparedValues = {
      ...values,
      currencyId: getValue(values.currencyId),
    }
    if (id) {
      await axiosInstance.put(`admin/countries/${id}`, preparedValues)
    } else {
      await axiosInstance.post('admin/countries', preparedValues)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <MultiLanguageInput
        itemProps={{
          name: 'name',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`name`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <MultiLanguageInput
        itemProps={{
          name: 'code',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`code`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <MultiLanguageInput
        itemProps={{
          name: 'phoneCode',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`phoneCode`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <FormItem name="vatRate" rules={[{ required: true }]}>
        <FloatNumberInput
          min={0}
          label={t`labels.VAT rate percentage`}
          required
        />
      </FormItem>
      <GenericAsyncSelect<CurrencyDto>
        path="admin/currencies"
        label={t`labels.Currency`}
        withAll={false}
        width="100%"
        name="currencyId"
        rules={[{ required: true }]}
        required
      />
      <MultiLanguageTextEditor
        form={form}
        itemProps={{
          name: 'description',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{
          label: t`labels.Description`,
          required: true,
        }}
        languageFields={langFields}
      />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CountryForm
