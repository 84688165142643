/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { MailingListFilters } from './mailing-list-filters';

/**
 * 
 * @export
 * @interface MailingListUpdateRequest
 */
export interface MailingListUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingListUpdateRequest
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingListUpdateRequest
     */
    'entityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailingListUpdateRequest
     */
    'type'?: MailingListUpdateRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingListUpdateRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListUpdateRequest
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListUpdateRequest
     */
    'lang'?: MailingListUpdateRequestLangEnum;
    /**
     * 
     * @type {MailingListFilters}
     * @memberof MailingListUpdateRequest
     */
    'filters'?: MailingListFilters;
    /**
     * 
     * @type {string}
     * @memberof MailingListUpdateRequest
     */
    'channel'?: MailingListUpdateRequestChannelEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingListUpdateRequest
     */
    'status'?: MailingListUpdateRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof MailingListUpdateRequest
     */
    'marketingLinkId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MailingListUpdateRequestTypeEnum {
    EVENT = 'event',
    ACTOR = 'actor',
    STAGE = 'stage',
    CITY = 'city',
    ACTOR_TYPE = 'actor_type',
    EVENT_TYPE = 'event_type'
}
/**
    * @export
    * @enum {string}
    */
export enum MailingListUpdateRequestLangEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
    * @export
    * @enum {string}
    */
export enum MailingListUpdateRequestChannelEnum {
    EMAIL = 'email',
    SMS = 'sms'
}
/**
    * @export
    * @enum {string}
    */
export enum MailingListUpdateRequestStatusEnum {
    DRAFT = 'draft',
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished'
}


