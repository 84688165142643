/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventPriceDenominationStatisticsView } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * EventPriceDenominationStatisticsControllerApi - axios parameter creator
 * @export
 */
export const EventPriceDenominationStatisticsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} eventId 
         * @param {string} [query] 
         * @param {ExportEventPricesCategoriesStatisticsFormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEventPricesCategoriesStatistics: async (pageable: Pageable, eventId: number, query?: string, format?: ExportEventPricesCategoriesStatisticsFormatEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('exportEventPricesCategoriesStatistics', 'pageable', pageable)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('exportEventPricesCategoriesStatistics', 'eventId', eventId)
            const localVarPath = `/v1/admin/statistics/price-categories/{eventId}/download`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventPricesCategoriesStatistics: async (pageRequest: Pageable, eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('getEventPricesCategoriesStatistics', 'pageRequest', pageRequest)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventPricesCategoriesStatistics', 'eventId', eventId)
            const localVarPath = `/v1/admin/statistics/price-categories/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventPriceDenominationStatisticsControllerApi - functional programming interface
 * @export
 */
export const EventPriceDenominationStatisticsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventPriceDenominationStatisticsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} eventId 
         * @param {string} [query] 
         * @param {ExportEventPricesCategoriesStatisticsFormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEventPricesCategoriesStatistics(pageable: Pageable, eventId: number, query?: string, format?: ExportEventPricesCategoriesStatisticsFormatEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEventPricesCategoriesStatistics(pageable, eventId, query, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventPricesCategoriesStatistics(pageRequest: Pageable, eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventPriceDenominationStatisticsView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventPricesCategoriesStatistics(pageRequest, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventPriceDenominationStatisticsControllerApi - factory interface
 * @export
 */
export const EventPriceDenominationStatisticsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventPriceDenominationStatisticsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} eventId 
         * @param {string} [query] 
         * @param {ExportEventPricesCategoriesStatisticsFormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEventPricesCategoriesStatistics(pageable: Pageable, eventId: number, query?: string, format?: ExportEventPricesCategoriesStatisticsFormatEnum, options?: any): AxiosPromise<object> {
            return localVarFp.exportEventPricesCategoriesStatistics(pageable, eventId, query, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventPricesCategoriesStatistics(pageRequest: Pageable, eventId: number, options?: any): AxiosPromise<Array<EventPriceDenominationStatisticsView>> {
            return localVarFp.getEventPricesCategoriesStatistics(pageRequest, eventId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventPriceDenominationStatisticsControllerApi - object-oriented interface
 * @export
 * @class EventPriceDenominationStatisticsControllerApi
 * @extends {BaseAPI}
 */
export class EventPriceDenominationStatisticsControllerApi extends BaseAPI {
    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} eventId 
     * @param {string} [query] 
     * @param {ExportEventPricesCategoriesStatisticsFormatEnum} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventPriceDenominationStatisticsControllerApi
     */
    public exportEventPricesCategoriesStatistics(pageable: Pageable, eventId: number, query?: string, format?: ExportEventPricesCategoriesStatisticsFormatEnum, options?: AxiosRequestConfig) {
        return EventPriceDenominationStatisticsControllerApiFp(this.configuration).exportEventPricesCategoriesStatistics(pageable, eventId, query, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventPriceDenominationStatisticsControllerApi
     */
    public getEventPricesCategoriesStatistics(pageRequest: Pageable, eventId: number, options?: AxiosRequestConfig) {
        return EventPriceDenominationStatisticsControllerApiFp(this.configuration).getEventPricesCategoriesStatistics(pageRequest, eventId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ExportEventPricesCategoriesStatisticsFormatEnum {
    EXCEL = 'excel',
    PDF = 'pdf'
}
