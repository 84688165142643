/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CountryDto } from './country-dto';

/**
 * 
 * @export
 * @interface TicketControlPersonDto
 */
export interface TicketControlPersonDto {
    /**
     * 
     * @type {number}
     * @memberof TicketControlPersonDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonDto
     */
    'status'?: TicketControlPersonDtoStatusEnum;
    /**
     * 
     * @type {CountryDto}
     * @memberof TicketControlPersonDto
     */
    'country'?: CountryDto;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketControlPersonDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketControlPersonDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlPersonDtoStatusEnum {
    CREATED = 'created',
    APPROVED = 'approved',
    BLOCKED = 'blocked'
}


