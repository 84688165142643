/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ResourceOperationDto } from './resource-operation-dto';

/**
 * 
 * @export
 * @interface AccessControlRoleDto
 */
export interface AccessControlRoleDto {
    /**
     * 
     * @type {string}
     * @memberof AccessControlRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlRoleDto
     */
    'type'?: AccessControlRoleDtoTypeEnum;
    /**
     * 
     * @type {Array<ResourceOperationDto>}
     * @memberof AccessControlRoleDto
     */
    'resourceOperations'?: Array<ResourceOperationDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessControlRoleDtoTypeEnum {
    ADMIN = 'admin',
    AGENCY = 'agency'
}


