import { useMemo } from 'react'
import { Rule } from 'rc-field-form/lib/interface'

export const useIsRequired = (rules?: Rule[]) => {
  const required = useMemo(
    () =>
      !!rules?.find((rule) => {
        if (typeof rule === 'object') {
          return rule.required === true
        }
        return false
      }),
    [rules],
  )

  return required
}
