const COLORS = [
  '#ff1e82',
  '#20452d',
  '#2fef6d',
  '#e91b4e',
  '#ffebc0',
  '#002b1a',
  '#f8bfc7',
  '#0e59f5',
  '#e342b1',
  '#e4049c',
  '#2f3858',
  '#74a61f',
  '#684671',
  '#c0d32c',
  '#a4f97c',
  '#c2ec6b',
  '#4b3f11',
  '#f0ad9a',
  '#9be655',
  '#ac66e1',
  '#8f483c',
  '#fcb141',
  '#26effb',
  '#ab99e8',
  '#ccdf10',
  '#aec256',
  '#6710d8',
  '#b76b29',
  '#e0fcc2',
  '#c8d450',
  '#58819c',
  '#8ca779',
  '#b9dd60',
  '#87481e',
  '#700f0d',
  '#dee743',
  '#7043f1',
  '#83f4e1',
  '#584efc',
  '#25a042',
  '#fe646d',
  '#20ca21',
  '#f69d91',
  '#972481',
  '#acfbc4',
  '#155569',
  '#8420c7',
  '#a3a997',
  '#a8b643',
  '#e99456',
  '#5f2466',
  '#894f39',
  '#7a3bbc',
  '#2cbf04',
  '#d33f6e',
  '#401c0a',
  '#e32477',
  '#12b562',
  '#84bd6c',
  '#1d86d6',
  '#4d9a2f',
  '#d72492',
  '#d07f24',
  '#a576e9',
]

export { COLORS }
