import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { MLFormProps } from '../types'
import MultiLanguageInput from '../../components/MultiLanguageInput'
import { useTranslation } from 'react-i18next'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const CurrencyForm = ({ onSuccess, id, langFields }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/currencies/${id}`)
        .then(({ data }) => {
          setFullItem(data)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const onFinish = async (values: CustomFormData) => {
    if (id) {
      await axiosInstance.put(`admin/currencies/${id}`, values)
    } else {
      await axiosInstance.post('admin/currencies', values)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <MultiLanguageInput
        itemProps={{
          name: 'code',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`code`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <MultiLanguageInput
        itemProps={{
          name: 'name',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`name`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CurrencyForm
