/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryUserDto } from '../models';
// @ts-ignore
import { PageCountryUserDto } from '../models';
// @ts-ignore
import { PageMediaFileDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * CountriesControllerApi - axios parameter creator
 * @export
 */
export const CountriesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find20: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find20', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find21: async (pageRequest: Pageable, entityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find21', 'pageRequest', pageRequest)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('find21', 'entityId', entityId)
            const localVarPath = `/v1/events/countries/{entityId}/media`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPictures3: async (pageable: Pageable, query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('findPictures3', 'pageable', pageable)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('findPictures3', 'query', query)
            const localVarPath = `/v1/events/countries/pictures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById15: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById15', 'id', id)
            const localVarPath = `/v1/events/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount15: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('getCount15', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/countries/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesControllerApi - functional programming interface
 * @export
 */
export const CountriesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find20(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCountryUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find20(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find21(pageRequest: Pageable, entityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMediaFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find21(pageRequest, entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPictures3(pageable: Pageable, query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMediaFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPictures3(pageable, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById15(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById15(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount15(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount15(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesControllerApi - factory interface
 * @export
 */
export const CountriesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find20(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageCountryUserDto> {
            return localVarFp.find20(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find21(pageRequest: Pageable, entityId: number, options?: any): AxiosPromise<PageMediaFileDto> {
            return localVarFp.find21(pageRequest, entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPictures3(pageable: Pageable, query: string, options?: any): AxiosPromise<PageMediaFileDto> {
            return localVarFp.findPictures3(pageable, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById15(id: number, options?: any): AxiosPromise<CountryUserDto> {
            return localVarFp.getById15(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount15(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount15(pageRequest, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesControllerApi - object-oriented interface
 * @export
 * @class CountriesControllerApi
 * @extends {BaseAPI}
 */
export class CountriesControllerApi extends BaseAPI {
    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesControllerApi
     */
    public find20(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return CountriesControllerApiFp(this.configuration).find20(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {number} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesControllerApi
     */
    public find21(pageRequest: Pageable, entityId: number, options?: AxiosRequestConfig) {
        return CountriesControllerApiFp(this.configuration).find21(pageRequest, entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesControllerApi
     */
    public findPictures3(pageable: Pageable, query: string, options?: AxiosRequestConfig) {
        return CountriesControllerApiFp(this.configuration).findPictures3(pageable, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesControllerApi
     */
    public getById15(id: number, options?: AxiosRequestConfig) {
        return CountriesControllerApiFp(this.configuration).getById15(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesControllerApi
     */
    public getCount15(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return CountriesControllerApiFp(this.configuration).getCount15(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }
}

