import { CustomFormData } from './index'
import { pick } from 'lodash'
import { SelectOption } from '@/types/Option'

export const prepareDataForSearch = (data: CustomFormData) => {
  const body = pick<CustomFormData>(data, [
    'userCountryId',
    'eventCountryId',
    'eventCityId',
    'eventStageId',
    'eventId',
    'actorId',
    'eventTypeId',
    'actorTypeId',
  ])
  for (let key in body) {
    const castedKey = key as keyof CustomFormData
    if (Array.isArray(body[castedKey])) {
      // @ts-ignore
      body[castedKey] = body[castedKey]?.map((item: SelectOption) => item.value)
    }
  }

  return body
}
