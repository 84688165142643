/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MediaFileCreateResponse
 */
export interface MediaFileCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof MediaFileCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'mediaType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'seoTextEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'seoTextPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'seoTextRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'seoTextUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'seoTextBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'hoverTitleEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'hoverTitlePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'hoverTitleRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'hoverTitleUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'hoverTitleBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFileCreateResponse
     */
    'entityType'?: MediaFileCreateResponseEntityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MediaFileCreateResponse
     */
    'entityId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaFileCreateResponseEntityTypeEnum {
    EVENT = 'event',
    ACTOR = 'actor',
    ACTOR_SOCIAL_MEDIA = 'actor_social_media',
    ACTOR_TYPE = 'actor_type',
    AGENCY = 'agency',
    CITY = 'city',
    COUNTRY = 'country',
    STAGE = 'stage',
    TOUR = 'tour',
    SOCIAL_MEDIA = 'social_media',
    EVENT_GROUPS = 'event_groups'
}


