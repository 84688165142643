import { useEffect } from 'react'
import { Form, DatePicker, Space } from 'antd'
import Page from 'components/Page'
import {
  CountryDto,
  EventsSalesStatisticsView,
  PageImplCountriesSalesStatisticsView,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import commonStyles from '../../commonStyles.module.less'
import { twoColumnsDateFormat } from '@pages/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { useAntdTable } from 'ahooks'
import { Params } from '@/types/Params'
import FormItem from '@components/FormItem'
import styles from './index.module.less'
import {
  DownloadReportButton,
  useLastParams,
} from '@pages/reports/shared/DownloadReportButton'
import { useExtraDatePickerFooter } from '@pages/reports/shared/useExtraDatePickerFooter'
import { useAuthContext } from '@/contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'

const SalesReportByCountries = () => {
  const [form] = Form.useForm()
  const { getSort, getQuery, setLastSort, setLastQuery } = useLastParams()
  const { isAdmin } = useAuthContext()
  const { t } = useTranslation()
  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<CountryDto>
    },
    filters: { dates?: [Dayjs, Dayjs] },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.dates?.[0] && filters.dates?.[1]) {
      query.push(
        `ticketSaleDate~DATE_AFTER~${filters.dates[0]?.format(DATE_FORMAT)}`,
      )
      query.push(
        `ticketSaleDate~DATE_BEFORE~${filters.dates?.[1]?.format(DATE_FORMAT)}`,
      )
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
      setLastSort({ sort: params.sort })
    } else {
      setLastSort(null)
    }
    params.query = query.join(';')
    setLastQuery(params.query)

    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageImplCountriesSalesStatisticsView>(
      'admin/statistics/countries/view',
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
    defaultParams: [
      {
        sorter: {
          columnKey: 'countryName',
          order: 'ascend',
        },
        current: 1,
        pageSize: 10,
      },
      {},
    ],
  })

  const columns: ExtendedColumns<EventsSalesStatisticsView>[] = [
    {
      title: t`labels.Country`,
      dataIndex: 'countryName',
      key: 'countryName',
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: t`labels.Currency Code`,
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      sorter: true,
    },
    {
      title: t`labels.Returned Tickets Count`,
      dataIndex: 'returnedTicketsCount',
      key: 'returnedTicketsCount',
      sorter: true,
    },
    {
      title: t`labels.Sales Net`,
      dataIndex: 'salesNet',
      key: 'salesNet',
      sorter: true,
    },
    {
      title: t`labels.Returns Vat`,
      dataIndex: 'returnsVat',
      key: 'returnsVat',
      sorter: true,
    },
    ...(isAdmin
      ? [
          {
            title: t`labels.Sales Service Fee`,
            dataIndex: 'salesServiceFee',
            key: 'salesServiceFee',
            sorter: true,
          },
        ]
      : []),
    {
      title: t`labels.Sales Vat`,
      dataIndex: 'salesVat',
      key: 'salesVat',
      sorter: true,
    },
    {
      title: t`labels.Sold Tickets Count`,
      dataIndex: 'soldTicketsCount',
      key: 'soldTicketsCount',
      sorter: true,
    },
    {
      title: t`labels.Ticket Last Sale Date`,
      dataIndex: 'ticketLastSaleDate',
      key: 'ticketLastSaleDate',
      sorter: true,
      render: twoColumnsDateFormat,
    },
    {
      title: t`labels.Ticket First Sale Date`,
      dataIndex: 'ticketFirstSaleDate',
      key: 'ticketFirstSaleDate',
      sorter: true,
      render: twoColumnsDateFormat,
    },
    {
      title: t`labels.Returns Net`,
      dataIndex: 'returnsNet',
      key: 'returnsNet',
      sorter: true,
    },
    ...(isAdmin
      ? [
          {
            title: t`labels.Billing Service Fee`,
            dataIndex: 'billingServiceFee',
            key: 'billingServiceFee',
            sorter: true,
          },
          {
            title: t`labels.Returns Service Fee`,
            dataIndex: 'returnsServiceFee',
            key: 'returnsServiceFee',
            sorter: true,
          },
        ]
      : []),
  ]

  const extraFooter = useExtraDatePickerFooter(form, submit)

  useEffect(() => {
    form.setFieldValue('dates', [dayjs().startOf('month'), dayjs()])
  }, [])

  return (
    <Page inner title={t`labels.Sales report by countries`}>
      <Form form={form}>
        <DragTable<EventsSalesStatisticsView>
          actionsMobile
          actions={
            <Space className={styles.topLine}>
              <FormItem name="dates" style={{ margin: 0 }}>
                <DatePicker.RangePicker
                  onChange={submit}
                  allowClear={false}
                  renderExtraFooter={() => extraFooter}
                  className={styles.range}
                />
              </FormItem>
              <DownloadReportButton
                entity="countries"
                getQuery={getQuery}
                getParams={getSort}
              />
            </Space>
          }
          rowKey={(record) => String(record.id)}
          bordered
          columns={columns}
          className={commonStyles.table}
          rowClassName={commonStyles.row}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default SalesReportByCountries
