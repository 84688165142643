import { useCallback, useEffect, useRef, useState } from 'react'
import { AxiosError, isAxiosError } from 'axios'
import { axiosInstance } from '@/api'

type Options = {
  manualFetch?: boolean
  noError?: boolean
}

const useAsyncGet = <T>(url: string, options?: Options) => {
  const [data, setData] = useState<T>()
  const [error, setError] = useState<AxiosError>()
  const [loaded, setLoaded] = useState(false)
  const manualFlag = useRef(false)

  const fetchData = useCallback(async () => {
    try {
      const headers = options?.noError ? { noError: true } : {}
      const { data } = await axiosInstance.get<T>(url, { headers })
      manualFlag.current = true
      setData(data)
    } catch (error) {
      if (isAxiosError(error)) {
        setError(error)
      }
    } finally {
      setLoaded(true)
    }
  }, [url])

  useEffect(() => {
    if (!options?.manualFetch) {
      fetchData()
    }
  }, [])

  return { data, error, loaded, refetch: fetchData }
}

export default useAsyncGet
