import { axiosInstance } from 'api'
import { PageEntity } from '@/types/PagiableEntity'
import { BaseFilers } from '@components/AsyncSelect/GenericAsyncSelect'

export const fetchPageableEntity =
  <T>(url: string, searchField: string = 'name', searchParam?: string) =>
  async (
    page: number,
    size: number,
    search?: string,
    baseFilters?: BaseFilers,
  ) => {
    const query = []
    if (!searchParam) {
      if (search) query.push(`${searchField}~~${search}`)
      for (let key in baseFilters) {
        if (baseFilters[key].value) {
          query.push(
            `${key}${baseFilters[key].operator}${baseFilters[key].value}`,
          )
        }
      }
    }

    const params: any = { page, size, query: query.join(';') }
    if (searchParam) {
      params[searchParam] = search
    }

    const { data } = await axiosInstance.get<PageEntity<T>>(url, {
      params,
    })

    return data
  }
