import { PropsWithChildren } from 'react'
import { FormItemProps, Select, SelectProps } from 'antd'
import FormLabel from '@components/formElements/Layout/FormLabel'
import FormItem from '@components/FormItem'
import { useIsRequired } from '@/hooks/useIsRequired'

export type FloatSelectProps = {
  onChange?: VoidFunction
  label: string
  name: string
  disabled?: boolean
  formItemProps?: FormItemProps
} & Partial<SelectProps> &
  PropsWithChildren

const FormSelect = (props: FloatSelectProps) => {
  let {
    label,
    value,
    placeholder,
    children,
    labelInValue = false,
    disabled,
    name,
    formItemProps,
    ...rest
  } = props

  const required = useIsRequired(formItemProps?.rules)

  return (
    <div>
      <FormLabel label={label} required={required} />
      <FormItem name={name} {...formItemProps}>
        <Select
          disabled={disabled}
          defaultValue={value}
          labelInValue={labelInValue}
          {...rest}
        >
          {children}
        </Select>
      </FormItem>
    </div>
  )
}

export default FormSelect
