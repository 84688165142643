import { useCallback, useState } from 'react'
import { EventCreateResponseLanguagesEnum } from '@/openapi'
import LanguagesEnum = EventCreateResponseLanguagesEnum
import { NamePath } from 'antd/es/form/interface'
import { CustomFormItemProps } from 'components/FormItem'
import TextEditor from '@components/FormEditor'
import { FormInstance } from 'antd/es/form/hooks/useForm'
import Form from 'antd/es/form'
import { FloatTextAreaProps } from '@components/FloatTextarea'
import { axiosInstance } from '@/api'
import { generateName } from '@components/MultiLanguageInputBase'
import { Spin } from 'antd'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import FormLabel from '@components/formElements/Layout/FormLabel'

type MultiLanguageInputProps = {
  itemProps: CustomFormItemProps
  inputProps: FloatTextAreaProps
  languages?: LanguagesEnum[]
  form: FormInstance
  disabled?: boolean
}

export const generateKey = (name: NamePath, lang: LanguagesEnum) => {
  if (Array.isArray(name)) {
    const [_, realName] = name
    return `${realName}${lang}`
  } else {
    return `${name}${lang}`
  }
}

const MultiLanguageTextEditorBase = ({
  itemProps,
  form,
  languages = [],
  inputProps,
  disabled,
}: MultiLanguageInputProps) => {
  const name = itemProps.name!
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const handleTranslateClick = useCallback(
    async (lang: LanguagesEnum, fieldName: string) => {
      if (!form) return
      const value = form.getFieldValue(fieldName)
      if (!value) return
      setLoading(true)
      try {
        const { data } = await axiosInstance.post<
          Record<LanguagesEnum, string>
        >(`${lang}/from`, value)
        Object.entries(data).forEach(([key, value]) => {
          form.setFields([
            {
              name: generateName(name, key as never as LanguagesEnum),
              value,
              touched: true,
              errors: [],
            },
          ])
        })
      } catch {
        toast.error(t`messages.cantTranslate`)
      }

      setLoading(false)
    },
    [form, name],
  )
  return (
    <>
      {languages.map((lang) => (
        <div
          style={{ position: 'relative' }}
          className={itemProps.className}
          key={generateKey(name, lang)}
        >
          <Form.Item
            {...itemProps}
            name={`${name}${lang}`}
            key={generateKey(name, lang)}
          >
            <FormLabel
              required={inputProps.required}
              label={inputProps.label}
            />
            <TextEditor
              name={`${name}${lang}`}
              form={form}
              readOnly={disabled}
              translateClick={() =>
                handleTranslateClick(lang, `${name}${lang}`)
              }
            />
          </Form.Item>
          {loading && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                backgroundColor: 'rgba(255,255,255,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default MultiLanguageTextEditorBase
