/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorCreateResponse } from './actor-create-response';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyCreateResponse } from './agency-create-response';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeCreateResponse } from './event-type-create-response';

/**
 * 
 * @export
 * @interface TourCreateResponse
 */
export interface TourCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TourCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'nameBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'descriptionBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TourCreateResponse
     */
    'languages'?: Array<TourCreateResponseLanguagesEnum>;
    /**
     * 
     * @type {string}
     * @memberof TourCreateResponse
     */
    'status'?: TourCreateResponseStatusEnum;
    /**
     * 
     * @type {AgencyCreateResponse}
     * @memberof TourCreateResponse
     */
    'agency'?: AgencyCreateResponse;
    /**
     * 
     * @type {Array<ActorCreateResponse>}
     * @memberof TourCreateResponse
     */
    'actors'?: Array<ActorCreateResponse>;
    /**
     * 
     * @type {EventTypeCreateResponse}
     * @memberof TourCreateResponse
     */
    'eventType'?: EventTypeCreateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum TourCreateResponseLanguagesEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
    * @export
    * @enum {string}
    */
export enum TourCreateResponseStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}


