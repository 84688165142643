export const eventStatusOption = [
  { value: 'all', label: 'All' },
  { value: 'unpublished', label: 'Unpublished' },
  { value: 'announced', label: 'Announced' },
  { value: 'published', label: 'Published' },
  { value: 'archived', label: 'Archived' },
]

export const eventReadyOption = [
  { value: 'all', label: 'All' },
  { value: 'ready', label: 'Ready' },
  { value: 'not_ready', label: 'Not ready' },
]
