import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import {
  EventDto,
  EventPriceDto,
  PageEventPriceDto,
  PageEventStageDefinitionDto,
  StageDefinitionDto,
  TicketDto,
} from '@/openapi'
import { renderEventLabel } from '@pages/transferEvent/index'
import { useCallback, useState } from 'react'
import { axiosInstance } from '@/api'
import { Card, Descriptions, Empty } from 'antd'
import LeftPartSoldTickets from '@pages/transferEvent/components/LeftPartSoldTickets'
import { LeftPartWithSD } from '@pages/transferEvent/components/LeftPartSD'
import { getDescriptions } from '@pages/transferEvent/helpers'

type Props = {
  onSelect: (ticket?: TicketDto) => void
  setFromEvent: (event?: EventDto) => void
  uniqueKey: number
  selectedTicket?: TicketDto
}

export const FromPart = ({
  onSelect,
  setFromEvent,
  uniqueKey,
  selectedTicket,
}: Props) => {
  const [selectedEvent, setSelectedEvent] = useState('')
  const [stageDefinition, setStageDefinition] = useState<
    StageDefinitionDto | undefined
  >()
  const [eventPrices, setEventPrices] = useState<EventPriceDto[] | undefined>()

  const toSelect = useCallback(async (id: string) => {
    setSelectedEvent(id)
    const { data: event } = await axiosInstance<EventDto>(`admin/events/${id}`)
    onSelect(undefined)
    setFromEvent(event)
    setStageDefinition(undefined)
    setEventPrices(undefined)
    const { data } = await axiosInstance.get<PageEventStageDefinitionDto>(
      `admin/event-stage-definitions?query=event.id==${id}`,
    )

    if (data.content?.length) {
      setStageDefinition(data.content[0])
    } else {
      const { data } = await axiosInstance.get<PageEventPriceDto>(
        `admin/event-prices?page=0&size=100&query=event.id==${id}`,
      )
      setEventPrices(data.content)
    }
  }, [])

  return (
    <div>
      <h2>{`From`}</h2>
      <GenericAsyncSelect<EventDto>
        path="admin/events"
        label=""
        withAll={false}
        width="100%"
        submit={toSelect}
        customPrepareOption={(event) => ({
          value: String(event.id),
          label: renderEventLabel(event),
        })}
      />
      {selectedTicket && (
        <Card
          style={{ marginBottom: '10px' }}
          title="Selected ticket"
          headStyle={{ textAlign: 'center' }}
        >
          <Descriptions
            size="small"
            contentStyle={{ width: '35%' }}
            items={getDescriptions(selectedTicket)}
            bordered
            column={2}
          />
        </Card>
      )}
      {selectedEvent && !stageDefinition && !eventPrices?.length && <Empty />}
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        key={uniqueKey}
      >
        {!!eventPrices?.length && (
          <LeftPartSoldTickets onSelect={onSelect} eventId={selectedEvent} />
        )}
      </div>
      {stageDefinition && (
        <LeftPartWithSD
          eventId={selectedEvent}
          definition={stageDefinition}
          onSelect={onSelect}
          key={uniqueKey}
        />
      )}
    </div>
  )
}
