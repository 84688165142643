import { useCallback, useState } from 'react'
import { EventCreateResponseLanguagesEnum } from '@/openapi'
import LanguagesEnum = EventCreateResponseLanguagesEnum
import { NamePath } from 'antd/es/form/interface'
import FormItem, { CustomFormItemProps } from 'components/FormItem'
import { FormInstance, Input } from 'antd'
import { axiosInstance } from '@/api'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { CompoundedComponent } from 'antd/es/float-button/interface'
import FormLabel from '@components/formElements/Layout/FormLabel'
import { LanguageButtons } from '@components/formElements/components/LanguageButtons'
import { useHandleFormErrors } from '@components/formElements/components/useHandleFormErrors'
import { AiButtonStatus } from '@components/formElements/types'
import { useIsRequired } from '@/hooks/useIsRequired'

type MultiLanguageInputProps<T> = {
  itemProps: CustomFormItemProps
  inputProps?: CompoundedComponent
  languages: LanguagesEnum[]
  form: FormInstance<T>
  listName?: string
  label?: string | null
  subLabel?: string | null
  placeholder?: string | null
}

export const generateName = (name: NamePath, lang: LanguagesEnum) => {
  if (Array.isArray(name)) {
    const [index, realName] = name
    return [index, `${realName}${lang}`]
  } else {
    return `${name}${lang}`
  }
}

export const generateKey = (name: NamePath, lang: LanguagesEnum) => {
  if (Array.isArray(name)) {
    const [_, realName] = name
    return `${realName}${lang}`
  } else {
    return `${name}${lang}`
  }
}

const InputMultiLanguage = <T,>({
  itemProps,
  inputProps,
  languages = [],
  form,
  listName,
  label,
  subLabel,
  placeholder,
}: MultiLanguageInputProps<T>) => {
  const name = itemProps.name!
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState<LanguagesEnum>(
    LanguagesEnum.EN,
  )
  const errors = useHandleFormErrors(languages, name, form)
  const [aiButtonStatus, setAiButtonStatus] = useState<AiButtonStatus>('ready')
  const required = useIsRequired(itemProps.rules)

  const handleTranslateClick = useCallback(async () => {
    if (!form) return
    let value: string | undefined
    const fieldName = generateName(name, currentLanguage) as string
    let selector: string | Array<any> = fieldName
    if (Array.isArray(fieldName)) {
      selector = [listName, fieldName[0], fieldName[1]]
      value = form.getFieldValue(selector)
    } else {
      value = form.getFieldValue(selector)
    }
    setLoading(true)
    try {
      const { data } = await axiosInstance.post<Record<LanguagesEnum, string>>(
        `${currentLanguage}/from`,
        value,
        {
          headers: {
            'Content-Type': 'text/plain',
          },
        },
      )
      setAiButtonStatus('done')
      const updatedFields = Object.entries(data).map(([key, value]) => {
        let fullName = generateName(name, key as never as LanguagesEnum)
        if (Array.isArray(fullName) && listName) {
          fullName.unshift(listName)
        }

        return { name: fullName, value, errors: [] }
      })
      form.setFields(updatedFields)
    } catch {
      toast.error(t`messages.cantTranslate`)
      setAiButtonStatus('error')
    }
    setLoading(false)
    await form.validateFields(languages.map((l) => generateName(name, l)))
  }, [form, name, currentLanguage])

  return (
    <div>
      <FormLabel
        style={{ marginBottom: '8px' }}
        label={label}
        subLabel={subLabel}
        required={required}
        bold
      />
      {languages.map((lang) => (
        <FormItem
          {...itemProps}
          name={generateName(name, lang)}
          key={generateKey(name, lang)}
          hidden={lang !== currentLanguage}
        >
          <Input
            {...inputProps}
            disabled={itemProps.disabled}
            placeholder={(placeholder || '') + ' ' + lang}
            onChange={() => setAiButtonStatus('ready')}
          />
        </FormItem>
      ))}
      <LanguageButtons
        languages={languages}
        currentLanguage={currentLanguage}
        loading={loading}
        setCurrentLanguage={setCurrentLanguage}
        errors={errors}
        handleTranslateClick={handleTranslateClick}
        aiButtonStatus={aiButtonStatus}
      />
    </div>
  )
}

export default InputMultiLanguage
