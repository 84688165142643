import Page from '@/components/Page'
import useAsyncGet from '@/hooks/useAsyncGet'
import { CountryDto, EventExtendedDto, PageEventPriceDto } from '@/openapi'
import { Button, Card, Col, Form, InputNumber, Row, Space } from 'antd'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { SelectOption } from '@/types/Option'
import FloatInput from '@components/FloatInput'
import { useCallback } from 'react'
import { axiosInstance } from '@/api'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FloatNumberInput from '@components/FloatNumberInput'
export type Props = {
  eventId: string
  event: EventExtendedDto
}
export const GiftWithoutSD = ({ eventId }: Props) => {
  const [form] = Form.useForm<any>()
  const { data, loaded } = useAsyncGet<PageEventPriceDto>(
    `admin/event-prices?page=0&size=100&query=event.id==${eventId}`,
  )
  const [_, setSearch] = useSearchParams()
  const { t } = useTranslation()

  const saveData = useCallback(
    async (values: any) => {
      const baseValues = {
        countryPhoneCode: values.countryPhoneCode,
        phone: values.phone,
        ...(values.email ? { email: values.email } : {}),
      }
      const ids: string[] = []
      Object.entries(values).forEach(([key, value]) => {
        if (key.includes('tickets.') && value) {
          const id = key.replace('tickets.', '') as string
          ids.push(...Array(value as number).fill(id))
        }
      })
      await axiosInstance.post(
        'admin/tickets/gift/bulk',
        ids.map((eventPriceId) => ({
          ...baseValues,
          eventPriceId,
        })),
      )
      toast.success(t`messages.giftCreated`)
      setSearch('tab=gift-tickets')
    },
    [t],
  )

  return (
    <Page inner loading={!loaded}>
      <Card style={{ marginTop: '15px' }}>
        <Row>
          <Col span={24}>
            <Form form={form} onFinish={saveData}>
              <Card
                style={{ marginBottom: '10px' }}
                title={t`labels.Please Enter person data`}
                extra={
                  <Button type="primary" htmlType="submit">
                    {t`labels.Send gift`}
                  </Button>
                }
              >
                <Row>
                  <Col span={12}>
                    {data?.content?.map((evPrice) => (
                      <Form.Item
                        key={evPrice.id}
                        name={`tickets.${evPrice.id}`}
                      >
                        <InputNumber
                          addonBefore={evPrice.name}
                          min={0}
                          max={evPrice.quantityLeft}
                        />
                      </Form.Item>
                    ))}
                  </Col>
                  <Col span={12}>
                    <Space>
                      <GenericAsyncSelect<CountryDto>
                        path="admin/countries"
                        label={t`phoneCode`}
                        name="countryPhoneCode"
                        withAll={false}
                        customPrepareOption={({
                          phoneCode,
                          name,
                        }: CountryDto): SelectOption => ({
                          value: String(phoneCode),
                          label: `${name} (+${phoneCode})`,
                        })}
                        rules={[{ required: true }]}
                      />
                      <Form.Item
                        name="phone"
                        hasFeedback
                        rules={[{ required: true }]}
                      >
                        <FloatNumberInput
                          controls={false}
                          maxLength={12}
                          label={t('labels.Phone')}
                          required
                        />
                      </Form.Item>
                      <Form.Item name="email" hasFeedback>
                        <FloatInput label={t`labels.Email`} />
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </Card>
    </Page>
  )
}
