import { useCallback, useState } from 'react'
import { EventCreateResponseLanguagesEnum } from '@/openapi'
import LanguagesEnum = EventCreateResponseLanguagesEnum
import { NamePath } from 'antd/es/form/interface'
import { CustomFormItemProps } from 'components/FormItem'
import TextEditor from '@components/FormEditor'
import { FormInstance } from 'antd/es/form/hooks/useForm'
import Form from 'antd/es/form'
import { FloatTextAreaProps } from '@components/FloatTextarea'
import { axiosInstance } from '@/api'
import { generateName } from '@components/MultiLanguageInputBase'
import { Spin } from 'antd'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import FormLabel from '@components/formElements/Layout/FormLabel'
import { useHandleFormErrors } from '@components/formElements/components/useHandleFormErrors'
import { LanguageButtons } from '@components/formElements/components/LanguageButtons'
import { AiButtonStatus } from '@components/formElements/types'

type MultiLanguageInputProps = {
  itemProps: CustomFormItemProps
  inputProps: FloatTextAreaProps
  languages?: LanguagesEnum[]
  form: FormInstance
  disabled?: boolean
  listName?: string
}

export const generateKey = (name: NamePath, lang: LanguagesEnum) => {
  if (Array.isArray(name)) {
    const [_, realName] = name
    return `${realName}${lang}`
  } else {
    return `${name}${lang}`
  }
}

const TextEditorMultiLanguage = ({
  itemProps,
  form,
  languages = [],
  inputProps,
  disabled,
  listName,
}: MultiLanguageInputProps) => {
  const name = itemProps.name!
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [aiButtonStatus, setAiButtonStatus] = useState<AiButtonStatus>('ready')

  const [currentLanguage, setCurrentLanguage] = useState<LanguagesEnum>(
    LanguagesEnum.EN,
  )
  const errors = useHandleFormErrors(languages, name, form)

  const handleTranslateClick = useCallback(async () => {
    if (!form) return
    let value: string | undefined
    const fieldName = generateName(name, currentLanguage) as string
    let selector: string | Array<any> = fieldName
    if (Array.isArray(fieldName)) {
      selector = [listName, fieldName[0], fieldName[1]]
      value = form.getFieldValue(selector)
    } else {
      value = form.getFieldValue(selector)
    }
    setLoading(true)
    try {
      const { data } = await axiosInstance.post<Record<LanguagesEnum, string>>(
        `${currentLanguage}/from`,
        value,
        {
          headers: {
            'Content-Type': 'text/plain',
          },
        },
      )
      setAiButtonStatus('done')
      Object.entries(data).forEach(([key, value]) => {
        form.setFields([
          {
            name: generateName(name, key as never as LanguagesEnum),
            value,
            touched: true,
            errors: [],
          },
        ])
      })
    } catch {
      toast.error(t`messages.cantTranslate`)
      setAiButtonStatus('error')
    }

    setLoading(false)
  }, [form, name, currentLanguage])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
          marginBottom: '8px',
        }}
      >
        <FormLabel required={inputProps.required} label={inputProps.label} />
        <LanguageButtons
          languages={languages}
          currentLanguage={currentLanguage}
          loading={loading}
          setCurrentLanguage={setCurrentLanguage}
          errors={errors}
          handleTranslateClick={handleTranslateClick}
          marginBottom="0px"
          aiButtonStatus={aiButtonStatus}
        />
      </div>
      {languages.map((lang) => (
        <div
          style={{ position: 'relative' }}
          className={itemProps.className}
          key={generateKey(name, lang)}
        >
          <Form.Item
            {...itemProps}
            name={`${name}${lang}`}
            key={generateKey(name, lang)}
            hidden={lang !== currentLanguage}
          >
            <TextEditor
              name={`${name}${lang}`}
              form={form}
              readOnly={disabled}
              translateClick={handleTranslateClick}
              handleChange={() => setAiButtonStatus('ready')}
            />
          </Form.Item>
          {loading && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                backgroundColor: 'rgba(255,255,255,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default TextEditorMultiLanguage
