import { Dayjs } from 'dayjs'
import { RuleObject } from 'rc-field-form/lib/interface'
import dayjs from 'dayjs'
import { SelectOption } from 'types/Option'
import { EventCreateRequestLanguagesEnum } from '@/openapi'

export const dateInFutureValidator = () => ({
  validator: (_: RuleObject, date: Dayjs) => {
    if (dayjs().isAfter(date)) {
      return Promise.reject('Date should be in future')
    }
    return Promise.resolve()
  },
})

export const dateToString = (date?: Dayjs | string | Date | null) => {
  if (!date) return
  if (typeof date === 'string') return date
  return dayjs(date).format('YYYY-MM-DD[T]HH:mm:ss')
}

export function getValue<B extends boolean>(
  data?: SelectOption | string,
  asNumber?: B,
): B extends true ? number | undefined : string | undefined
export function getValue(
  data?: SelectOption | string,
  asNumber: boolean = true,
): number | string | undefined {
  if (!data) return
  const result = typeof data === 'string' ? data : data.value
  return asNumber ? +result : result
}

export const prepareEABOption = (
  { billingSystem: billingSystem, id }: any,
  currentLang?: EventCreateRequestLanguagesEnum,
): SelectOption => {
  const label = billingSystem.code
  return {
    label,
    value: String(billingSystem.id),
  }
}

const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const generateCode = (length = 6) => {
  let text = ''

  for (let i = 0; i < length; i++) {
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length),
    )
  }

  return text
}
