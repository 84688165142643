import { Form, Modal } from 'antd'
import { useCallback, useState } from 'react'
import { PageTicketDto, TicketDto } from '@/openapi'
import commonStyles from '@pages/commonStyles.module.less'
import { useTranslation } from 'react-i18next'
import { SorterResult } from 'antd/es/table/interface'
import { Params } from '@/types/Params'
import { axiosInstance } from '@/api'
import { useAntdTable } from 'ahooks'
import { twoColumnsDateFormat } from '@pages/helpers'
import FloatInput from '@components/FloatInput'
import { DragTable, ExtendedColumns } from '@components/DragTable'

type Props = {
  onOk: (ticket?: TicketDto) => void
  onCancel: () => void
  queryStrings: string[]
}

export const LeftPartModal = ({ onOk, onCancel, queryStrings }: Props) => {
  const [ticket, setTicket] = useState<TicketDto | undefined>()
  const handleOk = useCallback(() => {
    onOk(ticket)
  }, [ticket])

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<TicketDto>
    },
    filters: { [key in 'email' | 'phone']: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [...queryStrings, `status==purchased`]

    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    if (filters.phone) {
      query.push(`user.phone~~${filters.phone}`)
    }
    if (filters.email) {
      query.push(`user.email~~${filters.email}`)
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageTicketDto>(`admin/tickets`, { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const columns: ExtendedColumns<TicketDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: t`labels.Created Date`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      defaultSortOrder: 'descend',
      render: twoColumnsDateFormat,
    },
    {
      title: t`labels.Price`,
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (_, ticket) => ticket.price + ' ' + ticket.currency?.name,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`Phone`}</div>
          <Form.Item name="phone">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'phone',
      key: 'phone',
      shortTitle: t`Phone`,
      render: (_, t) => t.user?.phone,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Email`}</div>
          <Form.Item name="email">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'email',
      key: 'email',
      shortTitle: t`labels.Email`,
      render: (_, t) => t.user?.email,
    },
  ]

  return (
    <Modal
      title="Please select ticket"
      open
      onOk={handleOk}
      onCancel={onCancel}
      width={900}
    >
      <Form form={form}>
        <DragTable<TicketDto>
          bordered
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          rowSelection={{
            type: 'radio',
            onSelect: setTicket,
          }}
          {...tableProps}
        />
      </Form>
    </Modal>
  )
}
