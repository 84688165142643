import { useEffect, useMemo, useState } from 'react'
import { DatePicker, Form, Space, Spin } from 'antd'
import Page from 'components/Page'
import {
  DailySalesStatisticsView,
  EventActorCountryView,
  PageImplDailySalesStatisticsView,
} from '@/openapi'
import dayjs, { Dayjs } from 'dayjs'
import FormItem from '@components/FormItem'
import {
  BaseFilers,
  FILTER_OPERATORS,
  GenericAsyncSelect,
} from '@components/AsyncSelect/GenericAsyncSelect'
import { SelectOption } from '@/types/Option'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { axiosInstance } from '@/api'
import { backgroundColor, borderColor } from '@/utils/chartColors'
import { ChartOptions } from 'chart.js/dist/types'
import { Params } from '@/types/Params'
import {
  DownloadReportButton,
  useLastParams,
} from '@pages/reports/shared/DownloadReportButton'
import { useExtraDatePickerFooter } from '@pages/reports/shared/useExtraDatePickerFooter'
import { useTranslation } from 'react-i18next'

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

type FormTypes = {
  dates?: [Dayjs, Dayjs]
  countryId?: SelectOption[]
  eventId?: SelectOption[]
  actorId?: SelectOption[]
}

type Props = {
  externalEventId?: string
}

const SalesByDates = ({ externalEventId }: Props) => {
  const [form] = Form.useForm()
  const [data, setData] = useState<DailySalesStatisticsView[]>([])
  const countryId = Form.useWatch('countryId', form)
  const eventId = Form.useWatch('eventId', form)
  const actorId = Form.useWatch('actorId', form)
  const values = Form.useWatch([], form) as FormTypes
  const [loading, setLoading] = useState(false)
  const { getQuery, setLastQuery } = useLastParams()
  const { t } = useTranslation()

  const getData = async (filters?: FormTypes) => {
    setLoading(true)
    const params: Params = { page: 0, size: 10000 }
    const query = []
    if (filters?.dates?.[0] && filters?.dates?.[1]) {
      query.push(`saleDate~DATE_AFTER~${filters.dates[0]?.format(DATE_FORMAT)}`)
      query.push(
        `saleDate~DATE_BEFORE~${filters.dates?.[1]?.format(DATE_FORMAT)}`,
      )
    }
    if (filters?.countryId?.length) {
      query.push(
        `countryId~in~${filters?.countryId
          .map(({ value }) => value)
          .toString()}`,
      )
    }
    if (filters?.eventId?.length) {
      query.push(
        `eventId~in~${filters?.eventId.map(({ value }) => value).toString()}`,
      )
    }
    if (filters?.actorId?.length) {
      query.push(
        `actorId$CUSTOM$${filters?.actorId
          .map(({ value }) => value)
          .toString()}`,
      )
    }
    if (externalEventId) {
      query.push(`eventId==${externalEventId}`)
    }
    params.query = query.join(';')
    setLastQuery(params.query)
    const {
      data: { content },
    } = await axiosInstance.get<PageImplDailySalesStatisticsView>(
      '/admin/statistics/daily/view?size=10000&sort=saleDate,asc',
      { params },
    )

    setData(content?.filter(({ soldTickets }) => !!soldTickets) || [])
    setLoading(false)
  }

  useEffect(() => {
    getData(values)
  }, [values])

  const extraFooter = useExtraDatePickerFooter(form)

  const actorBaseFilters = useMemo(() => {
    const result: BaseFilers = {}
    if (eventId?.length) {
      result.eventId = {
        operator: FILTER_OPERATORS.IN,
        value: eventId.map((i: SelectOption) => i.value),
      }
    }

    if (countryId?.length) {
      result.countryId = {
        operator: FILTER_OPERATORS.IN,
        value: countryId.map((i: SelectOption) => i.value),
      }
    }

    return Object.keys(result).length ? result : undefined
  }, [eventId, countryId])

  const countryBaseFilters = useMemo(() => {
    const result: BaseFilers = {}
    if (eventId?.length) {
      result.eventId = {
        operator: FILTER_OPERATORS.IN,
        value: eventId.map((i: SelectOption) => i.value),
      }
    }

    if (actorId?.length) {
      result.actorId = {
        operator: FILTER_OPERATORS.IN,
        value: actorId.map((i: SelectOption) => i.value),
      }
    }

    return Object.keys(result).length ? result : undefined
  }, [eventId, actorId])

  const eventBaseFilters = useMemo(() => {
    const result: BaseFilers = {}
    if (countryId?.length) {
      result.countryId = {
        operator: FILTER_OPERATORS.IN,
        value: countryId.map((i: SelectOption) => i.value),
      }
    }

    if (actorId?.length) {
      result.actorId = {
        operator: FILTER_OPERATORS.IN,
        value: actorId.map((i: SelectOption) => i.value),
      }
    }

    return Object.keys(result).length ? result : undefined
  }, [countryId, actorId])

  useEffect(() => {
    form.submit()
  }, [form])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (context) {
            const total = (context[0].raw as DailySalesStatisticsView)
              .soldTickets
            return `Total ${total} tickets`
          },
          label: function (context) {
            const casted = context.raw as DailySalesStatisticsView
            if (!casted.soldTickets) return ''
            return `${casted.salesAmount} ${casted.currencyCode}`
          },
        },
      },
    },
    parsing: {
      xAxisKey: 'saleDate',
      yAxisKey: 'soldTickets',
    },
  }

  const chartData = useMemo(() => {
    const currencies = Array.from(
      new Set(data.map(({ currencyCode }) => currencyCode)),
    )
    const labels = Array.from(new Set(data.map(({ saleDate }) => saleDate)))
    return {
      labels,
      datasets:
        currencies.map((currency, i) => ({
          label: currency,
          data: labels.map(
            (date) =>
              data.find(
                ({ saleDate, currencyCode }) =>
                  saleDate === date && currencyCode === currency,
              ) || { soldTickets: 0, saleDate: date },
          ),
          borderColor: borderColor[i],
          backgroundColor: backgroundColor[i],
        })) || [],
    }
  }, [data])

  return (
    <Page inner title={t`tabs.Sales by Dates`}>
      <Form
        form={form}
        onFinish={getData}
        initialValues={{ dates: [dayjs().startOf('month'), dayjs()] }}
      >
        <Space
          align="center"
          style={{
            gap: '30px',
            display: 'grid',
            alignItems: 'flex-start',
            gridTemplateColumns: 'auto 1fr 1fr 1fr auto',
          }}
        >
          <FormItem name="dates" style={{ marginTop: '22px' }}>
            <DatePicker.RangePicker
              showTime={false}
              allowClear={false}
              style={{ marginBottom: '20px' }}
              renderExtraFooter={() => extraFooter}
            />
          </FormItem>
          {externalEventId && (
            <>
              <div /> <div />
            </>
          )}
          {!externalEventId && (
            <>
              <GenericAsyncSelect<EventActorCountryView>
                path="admin/statistics/daily/countries"
                label={t`labels.Country`}
                name="countryId"
                width="100%"
                customPrepareOption={({ countryName, countryId }) => ({
                  label: String(countryName),
                  value: String(countryId),
                })}
                withAll={false}
                baseFilters={countryBaseFilters}
                controlProps={{ mode: 'multiple' }}
                searchField="countryName"
              />
              <GenericAsyncSelect<EventActorCountryView>
                path="admin/statistics/daily/events"
                label={t`labels.Event name`}
                name="eventId"
                width="100%"
                withAll={false}
                searchField="eventName"
                controlProps={{ mode: 'multiple' }}
                baseFilters={eventBaseFilters}
                customPrepareOption={({ eventName, eventId }) => ({
                  label: String(eventName),
                  value: String(eventId),
                })}
              />
              <GenericAsyncSelect<EventActorCountryView>
                path="admin/statistics/daily/actors"
                label={t`labels.Actor Name`}
                name="actorId"
                width="100%"
                searchField="actorName"
                withAll={false}
                baseFilters={actorBaseFilters}
                controlProps={{ mode: 'multiple' }}
                customPrepareOption={({ actorName, actorId }) => ({
                  label: String(actorName),
                  value: String(actorId),
                })}
              />
            </>
          )}
          <DownloadReportButton
            entity="daily"
            getQuery={getQuery}
            style={{ marginTop: '22px' }}
          />
        </Space>
      </Form>
      <Spin spinning={loading} delay={500} size="large">
        <Line options={options} data={chartData} />
      </Spin>
    </Page>
  )
}

export default SalesByDates
