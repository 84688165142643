import { DatePicker, DatePickerProps, FormItemProps } from 'antd'
import dayjs from 'dayjs'
import FormLabel from '@components/formElements/Layout/FormLabel'
import FormItem from '@components/FormItem'
import { CSSProperties } from 'react'
import { useIsRequired } from '@/hooks/useIsRequired'

type Props = {
  onChange?: (value?: string) => void
  label: string
  value?: string
  className?: string
  showTime?: boolean
  disabled?: boolean
  formItemProps?: FormItemProps
  style?: CSSProperties
} & Partial<Omit<DatePickerProps, 'value'>>

const FormDateTime = (props: Props) => {
  let {
    label,
    value,
    formItemProps,
    showTime = { format: 'HH:mm' },
    disabled,
    className,
    style,
    name,
  } = props

  const required = useIsRequired(formItemProps?.rules)

  return (
    <div className={className} style={style}>
      <FormLabel label={label} required={required} />
      <FormItem name={name} {...formItemProps} disabled={disabled}>
        <DatePicker
          style={{ width: '100%' }}
          onChange={props.onChange}
          defaultValue={value ? dayjs(value) : undefined}
          showTime={showTime}
          placeholder=""
          disabled={disabled}
        />
      </FormItem>
    </div>
  )
}

export default FormDateTime
