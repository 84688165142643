import { useCallback, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import Page from 'components/Page'
import {
  SocialMediaDto,
  PageSocialMediaDto,
  PictureTypeUserDtoEntityTypeEnum,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@components/FloatInput'
import SocialMediaForm from 'pages/socialMedia/socialMediaForm'

import { Params } from '@/types/Params'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { MediaModalExtended } from '@components/MediaModalExtended'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = SocialMediaDto
const Actor = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<SocialMediaDto>()
  const { languageFields } = useCatalogMultiLanguageFields('social-medias')
  const [mediaId, setMediaId] = useState<number | undefined>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<SocialMediaDto>
    },
    filters: { [key in keyof SocialMediaDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageSocialMediaDto>('admin/social-medias', {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/social-medias/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: SocialMediaDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '3') {
      setMediaId(record.id!)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns: ExtendedColumns<SocialMediaDto>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
      fixed: 'left',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`name`,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t`labels.Position`,
      dataIndex: 'position',
      key: 'position',
      sorter: true,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '3', name: t`media` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ]

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<SocialMediaDto>
          actions={
            <Button onClick={() => setIsOpenModal(true)}>{t`add`}</Button>
          }
          bordered
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={
          activeItem
            ? `${t`update`} ${activeItem.name}`
            : t`labels.Add Social Media`
        }
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <SocialMediaForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
      {mediaId && (
        <MediaModalExtended
          entity={PictureTypeUserDtoEntityTypeEnum.SOCIAL_MEDIA}
          id={mediaId}
          close={() => setMediaId(undefined)}
          allowPng
        />
      )}
    </Page>
  )
}

export default Actor
