import { Dropdown, MenuProps } from 'antd'
import { CSSProperties, FC, useCallback, useState } from 'react'
import { Params } from '@/types/Params'
import { axiosInstance } from '@/api'
import { useTranslation } from 'react-i18next'
import { getNameFromHeaders } from '@/utils/getNameFromHeaders'

type Props = {
  entity: string
  getQuery?: () => string
  getParams?: () => object
  style?: CSSProperties
}
export const DownloadReportButton: FC<Props> = ({
  entity,
  getQuery,
  style,
  getParams = () => ({}),
}) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const onMenuClick: MenuProps['onClick'] = useCallback(
    async (e: any) => {
      setLoading(true)
      const params: Params = { page: 0, size: 100000, ...getParams() }

      const query = getQuery?.()
      if (query) params.query = query
      params.format = e.key
      const { data, headers } = await axiosInstance.get<Blob>(
        `/admin/statistics/${entity}/download`,
        {
          responseType: 'blob',
          params,
        },
      )
      const download = getNameFromHeaders(headers)
      const fileURL = window.URL.createObjectURL(data)
      let alink = document.createElement('a')
      alink.href = fileURL
      if (download) {
        alink.download = download
      }
      alink.click()
      setLoading(false)
    },
    [getQuery, getParams, entity],
  )

  return (
    <Dropdown.Button
      loading={loading}
      disabled={loading}
      type="primary"
      style={style}
      menu={{
        items: [
          {
            key: 'pdf',
            label: 'pdf',
          },
          {
            key: 'excel',
            label: 'excel',
          },
        ],
        onClick: onMenuClick,
      }}
    >
      {t`labels.Download`}
    </Dropdown.Button>
  )
}

export const useLastParams = () => {
  const [sort, setLastSort] = useState<Record<string, string> | null>()
  const [query, setLastQuery] = useState<string | null>()

  const getQuery = useCallback(() => query || '', [query])
  const getSort = useCallback(() => sort || {}, [sort])

  return {
    setLastSort,
    setLastQuery,
    getQuery,
    getSort,
  }
}
