import Page from 'components/Page'
import {
  EventExtendedDto,
  PageEventStageDefinitionDto,
  TicketDto,
} from '@/openapi'

import { useParams } from 'react-router-dom'

import useAsyncGet from '@/hooks/useAsyncGet'
import { GiftWithSD } from '@pages/event/manage/newGift/giftWithSD'
import { GiftWithoutSD } from '@pages/event/manage/newGift/giftWithoutSD'

export type CustomFormData = TicketDto

const NewGiftTickets = ({ event }: { event?: EventExtendedDto }) => {
  const { id: eventId } = useParams<{ id: string }>()
  const { data, loaded } = useAsyncGet<PageEventStageDefinitionDto>(
    `admin/event-stage-definitions?page=0&size=100&query=event.id==${eventId}`,
  )
  return (
    <Page inner loading={!loaded}>
      {data?.content?.length ? (
        <GiftWithSD
          event={event!}
          eventId={eventId!}
          stageDefinition={data.content[0]}
        />
      ) : (
        <GiftWithoutSD event={event!} eventId={eventId!} />
      )}
    </Page>
  )
}

export default NewGiftTickets
