import { Button, DatePicker } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { addSessionSetting, getSessionSetting } from '@/utils/sessionSettings'
import { RangePickerProps } from 'antd/es/date-picker'

type DateObject = {
  from: Dayjs
  to: Dayjs
}

type Props = {
  onChange: (obj: DateObject) => void
  saveToLocalStorage?: boolean
  saveString?: string
}
export const CustomDatePicker = ({
  onChange,
  saveToLocalStorage,
  saveString,
}: Props) => {
  const [dates, setDates] = useState<RangePickerProps['value']>(() => {
    const fromStorage = getSessionSetting(`${saveString}-fromStorage`)
    const toStorage = getSessionSetting(`${saveString}-toStorage`)

    return [
      fromStorage && saveToLocalStorage
        ? dayjs(fromStorage)
        : dayjs().startOf('month'),
      toStorage && saveToLocalStorage ? dayjs(toStorage) : dayjs(),
    ]
  })
  const { t } = useTranslation()

  useEffect(() => {
    if (dates?.[0] && dates?.[1]) {
      onChange({
        from: dates[0],
        to: dates[1],
      })
    }
  }, [])

  const changeDatesHandler = useCallback(
    (values: any) => {
      if (!values[0] || !values[1]) return
      setDates(values)
      if (saveToLocalStorage) {
        addSessionSetting(
          `${saveString}-fromStorage`,
          values?.[0]?.toString() || '',
        )
        addSessionSetting(
          `${saveString}-toStorage`,
          values?.[1]?.toString() || '',
        )
      }
      onChange({
        from: values[0],
        to: values[1],
      })
    },
    [saveString, saveToLocalStorage],
  )

  const extraFooter = useMemo(
    () => (
      <div>
        <div style={{ padding: '10px', display: 'flex', gap: '10px' }}>
          <Button
            size="small"
            onClick={() =>
              changeDatesHandler([dayjs().startOf('day'), dayjs().endOf('day')])
            }
          >
            {t`labels.Today`}
          </Button>
          <Button
            size="small"
            onClick={() =>
              changeDatesHandler([
                dayjs().subtract(7, 'days').startOf('day'),
                dayjs().endOf('day'),
              ])
            }
          >
            {t`labels.Last 7 days`}
          </Button>
          <Button
            size="small"
            onClick={() =>
              changeDatesHandler([
                dayjs().subtract(14, 'days').startOf('day'),
                dayjs().endOf('day'),
              ])
            }
          >
            {t`labels.Last 14 days`}
          </Button>
          <Button
            size="small"
            onClick={() =>
              changeDatesHandler([
                dayjs().subtract(1, 'month').startOf('day'),
                dayjs().endOf('day'),
              ])
            }
          >
            {t`labels.Last month`}
          </Button>
        </div>
        <div
          style={{
            padding: '10px',
            display: 'flex',
            gap: '10px',
            paddingTop: 0,
          }}
        >
          <Button
            size="small"
            onClick={() =>
              changeDatesHandler([
                dayjs().startOf('month'),
                dayjs().endOf('day'),
              ])
            }
          >
            {t`labels.From month start`}
          </Button>
        </div>
      </div>
    ),
    [t],
  )

  return (
    <DatePicker.RangePicker
      onChange={changeDatesHandler}
      showTime
      allowClear={false}
      value={dates}
      style={{ marginBottom: '20px' }}
      renderExtraFooter={() => extraFooter}
    />
  )
}
