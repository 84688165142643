import { PageHeader } from '@ant-design/pro-components'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.less'
import Page from '@/components/Page'
import { Descriptions, Divider, Modal } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import { EventDto, EventSeatPriceDto, TicketDto } from '@/openapi'
import { ToPart } from '@pages/transferEvent/ToPart'
import { FromPart } from '@pages/transferEvent/FromPart'
import { useEffect, useState } from 'react'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { axiosInstance } from '@/api'
export const renderEventLabel = (event: EventDto) => {
  let label = String(event.id)
  if ((event.name?.length || 0) > 30) {
    label += ` - ${event.name?.slice(0, 30)}...`
  } else {
    label += ` - ${event.name}`
  }
  if (event.city?.name) {
    label += ` - ${event.city.name}`
  }
  return label
}
const TransferEvent = () => {
  const { t } = useTranslation()
  const [from, setFrom] = useState<TicketDto | undefined>()
  const [to, setTo] = useState<EventSeatPriceDto | undefined>()
  const [fromEvent, setFromEvent] = useState<EventDto | undefined>()
  const [toEvent, setToEvent] = useState<EventDto | undefined>()
  const { getLocalizedKey } = useLanguageContext()
  const [uniqueKey, setUniqueKey] = useState(new Date().getTime())

  useEffect(() => {
    if (from && to) {
      Modal.confirm({
        width: 900,
        title: 'Please, clarify ticket transfer',
        content: (
          <>
            <Descriptions
              bordered
              style={{ marginBottom: '10px' }}
              size="small"
              items={[
                {
                  key: 'name',
                  label: 'User phone',
                  children: from.user?.phone,
                },
                {
                  key: 'email',
                  label: 'User email',
                  children: from.user?.email,
                },
              ]}
            />
            <Descriptions
              bordered
              column={1}
              size="small"
              style={{ marginBottom: '10px' }}
              items={[
                {
                  key: 'event',
                  label: 'Event',
                  children: (
                    <div className={styles.changes}>
                      <span>{getLocalizedKey('name', fromEvent)}</span>
                      <ArrowRightOutlined />
                      <span>{getLocalizedKey('name', toEvent)}</span>
                    </div>
                  ),
                },
                {
                  key: 'price',
                  label: 'Price',
                  children: (
                    <div className={styles.changes}>
                      <span>
                        {from.price} {from.currency?.code}{' '}
                        {`(incl. service fee ${from.serviceFee}%) `}
                      </span>
                      <ArrowRightOutlined />
                      <span>
                        {to.eventPrice?.price} {toEvent?.currency?.code}
                      </span>
                    </div>
                  ),
                },
                {
                  key: 'eventPrice',
                  label: 'Event Price',
                  children: (
                    <div className={styles.changes}>
                      <span>{from.eventPrice?.name}</span>
                      <ArrowRightOutlined />
                      <span>{to.eventPrice?.name}</span>
                    </div>
                  ),
                },
                {
                  key: 'row',
                  label: 'Row',
                  children: (
                    <div className={styles.changes}>
                      <span>{from.eventSeat?.rowNum}</span>
                      <ArrowRightOutlined />
                      <span>{to.eventSeat?.rowNum}</span>
                    </div>
                  ),
                },
                {
                  key: 'place',
                  label: 'Place',
                  children: (
                    <div className={styles.changes}>
                      <span>{from.eventSeat?.place}</span>
                      <ArrowRightOutlined />
                      <span>{to.eventSeat?.place}</span>
                    </div>
                  ),
                },
              ]}
            />
          </>
        ),
        onOk: async () => {
          await axiosInstance.post(`admin/tickets/${from.id}/transfer`, {
            eventSeatId: to.eventSeat?.id,
            eventPriceId: to.eventPrice?.id,
          })
          setUniqueKey(new Date().getTime())
          setFrom(undefined)
          setTo(undefined)
        },
      })
    }
  }, [from, to, fromEvent, toEvent, getLocalizedKey])

  return (
    <>
      <PageHeader title={t`labels.transfer_events`} />
      <Page inner>
        <div className={styles.wrapper}>
          <FromPart
            onSelect={setFrom}
            setFromEvent={setFromEvent}
            uniqueKey={uniqueKey}
            selectedTicket={from}
          />
          <Divider
            type="vertical"
            style={{ height: '100%', paddingRight: '10px' }}
          />
          <ToPart
            uniqueKey={uniqueKey}
            onSelect={setTo}
            setToEvent={setToEvent}
          />
        </div>
      </Page>
    </>
  )
}

export default TransferEvent
