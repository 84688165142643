import { SelectOption } from 'types/Option'
import { EventTourViewDto } from '@/openapi'
import {
  isArchivedEvent,
  isCancelledEvent,
  isPublishedEvent,
} from '@/utils/eventsStatuses'

export function getValue<B extends boolean>(
  data?: SelectOption | string,
  asNumber?: B,
): B extends true ? number | undefined : string | undefined
export function getValue(
  data?: SelectOption | string,
  asNumber: boolean = true,
): number | string | undefined {
  if (!data) return
  const result = typeof data === 'string' ? data : data.value
  return asNumber ? +result : result
}

const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const generateCode = (length = 6) => {
  let text = ''

  for (let i = 0; i < length; i++) {
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length),
    )
  }

  return text
}

export const canEditEventRecord = (event: EventTourViewDto) =>
  !isArchivedEvent(event) &&
  !isPublishedEvent(event) &&
  !isCancelledEvent(event)
