import React, { useCallback, useContext, useRef } from 'react'
import { Form } from 'antd'

export interface IContext {
  onFormChangeCb?: (cb: Function, name: string) => void
  clearCb?: (name: string) => void
}

const FormContext = React.createContext<IContext | undefined>(undefined)

const FormProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const changeCb = useRef<{ [key: string]: Function }>({})

  const onFormChangeCb = useCallback((cb: Function, name: string) => {
    changeCb.current[name] = cb
  }, [])

  const clearCb = useCallback((name: string) => {
    delete changeCb.current?.[name]
  }, [])

  return (
    <FormContext.Provider value={{ onFormChangeCb, clearCb }}>
      <Form.Provider
        onFormChange={() => {
          Object.values(changeCb.current).forEach((f) => f())
        }}
      >
        {children}
      </Form.Provider>
    </FormContext.Provider>
  )
}

export function useFormContext() {
  const context = useContext(FormContext)

  if (typeof context === 'undefined') {
    throw new Error('useFormContext must be used within an FormProvider')
  }

  return context
}

export default FormProvider
