/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageAudit } from '../models';
// @ts-ignore
import { PageSeatTypeDto } from '../models';
// @ts-ignore
import { PageSeatTypeExtendedDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
// @ts-ignore
import { SeatTypeCreateRequest } from '../models';
// @ts-ignore
import { SeatTypeCreateResponse } from '../models';
// @ts-ignore
import { SeatTypeExtendedDto } from '../models';
// @ts-ignore
import { SeatTypeUpdateRequest } from '../models';
// @ts-ignore
import { SeatTypeUpdateResponse } from '../models';
/**
 * SeatTypesAdminControllerApi - axios parameter creator
 * @export
 */
export const SeatTypesAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<SeatTypeCreateRequest>} seatTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate16: async (seatTypeCreateRequest: Array<SeatTypeCreateRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seatTypeCreateRequest' is not null or undefined
            assertParamExists('bulkCreate16', 'seatTypeCreateRequest', seatTypeCreateRequest)
            const localVarPath = `/v1/admin/seat-types/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seatTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SeatTypeCreateRequest} seatTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create20: async (seatTypeCreateRequest: SeatTypeCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seatTypeCreateRequest' is not null or undefined
            assertParamExists('create20', 'seatTypeCreateRequest', seatTypeCreateRequest)
            const localVarPath = `/v1/admin/seat-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seatTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById17: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById17', 'id', id)
            const localVarPath = `/v1/admin/seat-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find52: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find52', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/seat-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended16: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findExtended16', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/seat-types/extended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById37: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById37', 'id', id)
            const localVarPath = `/v1/admin/seat-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount43: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/seat-types/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit16: async (pageable: Pageable, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getEntityAudit16', 'pageable', pageable)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit16', 'id', id)
            const localVarPath = `/v1/admin/seat-types/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties16: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/seat-types/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessTranslations16ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations16FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations16: async (toLanguage: ProcessTranslations16ToLanguageEnum, fromLanguage?: ProcessTranslations16FromLanguageEnum, forceTranslate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toLanguage' is not null or undefined
            assertParamExists('processTranslations16', 'toLanguage', toLanguage)
            const localVarPath = `/v1/admin/seat-types/i18n/from/{fromLanguage}/to/{toLanguage}`
                .replace(`{${"toLanguage"}}`, encodeURIComponent(String(toLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromLanguage !== undefined) {
                localVarQueryParameter['fromLanguage'] = fromLanguage;
            }

            if (forceTranslate !== undefined) {
                localVarQueryParameter['forceTranslate'] = forceTranslate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull16: async (id: number, properties: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPropertiesToNull16', 'id', id)
            // verify required parameter 'properties' is not null or undefined
            assertParamExists('setPropertiesToNull16', 'properties', properties)
            const localVarPath = `/v1/admin/seat-types/{id}/property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (properties) {
                localVarQueryParameter['properties'] = Array.from(properties);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SeatTypeUpdateRequest} seatTypeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update16: async (id: number, seatTypeUpdateRequest: SeatTypeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update16', 'id', id)
            // verify required parameter 'seatTypeUpdateRequest' is not null or undefined
            assertParamExists('update16', 'seatTypeUpdateRequest', seatTypeUpdateRequest)
            const localVarPath = `/v1/admin/seat-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seatTypeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeatTypesAdminControllerApi - functional programming interface
 * @export
 */
export const SeatTypesAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SeatTypesAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<SeatTypeCreateRequest>} seatTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreate16(seatTypeCreateRequest: Array<SeatTypeCreateRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeatTypeCreateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreate16(seatTypeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SeatTypeCreateRequest} seatTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create20(seatTypeCreateRequest: SeatTypeCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeatTypeCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create20(seatTypeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById17(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById17(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find52(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeatTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find52(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExtended16(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeatTypeExtendedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExtended16(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById37(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeatTypeExtendedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById37(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount43(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount43(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit16(pageable: Pageable, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit16(pageable, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nSupportedProperties16(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nSupportedProperties16(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessTranslations16ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations16FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTranslations16(toLanguage: ProcessTranslations16ToLanguageEnum, fromLanguage?: ProcessTranslations16FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTranslations16(toLanguage, fromLanguage, forceTranslate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPropertiesToNull16(id: number, properties: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPropertiesToNull16(id, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SeatTypeUpdateRequest} seatTypeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update16(id: number, seatTypeUpdateRequest: SeatTypeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeatTypeUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update16(id, seatTypeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SeatTypesAdminControllerApi - factory interface
 * @export
 */
export const SeatTypesAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SeatTypesAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<SeatTypeCreateRequest>} seatTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate16(seatTypeCreateRequest: Array<SeatTypeCreateRequest>, options?: any): AxiosPromise<Array<SeatTypeCreateResponse>> {
            return localVarFp.bulkCreate16(seatTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SeatTypeCreateRequest} seatTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create20(seatTypeCreateRequest: SeatTypeCreateRequest, options?: any): AxiosPromise<SeatTypeCreateResponse> {
            return localVarFp.create20(seatTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById17(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById17(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find52(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageSeatTypeDto> {
            return localVarFp.find52(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended16(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageSeatTypeExtendedDto> {
            return localVarFp.findExtended16(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById37(id: number, options?: any): AxiosPromise<SeatTypeExtendedDto> {
            return localVarFp.getById37(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount43(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount43(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit16(pageable: Pageable, id: number, options?: any): AxiosPromise<PageAudit> {
            return localVarFp.getEntityAudit16(pageable, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties16(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getI18nSupportedProperties16(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessTranslations16ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations16FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations16(toLanguage: ProcessTranslations16ToLanguageEnum, fromLanguage?: ProcessTranslations16FromLanguageEnum, forceTranslate?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processTranslations16(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull16(id: number, properties: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPropertiesToNull16(id, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SeatTypeUpdateRequest} seatTypeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update16(id: number, seatTypeUpdateRequest: SeatTypeUpdateRequest, options?: any): AxiosPromise<SeatTypeUpdateResponse> {
            return localVarFp.update16(id, seatTypeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SeatTypesAdminControllerApi - object-oriented interface
 * @export
 * @class SeatTypesAdminControllerApi
 * @extends {BaseAPI}
 */
export class SeatTypesAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<SeatTypeCreateRequest>} seatTypeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public bulkCreate16(seatTypeCreateRequest: Array<SeatTypeCreateRequest>, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).bulkCreate16(seatTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SeatTypeCreateRequest} seatTypeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public create20(seatTypeCreateRequest: SeatTypeCreateRequest, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).create20(seatTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public deleteById17(id: number, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).deleteById17(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public find52(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).find52(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public findExtended16(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).findExtended16(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public getById37(id: number, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).getById37(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public getCount43(query?: string, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).getCount43(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public getEntityAudit16(pageable: Pageable, id: number, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).getEntityAudit16(pageable, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public getI18nSupportedProperties16(options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).getI18nSupportedProperties16(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessTranslations16ToLanguageEnum} toLanguage 
     * @param {ProcessTranslations16FromLanguageEnum} [fromLanguage] 
     * @param {boolean} [forceTranslate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public processTranslations16(toLanguage: ProcessTranslations16ToLanguageEnum, fromLanguage?: ProcessTranslations16FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).processTranslations16(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} properties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public setPropertiesToNull16(id: number, properties: Array<string>, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).setPropertiesToNull16(id, properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SeatTypeUpdateRequest} seatTypeUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatTypesAdminControllerApi
     */
    public update16(id: number, seatTypeUpdateRequest: SeatTypeUpdateRequest, options?: AxiosRequestConfig) {
        return SeatTypesAdminControllerApiFp(this.configuration).update16(id, seatTypeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations16ToLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations16FromLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
