import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { prepareOption } from '@components/AsyncSelect/types'
import { CityAsyncSelect } from '@components/AsyncSelect/CityAsyncSelect'
import { SelectOption } from '@/types/Option'
import {
  EventCreateRequest,
  EventCreateRequestLanguagesEnum,
  EventDto,
  TourExtendedDto,
} from '@/openapi'
import { dateInFutureValidator, dateToString } from '@pages/event/helpers'
import FloatDateTime from '@components/FloatDateTime'
import FormItem from '@components/FormItem'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'

type EditEventFormProps = {
  onSuccess: () => void
  id?: number
  tour?: TourExtendedDto
}

type FormData = {
  time?: string
  cityId?: SelectOption
}

const EditEventForm = ({ onSuccess, id, tour }: EditEventFormProps) => {
  const [form] = Form.useForm<FormData>()
  const [fullItem, setFullItem] = useState<FormData>()
  const [loading, setLoading] = useState(true)
  const { appLang } = useLanguageContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance.get<EventDto>(`admin/events/${id}`).then(({ data }) => {
        setFullItem({
          cityId: data.city ? prepareOption(data.city!, appLang) : undefined,
          time: data.dateStart,
        })
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: FormData) => {
    if (id) {
      if (values.time) {
        await axiosInstance.put(`admin/events/step2/${id}`, {
          dateStart: dateToString(values.time),
        })
      }
      if (values.cityId) {
        await axiosInstance.put(`admin/events/step3/${id}`, {
          cityId: values.cityId?.value || values.cityId,
        })
      }
    } else {
      if (!tour) return
      const dataToSend: Partial<EventCreateRequest> = {
        agencyId: tour.agency?.id,
        languages: castType<EventCreateRequestLanguagesEnum[]>(tour.languages),
        actorIds: tour.actors?.map(({ id }) => id!),
        tourId: tour.id,
        eventTypeId: tour.eventType?.id,
        ageCategory: tour.ageCategory,
      }
      Object.entries(tour).forEach(([key, value]) => {
        if (key.startsWith('description') || key.startsWith('name')) {
          const castedKey = key as keyof EventCreateRequest
          dataToSend[castedKey] = value as any
        }
      })

      const {
        data: { id: eventId },
      } = await axiosInstance.post<EventDto>('admin/events/step1', dataToSend)
      if (values.time) {
        await axiosInstance.put(`admin/events/step2/${eventId}`, {
          dateStart: dateToString(values.time),
        })
      }
      if (values.cityId) {
        await axiosInstance.put(`admin/events/step3/${eventId}`, {
          cityId: values.cityId?.value || values.cityId,
        })
      }
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={fullItem}
      style={{ marginTop: '30px' }}
    >
      <FormItem name="time" hasFeedback rules={[dateInFutureValidator()]}>
        <FloatDateTime label={t`labels.Event date and time`} required />
      </FormItem>
      <CityAsyncSelect name="cityId" withAll={false} />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default EditEventForm
