import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Collapse, Descriptions, FormInstance, Steps, Tag } from 'antd'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import Page from 'components/Page'
import StepBasicInformation from './StepBasicInformation'
import { axiosInstance } from 'api'
import {
  PageTourDto,
  TourDto,
  TourExtendedDto,
  TourExtendedDtoStatusEnum,
} from 'openapi'
import { prepareOption } from 'components/AsyncSelect/types'
import { useLanguageContext } from 'contexts/LanguageProvider'
import { isNil, omitBy } from 'lodash'
import { PageHeader } from '@ant-design/pro-components'
import { useAuthContext } from '@/contexts/AuthContext'
import StepSchedule from '@pages/tour/StepSchedule'
import { valueOrDash } from '@pages/helpers'
import { useTranslation } from 'react-i18next'

type TPublish = { [key: string]: Array<string> }
const Tour = () => {
  const customStep = useRef(-1)
  const [search, setSearch] = useSearchParams()
  const [current, setCurrent] = useState<number>(
    Number(search.get('step')) || 0,
  )
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState<TourExtendedDto>()
  const [itemWithStats, setItemWithStats] = useState<TourDto>()
  const [publishErrors, setPublishErrors] = useState<TPublish>({})
  const [currentForm, setCurrentForm] = useState<FormInstance>()
  const { state } = useLocation()
  const { id } = useParams<{ id: string }>()
  const { appLang, getLocalizedKey } = useLanguageContext()
  const location = useLocation()
  const navigate = useNavigate()
  const { isAdmin } = useAuthContext()
  const { t } = useTranslation()

  useEffect(() => {
    setSearch({ step: String(current), view: search.get('view') || '' })
  }, [current])

  const onChange = useCallback(
    async (value: number) => {
      if (!currentForm) {
        setCurrent(value)
        return
      }
      try {
        await currentForm?.validateFields()
        customStep.current = value
        currentForm?.submit()
      } catch {
        customStep.current = -1
      }
    },
    [currentForm],
  )

  const isCreate = useMemo(() => id === 'new', [id])
  const disabled = useMemo(
    () =>
      !isCreate &&
      item?.status !== TourExtendedDtoStatusEnum.UNPUBLISHED &&
      item?.status !== TourExtendedDtoStatusEnum.ANNOUNCED,
    [item, isCreate],
  )

  const canEdit = useMemo(() => {
    if (isCreate) return true
    if (
      item?.status === TourExtendedDtoStatusEnum.UNPUBLISHED ||
      item?.status === TourExtendedDtoStatusEnum.ANNOUNCED
    )
      return true

    return isAdmin && item?.status === TourExtendedDtoStatusEnum.PUBLISHED
  }, [item, isCreate, isAdmin])

  useEffect(() => {
    // TODO clear form fields programmatically
    if (currentForm?.getFieldsValue()) {
      navigate(0)
    }
    if (isCreate) {
      setLoading(false)
      setItem(undefined)
    } else {
      axiosInstance
        .get<PageTourDto>(`admin/tours?query=id==${id}`)
        .then(({ data }) => {
          setItemWithStats(data.content?.[0])
        })
      axiosInstance
        .get<TourExtendedDto>(`admin/tours/${id}`)
        .then(({ data }) => {
          setItem(data)
          if (state === 'new') {
            setCurrent(1)
            navigate(location.pathname, {})
          }
          setLoading(false)
        })
    }
  }, [id, isCreate])

  const formattedItem = useMemo(() => {
    if (!item) return {}
    return {
      ...omitBy(item, isNil),
      actorIds: item.actors!.map((actor) => prepareOption(actor, appLang)),
      eventTypeId: prepareOption(item.eventType!, appLang),
      agencyId: item.agency && prepareOption(item.agency, appLang),
    }
  }, [item])

  const stepForward = useCallback(
    (data: Partial<TourExtendedDto>, reverse = false) => {
      setItem({ ...item, ...data })
      if (customStep.current === -1) {
        setCurrent((step) => step + (reverse ? -1 : 1))
      } else {
        setCurrent(customStep.current)
        customStep.current = -1
      }
    },
    [item],
  )

  const title = useMemo(
    () => (
      <div>
        <div>
          {isCreate ? t`create` : t`edit`} {t`labels.Tour`}
        </div>
        <div>
          <Tag color="#108ee9">{isCreate ? t`new` : item?.status}</Tag>
        </div>
      </div>
    ),
    [isCreate, item],
  )
  const subtitle = useMemo(() => <>{getLocalizedKey('name', item)}</>, [item])

  return (
    <>
      <PageHeader
        title={title}
        subTitle={subtitle}
        footer={
          <>
            <Collapse
              items={[
                {
                  key: '1',
                  label: t`labels.Tour Statistic`,
                  children: (
                    <>
                      <Descriptions
                        title={t`labels.Tour Count`}
                        size="small"
                        bordered
                      >
                        <Descriptions.Item label={t`labels.Total Events`}>
                          {valueOrDash(itemWithStats?.tourCount?.totalEvents)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Ready For Announce`}>
                          {valueOrDash(
                            itemWithStats?.tourCount?.readyForAnnounce,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Ready For Publish`}>
                          {valueOrDash(
                            itemWithStats?.tourCount?.readyForPublish,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Announced`}>
                          {valueOrDash(itemWithStats?.tourCount?.announced)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Published`}>
                          {valueOrDash(itemWithStats?.tourCount?.published)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Canceled`}>
                          {valueOrDash(itemWithStats?.tourCount?.canceled)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Archived`}>
                          {valueOrDash(itemWithStats?.tourCount?.archived)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Total Cities`}>
                          {valueOrDash(itemWithStats?.tourCount?.totalCities)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t`labels.Total Countries`}>
                          {valueOrDash(
                            itemWithStats?.tourCount?.totalCountries,
                          )}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions
                        title={t`labels.Tour Sales`}
                        size="small"
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                        bordered
                      >
                        <Descriptions.Item label={t`labels.Total Tickets Sold`}>
                          {valueOrDash(
                            itemWithStats?.tourSales?.totalTicketsSold,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t`labels.Predicted Tickets Sold`}
                        >
                          {valueOrDash(
                            itemWithStats?.tourSales?.predictedTicketsSold,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t`labels.Sold Out Percentage`}
                        >
                          {valueOrDash(
                            itemWithStats?.tourSales?.soldOutPercentage?.toFixed(
                              2,
                            ),
                            '%',
                          )}
                        </Descriptions.Item>
                      </Descriptions>
                    </>
                  ),
                },
              ]}
              defaultActiveKey={['1']}
            />
          </>
        }
      />
      <Page inner loading={loading}>
        <Steps
          current={current}
          onChange={onChange}
          items={[
            {
              title: t`labels.Basic information`,
              status: !!publishErrors.general ? 'error' : undefined,
            },
            {
              title: t`labels.Events and Schedule`,
              status: !!publishErrors.dates ? 'error' : undefined,
              disabled: isCreate,
            },
          ]}
        />
        {current === 0 && !loading && (
          <StepBasicInformation
            onSuccess={stepForward}
            item={formattedItem}
            setForm={setCurrentForm}
            isCreate={isCreate}
            disabled={disabled}
            errors={publishErrors.general}
            canEdit={canEdit}
          />
        )}
        {current === 1 && !loading && (
          <StepSchedule
            disabled={disabled}
            onSuccess={stepForward}
            item={item}
            setForm={setCurrentForm}
            errors={publishErrors.dates}
          />
        )}
      </Page>
    </>
  )
}

export default Tour
