import { Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { UserDto } from '@/openapi'
import UserTickets from '@pages/users/manage/userTickets'
import useAsyncGet from '@/hooks/useAsyncGet'
import VisitsHistory from '@pages/users/manage/visitsHistory'
import NotificationLogs from '@pages/users/manage/notificationLogs'
import { useTranslation } from 'react-i18next'
const ManageUsers = () => {
  const [search, setSearch] = useSearchParams()
  const { id } = useParams<{ id: string }>()

  const { data: user } = useAsyncGet<UserDto>(`admin/users/${id}`)
  const { t } = useTranslation()

  const items = useMemo(
    () => [
      {
        label: t`tabs.Manage Tickets`,
        key: 'tickets-management',
        children: <UserTickets user={user} />,
      },
      {
        label: t`tabs.User Visits History`,
        key: 'visits-history',
        children: <VisitsHistory filter={`userId==${id}`} />,
      },
      {
        label: t`tabs.Notification Logs`,
        key: 'notification-logs',
        children: <NotificationLogs baseQuery={`userId==${id}`} />,
      },
    ],
    [user, search, id],
  )

  return (
    <>
      <PageHeader title={`${t`labels.User`} ${user?.phone}`} />
      <Tabs
        type="card"
        activeKey={search.get('tab') || 'tickets-management'}
        onChange={(tab) => setSearch({ tab })}
        destroyInactiveTabPane={true}
        items={items}
        tabBarStyle={{ margin: 0 }}
      />
    </>
  )
}

export default ManageUsers
