import { useCallback, useState } from 'react'
import { Modal, Form } from 'antd'
import Page from 'components/Page'
import { CountryDto, PageCountryDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '../../components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '../../components/FloatInput'
import CountryForm from './countryForm'

import { Params } from '@/types/Params'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { useTranslation } from 'react-i18next'
import { MediaModal } from '@components/MediaModal'
import { useAdminColumns } from '@/hooks/adminColumns'
import { AdminButton } from '@components/Auth/AdminComponents'
import { SelectOption } from '@/types/Option'
import { useAuthContext } from '@/contexts/AuthContext'
import { DragTable } from '@components/DragTable'

export type CustomFormData = CountryDto & { currencyId?: SelectOption }

const Country = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<CustomFormData>()
  const { languageFields } = useCatalogMultiLanguageFields('countries')
  const { t } = useTranslation()
  const [mediaId, setMediaId] = useState<number | undefined>()
  const { isAdmin } = useAuthContext()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<CountryDto>
    },
    filters: { [key in keyof CountryDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (filters.phoneCode) {
      query.push(`phoneCode==${filters.phoneCode}`)
    }
    if (filters.code) {
      query.push(`code~~${filters.code}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageCountryDto>('admin/countries', { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/countries/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: CountryDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '3') {
      setMediaId(record.id!)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns = useAdminColumns<CountryDto>(
    [
      {
        title: 'ID',
        fixed: 'left',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        width: '70px',
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`countryName`}</div>
            <Form.Item name="name">
              <FloatInput label="" onChange={submit} />
            </Form.Item>
          </div>
        ),
        dataIndex: 'name',
        shortTitle: t`countryName`,
        key: 'name',
        sorter: true,
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`countryCode`}</div>
            <Form.Item name="code">
              <FloatInput label="" onChange={submit} />
            </Form.Item>
          </div>
        ),
        dataIndex: 'code',
        key: 'code',
        sorter: true,
        shortTitle: t`countryCode`,
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`phoneCode`}</div>
            <Form.Item name="phoneCode">
              <FloatInput label="" onChange={submit} />
            </Form.Item>
          </div>
        ),
        dataIndex: 'phoneCode',
        key: 'phoneCode',
        shortTitle: t`phoneCode`,
        sorter: true,
      },
      {
        title: t`labels.VAT rate`,
        dataIndex: 'vatRate',
        key: 'vatRate',
        sorter: true,
      },
      {
        title: t`labels.Currency`,
        dataIndex: 'currency',
        key: 'currency',
        render: (_, record) => record.currency?.code,
      },
      {
        title: t`actions`,
        key: 'operation',
        noDrag: true,
        noTooltip: true,
        width: '90px',
        render: (text, record) => {
          return (
            <DropOption
              onMenuClick={(e) => handleMenuClick(record, e)}
              menuOptions={[
                { key: '1', name: t`update` },
                { key: '3', name: t`media` },
                { key: '2', name: t`delete` },
              ]}
            />
          )
        },
      },
    ],
    ['operation', 'vatRate'],
  )

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<CountryDto>
          bordered
          actions={
            <AdminButton onClick={() => setIsOpenModal(true)}>
              {t`add`}
            </AdminButton>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={activeItem ? `${t`update`} ${activeItem.name}` : 'Add country'}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <CountryForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
      <MediaModal
        entity="countries"
        id={mediaId}
        close={() => setMediaId(undefined)}
      />
    </Page>
  )
}

export default Country
