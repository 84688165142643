import React, { useCallback, useMemo } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { FormInstance } from 'antd/es/form/hooks/useForm'

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
  'video',
  'image',
]

type Props = {
  placeholder?: string
  form: FormInstance
  name: string
  readOnly?: boolean
  translateClick?: () => void
  handleChange?: (v: string) => void
}

const icons = Quill.import('ui/icons')
icons.translate =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">\n' +
  '  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>\n' +
  '  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>\n' +
  '</svg>'

const TextEditor: React.FC<Props> = ({
  form,
  placeholder = 'Enter text',
  name,
  readOnly = false,
  translateClick,
  handleChange,
}) => {
  const onChange = useCallback(
    (value: string) => {
      form.setFields([
        {
          name,
          value,
          touched: true,
        },
      ])
      handleChange?.(value)
    },
    [form, handleChange],
  )

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'code'],
          ['clean'],
          ['video', 'image'],
          ...(translateClick ? [['translate']] : []),
        ],
        handlers: {
          translate: translateClick,
        },
      },
    }),
    [],
  )

  return (
    <>
      <ReactQuill
        theme="snow"
        value={form.getFieldValue(name)}
        modules={modules}
        formats={formats}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </>
  )
}

export default TextEditor
