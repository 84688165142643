/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { TicketControlActivityUpdateResponse } from './ticket-control-activity-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { TicketUpdateResponse } from './ticket-update-response';

/**
 * 
 * @export
 * @interface TicketControlVerificationUpdateResponse
 */
export interface TicketControlVerificationUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationUpdateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationUpdateResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationUpdateResponse
     */
    'verificationResult'?: TicketControlVerificationUpdateResponseVerificationResultEnum;
    /**
     * 
     * @type {TicketControlActivityUpdateResponse}
     * @memberof TicketControlVerificationUpdateResponse
     */
    'controlActivity'?: TicketControlActivityUpdateResponse;
    /**
     * 
     * @type {TicketUpdateResponse}
     * @memberof TicketControlVerificationUpdateResponse
     */
    'ticket'?: TicketUpdateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlVerificationUpdateResponseVerificationResultEnum {
    SUCCESS = 'success',
    DUPLICATE = 'duplicate',
    FAILED = 'failed'
}


