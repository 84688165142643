/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EventDto } from './event-dto';

/**
 * 
 * @export
 * @interface PromoCodeDto
 */
export interface PromoCodeDto {
    /**
     * 
     * @type {number}
     * @memberof PromoCodeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeDto
     */
    'type'?: PromoCodeDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeDto
     */
    'value'?: number;
    /**
     * 
     * @type {EventDto}
     * @memberof PromoCodeDto
     */
    'event'?: EventDto;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeDto
     */
    'validThru'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeDto
     */
    'quantity'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoCodeDto
     */
    'minPurchase'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PromoCodeDtoTypeEnum {
    PERCENT = 'percent',
    AMOUNT = 'amount'
}


