import { useMemo } from 'react'
import { BarsOutlined, DownOutlined } from '@ant-design/icons'
import { Dropdown, Button, MenuProps } from 'antd'

const DropOption = ({
  onMenuClick,
  menuOptions = [],
  buttonStyle,
  dropdownProps,
}: DropOptionProps) => {
  const items = useMemo(
    () => menuOptions.map((item) => ({ label: item.name, key: item.key })),
    [menuOptions],
  )
  return (
    <Dropdown menu={{ onClick: onMenuClick, items }} {...dropdownProps}>
      <Button style={{ border: 'none', ...buttonStyle }}>
        <BarsOutlined style={{ marginRight: 2 }} />
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export type MenuOption = {
  key: string
  name: string
}

type DropOptionProps = {
  onMenuClick: MenuProps['onClick']
  menuOptions: MenuOption[]
  buttonStyle?: Object
  dropdownProps?: Object
}

export default DropOption
