import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { CountryAsyncSelect } from '@components/AsyncSelect/CountryAsyncSelect'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { MLFormProps } from '../types'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { prepareOption } from '@components/AsyncSelect/types'
import MultiLanguageInput from '@components/MultiLanguageInput'
import MultiLanguageTextEditor from '@components/MultiLanguageTextEditor'
import { useTranslation } from 'react-i18next'
import { TimezoneResponse } from '@/openapi'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'

const CityForm = ({ onSuccess, id, langFields }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { appLang } = useLanguageContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/cities/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            countryId: prepareOption(data.country!, appLang),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    if (id) {
      await axiosInstance.put(`admin/cities/${id}`, {
        ...values,
        countryId: values.countryId.value || values.countryId,
      })
    } else {
      await axiosInstance.post('admin/cities', values)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <MultiLanguageInput
        itemProps={{
          name: 'name',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`name`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <CountryAsyncSelect
        name="countryId"
        rules={[{ required: true }]}
        withAll={false}
        width="100%"
      />
      <GenericAsyncSelect<TimezoneResponse>
        name="timezone"
        path="admin/timezones"
        label="Timezone"
        withAll={false}
        searchField="name"
        width="100%"
        required
        customPrepareOption={({ id, offSet }) => ({
          value: id!,
          label: `${id} (${offSet})`,
        })}
      />
      <MultiLanguageTextEditor
        form={form}
        itemProps={{
          name: 'description',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{
          label: t`labels.Description`,
          required: true,
        }}
        languageFields={langFields}
      />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CityForm
