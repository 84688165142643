import React, { ChangeEventHandler, useState } from 'react'
import { Input } from 'antd'
import cn from 'classnames'
import styles from './index.module.less'
import { TextAreaProps } from 'antd/es/input'

const { TextArea } = Input

export type FloatTextAreaProps = {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  label: string
  addonAfter?: React.ReactNode
  value?: string
} & Partial<TextAreaProps>

const FloatTextarea = (props: FloatTextAreaProps) => {
  const [focus, setFocus] = useState(false)
  let {
    label,
    value,
    placeholder,
    required,
    rows = 4,
    addonAfter,
    ...rest
  } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value && (value as string).length !== 0)

  const labelClass = cn(
    styles.label,
    isOccupied ? styles.asLabel : styles.asPlaceholder,
  )

  const requiredMark = required ? (
    <span className="text-danger">{'*'}</span>
  ) : null

  return (
    <div
      className={styles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <TextArea
        onChange={props.onChange}
        style={{ width: '100%' }}
        defaultValue={value}
        value={value}
        rows={rows}
        {...rest}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
      {!!addonAfter && <div className={styles.addon}>{addonAfter}</div>}
    </div>
  )
}

export default FloatTextarea
