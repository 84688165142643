import { useCallback, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import Page from 'components/Page'
import {
  EventGroupDto,
  PageEventGroupDto,
  PictureTypeUserDtoEntityTypeEnum,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@/components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '../../components/FloatInput'
import EntityForm from './entityForm'

import { Params } from '@/types/Params'
import { SelectOption } from '@/types/Option'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { useAdminColumns } from '@/hooks/adminColumns'
import { AdminButton } from '@components/Auth/AdminComponents'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MediaModalExtended } from '@components/MediaModalExtended'
import { LinkOutlined } from '@ant-design/icons'
import { DragTable } from '@components/DragTable'

export type CustomFormData = EventGroupDto & {
  eventIds: SelectOption[]
  agencyId: SelectOption
}

const EventGroups = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<EventGroupDto>()
  const { languageFields } = useCatalogMultiLanguageFields('cities')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [mediaId, setMediaId] = useState<number | undefined>()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<EventGroupDto>
    },
    filters: { [key in keyof EventGroupDto]: string } & { agencyName: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (filters.agencyName) {
      query.push(`agency.name~~${filters.agencyName}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageEventGroupDto>('admin/event-groups', {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/event-groups/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: EventGroupDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    } else if (e.key === '3') {
      navigate(`/app/eventGroup/${record.id}/manage`)
    } else if (e.key === 'media') {
      setMediaId(record.id!)
    }
  }, [])

  const columns = useAdminColumns<EventGroupDto>([
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      shortTitle: t`name`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Agency`}</div>
          <Form.Item name="agencyName">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'agency',
      key: 'agency',
      shortTitle: t`labels.Agency`,
      render: (t) => t?.name,
    },
    {
      title: t`labels.Number of events`,
      dataIndex: 'eventsCount',
      key: 'eventsCount',
    },
    {
      title: t`link`,
      dataIndex: 'link',
      key: 'link',
      noTooltip: true,
      width: '100px',
      render: (_, { id }) => (
        <Button
          type="primary"
          icon={<LinkOutlined />}
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_USERS_FE_URL}/event-group/${id}`,
              '_blank',
            )
          }
        />
      ),
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
              { key: 'media', name: t`media` },
              { key: '3', name: t`manage` },
            ]}
          />
        )
      },
    },
  ])

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<EventGroupDto>
          bordered
          actions={
            <AdminButton
              onClick={() => setIsOpenModal(true)}
            >{t`add`}</AdminButton>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={
          activeItem ? `${t`update`} ${activeItem.name}` : 'Add Events Group'
        }
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <EntityForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
      {mediaId && (
        <MediaModalExtended
          entity={PictureTypeUserDtoEntityTypeEnum.EVENT_GROUPS}
          id={mediaId}
          close={() => setMediaId(undefined)}
        />
      )}
    </Page>
  )
}

export default EventGroups
