/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ResourceOperationDto
 */
export interface ResourceOperationDto {
    /**
     * 
     * @type {string}
     * @memberof ResourceOperationDto
     */
    'resource'?: ResourceOperationDtoResourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceOperationDto
     */
    'operation'?: ResourceOperationDtoOperationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ResourceOperationDtoResourceEnum {
    ACTOR = 'actor',
    ACTOR_SOCIAL_MEDIA = 'actor_social_media',
    EVENT = 'event',
    EVENT_GROUP = 'event_group',
    EVENT_MEDIA_FILE = 'event_media_file',
    EVENT_PRICE = 'event_price',
    EVENT_SALE_QUOTA = 'event_sale_quota',
    EVENT_SEAT_PRICE = 'event_seat_price',
    EVENT_SEAT = 'event_seat',
    EVENT_STAGE_DEFINITION = 'event_stage_definition',
    GIFT_TICKET = 'gift_ticket',
    MARKETING_LINK = 'marketing_link',
    NOTIFICATION = 'notification',
    PROMO_CODE = 'promo_code',
    PURCHASE_ORDER = 'purchase_order',
    SOCIAL_MEDIA = 'social_media',
    TICKET_CONTROL_ACTIVITY = 'ticket_control_activity',
    TICKET_CONTROL_PERSON = 'ticket_control_person',
    TICKET_REFUND = 'ticket_refund',
    TICKET = 'ticket',
    TICKET_SALE_QUOTA = 'ticket_sale_quota',
    SYSTEM = 'system',
    CHANNEL_REPORT = 'channel_report',
    DAILY_SALES_REPORT = 'daily_sales_report',
    TICKET_SALES_REPORT = 'ticket_sales_report',
    EVENT_PRICE_DENOMINATION_REPORT = 'event_price_denomination_report',
    EVENT_SALES_REPORT = 'event_sales_report',
    MARKETING_SALES_REPORT = 'marketing_sales_report',
    TOUR = 'tour',
    TOUR_MANAGEMENT = 'tour_management',
    MAILING_LIST = 'mailing_list',
    PURCHASE_ORDER_REFUND = 'purchase_order_refund',
    DICTIONARY = 'dictionary',
    STAGE = 'stage'
}
/**
    * @export
    * @enum {string}
    */
export enum ResourceOperationDtoOperationEnum {
    CREATE = 'create',
    BULK_CREATE = 'bulk_create',
    DELETE = 'delete',
    UPDATE = 'update',
    PUBLISH = 'publish',
    READ = 'read',
    READ_LIST = 'read_list',
    ANNOUNCE = 'announce',
    PREVIEW = 'preview',
    CANCEL = 'cancel',
    UNPUBLISH = 'unpublish',
    START_MAILING = 'start_mailing',
    REPROCESS_PURCHASE_ORDER = 'reprocess_purchase_order',
    PROMO_CODE_ACTUALS = 'promo_code_actuals',
    REPROCESS_SMS_PRICE = 'reprocess_sms_price',
    EVENT_REGISTRATION = 'event_registration',
    SEARCH_TICKET_CONTROL_PERSON = 'search_ticket_control_person',
    TICKET_CONTROL_REGISTER = 'ticket_control_register',
    AUTH_TICKET_CONTROL_PERSON = 'auth_ticket_control_person',
    APPLY_TICKET_CONTROL_EVENT = 'apply_ticket_control_event',
    TICKET_CONTROL_VERIFICATION = 'ticket_control_verification',
    READ_EVENT_CONTROL_DATA = 'read_event_control_data',
    READ_BOOKED_TICKETS = 'read_booked_tickets',
    REFUND = 'refund',
    DETECT_TICKETS = 'detect_tickets',
    TRANSFER_TICKETS = 'transfer_tickets',
    REFUND_PURCHASE_ORDER = 'refund_purchase_order',
    MARK_QUOTA_AS_SOLD = 'mark_quota_as_sold',
    READ_TOUR_EVENTS = 'read_tour_events',
    USER_MAILING_LIST = 'user_mailing_list',
    EXPORT = 'export',
    DOWNLOAD = 'download',
    TRANSLATE = 'translate',
    UPDATE_FILTER_TYPE = 'update_filter_type',
    PAYMENT_LINK = 'payment_link'
}


