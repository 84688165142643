import { Button, Divider, Form } from 'antd'
import { axiosInstance } from 'api'
import FormItem from '@components/FormItem'
import {
  ActorSocialMediaCreateRequest,
  ActorSocialMediaDto,
  SocialMediaDto,
} from '@/openapi'
import FloatInput from '@components/FloatInput'
import { useTranslation } from 'react-i18next'

type FormData = {
  link?: string
}

type Props = {
  socialMedia?: SocialMediaDto
  onSuccess: () => void
  actorId: number
  item?: ActorSocialMediaDto
}

const ManageSocialMediaForm = ({
  onSuccess,
  socialMedia,
  actorId,
  item,
}: Props) => {
  const [form] = Form.useForm<FormData>()
  const { t } = useTranslation()

  const onFinish = async (values: FormData) => {
    if (item?.id) {
      await axiosInstance.put(`admin/actor-social-media/${item.id}`, values)
    } else {
      await axiosInstance.post<ActorSocialMediaCreateRequest>(
        'admin/actor-social-media',
        {
          ...values,
          actorId,
          socialMediaId: socialMedia?.id,
        },
      )
    }
    onSuccess()
  }

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={item}
      style={{ marginTop: '20px' }}
    >
      <FormItem name="link" rules={[{ required: true }]}>
        <FloatInput label={t`link`} required />
      </FormItem>
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ManageSocialMediaForm
