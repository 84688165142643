/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { UserDto } from './user-dto';

/**
 * 
 * @export
 * @interface UserSubscriptionDto
 */
export interface UserSubscriptionDto {
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionDto
     */
    'id'?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof UserSubscriptionDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionDto
     */
    'subscriptionEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDto
     */
    'type'?: UserSubscriptionDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionDto
     */
    'byEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionDto
     */
    'bySms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSubscriptionDtoTypeEnum {
    EVENT = 'event',
    ACTOR = 'actor',
    STAGE = 'stage',
    CITY = 'city',
    ACTOR_TYPE = 'actor_type',
    EVENT_TYPE = 'event_type'
}


