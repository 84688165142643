/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorUpdateResponse } from './actor-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyUpdateResponse } from './agency-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeUpdateResponse } from './event-type-update-response';

/**
 * 
 * @export
 * @interface TourUpdateResponse
 */
export interface TourUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof TourUpdateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'nameBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'descriptionBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TourUpdateResponse
     */
    'languages'?: Array<TourUpdateResponseLanguagesEnum>;
    /**
     * 
     * @type {string}
     * @memberof TourUpdateResponse
     */
    'status'?: TourUpdateResponseStatusEnum;
    /**
     * 
     * @type {AgencyUpdateResponse}
     * @memberof TourUpdateResponse
     */
    'agency'?: AgencyUpdateResponse;
    /**
     * 
     * @type {Array<ActorUpdateResponse>}
     * @memberof TourUpdateResponse
     */
    'actors'?: Array<ActorUpdateResponse>;
    /**
     * 
     * @type {EventTypeUpdateResponse}
     * @memberof TourUpdateResponse
     */
    'eventType'?: EventTypeUpdateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum TourUpdateResponseLanguagesEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
    * @export
    * @enum {string}
    */
export enum TourUpdateResponseStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}


