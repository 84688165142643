import { useCallback, useState } from 'react'
import { Modal, Form, Select } from 'antd'
import Page from 'components/Page'
import {
  PictureTypeDto,
  PagePictureTypeDto,
  PictureTypeDtoEntityTypeEnum,
  PictureTypeDtoDeviceTypeEnum,
  PictureTypeDtoUsageTypeEnum,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '../../components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '../../components/FloatInput'
import PictureTypesForm from 'pages/pictureTypes/pictureTypesForm'

import { Params } from '@/types/Params'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { useAdminColumns } from '@/hooks/adminColumns'
import { AdminButton } from '@components/Auth/AdminComponents'
import FormItem from '@/components/FormItem'
import { SelectOption } from '@/types/Option'
import { useTranslation } from 'react-i18next'
import { DragTable } from '@components/DragTable'
const { Option } = Select

export type CustomFormData = PictureTypeDto & {
  usageType?: SelectOption
  deviceType?: SelectOption
  entityType?: SelectOption
}

export const usageTypesOptions = [
  { value: PictureTypeDtoUsageTypeEnum.MAIN, label: 'Main' },
  { value: PictureTypeDtoUsageTypeEnum.BACKGROUND, label: 'Background' },
  { value: PictureTypeDtoUsageTypeEnum.OTHER, label: 'Other' },
  { value: PictureTypeDtoUsageTypeEnum.THUMB, label: 'Thumb' },
  { value: PictureTypeDtoUsageTypeEnum.TICKETS, label: 'Tickets' },
  {
    value: PictureTypeDtoUsageTypeEnum.SLIDER_PREVIEW,
    label: 'Slider preview',
  },
]

export const entityTypesOptions = [
  { value: PictureTypeDtoEntityTypeEnum.EVENT, label: 'Event' },
  { value: PictureTypeDtoEntityTypeEnum.ACTOR, label: 'Actor' },
  { value: PictureTypeDtoEntityTypeEnum.ACTOR_TYPE, label: 'Actor Type' },
  { value: PictureTypeDtoEntityTypeEnum.CITY, label: 'City' },
  { value: PictureTypeDtoEntityTypeEnum.COUNTRY, label: 'Country' },
  { value: PictureTypeDtoEntityTypeEnum.STAGE, label: 'Stage' },
  { value: PictureTypeDtoEntityTypeEnum.TOUR, label: 'Tour' },
  { value: PictureTypeDtoEntityTypeEnum.SOCIAL_MEDIA, label: 'Social Media' },
  { value: PictureTypeDtoEntityTypeEnum.AGENCY, label: 'Agency' },
  { value: PictureTypeDtoEntityTypeEnum.EVENT_GROUPS, label: 'Event groups' },
]

export const deviceTypesOptions = [
  { value: PictureTypeDtoDeviceTypeEnum.DESKTOP, label: 'Desktop' },
  { value: PictureTypeDtoDeviceTypeEnum.MOBILE, label: 'Mobile' },
]

const PictureTypes = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<PictureTypeDto>()
  const { languageFields } = useCatalogMultiLanguageFields('picture-types')
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<PictureTypeDto>
    },
    filters: { [key in keyof PictureTypeDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.usageType && filters.usageType !== 'All') {
      query.push(`usageType==${filters.usageType}`)
    }
    if (filters.deviceType && filters.deviceType !== 'All') {
      query.push(`deviceType==${filters.deviceType}`)
    }
    if (filters.entityType && filters.entityType !== 'All') {
      query.push(`entityType==${filters.entityType}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PagePictureTypeDto>('admin/picture-types', {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/picture-types/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: PictureTypeDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns = useAdminColumns<PictureTypeDto>([
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <FormItem name="name">
            <FloatInput label="" onChange={submit} />
          </FormItem>
        </div>
      ),
      shortTitle: t`name`,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Usage Type`}</div>
          <FormItem name="usageType">
            <Select
              onClick={(e) => e.stopPropagation()}
              onChange={submit}
              defaultValue="All"
            >
              <Option value="All" label={t`all`} key="all">
                {t`all`}
              </Option>
              {usageTypesOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
      ),
      dataIndex: 'usageType',
      key: 'usageType',
      shortTitle: t`labels.Usage Type`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Device Type`}</div>
          <FormItem name="deviceType">
            <Select
              onClick={(e) => e.stopPropagation()}
              onChange={submit}
              defaultValue="All"
            >
              <Option value="All" label={t`all`} key="all">
                {t`all`}
              </Option>
              {deviceTypesOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
      ),
      shortTitle: t`labels.Device Type`,
      dataIndex: 'deviceType',
      key: 'deviceType',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Entity Type`}</div>
          <FormItem name="entityType">
            <Select
              onClick={(e) => e.stopPropagation()}
              onChange={submit}
              defaultValue="All"
            >
              <Option value="All" label={t`all`} key="all">
                {t`all`}
              </Option>
              {entityTypesOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
      ),
      dataIndex: 'entityType',
      key: 'entityType',
      shortTitle: t`labels.Entity Type`,
      render: (type) =>
        entityTypesOptions.find(({ value }) => type === value)?.label || type,
    },
    {
      title: t`labels.Max Size`,
      dataIndex: 'maxSize',
      key: 'maxSize',
      sorter: true,
    },
    {
      title: t`labels.Width_px`,
      dataIndex: 'pixWidth',
      key: 'pixWidth',
      sorter: true,
    },
    {
      title: t`labels.Height_px`,
      dataIndex: 'pixHeight',
      key: 'pixHeight',
      sorter: true,
    },
    {
      title: t`labels.Ratio width`,
      dataIndex: 'ratioWidth',
      key: 'ratioWidth',
      sorter: true,
    },
    {
      title: t`labels.Ratio height`,
      dataIndex: 'ratioHeight',
      key: 'ratioHeight',
      sorter: true,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ])

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<PictureTypeDto>
          bordered
          actions={
            <AdminButton onClick={() => setIsOpenModal(true)}>
              {t`add`}
            </AdminButton>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={
          activeItem
            ? `${t`update`} ${activeItem.name}`
            : t`labels.Add picture type`
        }
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
      >
        <PictureTypesForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
    </Page>
  )
}

export default PictureTypes
