import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { MLFormProps } from '../types'
import { useTranslation } from 'react-i18next'
import FloatInput from '@components/FloatInput'
import { EventDto, EventDtoEventStatusEnum } from '@/openapi'
import {
  FILTER_OPERATORS,
  GenericAsyncSelect,
} from '@components/AsyncSelect/GenericAsyncSelect'
import { renderEventLabel } from '@pages/transferEvent'
import { AgencyAsyncSelect } from '@components/AsyncSelect/AgencyAsyncSelect'
import { prepareOption } from '@components/AsyncSelect/types'
import { useLanguageContext } from '@/contexts/LanguageProvider'

const EntityForm = ({ onSuccess, id }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const { appLang } = useLanguageContext()
  const events = Form.useWatch('eventIds', form)
  const agencyId = Form.useWatch('agencyId', form)

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/event-groups/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            eventIds: data.events!.map((event) =>
              prepareOption(event, appLang),
            ),
            agencyId: prepareOption(data.agency, appLang),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const onFinish = async (values: CustomFormData) => {
    const data = {
      ...values,
      eventIds: values.eventIds.map((item) => item.value || item),
    }
    if (id) {
      await axiosInstance.put(`admin/event-groups/${id}`, data)
    } else {
      await axiosInstance.post('admin/event-groups', data)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <Form.Item name="name" hasFeedback rules={[{ required: true }]}>
        <FloatInput label={t('labels.Group name')} required />
      </Form.Item>
      <AgencyAsyncSelect
        name="agencyId"
        rules={[{ required: true }]}
        withAll={false}
        required
        onChange={() => form.setFieldValue('eventIds', [])}
      />
      <GenericAsyncSelect<EventDto>
        path="admin/events"
        label={t`labels.Event name`}
        name="eventIds"
        withAll={false}
        width="100%"
        controlProps={{ mode: 'multiple', disabled: !agencyId }}
        customPrepareOption={(event) => ({
          value: String(event.id),
          label: renderEventLabel(event),
        })}
        rules={[{ required: true }]}
        required
        baseFilters={{
          eventStatus: {
            operator: FILTER_OPERATORS.IN,
            value: [
              EventDtoEventStatusEnum.PUBLISHED,
              EventDtoEventStatusEnum.ANNOUNCED,
              EventDtoEventStatusEnum.UNPUBLISHED,
            ].toString(),
          },
          'agency.id': {
            operator: FILTER_OPERATORS.EQUAL,
            value: agencyId as any,
          },
        }}
      />
      <h4>
        {t`labels.Selected events`} {':'} {events?.length || 0}
      </h4>
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default EntityForm
