import { useCallback, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import Page from 'components/Page'
import { CurrencyDto, PageCurrencyDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '../../components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '../../components/FloatInput'
import CurrencyForm from './currencyForm'

import { Params } from '@/types/Params'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = CurrencyDto

const Currency = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<CustomFormData>()
  const { languageFields } = useCatalogMultiLanguageFields('currencies')
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<CurrencyDto>
    },
    filters: { [key in keyof CurrencyDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (filters.code) {
      query.push(`code~~${filters.code}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageCurrencyDto>('admin/currencies', { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/currencies/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: CurrencyDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns: ExtendedColumns<CurrencyDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Code`}</div>
          <Form.Item name="code">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      shortTitle: t`labels.Code`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      shortTitle: t`name`,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ]

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<CurrencyDto>
          bordered
          actions={
            <Button
              className={commonStyles.addButton}
              onClick={() => setIsOpenModal(true)}
            >
              {t`add`}
            </Button>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={activeItem ? `${t`update`} ${activeItem.name}` : 'Add currency'}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
      >
        <CurrencyForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
    </Page>
  )
}

export default Currency
