import { PropsWithChildren } from 'react'
import classnames from 'classnames'
import Loader from '../Loader'
import styles from './Page.module.less'

type PageProps = PropsWithChildren<{
  className?: string
  title?: string | null
  loading?: boolean
  inner?: boolean
}>

const Page = ({
  className,
  children,
  loading = false,
  inner = false,
  title,
}: PageProps) => {
  const loadingStyle = {
    height: 'calc(100vh - 184px)',
    overflow: 'hidden',
  }
  return (
    <div
      className={classnames(className, {
        [styles.contentInner]: inner,
      })}
      style={loading ? loadingStyle : undefined}
    >
      {!!title && <h2 style={{ marginBottom: '25px' }}>{title}</h2>}
      {loading ? <Loader spinning /> : ''}
      {children}
    </div>
  )
}

export default Page
