import { useCallback, useEffect, useState } from 'react'
import { EventStageDefinitionDto, PageEventStageDefinitionDto } from '@/openapi'
import { axiosInstance } from 'api'
import { Button, Card, Empty, Modal } from 'antd'
import StageDefinitionPreview from './Preview'
import { useEventContext } from '@/contexts/EventContext'
import { useTranslation } from 'react-i18next'
const { Meta } = Card
import styles from '../styles.module.less'
import cn from 'classnames'

type TProps = {
  eventId: number
  stageId?: string
  disabled: boolean
}

export const StageDefinition = ({ eventId, stageId, disabled }: TProps) => {
  const [currentStageDefinition, setCurrentStageDefinition] =
    useState<EventStageDefinitionDto>()
  const [stages, setStages] = useState<EventStageDefinitionDto[]>([])
  const eventContext = useEventContext()
  const { t } = useTranslation()
  useEffect(() => {
    axiosInstance
      .get<PageEventStageDefinitionDto>(
        `admin/event-stage-definitions?query=event.id==${eventId}`,
      )
      .then(({ data: { content } }) => {
        if (Array.isArray(content) && content[0]) {
          setCurrentStageDefinition(content[0])
        }
      })
  }, [eventId])

  useEffect(() => {
    if (!stageId) {
      setStages([])
      return
    }
    axiosInstance
      .get<PageEventStageDefinitionDto>(
        `admin/stage-definitions?query=stage.id==${stageId}`,
      )
      .then(({ data: { content } }) => {
        setStages(content || [])
      })
  }, [stageId])

  const deleteDefinition = useCallback(() => {
    Modal.confirm({
      title: t`messages.tryDeleteSD`,
      content: (
        <StageDefinitionPreview
          definition={currentStageDefinition?.definition}
        />
      ),
      onOk: async () => {
        await axiosInstance.delete<EventStageDefinitionDto>(
          'admin/event-stage-definitions/' + currentStageDefinition!.id,
        )
        eventContext.setIsStageDefinitionSelected(false)
        setCurrentStageDefinition(undefined)
      },
    })
  }, [currentStageDefinition, t])

  const saveSeats = async (definition: string, eventID: number) => {
    const parser = new DOMParser()
    const parsed = parser.parseFromString(definition, 'image/svg+xml')
    const docElement = parsed.documentElement
    const elements: HTMLCollection = docElement.getElementsByTagName('*')
    const list = []

    for (let i = 0; i < elements.length; i++) {
      const element = elements.item(i) as SVGElement

      if (element) {
        let seatId = element.getAttribute('id')
        let capacity = parseInt(element.getAttribute('data-capacity') ?? '1')
        let rowNum = element.getAttribute('rownum')
        let place = element.getAttribute('data-place')
        let definition = element.getAttribute('data-definition')

        if (element.dataset.elementType === 'seat') {
          if (!definition) definition = '{}'
          if (!capacity) capacity = 1
          definition = decodeURIComponent(definition)
          const seatData = {
            seatId,
            capacity,
            rowNum,
            place,
            definition,
            eventId: String(eventID),
          }
          list.push(seatData)
        }
      }
    }

    try {
      await axiosInstance.post('admin/event-seats/bulk', list)
    } catch (e) {
      console.error('event-seats/bulk is failed')
    }
  }

  const setDefinition = useCallback(
    (code: string, definition: string) => {
      if (currentStageDefinition) {
        Modal.confirm({
          title: t`messages.tryAssignSD`,
          content: <StageDefinitionPreview definition={definition} />,
          onOk: async () => {
            const { data } = await axiosInstance.put<EventStageDefinitionDto>(
              'admin/event-stage-definitions/' + currentStageDefinition.id,
              {
                code,
                definition,
              },
            )
            await saveSeats(definition, eventId)
            eventContext.setIsStageDefinitionSelected(true)
            setCurrentStageDefinition(data)
          },
        })
      } else {
        Modal.confirm({
          title: t`messages.assignStageDefinition`,
          content: <StageDefinitionPreview definition={definition} />,
          onOk: async () => {
            const { data } = await axiosInstance.post<EventStageDefinitionDto>(
              'admin/event-stage-definitions',
              {
                code,
                definition,
                eventId,
              },
            )
            await saveSeats(definition, eventId)
            eventContext.setIsStageDefinitionSelected(true)
            setCurrentStageDefinition(data)
          },
        })
      }
    },
    [currentStageDefinition, t],
  )

  return (
    <div className={cn(styles.fullWidth, styles.formQuarter)}>
      {stageId && (
        <>
          {currentStageDefinition && (
            <>
              <h3
                style={{ marginTop: '20px' }}
                className={styles.fullWidth}
              >{t`labels.Active stage definitions`}</h3>
              <div className={styles.cardWrapper}>
                <div
                  className={cn(
                    styles.imageWrapper,
                    styles.imageWrapperWithBorder,
                  )}
                >
                  <StageDefinitionPreview
                    definition={currentStageDefinition.definition}
                  />
                </div>
                <div className={styles.cardText}>
                  {currentStageDefinition.code}
                </div>
                <Button
                  onClick={deleteDefinition}
                  disabled={disabled}
                  style={{ width: '90px', marginTop: '3px' }}
                >
                  {t`delete`}
                </Button>
              </div>
            </>
          )}
          {!disabled && !currentStageDefinition && (
            <>
              <h3
                className={styles.fullWidth}
                style={{ marginTop: '20px' }}
              >{t`labels.Available stage definitions`}</h3>
              {stages.map(({ definition, code, id }) => (
                <div className={styles.cardWrapper} key={id}>
                  <div className={styles.imageWrapper}>
                    <StageDefinitionPreview definition={definition} />
                  </div>
                  <div className={styles.cardText}>{code}</div>
                  <Button
                    onClick={() => setDefinition(code!, definition!)}
                    style={{ width: '90px', marginTop: '3px' }}
                  >
                    {t`select`}
                  </Button>
                </div>
              ))}
              {!stages.length && <Empty className={styles.fullWidth} />}
            </>
          )}
        </>
      )}
    </div>
  )
}
