import { EventSeatDto } from '@/openapi'
import { Tag } from 'antd'

export const SeatTag = ({ eventSeat }: { eventSeat: EventSeatDto }) => {
  const definition = JSON.parse(eventSeat.definition || '{}').name?.valueEN
  const row = eventSeat.rowNum
  const place = eventSeat.place
  return (
    <Tag color="#108ee9">
      {definition || ''} {row && 'row ' + row} {place && 'place ' + place}
    </Tag>
  )
}
