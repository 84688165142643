import { useState } from 'react'
import { InputNumber, InputNumberProps } from 'antd'
import cn from 'classnames'
import styles from './index.module.less'

export type FloatNumberInputProps = {
  onChange?: VoidFunction
  label: string
} & Partial<InputNumberProps<number>>

const FloatNumberInput = (props: FloatNumberInputProps) => {
  const [focus, setFocus] = useState(false)
  let { label, value, placeholder, type, required, ...rest } = props

  if (!placeholder) placeholder = label

  const isOccupied =
    focus || value || value === 0 || typeof rest.defaultValue === 'number'

  const labelClass = cn(
    styles.label,
    isOccupied ? styles.asLabel : styles.asPlaceholder,
  )

  const requiredMark = required ? (
    <span className="text-danger">{'*'}</span>
  ) : null

  return (
    <div
      className={styles.floatLabel}
      style={{ width: props.width }}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <InputNumber
        onChange={props.onChange}
        type={type}
        value={value ?? undefined}
        style={{ width: '100%' }}
        {...rest}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatNumberInput
