import { useCallback } from 'react'
import { Modal, Form, message, Space } from 'antd'
import Page from '@components/Page'
import { StageDefinitionDto, PageStageDefinitionDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@components/FloatInput'
import { CityAsyncSelect } from '@components/AsyncSelect/CityAsyncSelect'

import { Params } from '@/types/Params'
import { SelectOption } from '@/types/Option'
import commonStyles from '@pages/commonStyles.module.less'
import { useNavigate } from 'react-router-dom'
import StageDefinitionPreview from './components/StageDefinitionPreview'
import { useAdminColumns } from '@/hooks/adminColumns'
import { AdminButton } from '@components/Auth/AdminComponents'
import { useTranslation } from 'react-i18next'
import { DragTable } from '@components/DragTable'

export type CustomFormData = StageDefinitionDto & { cityId: SelectOption }

const StageDefinitionsList = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<StageDefinitionDto>
    },
    filters: { [key in keyof StageDefinitionDto | string]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.city && filters.city !== 'All') {
      query.push(`stage.city.id==${filters.city}`)
    }
    if (filters.code) {
      query.push(`code~~${filters.code}`)
    }
    if (filters.address) {
      query.push(`stage.address~~${filters.address}`)
    }
    if (sorter?.columnKey) {
      const sorterKey =
        sorter.columnKey === 'address' ? 'stage.address' : sorter.columnKey
      params.sort = `${sorterKey},${sorter.order === 'ascend' ? 'asc' : 'desc'}`
    }
    params.query = query.join(';')
    let response = await axiosInstance.get<PageStageDefinitionDto>(
      'admin/stage-definitions',
      { params },
    )
    if (response.data.content?.length === 0) {
      if (params.page > 0) {
        params.page = params.page - 1
        response = await axiosInstance.get<PageStageDefinitionDto>(
          'admin/stage-definitions',
          { params },
        )
      }
    }
    return {
      list: response.data.content || [],
      total: response.data.totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/stage-definitions/' + id)
      refresh()
    },
    [refresh],
  )

  const handleMenuClick = useCallback((record: StageDefinitionDto, e: any) => {
    if (e.key === '1') {
      navigate(record.id + '')
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!).catch((e) => {
            message.error('Item deletion failed!')
            console.log('error \n', e)
          })
        },
      })
    }
  }, [])

  const columns = useAdminColumns<StageDefinitionDto>([
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.City`}</div>
          <CityAsyncSelect fullWidth submit={submit} noLabel />
        </div>
      ),
      shortTitle: t`labels.City`,
      dataIndex: ['stage', 'city', 'name'],
      key: 'city',
    },
    {
      title: t`labels.Stage`,
      dataIndex: ['stage', 'name'],
      key: 'id',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Address`}</div>
          <Form.Item name="">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Address`,
      dataIndex: ['stage', 'address'],
      key: 'address',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Code`}</div>
          <Form.Item name="code">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Code`,
      dataIndex: 'code',
      key: 'code',
      sorter: true,
    },
    {
      title: t`labels.Preview`,
      dataIndex: 'definition',
      key: 'preview',
      sorter: false,
      render: (text, record) => {
        return <StageDefinitionPreview definition={record.definition} />
      },
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ])

  const createStageDefinition = () => {
    navigate('create')
  }

  return (
    <Page inner>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Form form={form}>
          <DragTable<StageDefinitionDto>
            bordered
            actions={
              <AdminButton
                className={commonStyles.addButton}
                onClick={createStageDefinition}
              >
                {t`add`}
              </AdminButton>
            }
            scroll={{ x: 700 }}
            columns={columns}
            rowKey={(record) => String(record.id)}
            className={commonStyles.table}
            {...tableProps}
          />
        </Form>
      </Space>
    </Page>
  )
}

export default StageDefinitionsList
