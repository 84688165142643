import { EventTourViewDto } from '@/openapi'
import styles from '@pages/tour/styles.module.less'
import { Link } from 'react-router-dom'
import { Progress, Tooltip } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { TooltipPlacement } from 'antd/es/tooltip'
import { useTranslation } from 'react-i18next'
import { EVENT_STEPS } from '@pages/event/types'

const generateIcon = (positive: boolean) =>
  positive ? (
    <CheckCircleOutlined style={{ color: 'green' }} />
  ) : (
    <CloseCircleOutlined style={{ color: 'red' }} />
  )

type Props = {
  event: EventTourViewDto
  size?: number
  placement?: TooltipPlacement
  strokeColor?: string
  trailColor?: string
}

export const AnnouncePercentTooltip = ({
  event,
  size = 50,
  placement = 'top',
  strokeColor,
  trailColor,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      zIndex={9999}
      placement={placement}
      title={
        <div className={styles.tooltipGrid}>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.BASIC}`}
            >{t`labels.Basic`}</Link>
          </div>
          <div>{generateIcon(!!event.announceReadiness?.readinessBasic)}</div>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.STAGE}`}
            >{t`labels.City`}</Link>
          </div>
          <div>{generateIcon(!!event.announceReadiness?.readinessCity)}</div>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.POSTERS}`}
            >{t`labels.Posters`}</Link>
          </div>
          <div>{generateIcon(!!event.announceReadiness?.readinessPosters)}</div>
        </div>
      }
    >
      <Progress
        type="circle"
        size={size}
        strokeColor={strokeColor}
        trailColor={trailColor}
        percent={event.announceReadiness?.readiness || 0}
      />
    </Tooltip>
  )
}

export const PublishPercentTooltip = ({
  event,
  size = 50,
  placement = 'top',
  strokeColor,
  trailColor,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      zIndex={9999}
      placement={placement}
      title={
        <div className={styles.tooltipGrid}>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.BASIC}`}
            >{t`labels.Basic`}</Link>
          </div>
          <div>{generateIcon(!!event.publishReadiness?.readinessBasic)}</div>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.STAGE}`}
            >{t`labels.City`}</Link>
          </div>
          <div>{generateIcon(!!event.publishReadiness?.readinessCity)}</div>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.POSTERS}`}
            >{t`labels.Posters`}</Link>
          </div>
          <div>{generateIcon(!!event.publishReadiness?.readinessPosters)}</div>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.SCHEDULE}`}
            >{t`labels.Schedule`}</Link>
          </div>
          <div>{generateIcon(!!event.publishReadiness?.readinessSchedule)}</div>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.STAGE}`}
            >{t`labels.Stage`}</Link>
          </div>
          <div>{generateIcon(!!event.publishReadiness?.readinessStage)}</div>
          <div>
            <Link
              to={`/app/event/${event?.id}?step=${EVENT_STEPS.TICKETS}`}
            >{t`labels.Ticket Prices`}</Link>
          </div>
          <div>
            {generateIcon(!!event.publishReadiness?.readinessTicketPrices)}
          </div>
        </div>
      }
    >
      <Progress
        type="circle"
        strokeColor={strokeColor}
        trailColor={trailColor}
        size={size}
        percent={event.publishReadiness?.readiness || 0}
      />
    </Tooltip>
  )
}
