import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import {
  EventDto,
  EventPriceDto,
  EventSeatPriceDto,
  PageEventPriceDto,
  PageEventStageDefinitionDto,
  StageDefinitionDto,
} from '@/openapi'
import { renderEventLabel } from '@pages/transferEvent/index'
import { useCallback, useState } from 'react'
import { axiosInstance } from '@/api'
import { Empty } from 'antd'
import { RightPartEventPrice } from '@pages/transferEvent/components/RightPartEventPrice'
import { RightPartSD } from '@pages/transferEvent/components/RightPartSD'

type Props = {
  onSelect: (eventSeatPrice?: EventSeatPriceDto) => void
  setToEvent: (eventSeatPrice: EventDto) => void
  uniqueKey: number
}

export const ToPart = ({ onSelect, setToEvent, uniqueKey }: Props) => {
  const [selectedEvent, setSelectedEvent] = useState('')
  const [stageDefinition, setStageDefinition] = useState<
    StageDefinitionDto | undefined
  >()
  const [eventPrices, setEventPrices] = useState<EventPriceDto[] | undefined>()

  const toSelect = useCallback(async (id: string) => {
    setSelectedEvent(id)
    const { data: event } = await axiosInstance<EventDto>(`admin/events/${id}`)
    setToEvent(event)
    setStageDefinition(undefined)
    setEventPrices(undefined)
    onSelect(undefined)
    const { data } = await axiosInstance.get<PageEventStageDefinitionDto>(
      `admin/event-stage-definitions?query=event.id==${id}`,
    )
    if (data.content?.length) {
      setStageDefinition(data.content[0])
    } else {
      const { data } = await axiosInstance.get<PageEventPriceDto>(
        `admin/event-prices?page=0&size=100&query=event.id==${id}`,
      )
      setEventPrices(data.content)
    }
  }, [])

  return (
    <div>
      <h2>{`To`}</h2>
      <GenericAsyncSelect<EventDto>
        path="admin/events"
        label=""
        withAll={false}
        width="100%"
        submit={toSelect}
        customPrepareOption={(event) => ({
          value: String(event.id),
          label: renderEventLabel(event),
        })}
      />
      {selectedEvent && !stageDefinition && !eventPrices?.length && (
        <Empty description="No available seats for selected event" />
      )}
      {!!eventPrices?.length && selectedEvent && (
        <RightPartEventPrice
          key={uniqueKey}
          onSelect={onSelect}
          eventId={selectedEvent}
        />
      )}
      {stageDefinition && (
        <RightPartSD
          eventId={selectedEvent}
          definition={stageDefinition}
          onSelect={onSelect}
          key={uniqueKey}
        />
      )}
    </div>
  )
}
