import styles from './index.module.less'
import { CSSProperties, FC } from 'react'
import cn from 'classnames'

type Props = {
  label?: string | null
  required?: boolean
  bold?: boolean
  subLabel?: string | null
  style?: CSSProperties
}
const FormLabel: FC<Props> = ({ label, required, subLabel, bold, style }) => {
  if (!label) {
    return null
  }

  return (
    <div style={style}>
      <div className={cn(styles.label, bold && styles.bold)}>
        {label} {required && <span className={styles.required}>{'*'}</span>}
      </div>
      {!!subLabel && <div className={styles.subLabel}>{subLabel}</div>}
    </div>
  )
}

export default FormLabel
