import { EventTypeDto } from 'openapi'
import AsyncSelect from '../index'
import { fetchPageableEntity } from '@/utils/fechPageableEntity'
import { Form, FormItemProps } from 'antd'
import { prepareOption } from '../types'
import { useTranslation } from 'react-i18next'

type AsyncSelectProps = {
  submit?: (v: string) => void
  withAll?: boolean
  width?: string
  disabled?: boolean
} & FormItemProps

export const EventTypeAsyncSelect = ({
  submit,
  withAll = true,
  name = 'actor',
  width = '200px',
  required,
  disabled = false,
  ...rest
}: AsyncSelectProps) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      name={name}
      initialValue={withAll ? 'All' : undefined}
      style={{ width, marginRight: 16 }}
      {...rest}
    >
      <AsyncSelect<EventTypeDto>
        label={t`labels.Event Type`}
        required={required}
        disabled={disabled}
        prepareOption={prepareOption}
        onChange={(v) => submit?.(v)}
        withAll={withAll}
        getData={fetchPageableEntity<EventTypeDto>('admin/event-types')}
      />
    </Form.Item>
  )
}
