import { StageDto } from 'openapi'
import AsyncSelect from '../index'
import { fetchPageableEntity } from 'utils/fechPageableEntity'
import { Form, FormItemProps } from 'antd'
import { prepareOption } from '../types'
import { SelectProps } from 'antd/es'
import { BaseFilers } from '@components/AsyncSelect/GenericAsyncSelect'
import { useTranslation } from 'react-i18next'
import { useIsRequired } from '@/hooks/useIsRequired'

type CountryAsyncSelectProps = {
  submit?: (v: string) => void
  withAll?: boolean
  baseFilters?: BaseFilers
  fullWidth?: boolean
  disabled?: boolean
  noLabel?: boolean
  controlProps?: Omit<SelectProps, 'onChange'>
} & FormItemProps

export const StageAsyncSelect = ({
  submit,
  withAll = true,
  name = 'stage',
  baseFilters,
  fullWidth = false,
  controlProps,
  disabled,
  noLabel,
  ...rest
}: CountryAsyncSelectProps) => {
  const { t } = useTranslation()
  const required = useIsRequired(rest.rules)

  return (
    <Form.Item
      name={name}
      initialValue={withAll ? 'All' : undefined}
      style={{ width: fullWidth ? '100%' : '200px', marginRight: 16 }}
      {...rest}
    >
      <AsyncSelect<StageDto>
        label={noLabel ? undefined : t`labels.Stage`}
        prepareOption={prepareOption}
        onChange={(v) => submit?.(v)}
        withAll={withAll}
        getData={fetchPageableEntity<StageDto>('admin/stages')}
        required={required}
        baseFilters={baseFilters}
        disabled={disabled}
        {...controlProps}
      />
    </Form.Item>
  )
}
