import { useState } from 'react'
import { DatePicker, DatePickerProps } from 'antd'
import cn from 'classnames'
import styles from './index.module.less'
import dayjs from 'dayjs'

type FloatDateTimeProps = {
  onChange?: (value?: string) => void
  label: string
  value?: string
  required?: boolean
  showTime?: boolean
  disabled?: boolean
} & Partial<Omit<DatePickerProps, 'value'>>

const FloatDateTime = (props: FloatDateTimeProps) => {
  const [focus, setFocus] = useState(false)
  let {
    label,
    value,
    placeholder,
    required,
    showTime = { format: 'HH:mm' },
    disabled,
  } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value && value.length !== 0)

  const labelClass = cn(
    styles.label,
    isOccupied ? styles.asLabel : styles.asPlaceholder,
  )

  const requiredMark = required ? (
    <span className="text-danger">{'*'}</span>
  ) : null

  return (
    <div
      className={styles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <DatePicker
        style={{ width: '100%' }}
        onChange={props.onChange}
        defaultValue={value ? dayjs(value) : undefined}
        showTime={showTime}
        placeholder=""
        disabled={disabled}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatDateTime
