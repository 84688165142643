/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof UserUpdateRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'role'?: UserUpdateRequestRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'userStatus'?: UserUpdateRequestUserStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateRequest
     */
    'countryId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserUpdateRequestRoleEnum {
    USER = 'user',
    GUEST = 'guest',
    ADMIN = 'admin',
    PARTNER = 'partner'
}
/**
    * @export
    * @enum {string}
    */
export enum UserUpdateRequestUserStatusEnum {
    CREATED = 'created',
    APPROVED = 'approved',
    BLOCKED = 'blocked'
}


