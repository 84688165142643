import { useCallback, useState } from 'react'
import FloatTextarea, { FloatTextAreaProps } from '../FloatTextarea'
import {
  EventCreateRequestLanguagesEnum,
  EventCreateResponseLanguagesEnum,
} from '@/openapi'
import LanguagesEnum = EventCreateRequestLanguagesEnum
import { generateKey, generateName } from 'components/MultiLanguageInputBase'
import FormItem, { CustomFormItemProps } from 'components/FormItem'
import { axiosInstance } from '@/api'
import { toast } from 'react-toastify'
import { FormInstance, Spin } from 'antd'
import { TranslationOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'

type MultiLanguageTextareaProps<T> = {
  itemProps: CustomFormItemProps
  inputProps: FloatTextAreaProps
  languages?: LanguagesEnum[]
  form: FormInstance<T>
  listName?: string
}

const MultiLanguageTextareaBase = <T,>({
  itemProps,
  inputProps,
  languages = [],
  form,
  listName,
}: MultiLanguageTextareaProps<T>) => {
  const name = itemProps.name!
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const handleTranslateClick = useCallback(
    async (
      lang: EventCreateResponseLanguagesEnum,
      fieldName: string | [number, string],
    ) => {
      if (!form) return
      let value: string | undefined
      let selector: string | Array<any> = fieldName
      if (Array.isArray(fieldName)) {
        selector = [listName, fieldName[0], fieldName[1]]
        value = form.getFieldValue(selector)
      } else {
        value = form.getFieldValue(selector)
      }
      if (!value) return
      setLoading(true)
      try {
        const { data } = await axiosInstance.post<
          Record<LanguagesEnum, string>
        >(`${lang}/from`, value)

        const updatedFields = Object.entries(data).map(([key, value]) => {
          let fullName = generateName(
            name,
            castType<EventCreateResponseLanguagesEnum>(key),
          )
          if (Array.isArray(fullName) && listName) {
            fullName.unshift(listName)
          }

          return { name: fullName, value, errors: [] }
        })
        form.setFields(updatedFields)
      } catch {
        toast.error(t`messages.cantTranslate`)
      }

      setLoading(false)
    },
    [form, name, listName],
  )

  return (
    <>
      {castType<EventCreateResponseLanguagesEnum[]>(languages).map((lang) => (
        <div key={generateKey(name, lang)} className={itemProps.className}>
          <Spin spinning={loading} delay={500}>
            <FormItem
              {...itemProps}
              name={generateName(name, lang)}
              key={generateKey(name, lang)}
            >
              <FloatTextarea
                {...inputProps}
                addonAfter={
                  form &&
                  languages.length > 1 && (
                    <TranslationOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleTranslateClick(
                          lang,
                          generateName(name, lang) as string,
                        )
                      }}
                    />
                  )
                }
                label={`${inputProps.label} (${lang})`}
              />
            </FormItem>
          </Spin>
        </div>
      ))}
    </>
  )
}

export default MultiLanguageTextareaBase
