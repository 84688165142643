import { useMemo } from 'react'
import { Form, FormInstance } from 'antd'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import FloatTextarea, { FloatTextAreaProps } from '../FloatTextarea'
import { FormItemProps } from 'antd/es'
import MultiLanguageTextareaBase from '@components/MultiLanguageTextareaBase'

type MultiLanguageTextareaProps<T> = {
  itemProps: FormItemProps
  inputProps: FloatTextAreaProps
  languageFields: string[]
  form: FormInstance<T>
}

const MultiLanguageTextarea = <T,>({
  itemProps,
  inputProps,
  languageFields,
  form,
}: MultiLanguageTextareaProps<T>) => {
  const { languages } = useLanguageContext()

  const name = itemProps.name as string
  const isMultiLanguage = useMemo(
    () => languageFields.includes(name),
    [name, languages],
  )

  if (!isMultiLanguage) {
    return (
      <Form.Item {...itemProps}>
        <FloatTextarea {...inputProps} />
      </Form.Item>
    )
  }

  return (
    <MultiLanguageTextareaBase
      itemProps={itemProps}
      inputProps={inputProps}
      languages={languages}
      form={form}
    />
  )
}

export default MultiLanguageTextarea
