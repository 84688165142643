import { Button, Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import { useEffect, useState } from 'react'
import EventTable from './EventTable'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const EventsList = ({ value }: { value: string }) => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(value)
  const { t } = useTranslation()
  useEffect(() => {
    setActiveTab(value)
  }, [value])

  const items = [
    {
      label: t`labels.Published`,
      key: 'published',
      children: <EventTable type="published" />,
    },
    {
      label: t`labels.Announced`,
      key: 'announced',
      children: <EventTable type="announced" full={false} />,
    },
    {
      label: t`labels.Unpublished`,
      key: 'unpublished',
      children: <EventTable type="unpublished" full={false} />,
    },
    {
      label: t`labels.Archived`,
      key: 'archived',
      children: <EventTable type="archived" />,
    },
    {
      label: t`labels.Canceled`,
      key: 'canceled',
      children: <EventTable type="canceled" />,
    },
  ]

  return (
    <>
      <PageHeader
        title={t`labels.Events List`}
        extra={
          <div>
            <Button key="add-new" onClick={() => navigate('/app/event/new')}>
              {t`labels.Create Event`}
            </Button>
          </div>
        }
      />
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        type="card"
        destroyInactiveTabPane={true}
        items={items}
        tabBarStyle={{ margin: 0 }}
      />
    </>
  )
}

export default EventsList
