import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Page from 'components/Page'
import styles from './index.module.less'
import { Button, Col, message, Row, Steps } from 'antd'

import { UploadImage } from './components/stepsContent/UploadImage'
import { SetDefinitions } from './components/stepsContent/SetDefinitions'
import StageSelect from './components/stepsContent/StageSelect'
import { axiosInstance } from 'api'
import { useTranslation } from 'react-i18next'

type StepItem = {
  title: string
  content: JSX.Element
}

export const StageDefinitions = () => {
  const [current, setCurrent] = useState(0)
  const [nextButtonIsDisabled, changeButtonState] = useState([true, true, true])
  const [stageDefinitionID, setStageDefinitionID] = useState(null)
  const [stageDefinitionCode, setStageDefinitionCode] = useState(null)
  const [stageID, setStageID] = useState(null)
  const navigate = useNavigate()
  const urlParams = useParams()
  const { t } = useTranslation()
  const changeState = () => {
    const newArr = [...nextButtonIsDisabled]
    newArr[current] = false
    return newArr
  }

  const stepsCollection: StepItem[] = [
    {
      title: t`labels.Stage select`,
      content: (
        <StageSelect
          changeButtonStateProp={() => changeButtonState(changeState())}
          setStageID={setStageID}
        />
      ),
    },
    {
      title: t`labels.Upload Image`,
      content: (
        <UploadImage
          changeButtonStateProp={() => changeButtonState(changeState())}
          setStageDefinitionID={setStageDefinitionID}
          setStageDefinitionCode={setStageDefinitionCode}
          stageID={stageID}
        />
      ),
    },
    {
      title: t`labels.Set Definitions`,
      content: (
        <SetDefinitions
          stageDefinitionID={stageDefinitionID}
          stageDefinitionCode={stageDefinitionCode}
        />
      ),
    },
  ]

  let steps: StepItem[] = []

  if (urlParams.id !== undefined) {
    steps.push(stepsCollection[2])
  } else {
    steps = [...stepsCollection]
  }

  const getStageDefinition = async (id: string) => {
    try {
      const { data } = await axiosInstance.get(`admin/stage-definitions/${id}`)
      setStageDefinitionID(data.id)
      setStageDefinitionCode(data.code)
    } catch (e) {
      message.error(t`messages.errorGetDefinitions`)
    }
  }

  if (urlParams.id !== undefined) {
    getStageDefinition(urlParams.id)
  }

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const toDefinitionsList = () => {
    navigate('/app/stage-definitions')
  }

  return (
    <Page inner>
      <div>
        {(stageDefinitionID || steps.length === 3) && (
          <div>
            <Steps current={current} items={steps} />
            <div className={styles.stepsContent}>{steps[current].content}</div>
          </div>
        )}
        <div className={styles.stepsAction}>
          <Row>
            <Col span={8}>
              {current > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  {t`labels.Previous`}
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => message.success('Saved successfully!')}
                >
                  {t`save`}
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  disabled={nextButtonIsDisabled[current]}
                  type="primary"
                  onClick={() => next()}
                >
                  {t`labels.Next`}
                </Button>
              )}
            </Col>
            <Col span={6} offset={10} style={{ textAlign: 'end' }}>
              <Button type="primary" onClick={() => toDefinitionsList()}>
                {t`labels.To Definitions List`}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Page>
  )
}
