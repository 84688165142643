/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CurrencyUserDto } from './currency-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventBillingSystemUserDto } from './event-billing-system-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventPriceUserDto } from './event-price-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventSeatUserDto } from './event-seat-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PromoCodeUserDto } from './promo-code-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PurchaseOrderUserDto } from './purchase-order-user-dto';

/**
 * 
 * @export
 * @interface TicketUserDto
 */
export interface TicketUserDto {
    /**
     * 
     * @type {number}
     * @memberof TicketUserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketUserDto
     */
    'ticketId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketUserDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketUserDto
     */
    'status'?: TicketUserDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketUserDto
     */
    'type'?: TicketUserDtoTypeEnum;
    /**
     * 
     * @type {EventSeatUserDto}
     * @memberof TicketUserDto
     */
    'eventSeat'?: EventSeatUserDto;
    /**
     * 
     * @type {EventPriceUserDto}
     * @memberof TicketUserDto
     */
    'eventPrice'?: EventPriceUserDto;
    /**
     * 
     * @type {number}
     * @memberof TicketUserDto
     */
    'vat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketUserDto
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {EventBillingSystemUserDto}
     * @memberof TicketUserDto
     */
    'eventAgencyBillingSystem'?: EventBillingSystemUserDto;
    /**
     * 
     * @type {CurrencyUserDto}
     * @memberof TicketUserDto
     */
    'currency'?: CurrencyUserDto;
    /**
     * 
     * @type {PromoCodeUserDto}
     * @memberof TicketUserDto
     */
    'promoCode'?: PromoCodeUserDto;
    /**
     * 
     * @type {PurchaseOrderUserDto}
     * @memberof TicketUserDto
     */
    'purchaseOrder'?: PurchaseOrderUserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketUserDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketUserDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketUserDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketUserDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketUserDtoStatusEnum {
    RESERVED = 'reserved',
    PURCHASED = 'purchased',
    RETURN_REQUESTED = 'return_requested',
    RETURNED = 'returned',
    EXPIRED = 'expired'
}
/**
    * @export
    * @enum {string}
    */
export enum TicketUserDtoTypeEnum {
    REGULAR = 'regular',
    GIFT = 'gift',
    EXTERNAL = 'external'
}


