/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CityUpdateResponseTimezoneRulesTransitionsInnerOffsetBefore } from './city-update-response-timezone-rules-transitions-inner-offset-before';
// May contain unused imports in some cases
// @ts-ignore
import { LocalTime } from './local-time';

/**
 * 
 * @export
 * @interface CityUpdateResponseTimezoneRulesTransitionRulesInner
 */
export interface CityUpdateResponseTimezoneRulesTransitionRulesInner {
    /**
     * 
     * @type {string}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'month'?: CityUpdateResponseTimezoneRulesTransitionRulesInnerMonthEnum;
    /**
     * 
     * @type {string}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'timeDefinition'?: CityUpdateResponseTimezoneRulesTransitionRulesInnerTimeDefinitionEnum;
    /**
     * 
     * @type {CityUpdateResponseTimezoneRulesTransitionsInnerOffsetBefore}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'standardOffset'?: CityUpdateResponseTimezoneRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {CityUpdateResponseTimezoneRulesTransitionsInnerOffsetBefore}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'offsetBefore'?: CityUpdateResponseTimezoneRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {CityUpdateResponseTimezoneRulesTransitionsInnerOffsetBefore}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'offsetAfter'?: CityUpdateResponseTimezoneRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {string}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'dayOfWeek'?: CityUpdateResponseTimezoneRulesTransitionRulesInnerDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'dayOfMonthIndicator'?: number;
    /**
     * 
     * @type {LocalTime}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'localTime'?: LocalTime;
    /**
     * 
     * @type {boolean}
     * @memberof CityUpdateResponseTimezoneRulesTransitionRulesInner
     */
    'midnightEndOfDay'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CityUpdateResponseTimezoneRulesTransitionRulesInnerMonthEnum {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum CityUpdateResponseTimezoneRulesTransitionRulesInnerTimeDefinitionEnum {
    UTC = 'UTC',
    WALL = 'WALL',
    STANDARD = 'STANDARD'
}
/**
    * @export
    * @enum {string}
    */
export enum CityUpdateResponseTimezoneRulesTransitionRulesInnerDayOfWeekEnum {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}


