/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EventCreateResponse } from './event-create-response';
// May contain unused imports in some cases
// @ts-ignore
import { TicketControlPersonCreateResponse } from './ticket-control-person-create-response';

/**
 * 
 * @export
 * @interface TicketControlActivityCreateResponse
 */
export interface TicketControlActivityCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TicketControlActivityCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityCreateResponse
     */
    'status'?: TicketControlActivityCreateResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityCreateResponse
     */
    'oneTimeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityCreateResponse
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlActivityCreateResponse
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {EventCreateResponse}
     * @memberof TicketControlActivityCreateResponse
     */
    'event'?: EventCreateResponse;
    /**
     * 
     * @type {TicketControlPersonCreateResponse}
     * @memberof TicketControlActivityCreateResponse
     */
    'controlPerson'?: TicketControlPersonCreateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlActivityCreateResponseStatusEnum {
    REQUESTED = 'requested',
    VERIFIED = 'verified',
    FAILED = 'failed',
    CANCELED = 'canceled'
}


