import React, { PropsWithChildren, useMemo } from 'react'
import { Form } from 'antd'
import { FormItemProps } from 'antd/es'

export type CustomFormItemProps = {
  disabled?: boolean
} & Partial<FormItemProps>

const FormItem = ({
  children,
  rules,
  disabled = false,
  ...rest
}: PropsWithChildren<CustomFormItemProps>) => {
  const combinedRules = useMemo(
    () => (disabled ? undefined : rules),
    [rules, disabled],
  )

  return (
    <Form.Item {...rest} rules={combinedRules}>
      {React.isValidElement(children) &&
        React.cloneElement(children as React.ReactElement, { disabled })}
    </Form.Item>
  )
}

export default FormItem
