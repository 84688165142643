import Page from 'components/Page'
import { PageUserSubscriptionDto, UserSubscriptionDto } from '@/openapi'
import { useParams } from 'react-router-dom'
import useAsyncGet from '@/hooks/useAsyncGet'
import { useTranslation } from 'react-i18next'
export type CustomFormData = UserSubscriptionDto

const UsersSubscriptionsPartner = () => {
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data } = useAsyncGet<PageUserSubscriptionDto>(
    `admin/user-subscriptions?subscriptionEntityId==${eventId}&type==event&size=1`,
  )

  return (
    <Page inner>
      <h3>
        {t`labels.The number of unique subscribed users`}{' '}
        {data?.totalElements || 0}
      </h3>
    </Page>
  )
}

export default UsersSubscriptionsPartner
