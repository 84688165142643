import React, { useContext, useState } from 'react'

export interface IEventContext {
  isStageDefinitionSelected: boolean
  setIsStageDefinitionSelected: (value: boolean) => void
  definitionString: string
  setDefinitionString: (value: string) => void
}

const EventContext = React.createContext<IEventContext | undefined>(undefined)

const EventProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isStageDefinitionSelected, setIsStageDefinitionSelected] =
    useState(false)
  const [definitionString, setDefinitionString] = useState('')

  let value = {
    isStageDefinitionSelected,
    setIsStageDefinitionSelected,
    definitionString,
    setDefinitionString,
  }

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>
}

export function useEventContext() {
  const context = useContext(EventContext)

  if (typeof context === 'undefined') {
    throw new Error('useEventContext must be used within an EventProvider')
  }

  return context
}

export default EventProvider
