/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PictureTypeUpdateResponse
 */
export interface PictureTypeUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof PictureTypeUpdateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PictureTypeUpdateResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PictureTypeUpdateResponse
     */
    'usageType'?: PictureTypeUpdateResponseUsageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PictureTypeUpdateResponse
     */
    'deviceType'?: PictureTypeUpdateResponseDeviceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PictureTypeUpdateResponse
     */
    'entityType'?: PictureTypeUpdateResponseEntityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PictureTypeUpdateResponse
     */
    'maxSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PictureTypeUpdateResponse
     */
    'pixWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof PictureTypeUpdateResponse
     */
    'pixHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PictureTypeUpdateResponse
     */
    'ratioWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof PictureTypeUpdateResponse
     */
    'ratioHeight'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PictureTypeUpdateResponseUsageTypeEnum {
    MAIN = 'main',
    TICKETS = 'tickets',
    BACKGROUND = 'background',
    SLIDER_PREVIEW = 'slider_preview',
    THUMB = 'thumb',
    OTHER = 'other'
}
/**
    * @export
    * @enum {string}
    */
export enum PictureTypeUpdateResponseDeviceTypeEnum {
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}
/**
    * @export
    * @enum {string}
    */
export enum PictureTypeUpdateResponseEntityTypeEnum {
    EVENT = 'event',
    ACTOR = 'actor',
    ACTOR_SOCIAL_MEDIA = 'actor_social_media',
    ACTOR_TYPE = 'actor_type',
    AGENCY = 'agency',
    CITY = 'city',
    COUNTRY = 'country',
    STAGE = 'stage',
    TOUR = 'tour',
    SOCIAL_MEDIA = 'social_media',
    EVENT_GROUPS = 'event_groups'
}


