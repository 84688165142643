import { useCallback } from 'react'
import { Form, Button, Modal, Space } from 'antd'
import Page from 'components/Page'
import { PageTicketDto, TicketDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import { DeleteOutlined, CloudDownloadOutlined } from '@ant-design/icons'

import commonStyles from '@/pages/commonStyles.module.less'
import { useParams, useSearchParams } from 'react-router-dom'
import { Params } from '@/types/Params'
import {
  renderRowPlace,
  renderUser,
  twoColumnsDateFormat,
} from '@pages/helpers'
import { downloadTicket } from '@/utils/downloadTicket'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = TicketDto

const GiftTickets = () => {
  const [form] = Form.useForm()
  const { id: eventId } = useParams<{ id: string }>()
  const [_, setSearch] = useSearchParams()
  const { t } = useTranslation()

  const getData = async ({
    current,
    pageSize,
    sorter,
  }: {
    current: number
    pageSize: number
    sorter: SorterResult<TicketDto>
  }) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [`eventPrice.event.id==${eventId}`]
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageTicketDto>(`admin/tickets/gift`, { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const { tableProps, refresh } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const handleDeleteTicket = useCallback((id: number) => {
    Modal.confirm({
      title: t`Are you sure delete this record?`,
      onOk: async () => {
        await axiosInstance.delete(`admin/tickets/gift/${id}`)
        refresh()
      },
    })
  }, [])

  const columns: ExtendedColumns<TicketDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: t`labels.Created Date`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      defaultSortOrder: 'descend',
      render: twoColumnsDateFormat,
    },
    {
      title: t`labels.Event Price`,
      dataIndex: 'eventPrice',
      key: 'eventPrice',
      sorter: true,
      render: (val, record) => record.eventPrice?.name,
    },
    {
      title: t`labels.Row Place`,
      dataIndex: 'seat',
      key: 'seat',
      sorter: true,
      render: (val, record) => renderRowPlace(record),
    },
    {
      title: t`labels.User`,
      dataIndex: 'user',
      key: 'user',
      sorter: true,
      render: (val, record) => renderUser(record),
    },
    {
      title: t`actions`,
      dataIndex: 'actions',
      key: 'actions',
      width: '60px',
      noTooltip: true,
      render: (_, { id, user }) => (
        <Space>
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteTicket(id!)}
          />
          <Button
            type="primary"
            icon={<CloudDownloadOutlined />}
            onClick={() => downloadTicket(id, user?.phone)}
          />
        </Space>
      ),
    },
  ]

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<TicketDto>
          bordered
          actions={
            <Button onClick={() => setSearch('tab=new-gift')}>
              {t`labels.New Gift Ticket`}
            </Button>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default GiftTickets
