import { useMemo } from 'react'
import { Button } from 'antd'
import dayjs from 'dayjs'
import { FormInstance } from 'antd/es/form/hooks/useForm'
import { useTranslation } from 'react-i18next'

export const useExtraDatePickerFooter = (
  form: FormInstance,
  submit?: () => void,
) => {
  const { t } = useTranslation()
  return useMemo(
    () => (
      <div>
        <div style={{ padding: '10px', display: 'flex', gap: '10px' }}>
          <Button
            size="small"
            onClick={() => {
              form.setFieldValue('dates', [
                dayjs().startOf('day'),
                dayjs().endOf('day'),
              ])
              submit?.()
            }}
          >
            {t`labels.Today`}
          </Button>
          <Button
            size="small"
            onClick={() => {
              form.setFieldValue('dates', [
                dayjs().subtract(7, 'days').startOf('day'),
                dayjs().endOf('day'),
              ])
              submit?.()
            }}
          >
            {t`labels.Last 7 days`}
          </Button>
          <Button
            size="small"
            onClick={() => {
              form.setFieldValue('dates', [
                dayjs().subtract(14, 'days').startOf('day'),
                dayjs().endOf('day'),
              ])
              submit?.()
            }}
          >
            {t`labels.Last 14 days`}
          </Button>
          <Button
            size="small"
            onClick={() => {
              form.setFieldValue('dates', [
                dayjs().subtract(1, 'month').startOf('day'),
                dayjs().endOf('day'),
              ])
              submit?.()
            }}
          >
            {t`labels.Last month`}
          </Button>
        </div>
        <div
          style={{
            padding: '10px',
            display: 'flex',
            gap: '10px',
            paddingTop: 0,
          }}
        >
          <Button
            size="small"
            onClick={() => {
              form.setFieldValue('dates', [
                dayjs().startOf('month'),
                dayjs().endOf('day'),
              ])
              submit?.()
            }}
          >
            {t`labels.From month start`}
          </Button>
        </div>
      </div>
    ),
    [form, submit, t],
  )
}
