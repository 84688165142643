import React, { SetStateAction } from 'react'
import { Form } from 'antd'
import { StageDto, PageStageDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@components/FloatInput'
import { CityAsyncSelect } from '@components/AsyncSelect/CityAsyncSelect'

import { Params } from '@/types/Params'
import { SelectOption } from '@/types/Option'
import commonStyles from '@pages/commonStyles.module.less'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = StageDto & { cityId: SelectOption }
type props = {
  changeButtonStateProp: () => void
  setStageID: SetStateAction<any>
}

const StageSelect = ({ changeButtonStateProp, setStageID }: props) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<StageDto>
    },
    filters: { [key in keyof StageDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.city && filters.city !== 'All') {
      query.push(`city.id==${filters.city}`)
    }
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (filters.address) {
      query.push(`address~~${filters.address}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageStageDto>('admin/stages', { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: StageDto[]) => {
      setStageID(selectedRows[0].id)
      changeButtonStateProp()
    },
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const columns: ExtendedColumns<StageDto>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
      fixed: 'left',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.City`}</div>
          <CityAsyncSelect fullWidth submit={submit} noLabel />
        </div>
      ),
      shortTitle: t`labels.City`,
      dataIndex: 'city',
      key: 'city',
      render: ({ name }) => name,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label={t`name`} onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'name',
      shortTitle: t`name`,
      key: 'name',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Address`}</div>
          <Form.Item name="address">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Address`,
      dataIndex: 'address',
      key: 'address',
      sorter: true,
    },
  ]

  return (
    <Form form={form}>
      <DragTable<StageDto>
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        bordered
        columns={columns}
        rowKey={(record) => String(record.id)}
        className={commonStyles.table}
        {...tableProps}
      />
    </Form>
  )
}

export default StageSelect
