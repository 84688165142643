/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { TicketControlActivityExtendedDto } from './ticket-control-activity-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { TicketExtendedDto } from './ticket-extended-dto';

/**
 * 
 * @export
 * @interface TicketControlVerificationExtendedDto
 */
export interface TicketControlVerificationExtendedDto {
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationExtendedDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationExtendedDto
     */
    'verificationResult'?: TicketControlVerificationExtendedDtoVerificationResultEnum;
    /**
     * 
     * @type {TicketControlActivityExtendedDto}
     * @memberof TicketControlVerificationExtendedDto
     */
    'controlActivity'?: TicketControlActivityExtendedDto;
    /**
     * 
     * @type {TicketExtendedDto}
     * @memberof TicketControlVerificationExtendedDto
     */
    'ticket'?: TicketExtendedDto;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationExtendedDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationExtendedDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationExtendedDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationExtendedDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlVerificationExtendedDtoVerificationResultEnum {
    SUCCESS = 'success',
    DUPLICATE = 'duplicate',
    FAILED = 'failed'
}


