import { Alert, Button, Form } from 'antd'
import { useCallback, useEffect, useRef } from 'react'
import { StepProps } from './types'
import styles from './styles.module.less'
import { axiosInstance } from 'api'
import { dateInFutureValidator, dateToString } from './helpers'
import { isNil, keys, pickBy } from 'lodash'
import { EventStep2UpdateRequest } from '@/openapi'
import { toast } from 'react-toastify'
import FormItem from '../../components/FormItem'
import dayjs from 'dayjs'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import FormDateTime from '@components/formElements/FormDateTime'

type KType = keyof EventStep2UpdateRequest

const StepSchedule = ({
  onSuccess,
  item,
  setForm,
  disabled,
  errors,
}: StepProps) => {
  const [form] = Form.useForm<EventStep2UpdateRequest>()
  const goForward = useRef(true)
  const { t } = useTranslation()

  useEffect(() => {
    setForm(form)
  }, [form])

  const saleEnd = Form.useWatch('saleEnd', form)

  const onFinish = async (values: EventStep2UpdateRequest) => {
    if (disabled) {
      onSuccess({}, !goForward.current)
      return
    }
    const obj: Partial<EventStep2UpdateRequest> = {}
    Object.entries(values).forEach(([key, value]) => {
      const castedKey = key as KType
      // @ts-ignore
      obj[castedKey] = dateToString(value)
    })
    await axiosInstance.put(`admin/events/step2/${item.id}`, obj)
    const toDelete = keys(pickBy(obj, isNil))
      .map((a) => 'properties=' + a)
      .join('&')
    if (toDelete.length) {
      await axiosInstance.delete(`admin/events/${item.id}/property?${toDelete}`)
    }
    toast.success(t`messages.eventChangesSaved`)
    onSuccess(obj, !goForward.current)
  }

  const handleBackClick = useCallback(() => {
    goForward.current = false
    form.submit()
  }, [form])

  return (
    <div style={{ marginTop: '15px' }}>
      {errors?.length && (
        <Alert
          className={cn(styles.fullWidth, styles.alert)}
          message={
            <>
              {errors.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </>
          }
          type="error"
          showIcon
        />
      )}
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={item}
        className={styles.scheduleForm}
      >
        <FormDateTime
          label={t`labels.Event date and time`}
          name="dateStart"
          disabled={disabled}
          formItemProps={{
            rules: [dateInFutureValidator()],
            hasFeedback: true,
          }}
        />
        <div />
        <FormDateTime
          label={t`labels.Sale start`}
          name="saleStart"
          disabled={disabled}
          formItemProps={{
            rules: [
              {
                validator(_, value) {
                  if (dayjs(value).isBefore(saleEnd) || !value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    t`validation.Should be before Sales end`,
                  )
                },
              },
              dateInFutureValidator(),
            ],
            hasFeedback: true,
          }}
        />
        <FormDateTime
          label={t`labels.Sale end`}
          name="saleEnd"
          disabled={disabled}
          formItemProps={{
            rules: [dateInFutureValidator()],
            hasFeedback: true,
          }}
        />
        <div className={styles.fullWidth} style={{ marginTop: '70px' }}>
          <div className={cn(styles.buttonBlock)}>
            <Button htmlType="button" onClick={() => form.resetFields()}>
              {t`cancel`}
            </Button>
            <div className={styles.buttonBlock}>
              <Button htmlType="button" onClick={handleBackClick}>
                {t`labels.Back`}
              </Button>
              <FormItem
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  justifyContent: 'right',
                  marginTop: 0,
                }}
              >
                <Button type="primary" htmlType="submit">
                  {t`labels.Next`}
                </Button>
              </FormItem>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default StepSchedule
