import { PropsWithChildren, useState } from 'react'
import { Select, SelectProps } from 'antd'
import cn from 'classnames'
import styles from './index.module.less'

export type FloatSelectProps = {
  onChange?: VoidFunction
  label: string
  required?: boolean
} & Partial<SelectProps> &
  PropsWithChildren

const FloatSelect = (props: FloatSelectProps) => {
  const [focus, setFocus] = useState(false)
  let {
    label,
    value,
    placeholder,
    required = false,
    children,
    labelInValue = true,
    ...rest
  } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value && (value as string).length !== 0)

  const labelClass = cn(
    styles.label,
    isOccupied ? styles.asLabel : styles.asPlaceholder,
  )

  const requiredMark = required ? (
    <span className="text-danger">{'*'}</span>
  ) : null

  return (
    <div
      className={styles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <Select defaultValue={value} labelInValue={labelInValue} {...rest}>
        {children}
      </Select>
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatSelect
