import { useCallback, useState } from 'react'
import { Modal, Form, Button, Select, Spin } from 'antd'
import Page from 'components/Page'
import {
  MailingListDto,
  PageMailingListDto,
  EventDto,
  MarketingLinkDto,
  MailingListDtoStatusEnum,
  MailingListCreateRequestTypeEnum,
} from '@/openapi'
import { PlayCircleOutlined, CheckOutlined } from '@ant-design/icons'

import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import MailingListForm, { FormMode } from './Form'

import { Params } from '@/types/Params'
import commonStyles from '@/pages/commonStyles.module.less'
import { useParams } from 'react-router-dom'
import DropOption from '@components/DropOption/DropOption'
const { Option } = Select
import {
  channelOptions,
  prepareItem,
} from '@pages/users/manage/notificationLogs'
import { twoColumnsDateFormat } from '@pages/helpers'
import { SelectOption } from '@/types/Option'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = MailingListDto & {
  userCountryId?: SelectOption[]
  eventCountryId?: SelectOption[]
  eventCityId?: SelectOption[]
  eventStageId?: SelectOption[]
  eventId?: SelectOption[]
  actorId?: SelectOption[]
  eventTypeId?: SelectOption[]
  actorTypeId?: SelectOption[]
  channel?: SelectOption
  addLink?: boolean
  link: MarketingLinkDto
  marketingLink?: MarketingLinkDto
  eventCustomersOnly?: boolean
  excludeCustomersByEventIds?: boolean | string[]
}

export const statusOptions = [
  { value: MailingListDtoStatusEnum.DRAFT, label: 'Draft' },
  { value: MailingListDtoStatusEnum.IN_PROGRESS, label: 'In Progress' },
  { value: MailingListDtoStatusEnum.FINISHED, label: 'Finished' },
]

const MailingList = ({}: { event?: EventDto }) => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<MailingListDto>()
  const [formMode, setFormMode] = useState<FormMode>()
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<MailingListDto>
    },
    filters: { [key in keyof CustomFormData]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [
      `entityId==${eventId}`,
      `type==${MailingListCreateRequestTypeEnum.EVENT}`,
    ]
    if (filters.channel) {
      query.push(`channel~~${filters.channel}`)
    }
    if (filters.status) {
      query.push(`status~~${filters.status}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageMailingListDto>(`admin/mailing_lists`, {
      params,
    })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete(`admin/mailing_lists/${id}`)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback(
    (record: MailingListDto, e: any) => {
      if (e.key === 'edit') {
        setActiveItem(record)
        setIsOpenModal(true)
        setFormMode(
          record.status === MailingListDtoStatusEnum.DRAFT ? 'edit' : 'view',
        )
      }
      if (e.key === 'send') {
        setActiveItem(record)
        setFormMode('send')
        setIsOpenModal(true)
      } else if (e.key === 'delete') {
        Modal.confirm({
          title: t`Are you sure delete this record?`,
          onOk: async () => {
            await deleteItem(record.id!)
            refresh()
          },
        })
      }
    },
    [eventId],
  )

  const columns: ExtendedColumns<MailingListDto>[] = [
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Channel`}</div>
          <Form.Item name="channel">
            <Select onClick={(e) => e.stopPropagation()} onChange={submit}>
              {channelOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Channel`,
      dataIndex: 'channel',
      key: 'channel',
      width: '120px',
      render: prepareItem,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Status`}</div>
          <Form.Item name="status">
            <Select onClick={(e) => e.stopPropagation()} onChange={submit}>
              {statusOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Status`,
      dataIndex: 'status',
      key: 'status',
      render: prepareItem,
      width: '160px',
    },
    {
      title: 'Progress',
      dataIndex: 'statusIcon',
      key: 'statusIcon',
      noTooltip: true,
      render: (_, record) => {
        let icon = null
        switch (record.status) {
          case MailingListDtoStatusEnum.DRAFT:
            icon = (
              <PlayCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#1677ff',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setActiveItem(record)
                  setFormMode('send')
                  setIsOpenModal(true)
                }}
              />
            )
            break
          case MailingListDtoStatusEnum.IN_PROGRESS:
            icon = <Spin />
            break

          case MailingListDtoStatusEnum.FINISHED:
            icon = (
              <CheckOutlined
                style={{
                  fontSize: '20px',
                  color: 'rgb(82, 196, 26)',
                }}
              />
            )
            break
        }

        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {icon}
          </div>
        )
      },
      width: '40px',
    },
    {
      title: t`labels.Created Date`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: twoColumnsDateFormat,
      sorter: true,
    },
    {
      title: t`labels.Updated Date`,
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      render: twoColumnsDateFormat,
      sorter: true,
    },
    {
      title: t`labels.Recipients Count`,
      dataIndex: 'recipientsCount',
      key: 'recipientsCount',
      sorter: true,
    },
    {
      title: t`labels.Sent items quantity`,
      dataIndex: 'sentItemsQuantity',
      key: 'sentItemsQuantity',
      sorter: true,
    },
    {
      title: t`labels.Succeeded items quantity`,
      dataIndex: 'succeededItemsQuantity',
      key: 'succeededItemsQuantity',
      sorter: true,
    },
    {
      title: t`labels.Failed items quantity`,
      dataIndex: 'failedItemsQuantity',
      key: 'failedItemsQuantity',
      sorter: true,
    },
    {
      title: t`labels.cost`,
      dataIndex: 'cost',
      key: 'cost',
      render: (_, t) =>
        t.cost ? `${t.cost.toFixed(2)} ${t.costCurrencyCode}` : '—',
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        if (record.status === MailingListDtoStatusEnum.DRAFT) {
          return (
            <DropOption
              onMenuClick={(e) => handleMenuClick(record, e)}
              menuOptions={[
                { key: 'edit', name: t`View/Edit` },
                { key: 'send', name: t`Send mailing` },
                { key: 'delete', name: t`delete` },
              ]}
            />
          )
        }
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[{ key: 'edit', name: t`view` }]}
          />
        )
      },
    },
  ]
  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<MailingListDto>
          bordered
          columns={columns}
          actionsMobile
          actions={
            <Button
              type="primary"
              onClick={() => {
                setFormMode('create')
                setIsOpenModal(true)
              }}
            >
              {t`labels.Add new mailing`}
            </Button>
          }
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="70%"
      >
        <MailingListForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          eventId={eventId}
          mode={formMode}
        />
      </Modal>
    </Page>
  )
}

export default MailingList
