/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AccessControlRoleDto } from './access-control-role-dto';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyDto } from './agency-dto';

/**
 * 
 * @export
 * @interface PartnerDetailsDto
 */
export interface PartnerDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof PartnerDetailsDto
     */
    'role'?: PartnerDetailsDtoRoleEnum;
    /**
     * 
     * @type {AgencyDto}
     * @memberof PartnerDetailsDto
     */
    'agency'?: AgencyDto;
    /**
     * 
     * @type {AccessControlRoleDto}
     * @memberof PartnerDetailsDto
     */
    'accessControlRole'?: AccessControlRoleDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerDetailsDtoRoleEnum {
    USER = 'user',
    GUEST = 'guest',
    ADMIN = 'admin',
    PARTNER = 'partner'
}


