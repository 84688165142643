import { axiosInstance } from '@/api'
import {
  EventPriceDto,
  EventSeatPriceDto,
  EventSeatUserDto,
  PageEventPriceDto,
  PageEventSeatPriceDto,
  StageDefinitionDto,
  TicketsBookedDto,
} from '@/openapi'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Card } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import styles from '@pages/event/styles.module.less'
import useAsyncGet from '@/hooks/useAsyncGet'
const COLORS = [
  '#220597',
  '#8032FF',
  '#E372FF',
  '#FFA53B',
  '#FFD645',
  '#748AFF',
  '#1EC9FF',
  '#56D1C2',
  '#00EADC',
]

export const BOOKED_COLOR = '#E91D41'

export const PURCHASED_COLOR = '#d2d2d2'
export type Props = {
  eventId: string
  definition: StageDefinitionDto
  onSelect: (seatPrice: EventSeatPriceDto) => void
}

export const RightPartSD = ({ definition, eventId, onSelect }: Props) => {
  const definitionWrapperRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<Element>()
  const selectionFrame = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const [seatPrices, setSeatPrices] = useState<EventSeatPriceDto[] | null>(null)
  const [eventSeats, setEventSeats] = useState<EventSeatUserDto[] | null>(null)
  const [eventPrices, setEventPrices] = useState<EventPriceDto[] | undefined>(
    undefined,
  )
  const [filled, setFilled] = useState(false)
  const { appLang } = useLanguageContext()
  const svgString = useRef('')
  const { data: ticketsBooked, loaded: ticketsBookedLoaded } =
    useAsyncGet<TicketsBookedDto>(`admin/tickets/sold/${eventId}`)

  const defaultTicketCategory = {
    name: t('StepSeatAssignment.defaultTicketCategoryName'),
    description: t('StepSeatAssignment.defaultTicketCategoryName'),
    event: {},
    id: 0,
    price: 0,
    quantity: 0,
  }

  const ellipseClickHandler = useCallback(async (e: Event) => {
    e.stopPropagation()
    const target = e.target
    let ellipse
    if (target instanceof SVGTextElement) {
      const toSeat = target.dataset.toSeat || target.getAttribute('to')
      if (toSeat) {
        ellipse = svgRef.current?.querySelector('#' + toSeat)
      }
    } else {
      ellipse = target
    }
    if (ellipse instanceof SVGElement) {
      const id = ellipse.getAttribute('id')
      if (id) {
        const {
          data: { content },
        } = await axiosInstance.get<PageEventSeatPriceDto>(
          `admin/event-seat-prices?size=1&query=eventPrice.event.id==${eventId};eventSeat.seatId==${id}`,
        )
        if (content?.length) {
          onSelect(content[0])
        }
      }
    }
  }, [])

  const fillSeatsByPrice = (
    eventPrices: EventPriceDto[],
    eventSeatPrices: EventSeatPriceDto[],
  ) => {
    if (eventPrices && eventPrices.length > 0) {
      for (let i = 1; i < eventPrices.length; i++) {
        const eventPrice = eventPrices[i]
        for (const eventSeatPrice of eventSeatPrices) {
          if (eventSeatPrice.eventSeat?.seatId) {
            if (eventSeatPrice.eventPrice?.id === eventPrice.id) {
              const ele = svgRef.current?.querySelector(
                '#' + eventSeatPrice.eventSeat.seatId,
              )
              if (ele) {
                ele.setAttribute('fill', COLORS[i])
              }
            }
          }
        }
      }
    }
  }

  const getData = useCallback(async () => {
    svgString.current! = definition.definition as string
    const temp = document.createElement('template')
    temp.innerHTML = definition.definition as string
    if (temp.content.firstChild) {
      definitionWrapperRef.current!.appendChild(temp.content.firstChild)
    }

    const svg = definitionWrapperRef.current!.children[1]
    svgRef.current = svg

    svg.removeAttribute('width')
    svg.removeAttribute('height')

    const svgElements = svgRef.current!.querySelectorAll('[data-definition]')
    if (svgElements.length > 0) {
      for (let i = 0; i < svgElements.length; i++) {
        const ele = svgElements.item(i) as SVGElement
        const defData = ele.dataset.definition
        if (defData) {
          const definition = JSON.parse(decodeURIComponent(defData))

          const id = ele.getAttribute('id')
          if (id) {
            const linkedEle = svgRef.current!.querySelector(`[to="${id}"]`)
            if (linkedEle) {
              linkedEle.innerHTML = definition.name[`value${appLang}`]
            }
          }
        }
      }
    }

    await getAndSetEventSeatPrices()

    const {
      data: { content: eventSeats },
    } = await axiosInstance.get(
      `admin/event-seats?size=50000&query=event.id==${eventId}`,
    )
    setEventSeats(eventSeats)

    const seats: { [key: string]: { id: number } } = {}

    for (const eventSeat of eventSeats) {
      seats[eventSeat.seatId] = {
        id: eventSeat.id,
      }

      const ele = svgRef.current?.querySelector('#' + eventSeat.seatId)
      if (ele) {
        ele.addEventListener('click', ellipseClickHandler)
      }
      const textEle =
        svgRef.current.querySelector(`[data-to-seat="${eventSeat.seatId}"]`) ||
        svgRef.current.querySelector(`[to="${eventSeat.seatId}"]`)
      if (textEle) {
        textEle.addEventListener('click', ellipseClickHandler)
      }
    }

    const {
      data: { content: eventPrices },
    } = await axiosInstance.get<PageEventPriceDto>(
      `admin/event-prices?size=50000&query=event.id==${eventId}`,
    )
    setEventPrices(eventPrices)

    if (eventPrices) {
      setEventPrices(() => [defaultTicketCategory, ...eventPrices])
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (eventPrices && seatPrices) {
      fillSeatsByPrice(eventPrices, seatPrices)
      setFilled(true)
    }
  }, [seatPrices, eventPrices])

  useEffect(() => {
    const tempSeats: {
      [key: string]: { id: number; eventSeatPriceID?: number }
    } = {}
    if (eventSeats) {
      for (const eventSeat of eventSeats) {
        if (eventSeat.seatId) {
          tempSeats[eventSeat.seatId] = {
            id: eventSeat.id!,
          }
        }
      }
    }
    if (seatPrices) {
      for (const seatPrice of seatPrices) {
        if (seatPrice.eventSeat?.seatId) {
          if (tempSeats[seatPrice.eventSeat?.seatId] === undefined) {
            tempSeats[seatPrice.eventSeat?.seatId] = {
              id: seatPrice.eventSeat?.id!,
            }
          }
          tempSeats[seatPrice.eventSeat?.seatId].eventSeatPriceID = seatPrice.id
        }
      }
    }
  }, [eventSeats, seatPrices])

  const getAndSetEventSeatPrices = async () => {
    const {
      data: { content: eventSeatPrices },
    } = await axiosInstance.get(
      `admin/event-seat-prices?size=50000&query=eventPrice.event.id==${eventId};eventSeat.event.id==${eventId}`,
    )
    setSeatPrices(eventSeatPrices)

    return eventSeatPrices
  }

  useEffect(() => {
    if (!ticketsBookedLoaded || !filled) return

    const booked: string[] = ticketsBooked?.bookedSeatIds || []
    const purchased: string[] = ticketsBooked?.purchasedSeatIds || []

    for (const bookedID of booked) {
      const ele = svgRef.current?.querySelector<SVGElement>(`#${bookedID}`)
      const toElement = svgRef.current!.querySelector<SVGTextElement>(
        `[data-to-seat="${bookedID}"]`,
      )

      if (
        ele &&
        !seatPrices?.find(({ eventSeat }) => eventSeat === bookedID)?.eventSeat!
          .capacityLeft
      ) {
        ele.style.cursor = 'pointer'
        ele.setAttribute('fill', BOOKED_COLOR)
        ele.dataset.booked = 'booked'

        if (ele.tagName === 'rect') {
          if (ellipseClickHandler) {
            ele.style.cursor = 'default'
            ele.removeEventListener('click', ellipseClickHandler)
            toElement?.removeEventListener('click', ellipseClickHandler)
          }
        }
        if (ele.tagName === 'ellipse') {
          if (ellipseClickHandler) {
            ele.style.cursor = 'default'
            ele.removeEventListener('click', ellipseClickHandler)
            if (toElement) {
              toElement.removeEventListener('click', ellipseClickHandler)
              toElement.style.cursor = 'default'
            }
          }
        }
      }
    }

    for (const purchasedID of purchased) {
      const ele = svgRef.current?.querySelector<SVGElement>(`#${purchasedID}`)
      const toElement = svgRef.current!.querySelector<SVGTextElement>(
        `[data-to-seat="${purchasedID}"]`,
      )
      if (
        ele &&
        !seatPrices?.find(({ eventSeat }) => eventSeat!.seatId === purchasedID)
          ?.eventSeat!.capacityLeft
      ) {
        ele.setAttribute('fill', PURCHASED_COLOR)
        ele.dataset.purchased = 'purchased'
        if (ele.tagName === 'ellipse') {
          if (ellipseClickHandler) {
            ele.style.cursor = 'default'
            if (toElement) {
              toElement.removeEventListener('click', ellipseClickHandler)
              toElement.style.cursor = 'default'
            }
            ele.removeEventListener('click', ellipseClickHandler)
          }
        }
      }
    }
  }, [ticketsBookedLoaded, ticketsBooked, filled])

  return (
    <Card style={{ marginTop: '15px' }}>
      <Card
        style={{ height: '100%' }}
        headStyle={{ textAlign: 'center' }}
        title={t('StepSeatAssignment.mapTitle')}
      >
        <div
          ref={definitionWrapperRef}
          style={{ position: 'relative', userSelect: 'none' }}
        >
          <div id={styles.selectionFrame} ref={selectionFrame} />
        </div>
      </Card>
    </Card>
  )
}
