/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageAudit } from '../models';
// @ts-ignore
import { PageUserDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
// @ts-ignore
import { UserCreateRequest } from '../models';
// @ts-ignore
import { UserCreateResponse } from '../models';
// @ts-ignore
import { UserDto } from '../models';
// @ts-ignore
import { UserUpdateRequest } from '../models';
// @ts-ignore
import { UserUpdateResponse } from '../models';
/**
 * UsersAdminControllerApi - axios parameter creator
 * @export
 */
export const UsersAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<UserCreateRequest>} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate: async (userCreateRequest: Array<UserCreateRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequest' is not null or undefined
            assertParamExists('bulkCreate', 'userCreateRequest', userCreateRequest)
            const localVarPath = `/v1/admin/users/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (userCreateRequest: UserCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequest' is not null or undefined
            assertParamExists('create1', 'userCreateRequest', userCreateRequest)
            const localVarPath = `/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById1', 'id', id)
            const localVarPath = `/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find32: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find32', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findExtended', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/users/extended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById21: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById21', 'id', id)
            const localVarPath = `/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount21: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit: async (pageable: Pageable, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getEntityAudit', 'pageable', pageable)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit', 'id', id)
            const localVarPath = `/v1/admin/users/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessTranslationsToLanguageEnum} toLanguage 
         * @param {ProcessTranslationsFromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations: async (toLanguage: ProcessTranslationsToLanguageEnum, fromLanguage?: ProcessTranslationsFromLanguageEnum, forceTranslate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toLanguage' is not null or undefined
            assertParamExists('processTranslations', 'toLanguage', toLanguage)
            const localVarPath = `/v1/admin/users/i18n/from/{fromLanguage}/to/{toLanguage}`
                .replace(`{${"toLanguage"}}`, encodeURIComponent(String(toLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromLanguage !== undefined) {
                localVarQueryParameter['fromLanguage'] = fromLanguage;
            }

            if (forceTranslate !== undefined) {
                localVarQueryParameter['forceTranslate'] = forceTranslate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull: async (id: number, properties: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPropertiesToNull', 'id', id)
            // verify required parameter 'properties' is not null or undefined
            assertParamExists('setPropertiesToNull', 'properties', properties)
            const localVarPath = `/v1/admin/users/{id}/property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (properties) {
                localVarQueryParameter['properties'] = Array.from(properties);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, userUpdateRequest: UserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'userUpdateRequest' is not null or undefined
            assertParamExists('update', 'userUpdateRequest', userUpdateRequest)
            const localVarPath = `/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersAdminControllerApi - functional programming interface
 * @export
 */
export const UsersAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<UserCreateRequest>} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreate(userCreateRequest: Array<UserCreateRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserCreateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreate(userCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(userCreateRequest: UserCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(userCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find32(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find32(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExtended(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExtended(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById21(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById21(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount21(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount21(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit(pageable: Pageable, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit(pageable, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nSupportedProperties(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nSupportedProperties(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessTranslationsToLanguageEnum} toLanguage 
         * @param {ProcessTranslationsFromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTranslations(toLanguage: ProcessTranslationsToLanguageEnum, fromLanguage?: ProcessTranslationsFromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTranslations(toLanguage, fromLanguage, forceTranslate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPropertiesToNull(id: number, properties: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPropertiesToNull(id, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, userUpdateRequest: UserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, userUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersAdminControllerApi - factory interface
 * @export
 */
export const UsersAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<UserCreateRequest>} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate(userCreateRequest: Array<UserCreateRequest>, options?: any): AxiosPromise<Array<UserCreateResponse>> {
            return localVarFp.bulkCreate(userCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(userCreateRequest: UserCreateRequest, options?: any): AxiosPromise<UserCreateResponse> {
            return localVarFp.create1(userCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find32(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageUserDto> {
            return localVarFp.find32(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageUserDto> {
            return localVarFp.findExtended(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById21(id: number, options?: any): AxiosPromise<UserDto> {
            return localVarFp.getById21(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount21(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount21(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit(pageable: Pageable, id: number, options?: any): AxiosPromise<PageAudit> {
            return localVarFp.getEntityAudit(pageable, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getI18nSupportedProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessTranslationsToLanguageEnum} toLanguage 
         * @param {ProcessTranslationsFromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations(toLanguage: ProcessTranslationsToLanguageEnum, fromLanguage?: ProcessTranslationsFromLanguageEnum, forceTranslate?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processTranslations(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull(id: number, properties: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPropertiesToNull(id, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, userUpdateRequest: UserUpdateRequest, options?: any): AxiosPromise<UserUpdateResponse> {
            return localVarFp.update(id, userUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersAdminControllerApi - object-oriented interface
 * @export
 * @class UsersAdminControllerApi
 * @extends {BaseAPI}
 */
export class UsersAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<UserCreateRequest>} userCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public bulkCreate(userCreateRequest: Array<UserCreateRequest>, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).bulkCreate(userCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreateRequest} userCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public create1(userCreateRequest: UserCreateRequest, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).create1(userCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public deleteById1(id: number, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).deleteById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public find32(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).find32(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public findExtended(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).findExtended(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public getById21(id: number, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).getById21(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public getCount21(query?: string, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).getCount21(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public getEntityAudit(pageable: Pageable, id: number, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).getEntityAudit(pageable, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public getI18nSupportedProperties(options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).getI18nSupportedProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessTranslationsToLanguageEnum} toLanguage 
     * @param {ProcessTranslationsFromLanguageEnum} [fromLanguage] 
     * @param {boolean} [forceTranslate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public processTranslations(toLanguage: ProcessTranslationsToLanguageEnum, fromLanguage?: ProcessTranslationsFromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).processTranslations(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} properties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public setPropertiesToNull(id: number, properties: Array<string>, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).setPropertiesToNull(id, properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UserUpdateRequest} userUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminControllerApi
     */
    public update(id: number, userUpdateRequest: UserUpdateRequest, options?: AxiosRequestConfig) {
        return UsersAdminControllerApiFp(this.configuration).update(id, userUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslationsToLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslationsFromLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
