import { EventTourViewDto } from '@/openapi'
import {
  AnnouncePercentTooltip,
  PublishPercentTooltip,
} from '@pages/tour/components/PercentTooltip'
import { isArchivedEvent, isPublishedEvent } from '@/utils/eventsStatuses'

export const EventInfo = ({ event }: { event: EventTourViewDto }) => {
  const announced = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {'A '}
      <AnnouncePercentTooltip
        event={event}
        size={20}
        placement="right"
        strokeColor="white"
        trailColor="transparent"
      />
    </div>
  )

  const published = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {'P '}
      <PublishPercentTooltip
        event={event}
        size={20}
        placement="right"
        strokeColor="white"
        trailColor="transparent"
      />
    </div>
  )

  if (isPublishedEvent(event) || isArchivedEvent(event)) return null

  return (
    <div
      style={{
        position: 'absolute',
        right: '5px',
        top: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      {announced}
      {published}
    </div>
  )
}
