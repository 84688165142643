import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Table,
} from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StepProps } from '@pages/event/types'
import {
  EventPriceDto,
  EventSeatPriceDto,
  EventSeatPriceUpdateRequest,
  EventSeatUpdateRequest,
  EventSeatUserDto,
  PageEventPriceDto,
  PageEventStageDefinitionDto,
  TicketsBookedDto,
} from '@/openapi'
import { axiosInstance } from '@/api'
import { useTranslation } from 'react-i18next'
import type { ColumnsType } from 'antd/es/table'
import styles from './styles.module.less'
import cn from 'classnames'
import { Id, toast } from 'react-toastify'
import { COLORS } from '@/constants/colors'
import { FullscreenOutlined } from '@ant-design/icons'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useParams } from 'react-router-dom'
import useAsyncGet from '@/hooks/useAsyncGet'
import { BOOKED_COLOR, PURCHASED_COLOR } from './manage/newGift/giftWithSD'
import { coords } from '@/utils/svgUtils'
import { ToPdf } from '@pages/event/components/definitionToPdf'

const { confirm } = Modal

export const getAssignedSeatsCount = (
  eventPrices: EventPriceDto[],
  eventSeatPrices: EventSeatPriceDto[],
  eventSeats: EventSeatUserDto[],
) => {
  const list: {
    [key: string]: {
      count: number
      seatsID: string[]
    }
  } = {}
  list['0'] = { count: eventSeats.length - eventSeatPrices.length, seatsID: [] }

  for (const eventPrice of eventPrices) {
    if (eventPrice.id) {
      list[String(eventPrice.id)] = { count: 0, seatsID: [] }
      for (const eventSeatPrice of eventSeatPrices) {
        if (eventSeatPrice.eventPrice) {
          if (eventSeatPrice.eventPrice.id === eventPrice.id) {
            if (eventSeatPrice.eventSeat?.seatId) {
              list[eventPrice.id].seatsID.push(eventSeatPrice.eventSeat?.seatId)
            }
            list[eventPrice.id].count += eventSeatPrice.eventSeat?.capacity || 1
          }
        }
      }
    }
  }
  return list
}

const StepSeatAssignment = ({ onSuccess, canEdit, errors }: StepProps) => {
  const { id: eventID } = useParams<{ id: string }>()

  const definitionWrapperRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<Element>()
  const selectionFrame = useRef<HTMLDivElement | null>(null)
  const addSeatsHintID = useRef<Id | null>(null)
  const [form] = Form.useForm<any>()
  const goForward = useRef(true)
  const { t } = useTranslation()
  const [seats, setSeats] = useState<{
    [key: string]: { id: number; eventSeatPriceID?: number }
  }>({})
  const [seatPrices, setSeatPrices] = useState<EventSeatPriceDto[] | null>(null)
  const [eventSeats, setEventSeats] = useState<EventSeatUserDto[] | null>(null)
  const [eventPrices, setEventPrices] = useState<EventPriceDto[] | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState(false)
  const selectedSeats = useRef<string[]>([])
  const [selectedSeatsCount, setSelectedSeatsCount] = useState(0)
  const [isSeatsSelected, setIsSeatsSelected] = useState(false)
  const [selectedSeatsHasCategoryModal, setSelectedSeatsHasCategoryModal] =
    useState(false)
  const [newAddedSeats, setNewAddedSeats] = useState<
    EventSeatPriceUpdateRequest[]
  >([])
  const [seatsToUpdate, setSeatsToUpdate] = useState<
    EventSeatPriceUpdateRequest[]
  >([])
  const { appLang } = useLanguageContext()
  const [isCapacityModalVisible, setShowCapacityModal] = useState(false)
  const [capacityInputValue, capacityInputHandler] = useState<number | null>()
  const [stageDefinitionCode, setStageDefinitionCode] = useState<
    string | undefined
  >()
  const svgString = useRef('')
  const initialViewBox = useRef('')
  const wheelDeltaRef = useRef(4)
  const selectedExcludedSeats = useRef<string[]>([])
  const [selectedExcludedSeatsCount, setSelectedExcludedSeatsCount] =
    useState(0)

  const defaultTicketCategory = {
    name: t('StepSeatAssignment.defaultTicketCategoryName'),
    description: t('StepSeatAssignment.defaultTicketCategoryName'),
    event: {},
    id: 0,
    price: 0,
    quantity: 0,
  }
  const [filled, setFilled] = useState(false)

  const onFinish = async () => {
    toast.success(t`messages.eventChangesSaved`)
    onSuccess({}, !goForward.current)
  }

  const seatClickHandler = useCallback((e: Event) => {
    const target = e.target
    let ellipse
    debugger
    if (target instanceof SVGTextElement) {
      const toSeat = target.dataset.toSeat
      if (toSeat) {
        ellipse = document.getElementById(toSeat)
      }
    } else {
      ellipse = target
    }

    if (ellipse instanceof SVGElement) {
      const id = ellipse.getAttribute('id')
      const selected = ellipse.dataset.selected
      const excluded = ellipse.dataset.excluded

      if (!selected || selected === 'false') {
        if (id) {
          if (excluded !== '') {
            selectedSeats.current.push(id)
            setSelectedSeatsCount(selectedSeats.current!.length)
            setIsSeatsSelected(true)
          } else {
            selectedExcludedSeats.current.push(id)
            setSelectedExcludedSeatsCount(selectedExcludedSeats.current.length)
          }
        }
        ellipse.setAttribute('stroke', 'red')
        ellipse.setAttribute('stroke-width', '3')
        ellipse.dataset.selected = 'true'
      } else {
        if (id) {
          if (excluded !== '') {
            const idx = selectedSeats.current.indexOf(id)
            selectedSeats.current.splice(idx, 1)
            setSelectedSeatsCount(selectedSeats.current!.length)
            if (selectedSeats.current.length === 0) {
              setIsSeatsSelected(false)
            }
          } else {
            const idx = selectedExcludedSeats.current.indexOf(id)
            selectedExcludedSeats.current.splice(idx, 1)
            setSelectedExcludedSeatsCount(selectedExcludedSeats.current.length)
          }
        }

        ellipse.setAttribute('stroke', '#000000')
        ellipse.setAttribute('stroke-width', '1')
        ellipse.dataset.selected = 'false'
      }
    }
  }, [])

  const clearSelection = (ids: string[]): void => {
    for (const id of ids) {
      const element = document.getElementById(id)
      if (element) {
        element.setAttribute('stroke', '#000000')
        element.setAttribute('stroke-width', '1')
        element.dataset.selected = 'false'
      }
    }
  }

  const removeSelection = (
    ids: string[],
    seatsType: 'selected' | 'excluded',
  ) => {
    clearSelection(ids)
    if (seatsType === 'selected') {
      selectedSeats.current! = []
      setSelectedSeatsCount(0)
      setIsSeatsSelected(false)
    } else {
      selectedExcludedSeats.current! = []
      setSelectedExcludedSeatsCount(0)
    }
  }

  const clearFilledSeats = (ids: string[]): void => {
    for (const id of ids) {
      const element = document.getElementById(id)
      if (element) {
        element.setAttribute('fill', '#ffffff')
      }
    }
  }

  const fillSeatsByPrice = (
    eventPrices: EventPriceDto[],
    eventSeatPrices: EventSeatPriceDto[],
  ) => {
    if (eventPrices && eventPrices.length > 0) {
      for (let i = 1; i < eventPrices.length; i++) {
        const eventPrice = eventPrices[i]
        for (const eventSeatPrice of eventSeatPrices) {
          if (eventSeatPrice.eventSeat?.seatId) {
            if (eventSeatPrice.eventPrice?.id === eventPrice.id) {
              const ele = document.getElementById(
                eventSeatPrice.eventSeat.seatId,
              )
              if (ele) {
                ele.setAttribute('fill', COLORS[i])
              }
            }
          }
        }
      }
    }
  }

  const assignSeatsToPrice = async (list: EventSeatPriceUpdateRequest[]) => {
    const response = await axiosInstance.post(
      'admin/event-seat-prices/bulk',
      list,
    )
    if (response.status === 200) {
      clearSelection(selectedSeats.current)
      clearSelection(selectedExcludedSeats.current)
      selectedSeats.current = []
      setIsSeatsSelected(false)
      selectedExcludedSeats.current = []
      setSelectedExcludedSeatsCount(0)
      await getAndSetEventSeatPrices()
      toast.success(t`messages.savedSuccessfully`)
    } else {
      toast.error(t`somethingWentWrong`)
    }
  }

  const { data: ticketsBooked, loaded: ticketsBookedLoaded } =
    useAsyncGet<TicketsBookedDto>(`admin/tickets/sold/${eventID}`)

  useEffect(() => {
    if (!ticketsBookedLoaded || !filled || !canEdit) return

    const booked: string[] = ticketsBooked?.bookedSeatIds || []
    const purchased: string[] = ticketsBooked?.purchasedSeatIds || []

    for (const bookedID of booked) {
      const ele = svgRef.current?.querySelector<SVGElement>(`#${bookedID}`)
      const toElement = svgRef.current!.querySelector<SVGTextElement>(
        `[data-to-seat="${bookedID}"]`,
      )

      if (
        ele &&
        !seatPrices?.find(({ eventSeat }) => eventSeat === bookedID)?.eventSeat!
          .capacityLeft
      ) {
        ele.style.cursor = 'pointer'
        ele.setAttribute('fill', BOOKED_COLOR)
        ele.dataset.booked = 'booked'

        if (ele.tagName === 'rect') {
          if (seatClickHandler) {
            ele.style.cursor = 'default'
            ele.removeEventListener('click', seatClickHandler)
            toElement?.removeEventListener('click', seatClickHandler)
          }
        }
        if (ele.tagName === 'ellipse' && ele.dataset?.elementType === 'seat') {
          if (seatClickHandler) {
            ele.style.cursor = 'default'
            ele.setAttribute('fill', '#dddddd')
            ele.removeEventListener('click', seatClickHandler)
            if (toElement) {
              toElement.removeEventListener('click', seatClickHandler)
              toElement.style.cursor = 'default'
              toElement.setAttribute('fill', '#dddddd')
            }
          }
        }
      }
      if (toElement) {
      }
    }

    for (const purchasedID of purchased) {
      const ele = svgRef.current?.querySelector<SVGElement>(`#${purchasedID}`)
      const toElement = svgRef.current!.querySelector<SVGTextElement>(
        `[data-to-seat="${purchasedID}"]`,
      )
      if (
        ele &&
        !seatPrices?.find(({ eventSeat }) => eventSeat!.seatId === purchasedID)
          ?.eventSeat!.capacityLeft
      ) {
        ele.setAttribute('fill', PURCHASED_COLOR)
        ele.dataset.purchased = 'purchased'
        if (ele.tagName === 'ellipse' && ele.dataset?.elementType === 'seat') {
          if (seatClickHandler) {
            ele.style.cursor = 'default'
            ele.setAttribute('fill', '#dddddd')
            if (toElement) {
              toElement.removeEventListener('click', seatClickHandler)
              toElement.style.cursor = 'default'
              toElement.setAttribute('fill', '#dddddd')
            }
            ele.removeEventListener('click', seatClickHandler)
          }
        }
      }
    }
  }, [ticketsBookedLoaded, ticketsBooked, filled])

  const updateSeatsPrices = async (list: EventSeatPriceUpdateRequest[]) => {
    const requests = list.map((item: EventSeatPriceUpdateRequest) => {
      const data = {
        id: item.id,
        eventSeatId: item.eventSeatId,
        eventPriceId: item.eventPriceId,
      }
      return axiosInstance.put(`admin/event-seat-prices/${item.id}`, data)
    })
    return Promise.all(requests)
  }

  const doSelection = ({
    x1,
    y1,
    x2,
    y2,
    eventPageX1,
    eventPageY1,
    eventPageX2,
    eventPageY2,
  }: {
    x1: number
    y1: number
    x2: number
    y2: number
    eventPageX1: number
    eventPageY1: number
    eventPageX2: number
    eventPageY2: number
  }) => {
    x1 = eventPageX1 - window.scrollX
    y1 = eventPageY1 - window.scrollY
    x2 = eventPageX2 - window.scrollX
    y2 = eventPageY2 - window.scrollY

    if (x1 === x2) {
      return
    }
    if (y1 === y2) {
      return
    }

    if (x1 > x2) {
      x1 = x1 + x2
      x2 = x1 - x2
      x1 = x1 - x2
    }
    if (y1 > y2) {
      y1 = y1 + y2
      y2 = y1 - y2
      y1 = y1 - y2
    }
    const selected: string[] = []

    const elements = definitionWrapperRef.current!.getElementsByTagName('*')
    for (let i = 0; i < elements.length; i++) {
      const item = elements.item(i) as SVGElement
      const itemCoords = item.getBoundingClientRect()

      if (item instanceof SVGElement) {
        const id = item.getAttribute('id')
        if (!id) continue

        let x, y

        x = itemCoords.left
        y = itemCoords.top

        if (x && y && x >= x1 && x <= x2 && y >= y1 && y <= y2) {
          if (selectedSeats.current.indexOf(id!) >= 0) {
            continue
          }

          let inSeats = false
          setSeats((prev) => {
            if (prev[id!] !== undefined) inSeats = true
            return prev
          })

          if (!inSeats) continue

          selected.push(id!)
          item.setAttribute('stroke', 'red')
          item.setAttribute('stroke-width', '3')
          item.dataset.selected = 'true'
        }
      }
    }

    selectedSeats.current = [...selectedSeats.current, ...selected]
    if (selectedSeats.current.length > 0) {
      setSelectedSeatsCount(selectedSeats.current.length)
      setIsSeatsSelected(true)
    }
  }

  useEffect(() => {
    let mouseDownListener: (this: HTMLDivElement, e: MouseEvent) => boolean,
      mouseMoveListener: (this: HTMLDivElement, e: MouseEvent) => void,
      mouseUpListener: (this: HTMLDivElement, e: MouseEvent) => void

    const getData = async () => {
      const {
        data: { content },
      } = await axiosInstance.get<PageEventStageDefinitionDto>(
        `admin/event-stage-definitions?query=event.id==${eventID}`,
      )

      const seats: { [key: string]: { id: number; excluded: boolean } } = {}

      if (content && content[0]?.definition) {
        setStageDefinitionCode(content[0].definition)
        svgString.current! = content[0].definition
        const temp = document.createElement('template')
        temp.innerHTML = content[0].definition
        if (temp.content.firstChild) {
          definitionWrapperRef.current!.appendChild(temp.content.firstChild)
        }

        svgRef.current = definitionWrapperRef.current!.children[1]

        definitionWrapperRef.current!.style.cursor = 'grab'

        const currentViewBox = svgRef.current!.getAttribute('viewBox')
        initialViewBox.current! = currentViewBox ?? ''
        let svgWidth: number = parseFloat(currentViewBox!.split(' ')[2]) ?? 0
        let svgHeight: number = parseFloat(currentViewBox!.split(' ')[3]) ?? 0

        let viewBox = {
          x: 0,
          y: 0,
          w: svgWidth,
          h: svgHeight,
        }
        svgRef.current!.setAttribute(
          'viewBox',
          `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`,
        )
        const svgSize = {
          w: svgWidth,
          h: svgHeight,
        }
        let isPanning = false
        let startPoint = {
          x: 0,
          y: 0,
        }
        let endPoint = {
          x: 0,
          y: 0,
        }
        let scale = 1

        const svgContainerMouseWheel = function (e: WheelEvent) {
          const offsetX =
            definitionWrapperRef.current!.getBoundingClientRect().x +
            definitionWrapperRef.current!.getBoundingClientRect().width / 2
          const offsetY =
            definitionWrapperRef.current!.getBoundingClientRect().y +
            definitionWrapperRef.current!.getBoundingClientRect().height / 2

          e.preventDefault()
          let deltaY = e.deltaY || wheelDeltaRef.current
          const w = viewBox.w
          const h = viewBox.h
          const mx = e.offsetX || offsetX
          const my = e.offsetY || offsetY
          const dw = w * Math.sign(deltaY) * 0.05
          const dh = h * Math.sign(deltaY) * 0.05
          const dx = (dw * mx) / svgSize.w
          const dy = (dh * my) / svgSize.h
          viewBox = {
            x: viewBox.x + dx,
            y: viewBox.y + dy,
            w: viewBox.w - dw,
            h: viewBox.h - dh,
          }
          scale = svgSize.w / viewBox.w
          svgRef.current!.setAttribute(
            'viewBox',
            `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`,
          )
        }

        const svgContainerMouseDown = function (e: MouseEvent) {
          if (e.shiftKey) return

          definitionWrapperRef.current!.style.cursor = 'grabbing'

          isPanning = true
          startPoint = {
            x: e.x,
            y: e.y,
          }
        }

        const svgContainerMouseMove = function (e: MouseEvent) {
          if (e.shiftKey) return

          if (isPanning) {
            endPoint = {
              x: e.x,
              y: e.y,
            }
            const dx = (startPoint.x - endPoint.x) / scale
            const dy = (startPoint.y - endPoint.y) / scale
            const movedViewBox = {
              x: viewBox.x + dx,
              y: viewBox.y + dy,
              w: viewBox.w,
              h: viewBox.h,
            }
            svgRef.current!.setAttribute(
              'viewBox',
              `${movedViewBox.x} ${movedViewBox.y} ${movedViewBox.w} ${movedViewBox.h}`,
            )
          }
        }

        const svgContainerMouseUp = function (e: MouseEvent) {
          if (e.shiftKey) return

          definitionWrapperRef.current!.style.cursor = 'grab'

          if (isPanning) {
            endPoint = {
              x: e.x,
              y: e.y,
            }
            const dx = (startPoint.x - endPoint.x) / scale
            const dy = (startPoint.y - endPoint.y) / scale
            viewBox = {
              x: viewBox.x + dx,
              y: viewBox.y + dy,
              w: viewBox.w,
              h: viewBox.h,
            }
            svgRef.current!.setAttribute(
              'viewBox',
              `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`,
            )
            isPanning = false
          }
        }

        const svgContainerMouseLeave = function (e: MouseEvent) {
          if (e.shiftKey) return

          isPanning = false
        }

        definitionWrapperRef.current!.addEventListener(
          'wheel',
          svgContainerMouseWheel,
        )
        definitionWrapperRef.current!.addEventListener(
          'mousedown',
          svgContainerMouseDown,
        )
        definitionWrapperRef.current!.addEventListener(
          'mousemove',
          svgContainerMouseMove,
        )
        definitionWrapperRef.current!.addEventListener(
          'mouseup',
          svgContainerMouseUp,
        )
        definitionWrapperRef.current!.addEventListener(
          'mouseleave',
          svgContainerMouseLeave,
        )

        let msdown = false,
          msx = 0,
          msy = 0,
          eventPageX1 = 0,
          eventPageY1 = 0

        const winMouseUpListener = () => {
          msdown = false
          selectionFrame.current!.style.visibility = 'hidden'
          window.removeEventListener('mouseup', winMouseUpListener)
          window.removeEventListener('mouseleave', winMouseUpListener)
        }

        const mouseLeaveListener = () => {
          window.addEventListener('mouseup', winMouseUpListener)
          window.addEventListener('mouseleave', winMouseUpListener)
        }

        mouseDownListener = function (this: HTMLDivElement, e: MouseEvent) {
          if (!e.shiftKey) return false

          msdown = true
          const mousexy = coords(
            e,
            this.getBoundingClientRect().x,
            this.getBoundingClientRect().y,
          )
          msx = mousexy[0]
          msy = mousexy[1]
          eventPageX1 = mousexy[2]
          eventPageY1 = mousexy[3]
          return false
        }

        mouseMoveListener = function (this: HTMLDivElement, e: MouseEvent) {
          if (!e.shiftKey) return

          let x1, x2, y1, y2
          const mousexy = coords(
            e,
            this.getBoundingClientRect().x,
            this.getBoundingClientRect().y,
          )
          x1 = msx
          y1 = msy
          x2 = mousexy[0]
          y2 = mousexy[1]
          if (x1 === x2) {
            return
          }
          if (y1 === y2) {
            return
          }
          if (x1 > x2) {
            x1 = x1 + x2
            x2 = x1 - x2
            x1 = x1 - x2
          }
          if (y1 > y2) {
            y1 = y1 + y2
            y2 = y1 - y2
            y1 = y1 - y2
          }

          selectionFrame.current!.style.top = y1 + 'px'
          selectionFrame.current!.style.left = x1 + 'px'
          selectionFrame.current!.style.width = x2 - x1 + 'px'
          selectionFrame.current!.style.height = y2 - y1 + 'px'
          selectionFrame.current!.style.visibility = msdown
            ? 'visible'
            : 'hidden'
        }

        mouseUpListener = function (this: HTMLDivElement, e: MouseEvent) {
          if (!e.shiftKey) return

          msdown = false
          const mousexy = coords(
            e,
            this.getBoundingClientRect().x,
            this.getBoundingClientRect().y,
          )
          doSelection({
            x1: msx,
            y1: msy,
            x2: mousexy[0],
            y2: mousexy[1],
            eventPageX1,
            eventPageY1,
            eventPageX2: mousexy[2],
            eventPageY2: mousexy[3],
          })
          selectionFrame.current!.style.visibility = msdown
            ? 'visible'
            : 'hidden'
        }

        definitionWrapperRef.current!.addEventListener(
          'mousedown',
          mouseDownListener,
        )
        definitionWrapperRef.current!.addEventListener(
          'mousemove',
          mouseMoveListener,
        )
        definitionWrapperRef.current!.addEventListener(
          'mouseup',
          mouseUpListener,
        )
        definitionWrapperRef.current!.addEventListener(
          'mouseleave',
          mouseLeaveListener,
        )

        svgRef.current!.setAttribute('width', '100%')
        svgRef.current!.setAttribute('height', '100%')

        const svgElements = svgRef.current!.querySelectorAll('*')
        if (svgElements.length > 0) {
          for (let i = 0; i < svgElements.length; i++) {
            const svgEle = svgElements.item(i) as SVGElement
            if (svgEle.id) {
              seats[svgEle.id] = {
                id: 0,
                excluded: true,
              }
            }
            if (
              svgEle instanceof SVGEllipseElement &&
              svgEle.dataset.elementType === 'seat'
            ) {
              svgEle.setAttribute('fill', '#dddddd')
              svgEle.dataset.excluded = ''
            }
            if (
              svgEle instanceof SVGEllipseElement ||
              svgEle instanceof SVGRectElement
            ) {
              svgEle.addEventListener('click', seatClickHandler)
            }
            if (svgEle instanceof SVGRectElement) {
              svgEle.setAttribute('pointer-events', 'all')
            }
            const defData = svgEle.dataset.definition
            if (defData) {
              const definition = JSON.parse(decodeURIComponent(defData))

              const id = svgEle.getAttribute('id')
              if (id) {
                const linkedEle = svgRef.current!.querySelector(`[to="${id}"]`)
                if (linkedEle) {
                  linkedEle.innerHTML = definition.name[`value${appLang}`]
                }
              }
            }
          }
        }
      }

      await getAndSetEventSeatPrices()

      const {
        data: { content: eventSeats },
      } = await axiosInstance.get(
        `admin/event-seats?size=50000&query=event.id==${eventID}`,
      )
      setEventSeats(eventSeats)

      for (const eventSeat of eventSeats) {
        if (seats[eventSeat.seatId]) {
          seats[eventSeat.seatId].id = eventSeat.id
          seats[eventSeat.seatId].excluded = false
        } else {
          seats[eventSeat.seatId] = {
            id: eventSeat.id,
            excluded: false,
          }
        }

        const ele = document.getElementById(eventSeat.seatId)
        if (ele) {
          if (
            ele instanceof SVGEllipseElement &&
            ele.dataset.elementType === 'seat'
          ) {
            ele.setAttribute('fill', '#ffffff')
            delete ele.dataset.excluded
          }
          ele.style.cursor = 'pointer'
        }
        const textEle = document.querySelector(
          `[data-to-seat="${eventSeat.seatId}"]`,
        )
        if (textEle) {
          textEle.addEventListener('click', seatClickHandler)
        }
      }
      setSeats(seats)
      const {
        data: { content: eventPrices },
      } = await axiosInstance.get<PageEventPriceDto>(
        `admin/event-prices?size=50000&query=event.id==${eventID}`,
      )
      setEventPrices(eventPrices)

      if (eventPrices) {
        setEventPrices(() => [defaultTicketCategory, ...eventPrices])
      }
    }

    setLoading(true)
    getData().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (eventPrices && seatPrices) {
      fillSeatsByPrice(eventPrices, seatPrices)
      setFilled(true)
    }
  }, [seatPrices, eventPrices])

  useEffect(() => {
    if (selectedSeats.current!.length > 0) {
      // @ts-ignore
      addSeatsHintID.current = toast(t('StepSeatAssignment.addSeatsHint'), {
        position: 'top-center',
        closeOnClick: false,
        autoClose: false,
      })
    } else if (selectedSeats.current!.length === 0) {
      toast.dismiss(addSeatsHintID.current!)
    }
  }, [isSeatsSelected])

  useEffect(() => {
    setSeats({})

    const tempSeats: {
      [key: string]: { id: number; eventSeatPriceID?: number }
    } = {}
    if (eventSeats) {
      for (const eventSeat of eventSeats) {
        if (eventSeat.seatId) {
          tempSeats[eventSeat.seatId] = {
            id: eventSeat.id!,
          }
        }
      }
    }
    if (seatPrices) {
      for (const seatPrice of seatPrices) {
        if (seatPrice.eventSeat?.seatId) {
          if (tempSeats[seatPrice.eventSeat?.seatId] === undefined) {
            tempSeats[seatPrice.eventSeat?.seatId] = {
              id: seatPrice.eventSeat?.id!,
            }
          }
          tempSeats[seatPrice.eventSeat?.seatId].eventSeatPriceID = seatPrice.id
        }
      }
    }
    setSeats(tempSeats)
  }, [eventSeats, seatPrices])

  useEffect(
    () => () => {
      if (addSeatsHintID.current) {
        toast.dismiss(addSeatsHintID.current)
      }
    },
    [],
  )

  const removeAssignedSeats = async (id: number) => {
    const response = await axiosInstance.delete(
      `admin/event-seat-prices/event-price/${id}`,
    )
    if (response.status === 200) {
      clearFilledSeats(assignedSeatsByPrice[id].seatsID)
      await getAndSetEventSeatPrices()
      toast.success('Success!')
    } else {
      toast.error(t`somethingWentWrong`)
    }
  }

  const assignedSeatsByPrice = useMemo(() => {
    if (eventPrices && seatPrices && eventSeats) {
      return getAssignedSeatsCount(eventPrices, seatPrices, eventSeats)
    }
    return {}
  }, [eventPrices, seatPrices, eventSeats])

  const getAndSetEventSeatPrices = async () => {
    const {
      data: { content: eventSeatPrices },
    } = await axiosInstance.get(
      `admin/event-seat-prices?size=50000&query=eventPrice.event.id==${eventID};eventSeat.event.id==${eventID}`,
    )
    setSeatPrices(eventSeatPrices)

    return eventSeatPrices
  }

  const columns: ColumnsType<EventPriceDto> = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any, index: number) => {
        return (
          <Space>
            <span
              style={{
                display: 'block',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '1px solid #d9d9d9',
                backgroundColor: index === 0 ? '#ffffff' : COLORS[index],
              }}
            />
            <span style={index === 0 ? {} : { cursor: 'pointer' }}>
              {record.name}
            </span>
          </Space>
        )
      },
    },
    {
      key: 'price',
      render: (_, record, index) => {
        return (
          <>
            {index === 0 && assignedSeatsByPrice
              ? `${assignedSeatsByPrice[index].count} seats`
              : record?.price! + ' ' + record?.event?.currency?.code!}
          </>
        )
      },
    },
    {
      key: 'quantity',
      render: (_: string, record) => {
        return (
          <>
            {assignedSeatsByPrice && record.id
              ? `${assignedSeatsByPrice[record.id].count} seats`
              : ''}
          </>
        )
      },
    },
    {
      key: 'action',
      render: (text: any, record: any, idx: number) => {
        return (
          idx > 0 && (
            <Button
              onClick={(event) => {
                event.stopPropagation()
                seatDeletingConfirmationModal(record.id)
              }}
              type="link"
              data-type="delete"
            >
              {t('StepSeatAssignment.removeAllSeatsButton')}
            </Button>
          )
        )
      },
    },
  ]

  const handleBackClick = useCallback(() => {
    goForward.current = false
    form.submit()
  }, [form])

  const seatAssignmentConfirmationModal = (record: EventPriceDto) => {
    if (record.id === undefined) {
      toast.error(t`somethingWentWrong`)
      return
    }
    debugger
    if (record.id === 0) return
    if (selectedSeats.current.length === 0) {
      return toast.warning(t`messages.pleaseSelectSeats`)
    }

    const data: EventSeatPriceUpdateRequest[] = []
    const seatsToUpdate: EventSeatPriceUpdateRequest[] = []

    if (selectedSeats.current.length > 0) {
      for (const selectedSeatID of selectedSeats.current) {
        let toUpdate = false
        let id: number
        for (const seatPrice of seatPrices!) {
          if (seatPrice.eventSeat?.seatId === selectedSeatID) {
            if (seatPrice.id) {
              id = seatPrice.id
            }
            if (seatPrice.eventSeat?.id) {
              toUpdate = true
            }
          }
        }

        eventSeats?.forEach((eventSeat) => {
          if (eventSeat.seatId === selectedSeatID) {
            if (eventSeat.id) {
              if (!toUpdate) {
                data.push({
                  id,
                  eventSeatId: eventSeat.id,
                  eventPriceId: record.id!,
                })
              } else {
                seatsToUpdate.push({
                  id,
                  eventSeatId: eventSeat.id,
                  eventPriceId: record.id!,
                })
              }
            }
          }
        })
      }
    }

    if (seatsToUpdate.length > 0) {
      setNewAddedSeats(data)
      setSeatsToUpdate(seatsToUpdate)
      setSelectedSeatsHasCategoryModal(true)
    } else {
      confirm({
        centered: true,
        title: t('StepSeatAssignment.seatAssignmentConfirmation', {
          count: data.length,
          category: record.name,
        }),
        okText: t`confirm`,
        onOk() {
          return assignSeatsToPrice(data)
        },
        onCancel() {},
      })
    }
  }

  const seatDeletingConfirmationModal = (eventPriceID: number) => {
    confirm({
      centered: true,
      content: t('StepSeatAssignment.seatsDeletingConfirmation'),
      okText: t`yes`,
      onOk() {
        return removeAssignedSeats(eventPriceID)
      },
      onCancel() {},
    })
  }

  const excludeFromAssignment = async () => {
    confirm({
      centered: true,
      title: t('StepSeatAssignment.excludeFromAssignmentConfirmation'),
      okText: t('yes'),
      async onOk() {
        const eventSeatPricesRequests: number[] = []
        const eventSeatsRequests: number[] = []
        const elements: HTMLElement[] = []

        selectedSeats.current!.map((seatID: string) => {
          if (seats[seatID]) {
            if (seats[seatID].eventSeatPriceID !== undefined) {
              // @ts-ignore
              eventSeatPricesRequests.push(seats[seatID].eventSeatPriceID)
            }

            if (seats[seatID].id) {
              eventSeatsRequests.push(seats[seatID].id)
            }

            const ele = document.getElementById(seatID)
            if (ele) {
              elements.push(ele)
            }
          }
        })

        try {
          if (eventSeatPricesRequests.length) {
            await Promise.all(
              eventSeatPricesRequests.map((id) =>
                axiosInstance.delete('admin/event-seat-prices/' + id),
              ),
            )
          }
          if (eventSeatsRequests.length) {
            await Promise.all(
              eventSeatsRequests.map((id) =>
                axiosInstance.delete('admin/event-seats/' + id),
              ),
            )
          }

          if (elements.length) {
            elements.map((ele) => {
              ele.dataset.excluded = ''
              if (ele.dataset?.elementType === 'seat') {
                ele.setAttribute('fill', '#dddddd')
              }
            })
          }
          removeSelection(selectedSeats.current!, 'selected')
        } catch (e) {
          toast.error(t`somethingWentWrong`)
          return
        }
      },
      onCancel() {},
    })
  }

  const returnToAssignment = async () => {
    confirm({
      centered: true,
      title: t('StepSeatAssignment.returnToAssignmentConfirmation'),
      okText: t('yes'),
      async onOk() {
        const bulkList = selectedExcludedSeats.current.map((item) => {
          const element = svgRef.current!.querySelector(`#${item}`)

          if (element) {
            let seatId = element.getAttribute('id')
            let capacity: number | null =
              eventSeats?.find(({ seatId: id }) => id === seatId)?.capacity ||
              null
            let rowNum = element.getAttribute('rownum')
            let place = element.getAttribute('data-place')
            let definition = element.getAttribute('data-definition')

            if (element.tagName === 'ellipse' || element.tagName === 'rect') {
              if (!definition) definition = '{}'
              if (!capacity) capacity = 1
              definition = decodeURIComponent(definition)
              return {
                seatId,
                capacity,
                rowNum,
                place,
                definition,
                eventId: String(eventID),
              }
            }
          }
        })

        try {
          await axiosInstance.post('admin/event-seats/bulk', bulkList)
          clearFilledSeats(selectedExcludedSeats.current!)
          removeSelection(selectedExcludedSeats.current!, 'excluded')

          selectedExcludedSeats.current = []
          setSelectedExcludedSeatsCount(0)
          bulkList.map((item) => {
            if (item) {
              const ele = svgRef.current!.querySelector(
                `#${item.seatId}`,
              ) as SVGElement
              if (ele) {
                delete ele.dataset.excluded
              }
            }
          })
          // нужно обновлять список ивентситов, иначе он становится неактуальным после возвращения места
          // в назначение
          // const {
          //   data: { content: eventSeats },
          // } = await axiosInstance.get(
          //     `admin/event-seats?size=50000&query=event.id==${eventID}`,
          // )
          // setEventSeats(eventSeats)
        } catch (e) {
          toast.error(t`somethingWentWrong`)
          console.error('admin/event-seats/bulk is failed')
        }
      },
      onCancel() {},
    })
  }

  const fullScreen = () => {
    const bodyOverflowInit = document.body.style.overflow
    document.body.style.overflow = 'hidden'

    const initialStyles: { [key: string]: string } = {}

    const styles: { [key: string]: string } = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'calc(100% - 50px)',
      height: 'calc(100% - 50px)',
      zIndex: '1001',
      overflowY: 'auto',
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
    }

    for (const key in styles) {
      // @ts-ignore
      initialStyles[key] = definitionWrapperRef.current!.style[key]

      // @ts-ignore
      definitionWrapperRef.current!.style[key] = styles[key]
    }

    const mask = document.createElement('div')
    mask.style.position = 'fixed'
    mask.style.width = '100%'
    mask.style.height = '100%'
    mask.style.top = '0'
    mask.style.bottom = '0'
    mask.style.left = '0'
    mask.style.padding = '30px'
    mask.style.background = 'rgba(0, 0, 0, 0.45)'
    mask.style.zIndex = '1001'

    const setInitialStyles = () => {
      for (const key in styles) {
        // @ts-ignore
        definitionWrapperRef.current!.style[key] = initialStyles[key]
      }
      document.body.style.overflow = bodyOverflowInit
    }

    const closeButton = document.createElement('button')
    closeButton.style.height = '32px'
    closeButton.style.cursor = 'pointer'
    closeButton.style.border = '1px solid #000'
    closeButton.style.borderRadius = '3px'
    closeButton.style.backgroundColor = '#fff'
    closeButton.innerText = t`close`
    closeButton.addEventListener('click', function (e) {
      e.preventDefault()
      e.stopPropagation()
      setInitialStyles()
      mask.remove()
      this.remove()
    })
    definitionWrapperRef.current!.append(closeButton)

    definitionWrapperRef.current!.parentElement!.prepend(mask)
    mask.addEventListener('click', function () {
      setInitialStyles()
      this.remove()
    })
  }

  const showCapacityModal = () => {
    if (selectedSeatsCount > 1) return

    const id = selectedSeats.current![0]
    if (id) {
      const ele = svgRef.current!.querySelector<SVGElement>(`#${id}`)
      let capacity: number | undefined

      if (ele) {
        let seatId = ele.getAttribute('id')
        capacity = eventSeats?.find(({ seatId: id }) => id === seatId)?.capacity
        if (capacity) {
          capacityInputHandler(capacity)
        }
      }

      if (!capacity) {
        capacityInputHandler(1)
      }
    }

    setShowCapacityModal(true)
  }

  const setCapacity = async () => {
    const id = selectedSeats.current![0]

    if (id) {
      const ele = svgRef.current!.querySelector<SVGElement>(`#${id}`)

      if (ele) {
        ele.dataset.capacity = String(capacityInputValue)

        try {
          await axiosInstance.put<EventSeatUpdateRequest>(
            `admin/event-seats/${seats[id].id}`,
            {
              id: seats[id].id,
              capacity: capacityInputValue,
            },
          )
          setEventSeats(
            (old) =>
              old?.map((seat) => {
                if (seat.seatId === id)
                  return { ...seat, capacity: capacityInputValue! }
                return seat
              }) || null,
          )
          setSeatPrices(
            (old) =>
              old?.map((seat) => {
                if (seat.eventSeat?.seatId === id)
                  return {
                    ...seat,
                    eventSeat: {
                      ...seat.eventSeat,
                      capacity: capacityInputValue!,
                    },
                  }
                return seat
              }) || null,
          )
          toast.success(t`StepSeatAssignment.capacityChanged`)
        } catch (e) {
          toast.error(t`somethingWentWrong`)
          console.error('error \n', e)
          return e
        }
      }
    }

    removeSelection(selectedSeats.current!, 'selected')
    hideCapacityModal()
  }

  const hideCapacityModal = () => {
    capacityInputHandler(null)
    setShowCapacityModal(false)
  }

  const checkSeatsNumbering = () => {
    const elements = svgRef.current!.querySelectorAll<SVGElement>(
      `[data-element-type="seat"]`,
    )

    const markEle = (ele: SVGElement) => {
      ele.setAttribute('stroke', '#e24e14')
      ele.setAttribute('stroke-width', '3')
    }

    elements?.forEach((ele) => {
      const hasPlace = !(
        ele.dataset.place === undefined || ele.dataset.place === ''
      )
      const hasRownum = !!ele.getAttribute('rownum')
      const hasDefinition = !(
        ele.dataset.definition === undefined || ele.dataset.definition === ''
      )

      if (hasPlace && hasRownum) {
        return
      } else if (hasDefinition) {
        return
      }
      markEle(ele)
    })
  }

  return (
    <Card style={{ marginTop: '15px' }}>
      <ToPdf
        definition={stageDefinitionCode}
        seatPrices={seatPrices}
        eventSeats={eventSeats}
        eventPrices={eventPrices}
      />
      <Modal
        centered={true}
        closable={false}
        open={selectedSeatsHasCategoryModal}
        title={t('StepSeatAssignment.selectedSeatsHasCategoryModalTitle', {
          category: 'asdf',
        })}
        footer={[
          <Button
            key="cancel"
            onClick={() => setSelectedSeatsHasCategoryModal(false)}
          >
            {t`cancel`}
          </Button>,
          <Button
            key="all-seats"
            type="primary"
            onClick={async () => {
              try {
                await assignSeatsToPrice(newAddedSeats)
                await updateSeatsPrices(seatsToUpdate)
                await getAndSetEventSeatPrices()
                toast.success(t`messages.seatsUpdated`)
              } catch (e) {
                toast.error(t`somethingWentWrong`)
              }
              setSelectedSeatsHasCategoryModal(false)
            }}
          >
            {t('StepSeatAssignment.allSeatsToCategoryButton')}
          </Button>,
          <Button
            key="without category"
            type="primary"
            onClick={async () => {
              await assignSeatsToPrice(newAddedSeats)
              await getAndSetEventSeatPrices()
              toast.success(t`messages.seatsUpdated`)
              setSelectedSeatsHasCategoryModal(false)
            }}
          >
            {t('StepSeatAssignment.seatsWithoutCategoryToCategoryButton')}
          </Button>,
        ]}
      />
      <Modal
        centered={true}
        open={isCapacityModalVisible}
        title={t`SetDefinitions.setCapacity`}
        onOk={setCapacity}
        onCancel={hideCapacityModal}
      >
        <Row>
          <Col span={12}>
            <InputNumber
              style={{ width: 200 }}
              placeholder={t('SetDefinitions.setCapacity') || ''}
              onChange={(value) => capacityInputHandler(value)}
              value={capacityInputValue}
              min={1}
            />
          </Col>
        </Row>
      </Modal>
      <Form form={form} name="seat-assignment" onFinish={onFinish}>
        <Row>
          <Col span={12}>
            {errors?.length && (
              <Alert
                message={
                  <>
                    {errors.map((e) => (
                      <div key={e}>{e}</div>
                    ))}
                  </>
                }
                type="error"
                showIcon
                className={cn(styles.fullWidth, styles.alert)}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={12} xs={24} sm={24} md={12} xxl={14}>
            <Card
              style={{ height: '100%' }}
              extra={
                <Space>
                  <Button onClick={fullScreen} icon={<FullscreenOutlined />} />
                  <Button
                    onClick={checkSeatsNumbering}
                  >{t`StepSeatAssignment.showUnnumberedSeats`}</Button>
                </Space>
              }
              headStyle={{ textAlign: 'center' }}
              title={t('StepSeatAssignment.mapTitle')}
            >
              {isSeatsSelected && (
                <Button
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '62px',
                    right: '10px',
                    zIndex: '1',
                  }}
                  onClick={() =>
                    removeSelection(selectedSeats.current!, 'selected')
                  }
                >
                  {t('StepSeatAssignment.removeSelectionButton')}
                </Button>
              )}
              <div
                ref={definitionWrapperRef}
                style={{
                  position: 'relative',
                  userSelect: 'none',
                  padding: '10px',
                }}
              >
                <div id={styles.selectionFrame} ref={selectionFrame} />
              </div>
            </Card>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} xxl={10}>
            <Card
              headStyle={{ textAlign: 'center' }}
              title={t('StepSeatAssignment.ticketCategoriesTitle')}
            >
              <Table
                id="ticketCategories"
                scroll={{ x: 400 }}
                loading={loading}
                showHeader={false}
                pagination={false}
                dataSource={eventPrices}
                columns={columns}
                rowClassName={styles.rowHover}
                onRow={(record) => {
                  return {
                    onClick: () => seatAssignmentConfirmationModal(record),
                  }
                }}
              />
              {selectedSeatsCount > 0 && (
                <>
                  <div>
                    <Button
                      style={{ marginTop: '20px' }}
                      onClick={excludeFromAssignment}
                    >
                      {t('StepSeatAssignment.excludeFromAssignmentButton')}
                    </Button>
                  </div>
                </>
              )}
              {selectedExcludedSeatsCount > 0 && (
                <>
                  <div>
                    <Button
                      style={{ marginTop: '20px' }}
                      onClick={returnToAssignment}
                    >
                      {t('StepSeatAssignment.returnToAssignmentButton')}
                    </Button>
                  </div>
                </>
              )}
              {selectedSeatsCount === 1 && (
                <>
                  <div>
                    <Button
                      style={{ marginTop: '20px' }}
                      onClick={showCapacityModal}
                    >
                      {t`StepSeatAssignment.changeCapacity`}
                    </Button>
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
        <Divider />
        <div>
          <div className={styles.buttonBlock}>
            <Button type="primary" htmlType="button" onClick={handleBackClick}>
              {t`labels.Back`}
            </Button>
            <Form.Item
              style={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'left',
              }}
            >
              <Button type="primary" htmlType="submit">
                {t`labels.Next`}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Card>
  )
}

export default StepSeatAssignment
