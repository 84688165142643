import { BaseSyntheticEvent, SetStateAction, useState } from 'react'
import {
  Button,
  message,
  Upload,
  UploadProps,
  Row,
  Col,
  Input,
  Form,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import uuidStartsWithLetter from '@/utils/customUUID'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { axiosInstance } from '@/api'
import { useTranslation } from 'react-i18next'

type UploadImageProps = {
  changeButtonStateProp: () => void
  setStageDefinitionID: SetStateAction<any>
  setStageDefinitionCode: SetStateAction<any>
  stageID: number | null
}

export const UploadImage = ({
  changeButtonStateProp,
  setStageDefinitionID,
  setStageDefinitionCode,
  stageID,
}: UploadImageProps) => {
  const [codeIsValid, setCodeIsValid] = useState(false)
  const [codeInputValue, setCodeInputValue] = useState('')
  const { t } = useTranslation()

  const prepareFile = async (options: UploadRequestOption) => {
    const removeContentAttribute = (element: Element) => {
      const contentAttr = element.getAttribute('content')
      if (contentAttr) {
        element.removeAttribute('content')
      }
    }

    const file = options.file
    const fileReader = new FileReader()
    if (file instanceof Blob) {
      fileReader.readAsText(file)
      fileReader.onload = function () {
        let str = fileReader.result
        if (typeof str === 'string') {
          const parser = new DOMParser()
          const parsed = parser.parseFromString(str, 'image/svg+xml')
          const docElement = parsed.documentElement
          if (docElement.hasAttribute('content')) {
            docElement.removeAttribute('content')
          }
          const elements: HTMLCollection = docElement.getElementsByTagName('*')

          for (let i = 0; i < elements.length; i++) {
            const element = elements.item(i)

            if (element) {
              // removeContentAttribute(element)

              if (element.tagName === 'ellipse' || element.tagName === 'rect') {
                element.setAttribute('id', uuidStartsWithLetter())
              }

              if (element.tagName === 'ellipse') {
                element.setAttribute('data-element-type', 'seat')
              }

              if (element.tagName === 'rect') {
                element.setAttribute('data-element-type', 'other')
              }
            }
          }
          let encoded = encodeURI(docElement.outerHTML)
          let decoded = decodeURI(encoded)
          saveFile(decoded)
            .then(() => changeButtonStateProp())
            .catch(() => message.error(t('FileUpload.errorMessage')))
        }
      }
    }
  }

  const saveFile = async (str: string) => {
    try {
      const resp = await axiosInstance.post('admin/stage-definitions', {
        code: codeInputValue,
        definition: str,
        stageId: stageID,
      })

      if (resp.status === 200) {
        message.success(t('FileUpload.successMessage'))
        setStageDefinitionID(resp.data.id)
        setStageDefinitionCode(resp.data.code)
      }
    } catch (e) {
      message.error(t('FileUpload.errorMessage'))
    }
  }

  const codeInputHandler = (e: BaseSyntheticEvent) => {
    const val = e.target.value
    const re = new RegExp(/[^\w-]/, 'g')
    let isValid = false
    if (val !== '') {
      isValid = !re.test(val.trim())
      setCodeInputValue(val.trim())
    }
    setCodeIsValid(isValid)
  }

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    accept: 'image/svg+xml',
    showUploadList: false,
    customRequest: prepareFile,
  }

  return (
    <Row justify="center">
      <Col span={8} offset={3}>
        <Form
          name="code-input-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Form.Item
            name="code-input"
            rules={[
              {
                required: true,
                message: t('UploadImage.codeInputRequiredMessage') || '',
              },
              {
                pattern: /^[\w-]+$/gm,
                message: t('UploadImage.codeInputMessage') || '',
              },
            ]}
          >
            <Input
              placeholder={t('UploadImage.codeInputPlaceHolder') || ''}
              maxLength={256}
              onChange={codeInputHandler}
              value={codeInputValue}
            />
          </Form.Item>
          <Form.Item name="image-upload">
            <Upload {...uploadProps}>
              <Button disabled={!codeIsValid} icon={<UploadOutlined />}>
                {t('UploadImage.uploadButtonTitle')}
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
