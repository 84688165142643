/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { TicketControlActivityDto } from './ticket-control-activity-dto';
// May contain unused imports in some cases
// @ts-ignore
import { TicketDto } from './ticket-dto';

/**
 * 
 * @export
 * @interface TicketControlVerificationDto
 */
export interface TicketControlVerificationDto {
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationDto
     */
    'verificationResult'?: TicketControlVerificationDtoVerificationResultEnum;
    /**
     * 
     * @type {TicketControlActivityDto}
     * @memberof TicketControlVerificationDto
     */
    'controlActivity'?: TicketControlActivityDto;
    /**
     * 
     * @type {TicketDto}
     * @memberof TicketControlVerificationDto
     */
    'ticket'?: TicketDto;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlVerificationDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketControlVerificationDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlVerificationDtoVerificationResultEnum {
    SUCCESS = 'success',
    DUPLICATE = 'duplicate',
    FAILED = 'failed'
}


