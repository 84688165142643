const STORAGE_KEY = 'SESSION_SETTINGS'

type SessionType = { [key: string]: string }

export const addSessionSetting = (key: string, value: string) => {
  const sessionObject = JSON.parse(
    localStorage.getItem(STORAGE_KEY) || '{}',
  ) as SessionType
  sessionObject[key] = value

  localStorage.setItem(STORAGE_KEY, JSON.stringify(sessionObject))
}

export const getSessionSetting = (key: string) => {
  const sessionObject = JSON.parse(
    localStorage.getItem(STORAGE_KEY) || '{}',
  ) as SessionType

  return sessionObject[key]
}

export const resetSessionSetting = () => {
  localStorage.removeItem(STORAGE_KEY)
}
