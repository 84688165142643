import { axiosInstance } from '@/api'

export const downloadTicket = async (id?: number, name: string = '') => {
  const { data } = await axiosInstance.get<Blob>(
    `/admin/tickets/${id}/download`,
    {
      responseType: 'blob',
    },
  )
  const fileURL = window.URL.createObjectURL(data)
  let alink = document.createElement('a')
  alink.href = fileURL
  alink.download = `Ticket-${id}-${name}`
  alink.click()
}
