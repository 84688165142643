/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AgencyDto } from './agency-dto';

/**
 * 
 * @export
 * @interface LoginCredentialsDto
 */
export interface LoginCredentialsDto {
    /**
     * 
     * @type {number}
     * @memberof LoginCredentialsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsDto
     */
    'username'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginCredentialsDto
     */
    'roles'?: Array<LoginCredentialsDtoRolesEnum>;
    /**
     * 
     * @type {AgencyDto}
     * @memberof LoginCredentialsDto
     */
    'agency'?: AgencyDto;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginCredentialsDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginCredentialsDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginCredentialsDtoRolesEnum {
    USER = 'user',
    GUEST = 'guest',
    ADMIN = 'admin',
    PARTNER = 'partner'
}


