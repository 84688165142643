import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, FormInstance, Modal, Space, StepProps, Steps, Tag } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import Page from 'components/Page'
import styles from './styles.module.less'
import StepBasicInformation from './StepBasicInformation'
import { axiosInstance } from 'api'
import cn from 'classnames'
import {
  EventExtendedDto,
  EventExtendedDtoEventStatusEnum,
  EventPreviewDto,
  PageEventStageDefinitionDto,
} from 'openapi'
import { prepareOption } from 'components/AsyncSelect/types'
import StepSchedule from './StepSchedule'
import { useLanguageContext } from 'contexts/LanguageProvider'
import StepStage from './StepStage'
import { isNil, omitBy } from 'lodash'
import StepTickets from './StepTickets'
import StepPoster from '@pages/event/StepPoster'
import { PageHeader } from '@ant-design/pro-components'
import StepSeatAssignment from '@pages/event/StepSeatAssignment'
import { useEventContext } from '@/contexts/EventContext'
import dayjs from 'dayjs'
import axios from 'axios'
import { useAuthContext } from '@/contexts/AuthContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
// import StepQuotas from '@pages/event/quotas'
import {
  isAnnouncedEvent,
  isPublishedEvent,
  isUnpublishedEvent,
} from '@/utils/eventsStatuses'
import { EVENT_STEPS } from '@pages/event/types'
import EventStatusEnum = EventExtendedDtoEventStatusEnum

type TPublish = { [key: string]: Array<string> }
const Event = () => {
  const { t } = useTranslation()
  const customStep = useRef<EVENT_STEPS | -1>(-1)
  const [search, setSearch] = useSearchParams()
  const [currentStep, setCurrentStep] = useState<EVENT_STEPS>(
    (search.get('step') as EVENT_STEPS) || EVENT_STEPS.BASIC,
  )
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState<EventExtendedDto>()
  const [publishErrors, setPublishErrors] = useState<TPublish>({})
  const [currentForm, setCurrentForm] = useState<FormInstance>()
  const { state } = useLocation()
  const { id } = useParams<{ id: string }>()
  const { appLang, getLocalizedKey } = useLanguageContext()
  const location = useLocation()
  const navigate = useNavigate()
  const { isAdmin } = useAuthContext()
  const eventContext = useEventContext()

  const isCreate = useMemo(() => id === 'new', [id])

  const allSteps = useMemo(() => {
    const stepNames = [
      EVENT_STEPS.BASIC,
      EVENT_STEPS.STAGE,
      EVENT_STEPS.SCHEDULE,
      EVENT_STEPS.TICKETS,
      EVENT_STEPS.SEAT_ASSIGNMENT,
      EVENT_STEPS.POSTERS,
    ]
    if (!eventContext.isStageDefinitionSelected) {
      stepNames.splice(4, 1)
    }
    return stepNames
  }, [eventContext])

  const pageSteps = useMemo<StepProps[]>(() => {
    const steps: StepProps[] = [
      {
        title: t`labels.Basic information`,
        status: !!publishErrors.general ? 'error' : undefined,
      },
      {
        title: t`labels.Stage`,
        disabled: isCreate,
        status: !!publishErrors.stage ? 'error' : undefined,
      },
      {
        title: t`labels.Schedule`,
        status: !!publishErrors.dates ? 'error' : undefined,
        disabled: isCreate,
      },

      {
        title: t`labels.Tickets`,
        disabled: isCreate,
        status: !!publishErrors.tickets ? 'error' : undefined,
      },
    ]
    if (eventContext.isStageDefinitionSelected) {
      const seatAssignment: StepProps = {
        title: t`labels.Seat assignment`,
        disabled: isCreate,
        status: !!publishErrors.seats ? 'error' : undefined,
      }
      steps.push(seatAssignment)
    }
    const posters: StepProps = {
      title: t`labels.Posters`,
      disabled: isCreate,
      status: !!publishErrors.posters ? 'error' : undefined,
    }
    steps.push(posters)

    // if (isAdmin) {
    //   const quotas: StepProps = {
    //     title: t`Quotas.stepTitle`,
    //     disabled: isCreate,
    //     status: !!publishErrors.quotas ? 'error' : undefined,
    //   }
    //   steps.push(quotas)
    // }

    return steps
  }, [eventContext, isCreate, publishErrors])

  useEffect(() => {
    setSearch({ step: String(currentStep) })
  }, [currentStep])

  const onChange = useCallback(
    async (value: EVENT_STEPS) => {
      if (!currentForm) {
        setCurrentStep(value)
        return
      }
      try {
        await currentForm?.validateFields()
        customStep.current = value
        currentForm?.submit()
      } catch {
        customStep.current = -1
      }
    },
    [currentForm],
  )

  const disabled = useMemo(
    () =>
      !isCreate &&
      item?.eventStatus !== EventExtendedDtoEventStatusEnum.UNPUBLISHED &&
      item?.eventStatus !== EventExtendedDtoEventStatusEnum.ANNOUNCED,
    [item, isCreate],
  )

  const canEdit = useMemo(() => {
    if (isCreate) return true
    if (isUnpublishedEvent(item) || isAnnouncedEvent(item)) return true
    return isAdmin && isPublishedEvent(item)
  }, [item, isCreate, isAdmin])

  const canEditPartner = useMemo(() => {
    if (isCreate) return true
    if (isUnpublishedEvent(item) || isPublishedEvent(item)) return true
    return isAnnouncedEvent(item)
  }, [item, isCreate, isAdmin])

  useEffect(() => {
    // TODO clear form fields programmatically
    if (currentForm?.getFieldsValue()) {
      navigate(0)
    }
    if (isCreate) {
      setLoading(false)
      setItem(undefined)
    } else {
      axiosInstance
        .get<PageEventStageDefinitionDto>(
          `admin/event-stage-definitions?query=event.id==${id}`,
        )
        .then(({ data: { content } }) => {
          if (Array.isArray(content) && content.length > 0) {
            eventContext.setIsStageDefinitionSelected(true)
            eventContext.setDefinitionString(content[0].definition ?? '')
          }
        })
      axiosInstance
        .get<EventExtendedDto>(`admin/events/${id}`)
        .then(({ data }) => {
          setItem(data)
          if (state === 'new') {
            setCurrentStep(EVENT_STEPS.STAGE)
            navigate(location.pathname, {})
          }
          setLoading(false)
        })
    }
  }, [id, isCreate])

  const refetchItem = useCallback(async () => {
    const { data } = await axiosInstance.get<EventExtendedDto>(
      `admin/events/${id}`,
    )
    setItem(data)
  }, [])

  const formattedItem = useMemo(() => {
    if (!item) return {}
    return {
      ...omitBy(item, isNil),
      actorIds: item.actors!.map((actor) => prepareOption(actor, appLang)),
      eventTypeId: prepareOption(item.eventType!, appLang),
      stageId: item.stage && prepareOption(item.stage, appLang),
      cityId: item.city && prepareOption(item?.city, appLang),
      agencyId: item.agency && prepareOption(item.agency, appLang),
      dateStart: item.dateStart ? dayjs(item.dateStart) : undefined,
      dateEnd: item.dateEnd ? dayjs(item.dateEnd) : undefined,
      saleEnd: item.saleEnd ? dayjs(item.saleEnd) : undefined,
      saleStart: item.saleStart ? dayjs(item.saleStart) : undefined,
    }
  }, [item])

  const stepForward = useCallback(
    (data?: Partial<EventExtendedDto>, reverse = false) => {
      if (data) setItem({ ...item, ...data })
      if (customStep.current === -1) {
        setCurrentStep((step) => {
          const currentIndex = allSteps.indexOf(step as EVENT_STEPS)
          return reverse
            ? allSteps[currentIndex - 1]
            : allSteps[currentIndex + 1]
        })
      } else {
        setCurrentStep(customStep.current)
        customStep.current = -1
      }
    },
    [item, allSteps],
  )

  const title = useMemo(
    () => (
      <div>
        <div>
          {isCreate ? t`create` : t`edit`} {t`labels.Event`}
        </div>
        <div>
          <Tag color="#108ee9">{isCreate ? t`new` : item?.eventStatus}</Tag>
        </div>
      </div>
    ),
    [isCreate, item],
  )
  const subtitle = useMemo(
    () => (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {item?.tour && (
            <Link to={`/app/tour/${item.tour.id}`}>
              {t('labels.eventPartOfTour', {
                tour: getLocalizedKey('name', item.tour),
              })}
            </Link>
          )}
          {getLocalizedKey('name', item)}
        </div>
        {item?.dateStart
          ? `  (${dayjs(item?.dateStart).format('YYYY-MM-DD HH:mm:ss')})`
          : ''}
      </>
    ),
    [item],
  )

  const handlePublishLogic = useCallback(async () => {
    try {
      setPublishErrors({})
      await axiosInstance.post(`admin/events/publish/${id}`)
      setItem((old) => ({ ...old, eventStatus: EventStatusEnum.PUBLISHED }))
    } catch (e) {
      if (axios.isAxiosError(e)) setPublishErrors(e.response?.data)
    }
  }, [id])

  const handlePreviewLogic = useCallback(async () => {
    try {
      setPublishErrors({})
      let event: EventExtendedDto | undefined
      if (item?.eventStatus === EventStatusEnum.UNPUBLISHED) {
        const { data } = await axiosInstance.post<EventPreviewDto>(
          `admin/events/preview/${id}`,
        )
        event = data.event
      }
      let queryPart = ''
      if (event?.lockId) {
        queryPart = `?lockId=${event.lockId}`
      }
      window.open(
        `${process.env.REACT_APP_USERS_FE_URL}/event/${id}${queryPart}`,
        '_blank',
      )
    } catch (e) {
      if (axios.isAxiosError(e))
        setPublishErrors(e.response?.data?.publicationErrors)
    }
  }, [id, item?.eventStatus])

  const handleAnnounceLogic = useCallback(async () => {
    Modal.confirm({
      title: t`messages.announceTry`,
      onOk: async () => {
        try {
          setPublishErrors({})
          await axiosInstance.post(`admin/events/announce/${id}`)
          setItem((old) => ({ ...old, eventStatus: EventStatusEnum.ANNOUNCED }))
        } catch (e) {
          if (axios.isAxiosError(e)) setPublishErrors(e.response?.data)
        }
      },
    })
  }, [id, t])

  const handleUnpublishLogic = useCallback(async () => {
    Modal.confirm({
      title: t`messages.unpublishTry`,
      onOk: async () => {
        try {
          setPublishErrors({})
          await axiosInstance.post(`admin/events/unpublish/${id}`)
          setItem((old) => ({ ...old, eventStatus: EventStatusEnum.ANNOUNCED }))
        } catch (e) {
          if (axios.isAxiosError(e)) setPublishErrors(e.response?.data)
        }
      },
    })
  }, [id, t])

  const handleDeleteEvent = useCallback(() => {
    Modal.confirm({
      title: t`messages.tryDeleteEvent`,
      onOk: async () => {
        await axiosInstance.delete('admin/events/' + id)
        navigate('/app/events_unpublished')
      },
    })
  }, [id, t])

  const handleCancelEvent = useCallback(() => {
    Modal.confirm({
      title: t`messages.tryCancelEvent`,
      onOk: async () => {
        await axiosInstance.post(`admin/events/cancel/${id}`)
        setItem((old) => ({ ...old, eventStatus: EventStatusEnum.CANCELED }))
      },
    })
  }, [id, t])

  const goToManagement = useCallback(() => {
    Modal.confirm({
      title: t`messages.tryGoEventManagement`,
      onOk: async () => {
        try {
          await currentForm?.validateFields()
          currentForm?.submit()
          toast.success(t`messages.changesSaved`)
          navigate(`/app/event/${id}/manage`)
        } catch {}
      },
    })
  }, [id, currentForm, t])

  return (
    <>
      <PageHeader
        title={title}
        subTitle={subtitle}
        extra={
          <Space wrap>
            <Button
              type="primary"
              icon={<LinkOutlined />}
              onClick={goToManagement}
            >
              {t`labels.Go to Ticket Management`}
            </Button>
            {isUnpublishedEvent(item) && (
              <Button danger onClick={handleDeleteEvent}>
                {t`delete`}
              </Button>
            )}
            {isUnpublishedEvent(item) && (
              <Button key="announce" onClick={handleAnnounceLogic}>
                {t`labels.Announce`}
              </Button>
            )}
            {isAnnouncedEvent(item) && (
              <Button key="unpublish" onClick={handleUnpublishLogic}>
                {t`labels.Unpublish`}
              </Button>
            )}
            {isPublishedEvent(item) && isAdmin && (
              <Button key="cancel" onClick={handleCancelEvent}>
                {t`cancel`}
              </Button>
            )}
            <Button
              key="publish"
              onClick={handlePublishLogic}
              disabled={
                item?.eventStatus !== EventStatusEnum.UNPUBLISHED &&
                item?.eventStatus !== EventStatusEnum.ANNOUNCED
              }
            >
              {t`labels.Publish`}
            </Button>
            <Button key="preview" onClick={handlePreviewLogic}>
              {t`labels.Preview`}
            </Button>
          </Space>
        }
      />
      <Page inner loading={loading} className={styles.page}>
        <Steps
          current={allSteps.indexOf(currentStep)}
          onChange={(i) => onChange(allSteps[i])}
          items={pageSteps}
        />
        <div
          className={cn(
            styles.pageWrapper,
            currentStep !== EVENT_STEPS.SEAT_ASSIGNMENT &&
              styles.pageFixedWidth,
          )}
        >
          {currentStep === EVENT_STEPS.BASIC && !loading && (
            <StepBasicInformation
              onSuccess={stepForward}
              item={formattedItem}
              setForm={setCurrentForm}
              isCreate={isCreate}
              disabled={disabled}
              errors={publishErrors.general}
              canEdit={canEdit}
            />
          )}
          {currentStep === EVENT_STEPS.SCHEDULE && !loading && (
            <StepSchedule
              disabled={disabled}
              onSuccess={stepForward}
              item={formattedItem!}
              setForm={setCurrentForm}
              errors={publishErrors.dates}
            />
          )}
          {currentStep === EVENT_STEPS.STAGE && !loading && (
            <StepStage
              disabled={disabled}
              onSuccess={stepForward}
              item={formattedItem!}
              setForm={setCurrentForm}
              errors={publishErrors.stage}
              refetchItem={refetchItem}
            />
          )}
          {currentStep === EVENT_STEPS.TICKETS && !loading && (
            <StepTickets
              disabled={disabled}
              onSuccess={stepForward}
              item={formattedItem!}
              setForm={setCurrentForm}
              errors={publishErrors.tickets}
              canEdit={canEditPartner}
            />
          )}
          {currentStep === EVENT_STEPS.SEAT_ASSIGNMENT && !loading && (
            <StepSeatAssignment
              disabled={disabled}
              canEdit={canEditPartner}
              onSuccess={stepForward}
              item={formattedItem!}
              setForm={setCurrentForm}
              errors={publishErrors.seats}
            />
          )}
          {currentStep === EVENT_STEPS.POSTERS && !loading && (
            <StepPoster
              disabled={disabled}
              onSuccess={stepForward}
              item={formattedItem!}
              setForm={setCurrentForm}
              errors={publishErrors.posters}
              canEdit={canEdit}
            />
          )}
          {/*{currentStep === 'quotas' && !loading && (*/}
          {/*  <StepQuotas*/}
          {/*    disabled={disabled}*/}
          {/*    onSuccess={stepForward}*/}
          {/*    item={formattedItem!}*/}
          {/*    setForm={setCurrentForm}*/}
          {/*    errors={publishErrors.posters}*/}
          {/*    canEdit={canEdit}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </Page>
    </>
  )
}

export default Event
