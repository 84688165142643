import { ActorTypeDto } from 'openapi'
import AsyncSelect from '../index'
import { fetchPageableEntity } from '@/utils/fechPageableEntity'
import { Form, FormItemProps } from 'antd'
import { prepareOption } from '../types'
import { useTranslation } from 'react-i18next'

type Props = {
  submit?: (v: string) => void
  withAll?: boolean
  noLabel?: boolean
  fullWidth?: boolean
} & FormItemProps

export const ActorTypeAsyncSelect = ({
  submit,
  withAll = true,
  name = 'actorType',
  noLabel,
  fullWidth,
  ...rest
}: Props) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      initialValue={withAll ? 'All' : undefined}
      style={{ width: fullWidth ? '100%' : '200px', marginRight: 16 }}
      {...rest}
    >
      <AsyncSelect<ActorTypeDto>
        label={noLabel ? undefined : t`labels.Actor type`}
        prepareOption={prepareOption}
        onChange={(v) => submit?.(v)}
        withAll={withAll}
        getData={fetchPageableEntity<ActorTypeDto>('admin/actor-types')}
      />
    </Form.Item>
  )
}
