import { SelectOption } from '@/types/Option'
import { EventCreateRequestLanguagesEnum } from '@/openapi'
import LanguagesEnum = EventCreateRequestLanguagesEnum

export const prepareOption = (
  item?: any,
  currentLang?: LanguagesEnum,
): SelectOption => {
  return {
    label: String(item[`name${currentLang}`] || item.name),
    value: String(item.id),
  }
}
