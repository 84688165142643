/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CountryCreateResponse } from './country-create-response';

/**
 * 
 * @export
 * @interface TicketControlPersonCreateResponse
 */
export interface TicketControlPersonCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TicketControlPersonCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonCreateResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonCreateResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketControlPersonCreateResponse
     */
    'status'?: TicketControlPersonCreateResponseStatusEnum;
    /**
     * 
     * @type {CountryCreateResponse}
     * @memberof TicketControlPersonCreateResponse
     */
    'country'?: CountryCreateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketControlPersonCreateResponseStatusEnum {
    CREATED = 'created',
    APPROVED = 'approved',
    BLOCKED = 'blocked'
}


