import React, { useEffect, useState } from 'react'
import { FormInstance } from 'antd/es/form/hooks/useForm'
import FloatTextarea from '@components/FloatTextarea'
import { SegmentedMessage } from 'sms-segments-calculator'
import FormItem from '../FormItem'
import styles from './styles.module.css'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
type Props = {
  placeholder?: string
  form: FormInstance
  name: string
  readOnly?: boolean
  disabled?: boolean
  label: string
}

const SmsCalculator: React.FC<Props> = ({
  form,
  name,
  readOnly = false,
  label,
}) => {
  const [message, setMessage] = useState<SegmentedMessage | undefined>()
  const watchValue = Form.useWatch(name, form)
  const { t } = useTranslation()

  useEffect(() => {
    if (watchValue) setMessage(new SegmentedMessage(watchValue))
    else setMessage(undefined)
  }, [watchValue])

  return (
    <div className={styles.wrapper}>
      <FormItem
        name="text"
        hasFeedback
        rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('addLink') && !value.includes('{{link}}')) {
                return Promise.reject(new Error('Text {{link}} is missed'))
              }
              return Promise.resolve()
            },
          }),
        ]}
        disabled={readOnly}
      >
        <FloatTextarea label={label} disabled={readOnly} required />
      </FormItem>
      {message?.numberOfCharacters && (
        <div className={styles.textWrapper}>
          <div>
            {t`labels.Encoding used`}
            <span className={styles.bold}>{message?.getEncodingName()}</span>
          </div>
          <div>
            {t`labels.Number of segments`}
            <span className={styles.bold} style={{ fontSize: '16px' }}>
              {message?.segments.length}
            </span>
          </div>
          <div>
            {t`labels.Number of characters`}
            <span className={styles.bold}>{message?.numberOfCharacters}</span>
          </div>
          <div>
            {t`labels.Number of Unicode scalars`}
            <span className={styles.bold}>
              {message?.numberOfUnicodeScalars}
            </span>
          </div>
          <div>
            {t`labels.Message size`}
            <span className={styles.bold}>{message?.messageSize}</span>
          </div>
          <div>
            {t`labels.Total size sent`}
            <span className={styles.bold}>{message?.totalSize}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default SmsCalculator
