/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventCreateRequest } from '../models';
// @ts-ignore
import { EventCreateResponse } from '../models';
// @ts-ignore
import { EventExtendedDto } from '../models';
// @ts-ignore
import { EventPreviewDto } from '../models';
// @ts-ignore
import { EventStep1UpdateRequest } from '../models';
// @ts-ignore
import { EventStep2UpdateRequest } from '../models';
// @ts-ignore
import { EventStep3UpdateRequest } from '../models';
// @ts-ignore
import { EventStep4UpdateRequest } from '../models';
// @ts-ignore
import { EventUpdateResponse } from '../models';
// @ts-ignore
import { PageAudit } from '../models';
// @ts-ignore
import { PageEventDto } from '../models';
// @ts-ignore
import { PageEventExtendedDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * EventsAdminControllerApi - axios parameter creator
 * @export
 */
export const EventsAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announceEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('announceEvent', 'eventId', eventId)
            const localVarPath = `/v1/admin/events/announce/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<EventCreateRequest>} eventCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate24: async (eventCreateRequest: Array<EventCreateRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventCreateRequest' is not null or undefined
            assertParamExists('bulkCreate24', 'eventCreateRequest', eventCreateRequest)
            const localVarPath = `/v1/admin/events/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('cancelEvent', 'eventId', eventId)
            const localVarPath = `/v1/admin/events/cancel/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {ChangeEventFilterTypeFilterTypeEnum} filterType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEventFilterType: async (eventId: number, filterType: ChangeEventFilterTypeFilterTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('changeEventFilterType', 'eventId', eventId)
            // verify required parameter 'filterType' is not null or undefined
            assertParamExists('changeEventFilterType', 'filterType', filterType)
            const localVarPath = `/v1/admin/events/add-filter-type/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterType !== undefined) {
                localVarQueryParameter['filterType'] = filterType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventCreateRequest} eventCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create28: async (eventCreateRequest: EventCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventCreateRequest' is not null or undefined
            assertParamExists('create28', 'eventCreateRequest', eventCreateRequest)
            const localVarPath = `/v1/admin/events/step1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById25: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById25', 'id', id)
            const localVarPath = `/v1/admin/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep1: async (eventId: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('editStep1', 'eventId', eventId)
            // verify required parameter 'eventStep1UpdateRequest' is not null or undefined
            assertParamExists('editStep1', 'eventStep1UpdateRequest', eventStep1UpdateRequest)
            const localVarPath = `/v1/admin/events/step1/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventStep1UpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep2UpdateRequest} eventStep2UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep2: async (eventId: number, eventStep2UpdateRequest: EventStep2UpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('editStep2', 'eventId', eventId)
            // verify required parameter 'eventStep2UpdateRequest' is not null or undefined
            assertParamExists('editStep2', 'eventStep2UpdateRequest', eventStep2UpdateRequest)
            const localVarPath = `/v1/admin/events/step2/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventStep2UpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep3UpdateRequest} eventStep3UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep3: async (eventId: number, eventStep3UpdateRequest: EventStep3UpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('editStep3', 'eventId', eventId)
            // verify required parameter 'eventStep3UpdateRequest' is not null or undefined
            assertParamExists('editStep3', 'eventStep3UpdateRequest', eventStep3UpdateRequest)
            const localVarPath = `/v1/admin/events/step3/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventStep3UpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep4UpdateRequest} eventStep4UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep4: async (eventId: number, eventStep4UpdateRequest: EventStep4UpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('editStep4', 'eventId', eventId)
            // verify required parameter 'eventStep4UpdateRequest' is not null or undefined
            assertParamExists('editStep4', 'eventStep4UpdateRequest', eventStep4UpdateRequest)
            const localVarPath = `/v1/admin/events/step4/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventStep4UpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find61: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find61', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended24: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findExtended24', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/events/extended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById46: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById46', 'id', id)
            const localVarPath = `/v1/admin/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount51: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/events/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit24: async (pageable: Pageable, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getEntityAudit24', 'pageable', pageable)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit24', 'id', id)
            const localVarPath = `/v1/admin/events/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties24: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/events/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('previewEvent', 'eventId', eventId)
            const localVarPath = `/v1/admin/events/preview/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessTranslations24ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations24FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations24: async (toLanguage: ProcessTranslations24ToLanguageEnum, fromLanguage?: ProcessTranslations24FromLanguageEnum, forceTranslate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toLanguage' is not null or undefined
            assertParamExists('processTranslations24', 'toLanguage', toLanguage)
            const localVarPath = `/v1/admin/events/i18n/from/{fromLanguage}/to/{toLanguage}`
                .replace(`{${"toLanguage"}}`, encodeURIComponent(String(toLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromLanguage !== undefined) {
                localVarQueryParameter['fromLanguage'] = fromLanguage;
            }

            if (forceTranslate !== undefined) {
                localVarQueryParameter['forceTranslate'] = forceTranslate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('publishEvent', 'eventId', eventId)
            const localVarPath = `/v1/admin/events/publish/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull24: async (id: number, properties: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPropertiesToNull24', 'id', id)
            // verify required parameter 'properties' is not null or undefined
            assertParamExists('setPropertiesToNull24', 'properties', properties)
            const localVarPath = `/v1/admin/events/{id}/property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (properties) {
                localVarQueryParameter['properties'] = Array.from(properties);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('unpublishEvent', 'eventId', eventId)
            const localVarPath = `/v1/admin/events/unpublish/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} aLong 
         * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update24: async (aLong: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aLong' is not null or undefined
            assertParamExists('update24', 'aLong', aLong)
            // verify required parameter 'eventStep1UpdateRequest' is not null or undefined
            assertParamExists('update24', 'eventStep1UpdateRequest', eventStep1UpdateRequest)
            const localVarPath = `/v1/admin/events/{id}`
                .replace(`{${"aLong"}}`, encodeURIComponent(String(aLong)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventStep1UpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsAdminControllerApi - functional programming interface
 * @export
 */
export const EventsAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announceEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announceEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<EventCreateRequest>} eventCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreate24(eventCreateRequest: Array<EventCreateRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventCreateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreate24(eventCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {ChangeEventFilterTypeFilterTypeEnum} filterType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEventFilterType(eventId: number, filterType: ChangeEventFilterTypeFilterTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEventFilterType(eventId, filterType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EventCreateRequest} eventCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create28(eventCreateRequest: EventCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create28(eventCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById25(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById25(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editStep1(eventId: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editStep1(eventId, eventStep1UpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep2UpdateRequest} eventStep2UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editStep2(eventId: number, eventStep2UpdateRequest: EventStep2UpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editStep2(eventId, eventStep2UpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep3UpdateRequest} eventStep3UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editStep3(eventId: number, eventStep3UpdateRequest: EventStep3UpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editStep3(eventId, eventStep3UpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep4UpdateRequest} eventStep4UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editStep4(eventId: number, eventStep4UpdateRequest: EventStep4UpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editStep4(eventId, eventStep4UpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find61(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find61(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExtended24(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEventExtendedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExtended24(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById46(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventExtendedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById46(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount51(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount51(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit24(pageable: Pageable, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit24(pageable, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nSupportedProperties24(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nSupportedProperties24(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventPreviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessTranslations24ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations24FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTranslations24(toLanguage: ProcessTranslations24ToLanguageEnum, fromLanguage?: ProcessTranslations24FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTranslations24(toLanguage, fromLanguage, forceTranslate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPropertiesToNull24(id: number, properties: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPropertiesToNull24(id, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpublishEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} aLong 
         * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update24(aLong: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update24(aLong, eventStep1UpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsAdminControllerApi - factory interface
 * @export
 */
export const EventsAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announceEvent(eventId: number, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.announceEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<EventCreateRequest>} eventCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate24(eventCreateRequest: Array<EventCreateRequest>, options?: any): AxiosPromise<Array<EventCreateResponse>> {
            return localVarFp.bulkCreate24(eventCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelEvent(eventId: number, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.cancelEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {ChangeEventFilterTypeFilterTypeEnum} filterType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEventFilterType(eventId: number, filterType: ChangeEventFilterTypeFilterTypeEnum, options?: any): AxiosPromise<EventUpdateResponse> {
            return localVarFp.changeEventFilterType(eventId, filterType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventCreateRequest} eventCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create28(eventCreateRequest: EventCreateRequest, options?: any): AxiosPromise<EventCreateResponse> {
            return localVarFp.create28(eventCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById25(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById25(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep1(eventId: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options?: any): AxiosPromise<EventUpdateResponse> {
            return localVarFp.editStep1(eventId, eventStep1UpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep2UpdateRequest} eventStep2UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep2(eventId: number, eventStep2UpdateRequest: EventStep2UpdateRequest, options?: any): AxiosPromise<EventUpdateResponse> {
            return localVarFp.editStep2(eventId, eventStep2UpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep3UpdateRequest} eventStep3UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep3(eventId: number, eventStep3UpdateRequest: EventStep3UpdateRequest, options?: any): AxiosPromise<EventUpdateResponse> {
            return localVarFp.editStep3(eventId, eventStep3UpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {EventStep4UpdateRequest} eventStep4UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStep4(eventId: number, eventStep4UpdateRequest: EventStep4UpdateRequest, options?: any): AxiosPromise<EventUpdateResponse> {
            return localVarFp.editStep4(eventId, eventStep4UpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find61(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageEventDto> {
            return localVarFp.find61(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended24(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageEventExtendedDto> {
            return localVarFp.findExtended24(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById46(id: number, options?: any): AxiosPromise<EventExtendedDto> {
            return localVarFp.getById46(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount51(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount51(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit24(pageable: Pageable, id: number, options?: any): AxiosPromise<PageAudit> {
            return localVarFp.getEntityAudit24(pageable, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties24(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getI18nSupportedProperties24(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewEvent(eventId: number, options?: any): AxiosPromise<EventPreviewDto> {
            return localVarFp.previewEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessTranslations24ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations24FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations24(toLanguage: ProcessTranslations24ToLanguageEnum, fromLanguage?: ProcessTranslations24FromLanguageEnum, forceTranslate?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processTranslations24(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishEvent(eventId: number, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.publishEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull24(id: number, properties: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPropertiesToNull24(id, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishEvent(eventId: number, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.unpublishEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} aLong 
         * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update24(aLong: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options?: any): AxiosPromise<EventUpdateResponse> {
            return localVarFp.update24(aLong, eventStep1UpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsAdminControllerApi - object-oriented interface
 * @export
 * @class EventsAdminControllerApi
 * @extends {BaseAPI}
 */
export class EventsAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public announceEvent(eventId: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).announceEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<EventCreateRequest>} eventCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public bulkCreate24(eventCreateRequest: Array<EventCreateRequest>, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).bulkCreate24(eventCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public cancelEvent(eventId: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).cancelEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {ChangeEventFilterTypeFilterTypeEnum} filterType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public changeEventFilterType(eventId: number, filterType: ChangeEventFilterTypeFilterTypeEnum, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).changeEventFilterType(eventId, filterType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventCreateRequest} eventCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public create28(eventCreateRequest: EventCreateRequest, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).create28(eventCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public deleteById25(id: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).deleteById25(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public editStep1(eventId: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).editStep1(eventId, eventStep1UpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {EventStep2UpdateRequest} eventStep2UpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public editStep2(eventId: number, eventStep2UpdateRequest: EventStep2UpdateRequest, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).editStep2(eventId, eventStep2UpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {EventStep3UpdateRequest} eventStep3UpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public editStep3(eventId: number, eventStep3UpdateRequest: EventStep3UpdateRequest, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).editStep3(eventId, eventStep3UpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {EventStep4UpdateRequest} eventStep4UpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public editStep4(eventId: number, eventStep4UpdateRequest: EventStep4UpdateRequest, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).editStep4(eventId, eventStep4UpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public find61(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).find61(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public findExtended24(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).findExtended24(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public getById46(id: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).getById46(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public getCount51(query?: string, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).getCount51(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public getEntityAudit24(pageable: Pageable, id: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).getEntityAudit24(pageable, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public getI18nSupportedProperties24(options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).getI18nSupportedProperties24(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public previewEvent(eventId: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).previewEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessTranslations24ToLanguageEnum} toLanguage 
     * @param {ProcessTranslations24FromLanguageEnum} [fromLanguage] 
     * @param {boolean} [forceTranslate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public processTranslations24(toLanguage: ProcessTranslations24ToLanguageEnum, fromLanguage?: ProcessTranslations24FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).processTranslations24(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public publishEvent(eventId: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).publishEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} properties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public setPropertiesToNull24(id: number, properties: Array<string>, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).setPropertiesToNull24(id, properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public unpublishEvent(eventId: number, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).unpublishEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} aLong 
     * @param {EventStep1UpdateRequest} eventStep1UpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsAdminControllerApi
     */
    public update24(aLong: number, eventStep1UpdateRequest: EventStep1UpdateRequest, options?: AxiosRequestConfig) {
        return EventsAdminControllerApiFp(this.configuration).update24(aLong, eventStep1UpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ChangeEventFilterTypeFilterTypeEnum {
    REGULAR = 'regular',
    PROMOTION = 'promotion',
    HIDDEN = 'hidden'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations24ToLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations24FromLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
