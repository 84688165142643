import { Button, Card, Divider, Modal, Space, Switch, Tag } from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles.module.less'
import { axiosInstance } from 'api'
import dayjs from 'dayjs'
import { EventContentArg } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import AddTimeForm from '@pages/tour/components/addTimeForm'
import EditEvent from '@pages/tour/components/editEvent'
import { EventTourViewDto, TourExtendedDto } from '@/openapi'
import cn from 'classnames'
import { EventInfo } from '@pages/tour/components/EventInfo'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useTranslation } from 'react-i18next'
import { isAnnouncedEvent, isUnpublishedEvent } from '@/utils/eventsStatuses'

export const dateformat = (date?: string) => {
  if (!date) {
    return (
      <>
        <div>{'—'}</div>
      </>
    )
  } else {
    return <div>{dayjs(date).format('HH:mm:ss')}</div>
  }
}

const prepareEvent = (e: EventTourViewDto) => ({
  title: e.name,
  date: e.dateStart,
  id: String(e.id),
  editable: isUnpublishedEvent(e) || isAnnouncedEvent(e),
})

export type Props = {
  item?: TourExtendedDto
  events?: EventTourViewDto[]
  refetch: () => void
}

const colors: Record<string, string> = {
  archived: '#f50',
  published: '#87d068',
  unpublished: '#108ee9',
  announced: '#2db7f5',
}

const CalendarTab = ({ item, events, refetch }: Props) => {
  const draggableEvents = useRef<Draggable | null>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [addTimeEvent, setAddTimeEvent] = useState<
    EventTourViewDto | undefined
  >()
  const [editEvent, setEditEvent] = useState<EventTourViewDto | undefined>()
  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setEditEvent(undefined)
    setAddTimeEvent(undefined)
  }, [])
  const { getLocalizedKey } = useLanguageContext()
  const { t } = useTranslation()

  const [rowView, setRowView] = useState(false)

  const renderEventContent = useCallback(
    (eventInfo: EventContentArg) => {
      const event = events?.find((e) => e.id === +(eventInfo.event.id || 0))
      return (
        <Tag
          color={colors[event?.eventStatus || 'unpublished']}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: 0,
            minHeight: '50px',
          }}
          data-id={eventInfo.event.id}
        >
          <div>{dateformat(eventInfo.event.startStr)}</div>
          <div>{eventInfo.event.title}</div>
          <div>{event?.city?.name}</div>
          <EventInfo event={event!} />
        </Tag>
      )
    },
    [events],
  )

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refetch()
    setAddTimeEvent(undefined)
    setEditEvent(undefined)
  }, [])

  const calendarEvents = useMemo(
    () => events?.filter(({ dateStart }) => !!dateStart) || [],
    [events],
  )

  const otherEvents = useMemo(
    () => events?.filter(({ dateStart }) => !dateStart) || [],
    [events],
  )

  const handleAddEvent = useCallback(() => {
    setEditEvent({})
    setIsOpenModal(true)
  }, [])

  useEffect(() => {
    const container = document.querySelector(
      '.draggableContainer',
    ) as HTMLElement
    if (!container) return
    draggableEvents.current?.destroy()
    draggableEvents.current = new Draggable(container, {
      itemSelector: '.draggable',
      eventData: function (eventEl) {
        let id = eventEl.dataset.id
        let title = eventEl.getAttribute('title')
        let color = eventEl.dataset.color
        let custom = eventEl.dataset.custom

        return {
          id: id,
          title: title,
          color: color,
          custom: custom,
          create: true,
        }
      },
    })

    return () => draggableEvents.current?.destroy()
  }, [otherEvents])

  const initialDate = useMemo(
    () =>
      calendarEvents.sort((a, b) =>
        dayjs(a.dateStart).isAfter(dayjs(b.dateStart)) ? 1 : -1,
      )[0]?.dateStart,
    [calendarEvents],
  )

  return (
    <Card style={{ marginTop: '15px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '25px',
        }}
      >
        <div>
          <h4>{t`labels.Legend`}</h4>
          <Space>
            <Tag color={colors.unpublished}>{t`labels.Unpublished`}</Tag>
            <Tag color={colors.announced}>{t`labels.Announced`}</Tag>
            <Tag color={colors.published}>{t`labels.Published`}</Tag>
            <Tag color={colors.archived}>{t`labels.Archived`}</Tag>
          </Space>
        </div>
        <Switch
          checkedChildren={t`labels.Row view`}
          unCheckedChildren={t`labels.Column view`}
          checked={rowView}
          onChange={() => setRowView(!rowView)}
        />
      </div>
      <Divider />
      <div className={cn(styles.calendarGrid, rowView && styles.rowView)}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: '',
          }}
          initialView="dayGridMonth"
          initialDate={initialDate}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          events={calendarEvents?.map(prepareEvent) || []}
          eventContent={renderEventContent}
          firstDay={1}
          droppable
          dragScroll
          eventDrop={async (e) => {
            const id = e.event.id
            if (dayjs(e.event.startStr).isAfter(dayjs())) {
              await axiosInstance.put(`admin/events/step2/${id}`, {
                id,
                dateStart: e.event.startStr.slice(0, -6),
              })
              refetch()
            } else {
              Modal.error({
                title: t`error`,
                content: t`text.event.onlyFutureDates`,
              })
              e.revert()
            }
          }}
          eventReceive={async (e) => {
            e.revert()
          }}
          drop={(e) => {
            const id = e.draggedEl.dataset.id
            const event = events?.find((e) => e.id === +(id || 0))
            setAddTimeEvent({
              ...event,
              dateStart: e.dateStr,
            })
            setIsOpenModal(true)
          }}
          eventClick={(e) => {
            const id = e.event.id
            const event = events?.find((e) => e.id === +(id || 0))
            if (isAnnouncedEvent(event) || isUnpublishedEvent(event)) {
              setEditEvent(event)
              setIsOpenModal(true)
            }
          }}
        />
        <div>
          <Button
            type="primary"
            onClick={handleAddEvent}
            style={{ marginBottom: '20px' }}
          >
            {t`labels.Add event`}
          </Button>
          <div className={cn('draggableContainer', styles.draggableContainer)}>
            {otherEvents.map((ev) => (
              <div
                key={ev.id}
                className="draggable"
                data-id={ev.id}
                onClick={() => {
                  setEditEvent(ev)
                  setIsOpenModal(true)
                }}
                style={{ cursor: 'grabbing' }}
              >
                <Tag color="#1677ff" style={{ whiteSpace: 'pre-wrap' }}>
                  {item?.actors
                    ?.map((a) => getLocalizedKey('name', a))
                    .toString()}
                  {' - '}
                  {ev.city?.name}
                  {ev.city?.name}
                </Tag>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        open={isOpenModal}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="400px"
        title={
          editEvent && editEvent.id ? t`labels.Edit Event` : t`labels.Add event`
        }
      >
        {addTimeEvent && (
          <AddTimeForm onSuccess={handleSuccess} event={addTimeEvent} />
        )}
        {editEvent && (
          <EditEvent tour={item} id={editEvent.id} onSuccess={handleSuccess} />
        )}
      </Modal>
    </Card>
  )
}

export default CalendarTab
