/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LoginCredentialsCreateRequest
 */
export interface LoginCredentialsCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsCreateRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsCreateRequest
     */
    'password'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginCredentialsCreateRequest
     */
    'roles'?: Array<LoginCredentialsCreateRequestRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof LoginCredentialsCreateRequest
     */
    'agencyId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginCredentialsCreateRequestRolesEnum {
    USER = 'user',
    GUEST = 'guest',
    ADMIN = 'admin',
    PARTNER = 'partner'
}


