import {
  IAuthTokens,
  clearAuthTokens,
  applyAuthTokenInterceptor,
  getAccessToken,
  setAuthTokens,
  getRefreshToken,
} from 'axios-jwt'
import axios from 'axios'
import { toast } from 'react-toastify'
import { LoginResponse } from '@/openapi'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const axiosInstance = axios.create({ baseURL: BASE_URL })

const requestRefresh = async (refreshToken: string): Promise<IAuthTokens> => {
  try {
    const response = await axios.post(`${BASE_URL}refresh-access`, {
      refreshToken: refreshToken,
      accessToken: getAccessToken(),
    })

    return {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
    }
  } catch (e) {
    clearAuthTokens()
    if (!window.location.pathname.includes('/login')) {
      localStorage.setItem(
        'path',
        window.location.pathname + window.location.search,
      )
      window.location.pathname = '/login'
    }

    throw e
  }
}

applyAuthTokenInterceptor(axiosInstance, { requestRefresh })
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = getRefreshToken()
      if (!refreshToken) {
        return Promise.reject(error)
      }
      const access_token = await requestRefresh(refreshToken)
      setAuthTokens(access_token)
      return axiosInstance(originalRequest)
    } else if (error.response.status === 403) {
      clearAuthTokens()
      if (!window.location.pathname.includes('/login')) {
        window.location.pathname = '/login'
      }

      return
    }
    if (!originalRequest.headers.noGeneralError) {
      toast.error(
        `Something went wrong! \nRequest was failed with status ${error?.response?.status} and message "${error?.response?.data?.message}"`,
      )
    }

    return Promise.reject(error)
  },
)
axiosInstance.interceptors.request.use(async (config) => {
  if (!!config.apiVersion) {
    config.baseURL = config.baseURL?.replace('v1', `v${config.apiVersion}`)
  }
  // @ts-ignore
  config.headers!['tickets-language'] =
    JSON.parse(localStorage.getItem('lang')!) || 'EN'
  if (!getAccessToken()) {
    const {
      data: { accessToken, refreshToken },
    } = await axios.post<LoginResponse>(BASE_URL + 'guest-login')
    setAuthTokens({
      accessToken: accessToken!,
      refreshToken: refreshToken!,
    })
  }
  return config
})
