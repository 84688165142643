import { useMemo } from 'react'
import { Form, FormInstance } from 'antd'
import FloatInput, { FloatInputProps } from '../FloatInput'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { FormItemProps } from 'antd/es'
import MultiLanguageInputBase from '@components/MultiLanguageInputBase'
import { castType } from '@/utils/cast'
import { EventCreateResponseLanguagesEnum } from '@/openapi'

type MultiLanguageInputProps<T> = {
  itemProps: FormItemProps
  inputProps: FloatInputProps
  languageFields: string[]
  form: FormInstance<T>
}

const MultiLanguageInput = <T,>({
  itemProps,
  inputProps,
  languageFields,
  form,
}: MultiLanguageInputProps<T>) => {
  const { languages } = useLanguageContext()

  const name = itemProps.name as string
  const isMultiLanguage = useMemo(
    () => languageFields.includes(name),
    [name, languages],
  )

  if (!isMultiLanguage) {
    return (
      <Form.Item {...itemProps}>
        <FloatInput {...inputProps} />
      </Form.Item>
    )
  }

  return (
    <MultiLanguageInputBase
      languages={castType<EventCreateResponseLanguagesEnum[]>(languages)}
      inputProps={inputProps}
      itemProps={itemProps}
      form={form}
    />
  )
}

export default MultiLanguageInput
