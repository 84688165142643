import { Form } from 'antd'
import Page from 'components/Page'
import {
  MarketingSalesStatisticsView,
  PageImplMarketingSalesStatisticsView,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import commonStyles from 'pages/commonStyles.module.less'
import { percentFormat, roundToNearestFiveCents } from '@pages/helpers'
import { useAntdTable } from 'ahooks'
import { Params } from '@/types/Params'
import {
  DownloadReportButton,
  useLastParams,
} from '@pages/reports/shared/DownloadReportButton'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '@/contexts/AuthContext'
import FloatInput from '@components/FloatInput'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

const MarketingReport = () => {
  const [form] = Form.useForm()
  const { id: eventId } = useParams<{ id: string }>()
  const { getSort, setLastSort } = useLastParams()
  const { isAdmin } = useAuthContext()
  const { t } = useTranslation()
  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<MarketingSalesStatisticsView>
    },
    filters: { [key in keyof MarketingSalesStatisticsView]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    query.push(`eventId==${eventId}`)
    if (filters.utmLink) {
      query.push(`utmLink~~${filters.utmLink}`)
    }
    params.query = query.join(';')

    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
      setLastSort({ sort: params.sort })
    } else {
      setLastSort(null)
    }
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageImplMarketingSalesStatisticsView>(
      'admin/statistics/marketing/view',
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    form,
    defaultPageSize: 10,
  })

  const columns: ExtendedColumns<MarketingSalesStatisticsView>[] = [
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.UTM Link`}</div>
          <Form.Item name="utmLink">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'utmLink',
      key: 'utmLink',
      sorter: true,
      shortTitle: t`labels.UTM Link`,
    },
    {
      title: t`labels.Number of guest`,
      dataIndex: 'numberOfGuests',
      key: 'numberOfGuests',
      sorter: true,
    },
    {
      title: t`labels.Unique users`,
      dataIndex: 'numberOfUsers',
      key: 'numberOfUsers',
      sorter: true,
    },
    {
      title: t`labels.users_percent`,
      dataIndex: 'percentOfUsers',
      key: 'percentOfUsers',
      sorter: true,
      render: percentFormat,
    },
    {
      title: t`labels.Number of buyers`,
      dataIndex: 'numberOfBuyers',
      key: 'numberOfBuyers',
      sorter: true,
    },
    {
      title: t`labels.buyers_percent`,
      dataIndex: 'percentOfBuyers',
      key: 'percentOfBuyers',
      sorter: true,
      render: percentFormat,
    },
    {
      title: t`labels.Sold Tickets`,
      dataIndex: 'soldTickets',
      key: 'soldTickets',
      sorter: true,
    },
    {
      title: t`labels.Tickets per user`,
      dataIndex: 'ticketsPerBuyer',
      key: 'ticketsPerBuyer',
      sorter: true,
      render: roundToNearestFiveCents,
    },
    {
      title: t`labels.Sales Amount`,
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      sorter: true,
      render: roundToNearestFiveCents,
    },
    ...(isAdmin
      ? [
          {
            title: t`labels.Service fee`,
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            sorter: true,
            render: roundToNearestFiveCents,
          },
        ]
      : []),
    {
      title: t`labels.Average cost`,
      dataIndex: 'averageCost',
      key: 'averageCost',
      sorter: true,
      render: roundToNearestFiveCents,
    },
    {
      title: t`labels.budget`,
      dataIndex: 'budget',
      key: 'budget',
    },
    {
      title: t`labels.costPerUser`,
      dataIndex: 'costPerUser',
      key: 'costPerUser',
    },
    {
      title: t`labels.purchaseCost`,
      dataIndex: 'purchaseCost',
      key: 'purchaseCost',
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
    },
  ]

  return (
    <Page inner title={t`tabs.Marketing Report`}>
      <Form form={form}>
        <DragTable<MarketingSalesStatisticsView>
          rowKey={(record, index) => String(index)}
          bordered
          actions={
            <DownloadReportButton
              entity="marketing"
              getQuery={() => `eventId==${eventId}`}
              getParams={getSort}
            />
          }
          columns={columns}
          className={commonStyles.table}
          rowClassName={commonStyles.row}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default MarketingReport
