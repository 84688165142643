import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'

export const useCatalogMultiLanguageFields = (path: string) => {
  const [languageFields, setLanguageFields] = useState<string[]>([])

  useEffect(() => {
    axiosInstance
      .get<string[]>('admin/' + path + '/i18n')
      .then(({ data }) => setLanguageFields(data))
  }, [])

  return { languageFields }
}
