/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CountryUpdateResponse
 */
export interface CountryUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof CountryUpdateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'nameBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'descriptionBY'?: string;
    /**
     * 
     * @type {number}
     * @memberof CountryUpdateResponse
     */
    'phoneCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryUpdateResponse
     */
    'vatRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryUpdateResponse
     */
    'smsSender'?: CountryUpdateResponseSmsSenderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CountryUpdateResponseSmsSenderEnum {
    AWS = 'aws',
    TWILIO = 'twilio'
}


