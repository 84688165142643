import { Alert, Card } from 'antd'
import { StepProps } from './types'
import { useEffect, useMemo } from 'react'
import styles from './styles.module.less'
import cn from 'classnames'
import {
  EventMediaFileCreateRequest,
  EventMediaFileExtendedDto,
  PageEventMediaFileExtendedDto,
  PagePictureTypeDto,
} from 'openapi'
import { SelectOption } from '@/types/Option'
import { useParams } from 'react-router-dom'
import useAsyncGet from '@/hooks/useAsyncGet'
import UploadSingleFile from '@pages/event/components/UploadSingleFile'
import { isNotCanceledOrArchivedEvent } from '@/utils/eventsStatuses'

export type CustomFormData = EventMediaFileCreateRequest & {
  pictureTypeId: SelectOption
}

const StepPoster = ({
  item,
  setForm,
  errors,
  eventId,
}: StepProps & { eventId?: number }) => {
  const { id } = useParams<{ id: string }>()

  const resultId = useMemo(() => eventId || id, [id, eventId])

  useEffect(() => {
    setForm(undefined)
  }, [])

  const {
    data: files,
    loaded,
    refetch: refetchFiles,
  } = useAsyncGet<PageEventMediaFileExtendedDto>(
    `admin/event-media-files/extended?query=event.id==${resultId}&size=100`,
  )

  const { data: types } = useAsyncGet<PagePictureTypeDto>(
    `admin/picture-types?page=0&size=100&sort=usageType&query=entityType==event`,
  )

  const pictures: EventMediaFileExtendedDto[] = useMemo(
    () =>
      types?.content?.map((type) => {
        const file = files?.content?.find(
          ({ pictureType }) => pictureType?.id === type.id,
        )
        if (file) {
          return file
        }
        return {
          pictureType: type,
        }
      }) || [],
    [types, files],
  )

  return (
    <Card style={{ marginTop: '15px' }} loading={!loaded}>
      {errors?.length && (
        <Alert
          message={
            <>
              {errors.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </>
          }
          type="error"
          showIcon
          className={cn(styles.fullWidth, styles.alert)}
        />
      )}
      <div className={styles.picturesWrapper}>
        {pictures.map((pic) => (
          <UploadSingleFile
            key={pic.pictureType?.id}
            file={pic}
            refresh={refetchFiles}
            canEdit={isNotCanceledOrArchivedEvent(item)}
            event={item}
          />
        ))}
      </div>
    </Card>
  )
}

export default StepPoster
