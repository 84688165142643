import { Button, Divider, Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import {
  CustomFormData,
  usageTypesOptions,
  deviceTypesOptions,
  entityTypesOptions,
} from './index'
import { MLFormProps } from '../types'
import FloatSelect from '@components/FloatSelect'
import FormItem from '@components/FormItem'
import FloatInput from '@components/FloatInput'
import FloatNumberInput from '@components/FloatNumberInput'
import { useTranslation } from 'react-i18next'
const { Option } = Select

const PictureTypesForm = ({ onSuccess, id }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/picture-types/${id}`)
        .then(({ data }) => {
          setFullItem(data)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const onFinish = async (values: CustomFormData) => {
    const valuesToSend = {
      ...values,
      usageType: values.usageType?.value || values.usageType,
      deviceType: values.deviceType?.value || values.deviceType,
      entityType: values.entityType?.value || values.entityType,
    }
    if (id) {
      await axiosInstance.put(`admin/picture-types/${id}`, valuesToSend)
    } else {
      await axiosInstance.post('admin/picture-types', valuesToSend)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form form={form} onFinish={onFinish} initialValues={fullItem}>
      <FormItem name="name" hasFeedback rules={[{ required: true }]}>
        <FloatInput label={t`name`} required />
      </FormItem>
      <FormItem name="usageType" rules={[{ required: true }]}>
        <FloatSelect label={t`labels.Usage Type`} labelInValue={false} required>
          {usageTypesOptions.map(({ label, value }) => (
            <Option value={value} label={label} key={value}>
              {label}
            </Option>
          ))}
        </FloatSelect>
      </FormItem>
      <FormItem name="deviceType" rules={[{ required: true }]}>
        <FloatSelect
          label={t`labels.Device Type`}
          labelInValue={false}
          required
        >
          {deviceTypesOptions.map(({ label, value }) => (
            <Option value={value} label={label} key={value}>
              {label}
            </Option>
          ))}
        </FloatSelect>
      </FormItem>
      <FormItem name="entityType" rules={[{ required: true }]}>
        <FloatSelect
          label={t`labels.Entity Type`}
          labelInValue={false}
          required
        >
          {entityTypesOptions.map(({ label, value }) => (
            <Option value={value} label={label} key={value}>
              {label}
            </Option>
          ))}
        </FloatSelect>
      </FormItem>
      <FormItem name="maxSize" hasFeedback rules={[{ required: true }]}>
        <FloatNumberInput label={t`labels.Max Size`} required />
      </FormItem>
      <FormItem name="pixWidth" hasFeedback rules={[{ required: true }]}>
        <FloatNumberInput label={t`labels.Width_px`} required />
      </FormItem>
      <FormItem name="pixHeight" hasFeedback rules={[{ required: true }]}>
        <FloatNumberInput label={t`labels.Height_px`} required />
      </FormItem>
      <FormItem name="ratioWidth" hasFeedback>
        <FloatNumberInput label={t`labels.Ratio width`} />
      </FormItem>
      <FormItem name="ratioHeight" hasFeedback>
        <FloatNumberInput label={t`labels.Ratio height`} />
      </FormItem>
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PictureTypesForm
