import { Form, Modal, Select } from 'antd'
import {
  ActorTypeDto,
  EventDto,
  EventDtoEventStatusEnum,
  EventDtoFilterTypeEnum,
  EventSales,
  PageEventDto,
} from '@/openapi'
import commonStyles from '../commonStyles.module.less'
import Page from '../../components/Page'
import { useCallback } from 'react'
const { Option } = Select

import { Params } from '@/types/Params'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import { SorterResult } from 'antd/es/table/interface'
import FloatInput from '../../components/FloatInput'
import { ActorAsyncSelect } from '@components/AsyncSelect/ActorAsyncSelect'
import { Link, useNavigate } from 'react-router-dom'
import DropOption from '../../components/DropOption/DropOption'
import {
  generateDefaultSort,
  twoColumnsDateFormat,
  valueOrDash,
} from '@pages/helpers'
import { CityAsyncSelect } from '@components/AsyncSelect/CityAsyncSelect'
import { StageAsyncSelect } from '@components/AsyncSelect/StageAsyncSelect'
import { useAuthContext } from '@/contexts/AuthContext'
import {
  StarOutlined,
  StarFilled,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons'
import { toast } from 'react-toastify'

export const filterTypeOptions = [
  { value: 'all', label: 'All' },
  { value: EventDtoFilterTypeEnum.REGULAR, label: 'Regular' },
  { value: EventDtoFilterTypeEnum.PROMOTION, label: 'Promotion' },
  { value: EventDtoFilterTypeEnum.HIDDEN, label: 'Hidden' },
]
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

const EventTable = ({
  type,
  full = true,
}: {
  type: string
  full?: boolean
}) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { isAdmin } = useAuthContext()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<EventDto>
    },
    filters: { [key in keyof EventDto]: string } & { city: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    query.push(`eventStatus==${type}`)
    if (filters.city && filters.city !== 'All') {
      query.push(`stage.city.id==${filters.city}`)
    }
    if (filters.stage && filters.stage !== 'All') {
      query.push(`stage.id==${filters.stage}`)
    }
    if (filters.actors && filters.actors !== 'All') {
      query.push(`actors.id$$${filters.actors}`)
    }
    if (filters.filterType && filters.filterType !== 'all') {
      query.push(`filterType==${filters.filterType}`)
    }
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (filters.tour && filters.tour !== 'All') {
      query.push(`tourId==${filters.tour}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageEventDto>('admin/events', { params })

    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    form,
    defaultParams: generateDefaultSort('dateStart', 'ascend'),
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/events/' + id)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: ActorTypeDto, e: any) => {
    if (e.key === 'manage') {
      navigate(`/app/event/${record.id}/manage`)
    } else if (e.key === '1') {
      navigate(`/app/event/${record.id}`)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const promoteEvent = useCallback(
    async (event: EventDto) => {
      try {
        await axiosInstance.post(
          `/admin/events/add-filter-type/${event.id}?filterType=promotion`,
          {},
          {
            headers: {
              noGeneralError: true,
            },
          },
        )
        toast.success(t`messages.eventPromoted`)
        refresh()
      } catch (e) {
        toast.error(t`messages.cantPromoteEvent`)
      }
    },
    [refresh, t],
  )

  const hideEvent = useCallback(
    async (event: EventDto) => {
      Modal.confirm({
        title: t`messages.tryToHideEvent`,
        onOk: async () => {
          try {
            await axiosInstance.post(
              `/admin/events/add-filter-type/${event.id}?filterType=hidden`,
              {},
              {
                headers: {
                  noGeneralError: true,
                },
              },
            )
            toast.success(t`messages.eventWasHidden`)
            refresh()
          } catch (e) {}
        },
      })
    },
    [refresh],
  )

  const unpromoteEvent = useCallback(
    async (event: EventDto) => {
      Modal.confirm({
        title: t`messages.stopPromotion`,
        onOk: async () => {
          await axiosInstance.post(
            `/admin/events/add-filter-type/${event.id}?filterType=regular`,
          )
          refresh()
        },
      })
    },
    [refresh, t],
  )

  const unhideEvent = useCallback(
    async (event: EventDto) => {
      Modal.confirm({
        title: t`messages.eventWillBeVisible`,
        onOk: async () => {
          await axiosInstance.post(
            `/admin/events/add-filter-type/${event.id}?filterType=regular`,
          )
          refresh()
        },
      })
    },
    [refresh],
  )

  const columns: ExtendedColumns<EventDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`name`,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t`labels.Start Date`,
      dataIndex: 'dateStart',
      key: 'dateStart',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: twoColumnsDateFormat,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Stage`}</div>
          <StageAsyncSelect fullWidth={true} submit={submit} noLabel />
        </div>
      ),
      shortTitle: t`labels.Stage`,
      dataIndex: 'stage',
      key: 'stage',
      render: (s) => valueOrDash(s?.name),
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.City`}</div>
          <CityAsyncSelect fullWidth={true} submit={submit} noLabel />
        </div>
      ),
      shortTitle: t`labels.City`,
      dataIndex: 'stage',
      key: 'city',
      render: (_, s) => valueOrDash(s?.city?.name),
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Actors`}</div>
          <ActorAsyncSelect
            submit={submit}
            width="100%"
            name="actors"
            noLabel
          />
        </div>
      ),
      shortTitle: t`labels.Actors`,
      dataIndex: 'actors',
      key: 'actors',
      render: (t: ActorTypeDto[]) => t?.map(({ name }) => name).join(', '),
    },
    ...(isAdmin && (type === 'published' || type === 'announced')
      ? [
          {
            title: (
              <div className={commonStyles.headerCell}>
                <div>{t`labels.Event special typ`}</div>
                <Form.Item name="filterType">
                  <Select
                    onClick={(e) => e.stopPropagation()}
                    onChange={submit}
                    defaultValue="all"
                  >
                    {filterTypeOptions.map(({ label, value }) => (
                      <Option value={value} label={label} key={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            ),
            shortTitle: t`labels.Event special typ`,
            dataIndex: 'filterType',
            key: 'filterType',
            noTooltip: true,
            render: (_, t) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  gap: '10px',
                }}
              >
                {t.filterType === EventDtoFilterTypeEnum.PROMOTION ? (
                  <StarFilled
                    style={{ color: '#d3df16', fontSize: '30px' }}
                    onClick={() => unpromoteEvent(t)}
                  />
                ) : (
                  <StarOutlined
                    style={{ fontSize: '30px' }}
                    onClick={() => promoteEvent(t)}
                  />
                )}
                {t.filterType === EventDtoFilterTypeEnum.HIDDEN ? (
                  <EyeInvisibleOutlined
                    onClick={() => unhideEvent(t)}
                    style={{ fontSize: '30px' }}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() => hideEvent(t)}
                    style={{ fontSize: '30px' }}
                  />
                )}
              </div>
            ),
          } as ExtendedColumns<EventDto>,
        ]
      : []),
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Tour`}</div>
          <GenericAsyncSelect
            path="admin/tours"
            label=""
            name="tour"
            width="100%"
            submit={submit}
          />
        </div>
      ),
      shortTitle: t`labels.Tour`,
      dataIndex: 'tour',
      key: 'tour',
      render: (t) =>
        t?.name ? <Link to={`/app/tour/${t.id}`}>{t.name}</Link> : null,
    },
    ...(full
      ? [
          {
            title: t`labels.Sold Tickets`,
            dataIndex: 'eventSales',
            key: 'eventSales.soldTicketsCount',
            render: (t: EventSales) => t?.soldTicketsCount,
          },
          {
            title: t`labels.Amount excluding service fee`,
            dataIndex: 'eventSales',
            key: 'eventSales.salesAmount',
            render: (t: EventSales) => t?.salesAmount,
          },
        ]
      : []),
    ...(type === 'canceled'
      ? [
          {
            title: t`labels.Cancelation date`,
            dataIndex: 'updatedDate',
            key: 'updatedDate',
            render: twoColumnsDateFormat,
            sorter: true,
          },
        ]
      : []),
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: 'manage', name: t`labels.Ticket managеment` },
              { key: '1', name: t`View/Edit` },
              ...(record.eventStatus === EventDtoEventStatusEnum.UNPUBLISHED
                ? [{ key: '2', name: t`delete` }]
                : []),
            ]}
          />
        )
      },
    },
  ]

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<EventDto>
          bordered
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default EventTable
