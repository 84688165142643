import { CityDto } from 'openapi'
import AsyncSelect from '../index'
import { fetchPageableEntity } from 'utils/fechPageableEntity'
import { Form, FormItemProps } from 'antd'
import { prepareOption } from '../types'
import { useTranslation } from 'react-i18next'
import { useIsRequired } from '@/hooks/useIsRequired'

type CountryAsyncSelectProps = {
  submit?: (v: string) => void
  withAll?: boolean
  fullWidth?: boolean
  allowClear?: boolean
  disabled?: boolean
  noLabel?: boolean
} & FormItemProps

export const CityAsyncSelect = ({
  submit,
  withAll = true,
  name = 'city',
  fullWidth = false,
  allowClear,
  disabled,
  noLabel,
  ...rest
}: CountryAsyncSelectProps) => {
  const { t } = useTranslation()

  const required = useIsRequired(rest.rules)

  return (
    <Form.Item
      name={name}
      initialValue={withAll ? 'All' : undefined}
      style={{ width: fullWidth ? '100%' : '200px', marginRight: 16 }}
      {...rest}
    >
      <AsyncSelect<CityDto>
        label={noLabel ? undefined : t`labels.City`}
        prepareOption={prepareOption}
        onChange={(v) => submit?.(v)}
        withAll={withAll}
        required={required}
        allowClear={allowClear}
        disabled={disabled}
        getData={fetchPageableEntity<CityDto>('admin/cities')}
      />
    </Form.Item>
  )
}
