/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Actor } from './actor';
// May contain unused imports in some cases
// @ts-ignore
import { Agency } from './agency';
// May contain unused imports in some cases
// @ts-ignore
import { EventTourCountView } from './event-tour-count-view';
// May contain unused imports in some cases
// @ts-ignore
import { EventTourSalesView } from './event-tour-sales-view';
// May contain unused imports in some cases
// @ts-ignore
import { EventType } from './event-type';

/**
 * 
 * @export
 * @interface Tour
 */
export interface Tour {
    /**
     * 
     * @type {number}
     * @memberof Tour
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tour
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Tour
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tour
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Tour
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tour
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tour
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tour
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tour
     */
    'status'?: TourStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Tour
     */
    'languages'?: Array<TourLanguagesEnum>;
    /**
     * 
     * @type {Agency}
     * @memberof Tour
     */
    'agency'?: Agency;
    /**
     * 
     * @type {Array<Actor>}
     * @memberof Tour
     */
    'actors'?: Array<Actor>;
    /**
     * 
     * @type {EventType}
     * @memberof Tour
     */
    'eventType'?: EventType;
    /**
     * 
     * @type {EventTourCountView}
     * @memberof Tour
     */
    'tourCount'?: EventTourCountView;
    /**
     * 
     * @type {EventTourSalesView}
     * @memberof Tour
     */
    'tourSales'?: EventTourSalesView;
}

/**
    * @export
    * @enum {string}
    */
export enum TourStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum TourLanguagesEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}


