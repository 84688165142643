import { useMemo } from 'react'
import { Form } from 'antd'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { FloatTextAreaProps } from '../FloatTextarea'
import { FormItemProps } from 'antd/es'
import { FormInstance } from 'antd/es/form/hooks/useForm'
import TextEditor from '@components/FormEditor'
import MultiLanguageTextEditorBase from '@components/MultiLanguageTextEditorBase'
import { castType } from '@/utils/cast'
import { EventCreateResponseLanguagesEnum } from '@/openapi'

type MultiLanguageTextareaProps = {
  itemProps: FormItemProps
  inputProps: FloatTextAreaProps
  languageFields: string[]
  form: FormInstance
  disabled?: boolean
}

const MultiLanguageTextEditor = ({
  itemProps,
  inputProps,
  languageFields,
  form,
  disabled,
}: MultiLanguageTextareaProps) => {
  const { languages } = useLanguageContext()

  const name = itemProps.name as string
  const isMultiLanguage = useMemo(
    () => languageFields.includes(name),
    [name, languages],
  )

  if (!isMultiLanguage) {
    return (
      <Form.Item {...itemProps}>
        <TextEditor form={form} name={name} readOnly={disabled} />
      </Form.Item>
    )
  }

  return (
    <MultiLanguageTextEditorBase
      itemProps={itemProps}
      inputProps={inputProps}
      languages={castType<EventCreateResponseLanguagesEnum[]>(languages)}
      form={form}
      disabled={disabled}
    />
  )
}

export default MultiLanguageTextEditor
