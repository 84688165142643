/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorDto } from './actor-dto';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyDto } from './agency-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTourCountViewDto } from './event-tour-count-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTourSalesViewDto } from './event-tour-sales-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeDto } from './event-type-dto';

/**
 * 
 * @export
 * @interface TourDto
 */
export interface TourDto {
    /**
     * 
     * @type {number}
     * @memberof TourDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TourDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TourDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TourDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TourDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TourDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourDto
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourDto
     */
    'status'?: TourDtoStatusEnum;
    /**
     * 
     * @type {AgencyDto}
     * @memberof TourDto
     */
    'agency'?: AgencyDto;
    /**
     * 
     * @type {Array<ActorDto>}
     * @memberof TourDto
     */
    'actors'?: Array<ActorDto>;
    /**
     * 
     * @type {EventTypeDto}
     * @memberof TourDto
     */
    'eventType'?: EventTypeDto;
    /**
     * 
     * @type {EventTourCountViewDto}
     * @memberof TourDto
     */
    'tourCount'?: EventTourCountViewDto;
    /**
     * 
     * @type {EventTourSalesViewDto}
     * @memberof TourDto
     */
    'tourSales'?: EventTourSalesViewDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TourDtoStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}


