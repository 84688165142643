import { EventBillingSystemDto, EventPriceDto } from 'openapi'
import { axiosInstance } from 'api'
import { SelectOption } from '@/types/Option'
import { omit } from 'lodash'

export const handleEventsPricesChange = async (
  oldEventPrices: EventPriceDto[],
  newEventPrices: EventPriceDto[],
  eventId: number,
) => {
  const oldIds = oldEventPrices.map(({ id }) => id)
  const newIds = newEventPrices.filter(({ id }) => id).map(({ id }) => id)

  const toCreate = newEventPrices
    .filter(({ id }) => !id)
    .map((item) => ({ ...item, eventId }))
  const toUpdate = newEventPrices
    .filter(({ id }) => id)
    .map((item) => {
      delete item.event
      return item
    })
  const toDelete: number[] = []
  oldIds.forEach((id) => {
    if (!newIds.includes(id)) toDelete.push(id!)
  })

  await Promise.all(
    toDelete.map((id) => axiosInstance.delete('admin/event-prices/' + id)),
  )
  await Promise.all(
    toUpdate.map((item) =>
      axiosInstance.put(
        'admin/event-prices/' + item.id,
        omit(item, 'quantityLeft', 'active'),
      ),
    ),
  )
  await Promise.all(
    toCreate.map((item) => axiosInstance.post('admin/event-prices', item)),
  )
}

export const handleEventBillingChange = async (
  oldData: EventBillingSystemDto[],
  newData: SelectOption[],
  eventId: number,
) => {
  const oldIds = oldData
    .filter(({ id }) => !!id)
    .map(({ billingSystem }) => billingSystem?.id)
  const toCreate = newData
    .filter(({ value }) =>
      oldData.every(
        ({ billingSystem: billingSystem, id }) =>
          !id || billingSystem!.id !== +value,
      ),
    )
    .map((item) => ({ billingSystemId: item.value, eventId }))

  const toDelete: number[] = []
  oldIds.forEach((id) => {
    if (!newData.some(({ value }) => +value === id))
      toDelete.push(
        oldData.find(({ billingSystem }) => id === billingSystem!.id)!.id!,
      )
  })

  await Promise.all(
    toDelete.map((id) =>
      axiosInstance.delete('admin/event-billing-systems/' + id),
    ),
  )
  await Promise.all(
    toCreate.map((item) =>
      axiosInstance.post('admin/event-billing-systems/', item),
    ),
  )
}
