import { EventSeatPriceDto, PageEventPriceDto } from '@/openapi'
import styles from './styles.module.less'
import cn from 'classnames'
import useAsyncGet from '@/hooks/useAsyncGet'

type Props = {
  onSelect: (eventSeatPrice?: EventSeatPriceDto) => void
  eventId: string
}
export const RightPartEventPrice = ({ onSelect, eventId }: Props) => {
  const { data } = useAsyncGet<PageEventPriceDto>(
    `admin/event-prices?page=0&size=100&query=event.id==${eventId}`,
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {data?.content?.map((eventPrice) => (
        <div
          className={cn(
            styles.eventPriceWrapper,
            !eventPrice.quantityLeft && styles.eventPriceWrapperDisabled,
          )}
          onClick={() =>
            onSelect({
              eventSeat: undefined,
              eventPrice,
            })
          }
          key={eventPrice.id}
        >
          <div>{'Name'}</div>
          <div>{eventPrice.name}</div>
          <div>{'Price'}</div>
          <div>{eventPrice.price}</div>
          <div>{'Left'}</div>
          <div>{eventPrice.quantityLeft}</div>
        </div>
      ))}
    </div>
  )
}
