import { useCallback, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { Button, Form, Modal, Upload } from 'antd'
import {
  EventCreateResponseLanguagesEnum,
  EventExtendedDto,
  EventMediaFileCreateRequest,
  EventMediaFileDto,
  EventMediaFileExtendedDto,
} from '@/openapi'
import styles from './styles.module.css'
const { Dragger } = Upload
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { axiosInstance } from '@/api'
import MultiLanguageInputBase from '@components/MultiLanguageInputBase'
import Loader from '@components/Loader'
import { RcFile } from 'antd/es/upload'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'
const MAX_FILE_SIZE = 1

type Props = {
  file: EventMediaFileExtendedDto
  refresh: () => void
  canEdit?: boolean
  event?: EventExtendedDto
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const UploadSingleFile = ({ file, refresh, canEdit, event }: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [seoModal, setSeoModal] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [sizeError, setSizeError] = useState<string | undefined>()
  const [form] = Form.useForm<EventMediaFileCreateRequest>()
  const { t } = useTranslation()
  const handlePreview = useCallback(() => {
    if (!file.link) return
    setPreviewImage(file.link)
    setPreviewOpen(true)
  }, [file])

  const handleCancel = useCallback(() => setPreviewOpen(false), [])

  const deletePoster = useCallback(async () => {
    Modal.confirm({
      title: t(`messages.deleteSpecificImage`, {
        name: file.pictureType?.name,
      }),
      onOk: async () => {
        await axiosInstance.delete('admin/event-media-files/' + file.id)
        await refresh()
      },
    })
  }, [refresh, file])

  const onFinish = useCallback(
    async (values: EventMediaFileCreateRequest) => {
      await axiosInstance.put('admin/event-media-files/' + file.id, {
        ...values,
        id: file.id,
      })
      refresh()
      setSeoModal(false)
    },
    [file, refresh],
  )

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: 'image/jpeg',
    beforeUpload: async (newFile) => {
      setSizeError(undefined)
      const fileSize = newFile.size / (1024 * 1024)
      if (fileSize > MAX_FILE_SIZE) {
        setSizeError(fileSize.toFixed(2))
        return false
      }
      setUploading(true)

      if (newFile) {
        try {
          const base = await getBase64(newFile)
          await axiosInstance.post<EventMediaFileDto>(
            'admin/event-media-files',
            {
              eventId: event?.id,
              pictureTypeId: file.pictureType?.id,
              fileContent: base
                ?.replace('data:image/png;base64,', '')
                .replace('data:image/jpeg;base64,', ''),
            },
          )
          refresh()
        } finally {
          setUploading(false)
        }
      }

      setUploading(false)
      return false
    },
  }

  return (
    <div className={styles.draggerWrapper}>
      <div className={styles.name}>{`${file.pictureType?.name} *`}</div>
      {file.id ? (
        <div className={styles.imageWrapper}>
          <img src={file.link} className={styles.image} />
          <div className={styles.imageWrapperOverlay}>
            <Button
              icon={<EyeOutlined />}
              onClick={handlePreview}
              type="dashed"
              ghost
            />
            {canEdit && (
              <Button
                icon={<EditOutlined />}
                type="dashed"
                ghost
                onClick={() => setSeoModal(true)}
              />
            )}
            {canEdit && (
              <Button
                icon={<DeleteOutlined />}
                type="dashed"
                ghost
                onClick={deletePoster}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          {uploading && <Loader spinning className={styles.loader} />}
          {!uploading && (
            <Dragger
              {...props}
              className={styles.dragger}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t`labels.Drop image here or browse`}</p>
              <p className="ant-upload-hint">
                {`${file.pictureType?.pixWidth}x${file.pictureType?.pixHeight}px`}
              </p>
              <p className={cn('ant-upload-hint', sizeError && styles.redText)}>
                {`Max file size ${MAX_FILE_SIZE}mb   `}
                {sizeError && <span>{`${sizeError}mb`}</span>}
              </p>
            </Dragger>
          )}
        </>
      )}
      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{ width: '100%', marginTop: '30px' }}
          src={previewImage}
        />
      </Modal>
      <Modal
        open={seoModal}
        title={`Edit SEO information for ${file.pictureType?.name} image`}
        onCancel={() => setSeoModal(false)}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          initialValues={file}
          style={{ marginTop: '20px' }}
        >
          <MultiLanguageInputBase
            form={form}
            itemProps={{
              name: 'seoText',
              hasFeedback: true,
              rules: [{ required: true }],
              disabled: !canEdit,
            }}
            inputProps={{ label: t`labels.SEO Text`, required: true }}
            languages={castType<EventCreateResponseLanguagesEnum[]>(
              event?.languages,
            )}
          />
          <MultiLanguageInputBase
            form={form}
            itemProps={{
              name: 'hoverTitle',
              hasFeedback: true,
              rules: [{ required: true }],
              disabled: !canEdit,
            }}
            inputProps={{ label: t`labels.Hover Title`, required: true }}
            languages={castType<EventCreateResponseLanguagesEnum[]>(
              event?.languages,
            )}
          />
          <Form.Item
            style={{
              marginBottom: 0,
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <Button type="primary" htmlType="submit">
              {t`submit`}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default UploadSingleFile
