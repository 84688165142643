import AsyncSelect from '../index'
import { fetchPageableEntity } from 'utils/fechPageableEntity'
import { prepareOption } from '../types'
import { SelectProps } from 'antd/es'
import { SelectOption } from '@/types/Option'
import FormItem, { CustomFormItemProps } from '@/components/FormItem'

export const enum FILTER_OPERATORS {
  EQUAL = '==',
  CUSTOM = '$CUSTOM$',
  IN = '~in~',
}

export type BaseFilers = {
  [key: string]: {
    value: string
    operator: FILTER_OPERATORS
  }
}

type GenericAsyncSelectProps<T> = {
  path: string
  label: string
  submit?: (v: string) => void
  withAll?: boolean
  baseFilters?: BaseFilers
  required?: boolean
  fullWidth?: boolean
  width?: string
  controlProps?: Omit<SelectProps, 'onChange'>
  customPrepareOption?: (item: T) => SelectOption
  searchField?: string
  searchParam?: string
} & CustomFormItemProps

export const GenericAsyncSelect = <T extends {}>({
  path,
  submit,
  withAll = true,
  name,
  baseFilters,
  required,
  width = '200px',
  controlProps,
  label,
  customPrepareOption,
  searchField,
  searchParam,
  ...rest
}: GenericAsyncSelectProps<T>) => {
  return (
    <FormItem
      name={name}
      initialValue={withAll ? 'All' : undefined}
      style={{ width, marginRight: 16 }}
      {...rest}
      disabled={controlProps?.disabled}
    >
      <AsyncSelect<T>
        label={label}
        prepareOption={
          customPrepareOption ? customPrepareOption : prepareOption
        }
        onChange={(v) => submit?.(v)}
        withAll={withAll}
        getData={fetchPageableEntity<T>(path, searchField, searchParam)}
        required={required}
        baseFilters={baseFilters}
        {...controlProps}
      />
    </FormItem>
  )
}
