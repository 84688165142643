/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AgencyDto } from './agency-dto';
// May contain unused imports in some cases
// @ts-ignore
import { MailingListFilters } from './mailing-list-filters';
// May contain unused imports in some cases
// @ts-ignore
import { MarketingLinkDto } from './marketing-link-dto';

/**
 * 
 * @export
 * @interface MailingListDto
 */
export interface MailingListDto {
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'entityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'type'?: MailingListDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'lang'?: MailingListDtoLangEnum;
    /**
     * 
     * @type {MailingListFilters}
     * @memberof MailingListDto
     */
    'filters'?: MailingListFilters;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'channel'?: MailingListDtoChannelEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'status'?: MailingListDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'recipientsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'sentItemsQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'succeededItemsQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'failedItemsQuantity'?: number;
    /**
     * 
     * @type {MarketingLinkDto}
     * @memberof MailingListDto
     */
    'marketingLink'?: MarketingLinkDto;
    /**
     * 
     * @type {AgencyDto}
     * @memberof MailingListDto
     */
    'agency'?: AgencyDto;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'costCurrencyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MailingListDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MailingListDtoTypeEnum {
    EVENT = 'event',
    ACTOR = 'actor',
    STAGE = 'stage',
    CITY = 'city',
    ACTOR_TYPE = 'actor_type',
    EVENT_TYPE = 'event_type'
}
/**
    * @export
    * @enum {string}
    */
export enum MailingListDtoLangEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
    * @export
    * @enum {string}
    */
export enum MailingListDtoChannelEnum {
    EMAIL = 'email',
    SMS = 'sms'
}
/**
    * @export
    * @enum {string}
    */
export enum MailingListDtoStatusEnum {
    DRAFT = 'draft',
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished'
}


