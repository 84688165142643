import type { MenuProps } from 'antd'
import { ConfigProvider, Menu } from 'antd'
import React, { useMemo, useState } from 'react'
import styles from './MailLayout.module.less'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '@/contexts/AuthContext'
import { ReactComponent as CreateEventSvg } from '@/assets/create-event.svg'
import { ReactComponent as DashboardSvg } from '@/assets/dashboard.svg'
import { ReactComponent as EventSvg } from '@/assets/event.svg'
import { ReactComponent as TourSvg } from '@/assets/tour.svg'
import { ReactComponent as ActorsSvg } from '@/assets/actors.svg'
import { ReactComponent as FinanceSvg } from '@/assets/finance.svg'
import { ReactComponent as StagesSvg } from '@/assets/stages.svg'
import { ReactComponent as ManagementSvg } from '@/assets/management.svg'
import { ReactComponent as ReportsSvg } from '@/assets/reports.svg'
import { ReactComponent as ExpandIcon } from '@/assets/expand-icon.svg'
import cn from 'classnames'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: Array<MenuItem | false>,
  className?: string,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    className,
  } as MenuItem
}

const SideBar = () => {
  const { t } = useTranslation()
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const navigate = useNavigate()
  const { isAdmin } = useAuthContext()
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys((old) => keys.filter((key) => !old.includes(key)))
  }

  const items: MenuItem[] = useMemo(
    () => [
      getItem(
        t`labels.Create Event`,
        'event/new',
        <CreateEventSvg />,
        undefined,
        cn(styles.createEvent),
      ),
      getItem(t`menu.Dashboard`, 'dashboard', <DashboardSvg />),
      getItem(
        t`tabs.Reports`,
        'reports',
        <ReportsSvg />,
        [
          getItem(
            t`labels.Sales report by countries`,
            'sales_report_by_countries',
          ),
          isAdmin && getItem(t`labels.Agency report`, 'agency_report'),
          getItem(t`labels.Sales channels report`, 'sales_report_by_channels'),
          getItem(t`tabs.Sales by Dates`, 'sales_by_dates'),
        ].filter((i) => i),
      ),
      getItem(
        t`labels.Event`,
        'event',
        <EventSvg />,
        [
          getItem(t`labels.Published`, 'events_published'),
          getItem(t`labels.Announced`, 'events_announced'),
          getItem(t`labels.Unpublished`, 'events_unpublished'),
          getItem(t`labels.Archived`, 'events_archived'),
          getItem(t`labels.Canceled`, 'events_canceled'),
          isAdmin && getItem(t`labels.Event groups`, 'events_groups'),
          getItem(t`labels.Event Types`, 'eventTypes'),
          isAdmin && getItem(t`labels.transfer`, 'event_transfer'),
        ].filter((i) => i),
      ),
      getItem(t`tabs.Tours`, 'tours', <TourSvg />, [
        getItem(t`tabs.List of Tours`, 'tours_list'),
        getItem(t`labels.Create Tour`, 'tour/new'),
      ]),
      getItem(
        t`labels.Actors`,
        'actors-main',
        <ActorsSvg />,
        [
          getItem(t`labels.Actors`, 'actors'),
          getItem(t`labels.Actor Types`, 'actorTypes'),
        ],
        styles.margin,
      ),
      ...(isAdmin
        ? [
            getItem(
              t`tabs.Finance`,
              'finance',
              <FinanceSvg />,
              [
                getItem(t`labels.Billing Systems`, 'billing'),
                isAdmin && getItem(t`tabs.Currencies`, 'currency'),
              ].filter((item) => item),
              styles.menuItem,
            ),
          ]
        : []),
      getItem(t`tabs.Stages`, 'stages-main', <StagesSvg />, [
        getItem(t`tabs.Countries`, 'countries'),
        getItem(t`tabs.Cities`, 'cities'),
        getItem(t`tabs.Stages`, 'stages'),
        getItem(t`tabs.Stage Definitions`, 'stage-definitions'),
      ]),
      getItem(
        t`tabs.Management`,
        'management',
        <ManagementSvg />,
        [
          isAdmin && getItem(t`tabs.Partners`, 'login-credentials'),
          getItem(t`tabs.Ticket Control Persons`, 'ticketControlPersons'),
          getItem(t`tabs.Ticket Operators`, 'ticketOperators'),
          isAdmin && getItem(t`tabs.Users`, 'users'),
          isAdmin && getItem(t`tabs.Agencies`, 'agencies'),
          isAdmin && getItem(t`tabs.Picture types`, 'picture-types'),
          isAdmin && getItem(t`tabs.Social medias`, 'social-medias'),
        ].filter((item) => item),
        styles.margin,
      ),
    ],
    [isAdmin, t],
  )

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            collapsedIconSize: 18,
            darkItemSelectedBg: 'transparent',
            itemBorderRadius: 4,
            darkItemBg: 'black',
            darkSubMenuItemBg: 'black',
          },
        },
      }}
    >
      <div className={styles.sidebarWrapper}>
        <Menu
          title="Relivent"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          items={items}
          onClick={({ key }) => navigate(key)}
          theme="dark"
          className={cn(styles.sidebar)}
          expandIcon={<ExpandIcon />}
        />
      </div>
    </ConfigProvider>
  )
}

export default SideBar
