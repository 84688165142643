import {
  Button,
  Dropdown,
  Space,
  Switch,
  Table,
  TableProps,
  theme,
  Tooltip,
} from 'antd'
import { SettingOutlined, MenuOutlined } from '@ant-design/icons'

import ReactDragListView from 'react-drag-listview'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { ColumnsType, ColumnType } from 'antd/lib/table'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'
import cn from 'classnames'
import styles from './index.module.less'

export type ExtendedColumns<T> = {
  shortTitle?: string | null
  noDrag?: boolean
  noTooltip?: boolean
} & ColumnType<T>

type Props<T> = {
  columns: ExtendedColumns<T>[]
  actions?: ReactNode
  actionsMobile?: boolean
} & Omit<TableProps<T>, 'columns'>

const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const { useToken } = theme

export const DragTable = <T extends object>({
  columns: cols,
  actions,
  actionsMobile,
  ...rest
}: Props<T>) => {
  const [columns, setColumns] = useState<ColumnsType<T>>(cols)
  const [open, setOpen] = useState(false)
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag)
  }
  const { token } = useToken()

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  }

  const [config, setConfig] = useState(
    cols?.map(({ key, title, shortTitle, noDrag, fixed }) => ({
      key,
      visible: true,
      title: shortTitle || title,
      noDrag: noDrag || fixed,
    })) || [],
  )

  useEffect(() => {
    setColumns(
      config
        .filter(({ visible }) => visible)
        .map(({ key }) => cols.find((c) => c.key === key) as ExtendedColumns<T>)
        .map((c, i) => ({
          ...c,
          ellipsis: {
            showTitle: false,
          },
          render: (value, item) => {
            const content = c?.render ? c.render(value, item, i) : value
            if (c?.noTooltip) return content

            return (
              <Tooltip title={content} autoAdjustOverflow>
                {content}
              </Tooltip>
            )
          },
        })),
    )
  }, [config, cols])

  const items: MenuItemType[] = useMemo(
    () =>
      config.map(
        (item) =>
          ({
            key: item.key,
            className: item.noDrag && styles.noDrag,
            icon: item.noDrag ? undefined : <MenuOutlined />,
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '20px',
                  height: '26px',
                  alignItems: 'center',
                }}
              >
                <>
                  {item.title}
                  {!item.noDrag && (
                    <Switch
                      checked={item.visible}
                      onChange={(checked) =>
                        setConfig((old) => {
                          return old.map((i) => {
                            if (i.key === item.key) {
                              return { ...i, visible: checked }
                            }
                            return i
                          })
                        })
                      }
                    />
                  )}
                </>
              </div>
            ),
          }) as MenuItemType,
      ),
    [config],
  )

  return (
    <div>
      <div className={cn(styles.topRow, actionsMobile && styles.actionsMobile)}>
        <div>{actions}</div>
        <ReactDragListView
          nodeSelector="[data-draggable]"
          onDragEnd={(fromIndex, toIndex) => {
            setConfig((old) => reorder(old, fromIndex, toIndex))
          }}
        >
          <Dropdown
            placement="bottomRight"
            dropdownRender={() => (
              <div style={contentStyle} className={styles.list}>
                {items.map((item) => (
                  <div
                    key={item?.key}
                    className={cn(item?.className, styles.item)}
                    data-draggable="true"
                  >
                    {item?.icon}
                    {item?.label}
                  </div>
                ))}
              </div>
            )}
            trigger={['click']}
            onOpenChange={handleOpenChange}
            open={open}
          >
            <Button className={cn(actionsMobile && styles.fullWidth)}>
              <Space>
                <SettingOutlined />
                {'Table Settings'}
              </Space>
            </Button>
          </Dropdown>
        </ReactDragListView>
      </div>

      <Table<T>
        columns={columns}
        scroll={{ x: 'max-content' }}
        sticky
        {...rest}
      />
    </div>
  )
}
