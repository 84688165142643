/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Actor } from './actor';
// May contain unused imports in some cases
// @ts-ignore
import { Agency } from './agency';
// May contain unused imports in some cases
// @ts-ignore
import { City } from './city';
// May contain unused imports in some cases
// @ts-ignore
import { CityUpdateResponseTimezone } from './city-update-response-timezone';
// May contain unused imports in some cases
// @ts-ignore
import { Currency } from './currency';
// May contain unused imports in some cases
// @ts-ignore
import { EventPriceSales } from './event-price-sales';
// May contain unused imports in some cases
// @ts-ignore
import { EventSales } from './event-sales';
// May contain unused imports in some cases
// @ts-ignore
import { EventType } from './event-type';
// May contain unused imports in some cases
// @ts-ignore
import { Stage } from './stage';
// May contain unused imports in some cases
// @ts-ignore
import { Tour } from './tour';

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'admissionStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'admissionEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'saleStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'saleEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'pinCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'lockId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'eventStatus'?: EventEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'filterType'?: EventFilterTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Event
     */
    'languages'?: Array<EventLanguagesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    'includeVat'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'vat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    'includeServiceFee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'commissionRate'?: number;
    /**
     * 
     * @type {Agency}
     * @memberof Event
     */
    'agency'?: Agency;
    /**
     * 
     * @type {Array<Actor>}
     * @memberof Event
     */
    'actors'?: Array<Actor>;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    'eventType'?: EventType;
    /**
     * 
     * @type {City}
     * @memberof Event
     */
    'city'?: City;
    /**
     * 
     * @type {Stage}
     * @memberof Event
     */
    'stage'?: Stage;
    /**
     * 
     * @type {Currency}
     * @memberof Event
     */
    'currency'?: Currency;
    /**
     * 
     * @type {Tour}
     * @memberof Event
     */
    'tour'?: Tour;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'tourId'?: number;
    /**
     * 
     * @type {EventSales}
     * @memberof Event
     */
    'eventSales'?: EventSales;
    /**
     * 
     * @type {Array<EventPriceSales>}
     * @memberof Event
     */
    'eventPriceSales'?: Array<EventPriceSales>;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'minPriceSeatsLeft'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    'soldOut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {CityUpdateResponseTimezone}
     * @memberof Event
     */
    'timezone'?: CityUpdateResponseTimezone;
}

/**
    * @export
    * @enum {string}
    */
export enum EventEventStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum EventFilterTypeEnum {
    REGULAR = 'regular',
    PROMOTION = 'promotion',
    HIDDEN = 'hidden'
}
/**
    * @export
    * @enum {string}
    */
export enum EventLanguagesEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}


