import React, { useEffect, useMemo, useState } from 'react'
import { axiosInstance } from 'api'
import { Modal } from 'antd'
import {
  EventMediaFileExtendedDto,
  PageEventMediaFileExtendedDto,
  PagePictureTypeDto,
  PictureTypeUserDtoEntityTypeEnum,
} from '@/openapi'
import useAsyncGet from '@/hooks/useAsyncGet'
import styles from '@pages/event/styles.module.less'
import UploadSingleFileExtended from './SIngleUpload/UploadSingleFileExtended'

type Props = {
  entity: PictureTypeUserDtoEntityTypeEnum
  close: VoidFunction
  id?: number
  allowPng?: boolean
  footer?: React.ReactNode
}

const typeMapping: Record<string, string> = {
  [PictureTypeUserDtoEntityTypeEnum.ACTOR]: 'actors',
  [PictureTypeUserDtoEntityTypeEnum.SOCIAL_MEDIA]: 'social-medias',
  [PictureTypeUserDtoEntityTypeEnum.AGENCY]: 'agencies',
  [PictureTypeUserDtoEntityTypeEnum.EVENT_GROUPS]: 'event-groups',
}

export const MediaModalExtended = ({
  entity,
  id,
  close,
  allowPng,
  footer,
}: Props) => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (!id) {
      setTitle('')
      return
    }
    axiosInstance.get(`admin/${typeMapping[entity]}/${id}`).then(({ data }) => {
      setTitle('Media files for ' + (data.nameEN || data.name))
    })
  }, [id])

  const {
    data: files,
    loaded,
    refetch: refetchFiles,
  } = useAsyncGet<PageEventMediaFileExtendedDto>(
    `admin/${typeMapping[entity]}/${id}/media?size=100000`,
  )

  const { data: types } = useAsyncGet<PagePictureTypeDto>(
    `admin/picture-types?page=0&size=100&sort=usageType&query=entityType==${entity}`,
  )

  const pictures: EventMediaFileExtendedDto[] = useMemo(
    () =>
      types?.content?.map((type) => {
        const file = files?.content?.find(
          ({ pictureType }) => pictureType?.id === type.id,
        )
        if (file) {
          return file
        }
        return {
          pictureType: type,
        }
      }) || [],
    [types, files],
  )

  return (
    <Modal
      open={!!id}
      title={title}
      onCancel={close}
      onOk={close}
      destroyOnClose={true}
      footer={footer}
      width="50%"
    >
      <div className={styles.picturesWrapper}>
        {pictures.map((pic) => (
          <UploadSingleFileExtended
            key={pic.pictureType?.id}
            file={pic}
            refresh={refetchFiles}
            canEdit
            entity={typeMapping[entity]}
            itemId={String(id)}
            allowPng={allowPng}
          />
        ))}
      </div>
    </Modal>
  )
}
