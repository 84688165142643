import {
  EventDto,
  EventExtendedDto,
  EventPriceDto,
  EventStep1UpdateRequest,
  EventStep3UpdateRequest,
  EventStep4UpdateRequest,
} from 'openapi'
import { SelectOption } from 'types/Option'
import { FormInstance } from 'antd'

type UnionEventCreate = Partial<EventExtendedDto> &
  Partial<EventStep3UpdateRequest> &
  Partial<EventStep4UpdateRequest>

export type StepProps = {
  onSuccess: (data?: UnionEventCreate, reverse?: boolean) => void
  setForm: (form?: FormInstance) => void
  item: EventExtendedDto
  disabled: boolean
  errors?: string[]
  canEdit?: boolean
  refetchItem?: () => void
}

export type FirstStepProps = Omit<StepProps, 'item'> & {
  item?: EventDto
  canEdit?: boolean
}

export type CustomFormData = EventExtendedDto & {
  actorIds: SelectOption[] | string[]
  eventTypeId: SelectOption | string
  agencyId: SelectOption | string
  cityId?: SelectOption | string
  stageId?: SelectOption | string
  agencyBillingSystemId?: SelectOption | string
  currencyId?: SelectOption | string
  ageCategory?: SelectOption | string
}

export type EventStep1Form = Omit<
  EventStep1UpdateRequest,
  'actorIds' | 'eventTypeId' | 'agencyId'
> & {
  actorIds: SelectOption[] | string[]
  eventTypeId: SelectOption | string
  agencyId: SelectOption | string
  ageCategory?: SelectOption | string
}

export type EventStep3Form = Omit<EventStep3UpdateRequest, 'stageId'> & {
  stageId: SelectOption | string
  cityId: SelectOption | string
}

export type EventStep4Form = Omit<EventStep4UpdateRequest, 'currencyId'> & {
  currencyId: SelectOption | string
  eventPrices: EventPriceDto[]
  billingSystemId: SelectOption[]
  external: boolean
  externalUrl: string
}

export enum EVENT_STEPS {
  BASIC = 'basic_information',
  STAGE = 'stage',
  SCHEDULE = 'schedule',
  TICKETS = 'tickets',
  SEAT_ASSIGNMENT = 'seat_assignment',
  POSTERS = 'posters',
}
