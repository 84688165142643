import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enJSON from 'languages/EN.json'
import plJSON from 'languages/PL.json'
import ruJSON from 'languages/RU.json'
import byJSON from 'languages/BY.json'
import uaJSON from 'languages/UA.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      EN: {
        translation: enJSON,
      },
      RU: {
        translation: ruJSON,
      },
      PL: {
        translation: plJSON,
      },
      BY: {
        translation: byJSON,
      },
      UA: {
        translation: uaJSON,
      },
    },
    lng: JSON.parse(localStorage.getItem('lang')!) || 'EN',
    fallbackLng: 'EN',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
