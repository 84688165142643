import { Button, Divider, Form, Select, Tooltip } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import FloatDateTime from '@components/FloatDateTime'
import FormItem from '@components/FormItem'
import { useParams } from 'react-router-dom'
import FloatSelect from '@components/FloatSelect'
import { dateToString, generateCode } from '../../helpers'
import { ReactComponent as Dice } from './dice-5.svg'
import styles from './styles.module.less'
import FloatNumberInput from '@components/FloatNumberInput'
import axios from 'axios'
import FloatInput from '@/components/FloatInput'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

type MLFormProps = {
  onSuccess: () => void
  id?: number
}

const PromoCodesForm = ({ onSuccess, id }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/promo-codes/${id}`)
        .then(({ data }) => {
          setFullItem(data)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    try {
      const validThru = values.validThru
        ? { validThru: dateToString(values.validThru) }
        : {}
      if (id) {
        await axiosInstance.put(`admin/promo-codes/${id}`, {
          ...values,
          ...validThru,
        })
      } else {
        await axiosInstance.post('admin/promo-codes', {
          ...values,
          ...validThru,
          eventId,
        })
      }
      onSuccess()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (
            e.response?.data.message && e.response?.data.message.includes('constraint [promo_codes.code]')
        ) {
          form.setFields([
            {
              name: 'code',
              errors: ['This code already exists'],
            },
          ])
        }
        if (
          e.response?.data.minPurchase
        ) {
          form.setFields([
            {
              name: 'minPurchase',
              errors: [e.response?.data.minPurchase],
            },
          ])
        }
      }
    }
  }

  const handleRandomClick = useCallback(() => {
    const code = generateCode()
    form.setFieldValue('code', code)
  }, [form])

  if (loading) return null

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <div style={{ position: 'relative' }}>
        <FormItem
          name="code"
          rules={[
            { required: true },
            {
              pattern: /^[0-9A-Z]{6,12}$/,
              message: t`messages.invalidPromoCode` as string,
            },
          ]}
        >
          <FloatInput label={t`labels.Code`} required />
        </FormItem>
        <Tooltip title="Generate">
          <Button className={styles.generateButton} onClick={handleRandomClick}>
            <Dice />
          </Button>
        </Tooltip>
      </div>
      <FormItem name="value" rules={[{ required: true }]}>
        <FloatNumberInput min={0} label={t`labels.Value`} required />
      </FormItem>
      <FormItem name="type" rules={[{ required: true }]}>
        <FloatSelect label={t`labels.Type`} labelInValue={false} required>
          <Option value="percent">{t`labels.Percent`}</Option>
          <Option value="amount">{t`labels.Amount`}</Option>
        </FloatSelect>
      </FormItem>
      <FormItem name="quantity">
        <FloatNumberInput min={0} label={t`labels.Quantity`} />
      </FormItem>
      <FormItem name="minPurchase">
        <FloatNumberInput min={0} label={t`labels.Minimum Purchase`} />
      </FormItem>
      <FormItem name="validThru" hasFeedback>
        <FloatDateTime label={t`labels.Valid Thru`} />
      </FormItem>
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PromoCodesForm
