import classNames from 'classnames'
import styles from './Loader.module.less'
import { useTranslation } from 'react-i18next'

type LoaderProps = {
  spinning: boolean
  fullScreen?: boolean
  className?: string
}

const Loader = ({ spinning = false, fullScreen, className }: LoaderProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(
        styles.loader,
        {
          [styles.hidden]: !spinning,
          [styles.fullScreen]: fullScreen,
        },
        className,
      )}
    >
      <div className={styles.wrapper}>
        <div className={styles.inner} />
        <div className={styles.text}>{t`labels.Loading`?.toUpperCase()}</div>
      </div>
    </div>
  )
}

export default Loader
