import { useCallback, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import Page from 'components/Page'
import {
  LoginCredentialsDto,
  LoginCredentialsDtoRolesEnum,
  PageLoginCredentialsDto,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '../../components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '../../components/FloatInput'
import LoginCredentialsForm from './loginCredentialsForm'

import { Params } from '@/types/Params'
import { SelectOption, SelectOptionGeneric } from '@/types/Option'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { AgencyAsyncSelect } from '@components/AsyncSelect/AgencyAsyncSelect'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

export type CustomFormData = Omit<LoginCredentialsDto, 'roles'> & {
  agencyId: SelectOption
  roles?: Array<SelectOptionGeneric<LoginCredentialsDtoRolesEnum>>
  password: string
}

const City = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<LoginCredentialsDto>()
  const { languageFields } = useCatalogMultiLanguageFields('login-credentials')
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<LoginCredentialsDto>
    },
    filters: { [key in keyof LoginCredentialsDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.username) {
      query.push(`username~~${filters.username}`)
    }
    if (filters.roles) {
      query.push(`roles~~${filters.roles}`)
    }
    if (filters.agency && filters.agency !== 'All') {
      query.push(`agency.id==${filters.agency}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageLoginCredentialsDto>(
      'admin/login-credentials',
      {
        params,
      },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/login-credentials/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )
  const handleMenuClick = useCallback((record: LoginCredentialsDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns: ExtendedColumns<LoginCredentialsDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.User name`}</div>
          <Form.Item name="username">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'username',
      key: 'username',
      sorter: true,
      shortTitle: t`labels.User name`,
    },
    {
      title: t`labels.Roles`,
      dataIndex: 'roles',
      key: 'roles',
      render: (r) => r.toString(),
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Agency`}</div>
          <AgencyAsyncSelect width="100%" submit={submit} noLabel />
        </div>
      ),
      dataIndex: 'agency',
      shortTitle: t`labels.Agency`,
      key: 'agency',
      render: (t) => t?.name,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ]

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<LoginCredentialsDto>
          bordered
          actions={
            <Button onClick={() => setIsOpenModal(true)}>{t`add`}</Button>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={
          activeItem
            ? `${t`update`} ${activeItem.username}`
            : t`labels.Add user`
        }
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <LoginCredentialsForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
    </Page>
  )
}

export default City
