import { TicketDto } from '@/openapi'
import { DescriptionsProps } from 'antd'

export const getDescriptions = (
  ticket: TicketDto,
): DescriptionsProps['items'] =>
  [
    {
      key: 'User phone',
      label: 'User phone',
      children: ticket.user?.phone,
    },
    {
      key: 'User email',
      label: 'User email',
      children: ticket.user?.email,
    },
    {
      key: 'Price',
      label: 'Price',
      children: ticket.price + ' ' + ticket.currency?.name,
    },
    {
      key: 'Event Price',
      label: 'Event Price',
      children: ticket.eventPrice?.name,
    },
    {
      key: 'Row',
      label: 'Row',
      children: ticket.eventSeat?.rowNum,
    },
    {
      key: 'Place',
      label: 'Place',
      children: ticket.eventSeat?.place,
    },
  ].filter((i) => !!i.children)
