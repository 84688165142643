import { AgencyDto } from 'openapi'
import AsyncSelect from '../index'
import { fetchPageableEntity } from 'utils/fechPageableEntity'
import { Form, FormItemProps } from 'antd'
import { prepareOption } from '../types'
import { useTranslation } from 'react-i18next'

type Props = {
  submit?: (v: string) => void
  withAll?: boolean
  disabled?: boolean
  noLabel?: boolean
  width?: string
  onChange?: (v: string) => void
} & FormItemProps

export const AgencyAsyncSelect = ({
  submit,
  withAll = true,
  name = 'agency',
  disabled = false,
  noLabel,
  width = '200px',
  onChange,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      name={name}
      initialValue={withAll ? 'All' : undefined}
      style={{ width, marginRight: 16 }}
      {...rest}
    >
      <AsyncSelect<AgencyDto>
        label={noLabel ? undefined : t`labels.Agency`}
        disabled={disabled}
        prepareOption={prepareOption}
        onChange={(v) => {
          onChange?.(v)
          submit?.(v)
        }}
        withAll={withAll}
        required={rest.required}
        getData={fetchPageableEntity<AgencyDto>('admin/agencies')}
      />
    </Form.Item>
  )
}
