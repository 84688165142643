/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EventExtendedDto } from './event-extended-dto';

/**
 * 
 * @export
 * @interface EventSeatExtendedDto
 */
export interface EventSeatExtendedDto {
    /**
     * 
     * @type {number}
     * @memberof EventSeatExtendedDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'seatId'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventSeatExtendedDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventSeatExtendedDto
     */
    'capacityLeft'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'rowNum'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'place'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'definition'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'def'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'type'?: EventSeatExtendedDtoTypeEnum;
    /**
     * 
     * @type {EventExtendedDto}
     * @memberof EventSeatExtendedDto
     */
    'event'?: EventExtendedDto;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventSeatExtendedDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventSeatExtendedDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventSeatExtendedDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EventSeatExtendedDtoTypeEnum {
    SEAT = 'seat',
    FLOOR = 'floor',
    STAGE = 'stage',
    TABLE = 'table',
    AREA = 'area',
    LINE = 'line',
    LABEL = 'label'
}


