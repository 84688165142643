/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'role': UserCreateRequestRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof UserCreateRequest
     */
    'countryId': number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserCreateRequestRoleEnum {
    USER = 'user',
    GUEST = 'guest',
    ADMIN = 'admin',
    PARTNER = 'partner'
}


