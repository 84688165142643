import { useCallback, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import Page from 'components/Page'
import { StageDto, PageStageDto } from 'openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from 'components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from 'components/FloatInput'
import { CityAsyncSelect } from 'components/AsyncSelect/CityAsyncSelect'
import StageForm from './stageForm'
import { LinkOutlined } from '@ant-design/icons'

import { Params } from 'types/Params'
import { SelectOption } from 'types/Option'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from 'hooks/useCatalogMultiLanguageFields'
import { MediaModal } from '@components/MediaModal'
import { useAdminColumns } from '@/hooks/adminColumns'
import { AdminButton } from '@components/Auth/AdminComponents'
import { useTranslation } from 'react-i18next'
import { DragTable } from '@components/DragTable'

export type CustomFormData = StageDto & { cityId: SelectOption }

const Stage = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<StageDto>()
  const { languageFields } = useCatalogMultiLanguageFields('stages')
  const [mediaId, setMediaId] = useState<number | undefined>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<StageDto>
    },
    filters: { [key in keyof StageDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.city && filters.city !== 'All') {
      query.push(`city.id==${filters.city}`)
    }
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (filters.address) {
      query.push(`address~~${filters.address}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageStageDto>('admin/stages', { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      await axiosInstance.delete('admin/stages/' + id)
      setIsOpenModal(false)
      refresh()
    },
    [refresh],
  )

  const handleMenuClick = useCallback((record: StageDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    } else if (e.key === '3') {
      setMediaId(record.id!)
    } else if (e.key === '2') {
      Modal.confirm({
        title: t`Are you sure delete this record?`,
        onOk() {
          deleteItem(record.id!)
        },
      })
    }
  }, [])

  const columns = useAdminColumns<StageDto>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      fixed: 'left',
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.City`}</div>
          <CityAsyncSelect fullWidth submit={submit} noLabel />
        </div>
      ),
      dataIndex: 'city',
      key: 'city',
      render: ({ name }) => name,
      shortTitle: t`labels.City`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      shortTitle: t`name`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Address`}</div>
          <Form.Item name="address">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      shortTitle: t`labels.Address`,
    },
    {
      title: t`labels.Map`,
      dataIndex: 'linkToGoogleMaps',
      key: 'linkToGoogleMaps',
      width: '70px',
      noTooltip: true,
      render: (v) =>
        v ? (
          <a href={v} target="_blank" rel="noreferrer">
            <Button icon={<LinkOutlined />} />
          </a>
        ) : null,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '3', name: t`media` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ])

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
    refresh()
  }, [])

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<StageDto>
          bordered
          actions={
            <AdminButton onClick={() => setIsOpenModal(true)}>
              {t`add`}
            </AdminButton>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={
          activeItem ? `${t`update`} ${activeItem.name}` : t`labels.Add stage`
        }
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <StageForm
          onSuccess={handleSuccess}
          id={activeItem?.id}
          langFields={languageFields}
        />
      </Modal>
      <MediaModal
        entity="stages"
        id={mediaId}
        close={() => setMediaId(undefined)}
      />
    </Page>
  )
}

export default Stage
