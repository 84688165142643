import { useCallback, useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { PageEntity } from '@/types/PagiableEntity'
import { Button, Form, Modal, Upload, UploadProps } from 'antd'
import MultiLanguageInputBase from '@components/MultiLanguageInputBase'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { UploadOutlined } from '@ant-design/icons'
import styles from './media.module.less'
import ImagesPreview from '@components/imagesPreview'
import { EventCreateResponseLanguagesEnum, MediaFileDto } from '@/openapi'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'

type Props = {
  entity: string
  close: VoidFunction
  id?: number
}

type CustomFormData = {
  fileContent: {
    file: File
  }
}

export const MediaModal = ({ entity, id, close }: Props) => {
  const [data, setData] = useState<MediaFileDto[]>([])
  const [imgSrc, setImgSrc] = useState('')
  const [title, setTitle] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [form] = Form.useForm<CustomFormData>()
  const { t } = useTranslation()

  useEffect(() => {
    if (!id) {
      setTitle('')
      return
    }
    axiosInstance.get(`admin/${entity}/${id}`).then(({ data }) => {
      setTitle('Media files for ' + data.nameEN)
    })
  }, [id])

  useEffect(() => {
    if (!id) {
      setData([])
      form.resetFields()
      return
    }

    getData()
  }, [id])

  const getData = useCallback(async () => {
    const { data } = await axiosInstance.get<PageEntity<MediaFileDto>>(
      `admin/${entity}/${id}/media?size=100000`,
    )

    setData(data?.content || [])
  }, [id])

  const { languages } = useLanguageContext()

  const onSelectFile: UploadProps['beforeUpload'] = (file) => {
    if (file) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(file)
    }
    return false
  }

  const onFinish = useCallback(
    async (values: CustomFormData) => {
      if (!imgSrc) {
        return
      }
      setDisabled(true)
      await axiosInstance.post(`admin/${entity}/${id}/media`, {
        ...values,
        fileName: values.fileContent.file.name,
        fileContent: imgSrc?.replace(/^data:image\/[a-z]+;base64,/, ''),
      })
      form.resetFields()
      setImgSrc('')
      getData()
      setDisabled(false)
    },
    [imgSrc],
  )

  if (!id) return null

  return (
    <Modal
      open={!!id}
      title={title}
      onCancel={close}
      destroyOnClose={true}
      footer={null}
      width="50%"
    >
      <div className={styles.wrapper}>
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="fileContent" hasFeedback={true}>
            <Upload
              name="logo"
              accept="image/*"
              beforeUpload={onSelectFile}
              multiple={false}
              maxCount={1}
            >
              <Button
                icon={<UploadOutlined />}
              >{t`labels.Click to upload`}</Button>
            </Upload>
          </Form.Item>
          <div style={{ marginTop: '50px' }} />
          <MultiLanguageInputBase
            itemProps={{
              name: 'seoText',
              hasFeedback: true,
              rules: [{ required: true }],
            }}
            inputProps={{ label: t`labels.SEO Text`, required: true }}
            languages={castType<EventCreateResponseLanguagesEnum[]>(languages)}
            form={form}
          />
          <MultiLanguageInputBase
            itemProps={{
              name: 'hoverTitle',
              hasFeedback: true,
              rules: [{ required: true }],
            }}
            inputProps={{ label: t`labels.Hover Title`, required: true }}
            languages={castType<EventCreateResponseLanguagesEnum[]>(languages)}
            form={form}
          />
          <Form.Item
            style={{
              marginBottom: 0,
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={disabled}
              loading={disabled}
            >
              {t`submit`}
            </Button>
          </Form.Item>
        </Form>
        <div>
          <ImagesPreview files={data} entity={entity} entityId={id} />
        </div>
      </div>
    </Modal>
  )
}
