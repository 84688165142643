import { useCallback, useState } from 'react'
import { Modal, Form, Button, Tag } from 'antd'
import Page from 'components/Page'
import {
  TicketControlPersonDto,
  PageTicketControlPersonDto,
  TicketControlPersonDtoStatusEnum,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@/components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@/components/FloatInput'
import { CountryAsyncSelect } from '@components/AsyncSelect/CountryAsyncSelect'
import TcsForm from './tcsForm'

import { Params } from '@/types/Params'
import { SelectOption } from '@/types/Option'
import commonStyles from '../commonStyles.module.less'
import { useCatalogMultiLanguageFields } from '@/hooks/useCatalogMultiLanguageFields'
import { PageHeader } from '@ant-design/pro-components'
import axios from 'axios'
import { toast } from 'react-toastify'
import { generateDefaultSort } from '@pages/helpers'
import CountryFlag from '@components/CountryFlag/CountryFlag'
import { useAuthContext } from '@/contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { DragTable } from '@components/DragTable'
import { useAdminColumns } from '@/hooks/adminColumns'

export type CustomFormData = TicketControlPersonDto & {
  countryId: SelectOption
}

const statusToTag = (status: TicketControlPersonDtoStatusEnum) => {
  switch (status) {
    case TicketControlPersonDtoStatusEnum.APPROVED:
      return <Tag color="green">{'APPROVED'}</Tag>
    case TicketControlPersonDtoStatusEnum.BLOCKED:
      return <Tag color="red">{'BLOCKED'}</Tag>
    case TicketControlPersonDtoStatusEnum.CREATED:
      return <Tag color="geekblue">{'CREATED'}</Tag>
    default:
      return null
  }
}

const TicketControlPerson = () => {
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<TicketControlPersonDto>()
  const { isAdmin } = useAuthContext()
  const { languageFields } = useCatalogMultiLanguageFields('cities')
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<TicketControlPersonDto>
    },
    filters: { [key in keyof TicketControlPersonDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.country && filters.country !== 'All') {
      query.push(`country.id==${filters.country}`)
    }
    if (filters.phoneNumber) {
      query.push(`phoneNumber~~${filters.phoneNumber}`)
    }
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageTicketControlPersonDto>(
      'admin/ticket-control-persons',
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultParams: generateDefaultSort('country', 'ascend'),
    form,
  })

  const deleteItem = useCallback(
    async (id: number) => {
      try {
        await axiosInstance.delete('admin/ticket-control-persons/' + id)
        refresh()
      } catch (e) {
        if (
          axios.isAxiosError(e) &&
          e?.response?.data.message.includes('constraint')
        ) {
          toast.error(t`messages.cantRemoveControlPerson`)
        }
      } finally {
        setIsOpenModal(false)
      }
    },
    [refresh],
  )
  const handleMenuClick = useCallback(
    (record: TicketControlPersonDto, e: any) => {
      if (e.key === '1') {
        setActiveItem(record)
        setIsOpenModal(true)
      } else if (e.key === '2') {
        Modal.confirm({
          title: t`Are you sure delete this record?`,
          onOk() {
            deleteItem(record.id!)
          },
        })
      }
    },
    [],
  )

  const columns = useAdminColumns<TicketControlPersonDto>([
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Country`}</div>
          <CountryAsyncSelect width="100%" submit={submit} noLabel />
        </div>
      ),
      shortTitle: t`labels.Country`,
      dataIndex: 'country',
      key: 'country',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (t) => (
        <div>
          {t.name} <CountryFlag countryCode={t.code} />
        </div>
      ),
    },
    {
      title: t`phoneCode`,
      dataIndex: 'phoneCode',
      key: 'phoneCode',
      width: '120px',
      render: (_, { country }) => `+${country?.phoneCode}`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`name`,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Phone Number`}</div>
          <Form.Item name="phoneNumber">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Phone Number`,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: true,
    },
    {
      title: t`labels.Status`,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: statusToTag,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: t`update` },
              { key: '2', name: t`delete` },
            ]}
          />
        )
      },
    },
  ])

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <>
      <PageHeader title={t`labels.Ticket Control Person`} />
      <Page inner>
        <Form form={form}>
          <DragTable<TicketControlPersonDto>
            bordered
            actions={
              <Button onClick={() => setIsOpenModal(true)}>{t`add`}</Button>
            }
            columns={columns}
            rowKey={(record) => String(record.id)}
            className={commonStyles.table}
            {...tableProps}
          />
        </Form>
        <Modal
          open={isOpenModal}
          title={activeItem ? t`labels.Update entry` : t`labels.Add entry`}
          onCancel={closeModal}
          destroyOnClose={true}
          footer={null}
        >
          <TcsForm
            onSuccess={handleSuccess}
            id={activeItem?.id}
            langFields={languageFields}
          />
        </Modal>
      </Page>
    </>
  )
}

export default TicketControlPerson
