import { CountryDto } from 'openapi'
import AsyncSelect from '../index'
import { fetchPageableEntity } from '@/utils/fechPageableEntity'
import { Form, FormItemProps } from 'antd'
import { prepareOption } from '../types'
import { useTranslation } from 'react-i18next'

type CountryAsyncSelectProps = {
  submit?: (v: string) => void
  withAll?: boolean
  disabled?: boolean
  mode?: 'multiple' | 'tags'
  width?: string
  noLabel?: boolean
} & FormItemProps

export const CountryAsyncSelect = ({
  submit,
  withAll = true,
  name = 'country',
  disabled = false,
  noLabel,
  mode,
  width = '200px',
  ...rest
}: CountryAsyncSelectProps) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      initialValue={withAll ? 'All' : undefined}
      style={{ width, marginRight: 16 }}
      {...rest}
    >
      <AsyncSelect<CountryDto>
        label={noLabel ? undefined : t('labels.Country')}
        disabled={disabled}
        prepareOption={prepareOption}
        onChange={(v) => submit?.(v)}
        withAll={withAll}
        mode={mode}
        getData={fetchPageableEntity<CountryDto>('admin/countries')}
      />
    </Form.Item>
  )
}
