import {
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Card,
  InputRef,
  Form,
  Button,
  InputNumber,
  Select,
} from 'antd'
import { axiosInstance } from 'api'
import { useEffect, useState, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import uuidStartsWithLetter from '@/utils/customUUID'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useForm } from 'antd/es/form/Form'
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import MultiLanguageInputBase from '@components/MultiLanguageInputBase'
import { useTranslation } from 'react-i18next'
import { coords, clearSelection, setSelection } from '@/utils/svgUtils'
import styles from '@pages/event/styles.module.less'
import { parseInt } from 'lodash'
import { castType } from '@/utils/cast'
import { EventCreateResponseLanguagesEnum } from '@/openapi'

type props = {
  stageDefinitionID?: number | null
  stageDefinitionCode?: number | null
}

type RowType = {
  [key: string]: {
    seatIDs?: string[]
    labelIDs?: string[]
  }
}
type SeatType = {
  [key: string]: {
    seatNumber?: string
    rowNum?: string
    capacity?: number
    x?: string | number | null
    y?: string | number | null
    rowLabelID?: string
  }
}
type Stage = {
  svgString: string
  seats: SeatType
  HTML: any
  container: any
  rows: RowType
  seatsInRow: {
    [key: string]: string[]
  }
}

export const SetDefinitions = ({
  stageDefinitionID,
  stageDefinitionCode,
}: props) => {
  const definitionWrapperRef = useRef<HTMLDivElement>(null)
  const selectionWrapperRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<Element>()
  const [isModalOpen, setModalOpen] = useState({
    seat: false,
    capacity: false,
    row: false,
    rect: false,
    setRowToSelected: false,
    setContainerToSelected: false,
    changeElementType: false,
  })
  const containers = useRef<string[]>([])
  const [selectedRow, setSelectedRow] = useState('')
  const [selectedRect, setSelectedRect] = useState('')
  const stage = useRef<Stage>({
    svgString: '',
    seats: {},
    HTML: null,
    container: null,
    rows: {},
    seatsInRow: {},
  })
  const [capacityInputValue, capacityInputHandler] = useState<number | null>(1)
  const inputRef = useRef<InputRef>(null)
  const selectedSeats = useRef<string[]>([])
  const [isSeatsSelected, setIsSeatsSelected] = useState(false)
  const selectionFrame = useRef<HTMLDivElement | null>(null)
  const urlParams = useParams()
  const { appLang, languages } = useLanguageContext()
  const [form] = useForm()
  const { t } = useTranslation()
  const [modalForm] = Form.useForm()
  const allSelectedIs = useMemo(() => {
    if (!isSeatsSelected) return ''
    const arr = []
    for (const id of selectedSeats.current) {
      const ele = svgRef.current!.querySelector<SVGElement>(`#${id}`)

      arr.push(ele?.dataset.elementType)
    }

    if (arr.every((item) => item === 'seat')) {
      return 'seat'
    } else if (arr.every((item) => item === 'container')) {
      return 'container'
    } else if (arr.every((item) => item === 'label')) {
      return 'label'
    } else if (arr.every((item) => item === 'other')) {
      return 'other'
    } else {
      return ''
    }
  }, [selectedSeats, isSeatsSelected])
  const wheelDeltaRef = useRef(4)
  const initialViewBox = useRef('')

  useEffect(() => {
    const getStageDefinition = async (stageDefinitionID: number | null) => {
      try {
        const response = await axiosInstance.get(
          `admin/stage-definitions/${stageDefinitionID}`,
        )

        if (response) {
          let svgString = response.data.definition
          attachDefinitionToWrapper(svgString)

          if (urlParams.id) {
            const gEle = svgRef.current!.querySelector<SVGElement>('g')
            const ellipses = gEle!.getElementsByTagName('ellipse')
            const rects = gEle!.getElementsByTagName('rect')
            const textElements = gEle!.getElementsByTagName('text')
            stage.current!.svgString = svgString
            if (ellipses.length > 0) {
              for (let i = 0; i < ellipses.length; i++) {
                const ellipse = ellipses.item(i)
                const id = ellipse!.id
                if (stage.current!.seats[id] === undefined) {
                  stage.current!.seats[id] = {}
                }
                let xCoord: string | number | null = ellipse!.getAttribute('cx')
                if (xCoord) {
                  xCoord = parseFloat(xCoord)
                }
                let yCoord: string | number | null = ellipse!.getAttribute('cy')
                if (yCoord) {
                  yCoord = parseFloat(yCoord)
                }
                stage.current!.seats[id].x = xCoord
                stage.current!.seats[id].y = yCoord
                let rowNum = ellipse!.getAttribute('rownum')
                if (rowNum) stage.current!.seats[id].rowNum = rowNum

                stage.current!.seats[id].seatNumber =
                  ellipse!.dataset.place ?? ''
                stage.current!.seats[id].capacity = ellipse!.dataset.capacity
                  ? parseInt(ellipse!.dataset.capacity)
                  : 1
                stage.current!.seats[id].rowLabelID =
                  ellipse!.dataset.rowLabelId ?? ''
              }
            }
            if (textElements.length > 0) {
              for (let i = 0; i < textElements.length; i++) {
                const ele = textElements.item(i)

                const dataSetRowLabel = ele!.dataset.rowLabel
                if (dataSetRowLabel) {
                  if (stage.current.rows[dataSetRowLabel] === undefined) {
                    stage.current.rows[dataSetRowLabel] = {
                      labelIDs: [],
                    }
                  }

                  const rowLabelEleID = ele!.id
                  stage.current!.seatsInRow[rowLabelEleID] = []
                  stage.current.rows[dataSetRowLabel].labelIDs!.push(
                    rowLabelEleID,
                  )

                  for (const key in stage.current!.seats) {
                    const seat = stage.current!.seats[key]

                    if (seat.rowNum && seat.rowNum === dataSetRowLabel) {
                      stage.current!.seatsInRow[rowLabelEleID].push(key)
                    }
                  }

                  // ele!.addEventListener('click', rowLabelClickHandler)
                }

                const rectID = ele!.getAttribute('to')
                if (rectID) {
                  const rect: SVGElement | null = svgRef.current!.querySelector(
                    `#${rectID}`,
                  )
                  if (rect) {
                    let definition: any = rect.dataset.definition
                    if (definition) {
                      try {
                        definition = JSON.parse(decodeURIComponent(definition))
                        ele!.innerHTML = definition.name['value' + appLang]
                      } catch (e) {
                        continue
                      }
                    }
                  }
                }
              }
            }
            // addListeners(ellipses, 'click', ellipseClickHandler)
            // addListeners(rects, 'click', rectClickHandler)

            return
          }

          stage.current!.svgString = svgString
          stage.current!.rows = {}

          svgRef.current = definitionWrapperRef.current!.children[0]

          const ellipses = svgRef.current!.getElementsByTagName('ellipse')
          const rects = svgRef.current!.getElementsByTagName('rect')

          if (ellipses.length > 0) {
            for (let i = 0; i < ellipses.length; i++) {
              const ellipse = ellipses.item(i)
              if (ellipse) {
                if (stage.current!.seats[ellipse.id] === undefined) {
                  stage.current!.seats[ellipse.id] = {}
                }
                ellipse.style.cursor = 'pointer'
                const ellipseX = ellipse.getAttribute('cx')
                const ellipseY = ellipse.getAttribute('cy')
                stage.current!.seats[ellipse.id].x = ellipseX
                stage.current!.seats[ellipse.id].y = ellipseY
              }
            }
            addListeners(ellipses, 'click', seatClickHandler)
          }

          if (rects.length > 0) {
            for (let i = 0; i < rects.length; i++) {
              const rect = rects.item(i)
              if (rect) {
                rect.style.cursor = 'pointer'
              }
            }
            addListeners(rects, 'click', rectClickHandler)
          }
        }
      } catch (e) {
        message.error('Get definition error!')
        console.log('error \n', e)
      }
    }

    if (stageDefinitionID) {
      getStageDefinition(stageDefinitionID)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }, [isModalOpen])

  const setModalInitialValues = (type?: string) => {
    if (!type) return

    if (type === 'seat') {
      if (selectedSeats.current!.length === 1) {
        const ele = svgRef.current!.querySelector(
          `#${selectedSeats.current![0]}`,
        ) as SVGElement
        if (ele) {
          const seat = ele.dataset.place
          if (seat) {
            modalForm.setFieldValue('seat', seat)
          }
        }
      }
    } else if (type === 'capacity') {
      if (selectedSeats.current!.length === 1) {
        const ele = svgRef.current!.querySelector(
          `#${selectedSeats.current![0]}`,
        ) as SVGElement
        if (ele) {
          const capacity = ele.dataset.capacity ? ele.dataset.capacity : 1
          modalForm.setFieldValue('capacity', capacity)
        }
      } else {
        modalForm.setFieldValue('capacity', 1)
      }
    }
  }

  const toggleModal = (type?: string) => {
    setModalOpen(
      (prevState: {
        seat: boolean
        capacity: boolean
        row: boolean
        rect: boolean
        setRowToSelected: boolean
        setContainerToSelected: boolean
        changeElementType: boolean
      }) => {
        if (type === undefined) {
          const resetModal = { ...prevState }

          for (const key in resetModal) {
            // @ts-ignore
            resetModal[key] = false
          }
          return resetModal
        }
        // @ts-ignore
        const newValue = !prevState[type]
        if (newValue) {
          setModalInitialValues(type)
        }
        prevState = {
          ...prevState,
          [type]: newValue,
        }

        return prevState
      },
    )
  }

  const changeRow = (rowNum: string) => {
    for (const rowKey in stage.current.rows) {
      const row = stage.current.rows[rowKey]
      if (row.labelIDs!.includes(selectedRow)) {
        const idx = row.labelIDs!.indexOf(selectedRow)
        row.labelIDs!.splice(idx, 1)
      }
    }
    if (stage.current.rows[rowNum] === undefined) {
      stage.current.rows[rowNum] = {
        labelIDs: [],
      }
    }
    stage.current.rows[rowNum].labelIDs!.push(selectedRow)

    const rowLabel = svgRef.current!.querySelector<SVGElement>(
      `#${selectedRow}`,
    )
    if (rowLabel) {
      rowLabel.innerHTML = `${rowNum}`
      rowLabel.dataset.rowLabel = rowNum
    }

    const ellipses = svgRef.current!.querySelectorAll<SVGElement>(
      `[data-row-label-id=${selectedRow}]`,
    )

    for (let i = 0; i < ellipses.length; i++) {
      const ellipse = ellipses.item(i)
      if (ellipse) {
        ellipse.setAttribute('rownum', rowNum)
      }
    }

    setSelectedRow('')
    toggleModal('row')
  }

  const closeModal = (type: string) => {
    switch (type) {
      case 'seat':
        toggleModal('seat')
        break
      case 'capacity':
        toggleModal('capacity')
        break
      case 'row':
        toggleModal('row')
        break
      case 'setRowToSelected':
        toggleModal('setRowToSelected')
        break
      case 'setContainerToSelected':
        toggleModal('setContainerToSelected')
        break
      case 'changeElementType':
        toggleModal('changeElementType')
        break
    }
    modalForm.resetFields()
  }

  const handleRectModalOk = async (definition: any) => {
    if (definition['undefined']) delete definition['undefined']
    const gEle = svgRef.current!.querySelector<SVGGElement>('g')
    const textElements = svgRef.current!.getElementsByTagName('text')
    let elementIsExist = false

    for (let i = 0; i < textElements.length; i++) {
      const textEle = textElements.item(i)
      if (textEle) {
        const toAttr = textEle.getAttribute('to')
        if (toAttr === selectedRect) {
          textEle.innerHTML = definition.name[`value${appLang}`]
          const rect: SVGElement | null = svgRef.current!.querySelector(
            `#${toAttr}`,
          )
          if (rect) {
            rect.dataset.definition = encodeURIComponent(
              JSON.stringify(definition),
            )
            rect.dataset.capacity = String(capacityInputValue)
            elementIsExist = true
          }
        }
      }
    }
    if (!elementIsExist) {
      const rect: SVGElement | null = svgRef.current!.querySelector(
        `#${selectedRect}`,
      )
      if (rect) {
        const elementType = rect.dataset.elementType ?? ''
        rect.dataset.capacity = String(capacityInputValue)
        if (elementType !== 'container') {
          let rectX: any = rect.getAttribute('x')
          rectX = parseFloat(rectX)
          let rectY: any = rect.getAttribute('y')
          rectY = parseFloat(rectY)
          let rectWidth: any = rect.getAttribute('width')
          rectWidth = parseFloat(rectWidth)
          let rectHeight: any = rect.getAttribute('height')
          rectHeight = parseFloat(rectHeight)
          const textEle = document.createElement('text')

          const textFontSize = (rectHeight / 2) * 0.7
          const sizes = getTextElementSizes(
            definition.name[`value${appLang}`],
            textFontSize,
          )
          textEle.setAttribute(
            'x',
            `${rectX + rectWidth / 2 - sizes.width / 2}`,
          )
          textEle.setAttribute(
            'y',
            `${rectY + rectHeight / 2 + sizes.height * 0.3}`,
          )
          textEle.setAttribute('style', `font-size: ${textFontSize}px`)
          textEle.setAttribute('to', selectedRect)
          textEle.innerHTML = definition.name[`value${appLang}`]

          if (gEle) {
            gEle.appendChild(textEle)
          }
        }

        rect.dataset.definition = encodeURIComponent(JSON.stringify(definition))
      }
    }

    removeSelection()

    try {
      await putDefinition()
    } catch (e) {
      console.log('error \n', e)
    }

    capacityInputHandler(1)
    reAttachDefinition()
    toggleModal('rect')
  }

  const handleRectModalCancel = () => {
    capacityInputHandler(1)
    toggleModal('rect')
  }

  const addRowLabel = (x?: number, y?: number): { id: string } => {
    const labelElement = document.createElement('text')
    labelElement.setAttribute('x', x ? String(x) : '0')
    labelElement.setAttribute('y', y ? String(y) : '0')
    labelElement.setAttribute('font-size', '12px')
    labelElement.classList.add('row-label')
    labelElement.dataset.rowLabel = ''
    labelElement.id = uuidStartsWithLetter()
    labelElement.innerText = t`SetDefinitions.moveAndSetRow`

    const gEle = svgRef.current!.querySelector('g')
    if (gEle) {
      gEle.append(labelElement)

      reAttachDefinition()
    }

    return {
      id: labelElement.id,
    }
  }

  const removeRowLabel = async () => {
    const elements = svgRef.current!.querySelectorAll(
      `[data-row-label-id='${selectedRow}']`,
    )
    elements.forEach((ele) => {
      if (ele instanceof SVGElement) {
        delete ele.dataset.rowLabelId
        ele.removeAttribute('rownum')
      }
    })
    const rowLabel = svgRef.current!.querySelector(`#${selectedRow}`)
    if (rowLabel) {
      rowLabel.remove()
    }
    try {
      await putDefinition()
    } catch (e) {
      message.error('Error!')
      console.log('error \n', e)
    }
    closeModal('row')
  }

  type Modal = {
    name: string
    title: string
    open: boolean
    onOk?: () => void
    onCancel: (type: string) => void
    footer?: any[]
    inputs: {
      name: string
      placeholder: string
      onChange?: any
      value?: any
      ref?: any
      type?: string
      min?: number
      onPressEnter?: () => void
      items?: { value: string; label: string }[]
    }[]
  }
  const modals: Modal[] = [
    {
      name: 'seat',
      title: t('seat'),
      open: isModalOpen.seat,
      onCancel: closeModal,
      inputs: [
        {
          name: 'seat',
          placeholder: t('seat'),
          type: 'input',
          onPressEnter: modalForm.submit,
        },
      ],
    },
    {
      name: 'capacity',
      title: t('capacity'),
      open: isModalOpen.capacity,
      onCancel: closeModal,
      inputs: [
        {
          name: 'capacity',
          type: 'number',
          placeholder: t('SetDefinitions.setCapacity') || '',
          min: 1,
          onPressEnter: modalForm.submit,
        },
      ],
    },
    {
      name: 'row',
      title: t('row'),
      open: isModalOpen.row,
      onCancel: closeModal,
      footer: [
        <Row key={'footer-modal-row'} justify="end" style={{ marginTop: 30 }}>
          <Col span={24}>
            <Space size="small">
              <Form.Item style={{ marginRight: 10, marginBottom: 0 }}>
                <Button danger onClick={removeRowLabel}>{t`delete`}</Button>
              </Form.Item>
              <Form.Item style={{ marginRight: 10, marginBottom: 0 }}>
                <Button onClick={() => closeModal('row')}>{t`cancel`}</Button>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  onClick={modalForm.submit}
                >{t`ok`}</Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>,
      ],
      inputs: [
        {
          name: 'row',
          placeholder: t('row'),
          type: 'input',
          onPressEnter: modalForm.submit,
        },
      ],
    },
    {
      name: 'setRowToSelected',
      title: t`SetDefinitions.setRowToSelected`,
      open: isModalOpen.setRowToSelected,
      onCancel: closeModal,
      inputs: [
        {
          name: 'rowToSelected',
          placeholder: t('row'),
          type: 'input',
        },
      ],
    },
    {
      name: 'setContainerToSelected',
      title: t`SetDefinitions.setContainerToSelected`,
      open: isModalOpen.setContainerToSelected,
      onCancel: closeModal,
      inputs: [
        {
          name: 'containerToSelected',
          placeholder: t('SetDefinitions.selectContainer'),
          type: 'containersSelect',
        },
      ],
    },
    {
      name: 'changeElementType',
      title: t`SetDefinitions.changeElementType`,
      open: isModalOpen.changeElementType,
      onCancel: closeModal,
      inputs: [
        {
          name: 'changeElementType',
          placeholder: t('SetDefinitions.changeElementType'),
          type: 'elementTypeSelect',
          items: [
            { value: 'seat', label: t`seat` },
            { value: 'container', label: t`container` },
            { value: 'other', label: t`other` },
          ],
        },
      ],
    },
  ]

  const setInitialViewBox = (definition: string): string => {
    return definition.replace(
      /viewBox=\"([^"]*)\"/gim,
      `viewBox="${initialViewBox.current!}"`,
    )
  }

  const putDefinition = async (): Promise<any> => {
    let div: Element | null = document.createElement('div')
    const clone = svgRef.current!.cloneNode(true)
    div.appendChild(clone)
    let ele = div.querySelector('svg')
    const splitted = initialViewBox.current.split(' ')
    const viewBoxObj = {
      x: splitted[0],
      y: splitted[1],
      width: splitted[2],
      height: splitted[3],
    }
    ele!.setAttribute('width', viewBoxObj.width)
    ele!.setAttribute('height', viewBoxObj.height)

    let definition = ele!.outerHTML
    div = null
    ele = null
    definition = removeTextAttribute(definition)
    definition = setInitialViewBox(definition)

    try {
      return await axiosInstance.put(
        `admin/stage-definitions/${stageDefinitionID}`,
        {
          id: stageDefinitionID,
          code: stageDefinitionCode,
          definition,
        },
      )
    } catch (e) {
      message.error('Definition update failed!')
      console.log('error \n', e)
      return e
    }
  }

  const removeTextAttribute = (str: string): string => {
    return str.replace(
      /[^<text]xmlns="http:\/\/www.w3.org\/1999\/xhtml"[^>]/gm,
      ' ',
    )
  }

  const attachDefinitionToWrapper = (str: string): void => {
    if (definitionWrapperRef.current!.children.length > 0) {
      definitionWrapperRef.current!.replaceChildren()
    }

    str = removeTextAttribute(str)

    stage.current.svgString = str

    const temp = document.createElement('template')
    temp.innerHTML = str
    for (let i = 0; i < temp.content.children.length; i++) {
      const ele = temp.content.children.item(i)

      if (ele && ele.tagName === 'svg') {
        definitionWrapperRef.current!.append(ele)
        svgRef.current = ele
        break
      }
    }

    if (!svgRef.current) {
      message.error('Something went wrong!')
      console.error('no svgRef.current')
      return
    }

    definitionWrapperRef.current!.style.cursor = 'grab'

    const currentViewBox = svgRef.current!.getAttribute('viewBox')
    initialViewBox.current! = currentViewBox ?? ''
    let svgWidth: number = parseFloat(currentViewBox!.split(' ')[2]) ?? 0
    let svgHeight: number = parseFloat(currentViewBox!.split(' ')[3]) ?? 0

    let viewBox = {
      x: 0,
      y: 0,
      w: svgWidth,
      h: svgHeight,
    }
    svgRef.current!.setAttribute(
      'viewBox',
      `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`,
    )
    const svgSize = {
      w: svgWidth,
      h: svgHeight,
    }
    let isPanning = false
    let startPoint = {
      x: 0,
      y: 0,
    }
    let endPoint = {
      x: 0,
      y: 0,
    }
    let scale = 1

    const svgContainerMouseWheel = function (e: WheelEvent) {
      const offsetX =
        definitionWrapperRef.current!.getBoundingClientRect().x +
        definitionWrapperRef.current!.getBoundingClientRect().width / 2
      const offsetY =
        definitionWrapperRef.current!.getBoundingClientRect().y +
        definitionWrapperRef.current!.getBoundingClientRect().height / 2

      e.preventDefault()
      let deltaY = e.deltaY || wheelDeltaRef.current
      const w = viewBox.w
      const h = viewBox.h
      const mx = e.offsetX || offsetX //mouse x
      const my = e.offsetY || offsetY
      const dw = w * Math.sign(deltaY) * 0.05
      const dh = h * Math.sign(deltaY) * 0.05
      const dx = (dw * mx) / svgSize.w
      const dy = (dh * my) / svgSize.h
      viewBox = {
        x: viewBox.x + dx,
        y: viewBox.y + dy,
        w: viewBox.w - dw,
        h: viewBox.h - dh,
      }
      scale = svgSize.w / viewBox.w
      svgRef.current!.setAttribute(
        'viewBox',
        `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`,
      )
    }

    const svgContainerMouseDown = function (e: MouseEvent) {
      if (e.shiftKey) return

      definitionWrapperRef.current!.style.cursor = 'grabbing'

      isPanning = true
      startPoint = {
        x: e.x,
        y: e.y,
      }
    }

    const svgContainerMouseMove = function (e: MouseEvent) {
      if (e.shiftKey) return

      if (isPanning) {
        endPoint = {
          x: e.x,
          y: e.y,
        }
        const dx = (startPoint.x - endPoint.x) / scale
        const dy = (startPoint.y - endPoint.y) / scale
        const movedViewBox = {
          x: viewBox.x + dx,
          y: viewBox.y + dy,
          w: viewBox.w,
          h: viewBox.h,
        }
        svgRef.current!.setAttribute(
          'viewBox',
          `${movedViewBox.x} ${movedViewBox.y} ${movedViewBox.w} ${movedViewBox.h}`,
        )
      }
    }

    const svgContainerMouseUp = function (e: MouseEvent) {
      if (e.shiftKey) return

      definitionWrapperRef.current!.style.cursor = 'grab'

      if (isPanning) {
        endPoint = {
          x: e.x,
          y: e.y,
        }
        const dx = (startPoint.x - endPoint.x) / scale
        const dy = (startPoint.y - endPoint.y) / scale
        viewBox = {
          x: viewBox.x + dx,
          y: viewBox.y + dy,
          w: viewBox.w,
          h: viewBox.h,
        }
        svgRef.current!.setAttribute(
          'viewBox',
          `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`,
        )
        isPanning = false
      }
    }

    const svgContainerMouseLeave = function (e: MouseEvent) {
      if (e.shiftKey) return

      isPanning = false
    }

    definitionWrapperRef.current!.addEventListener(
      'wheel',
      svgContainerMouseWheel,
    )
    definitionWrapperRef.current!.addEventListener(
      'mousedown',
      svgContainerMouseDown,
    )
    definitionWrapperRef.current!.addEventListener(
      'mousemove',
      svgContainerMouseMove,
    )
    definitionWrapperRef.current!.addEventListener(
      'mouseup',
      svgContainerMouseUp,
    )
    definitionWrapperRef.current!.addEventListener(
      'mouseleave',
      svgContainerMouseLeave,
    )

    svgRef.current!.setAttribute('width', '100%')
    svgRef.current!.setAttribute('height', '100%')

    let msdown = false,
      msx = 0,
      msy = 0,
      eventPageX1 = 0,
      eventPageY1 = 0

    function winMouseUpListener() {
      msdown = false
      selectionFrame.current!.style.visibility = 'hidden'
      window.removeEventListener('mouseup', winMouseUpListener)
      window.removeEventListener('mouseleave', winMouseUpListener)
    }

    function mouseLeaveListener() {
      window.addEventListener('mouseup', winMouseUpListener)
      window.addEventListener('mouseleave', winMouseUpListener)
    }

    const mouseDownListener = function (this: HTMLDivElement, e: MouseEvent) {
      if (!e.shiftKey) return

      msdown = true
      const mousexy = coords(
        e,
        this.getBoundingClientRect().x,
        this.getBoundingClientRect().y,
      )
      msx = mousexy[0]
      msy = mousexy[1]
      eventPageX1 = mousexy[2]
      eventPageY1 = mousexy[3]
      return false
    }

    const mouseMoveListener = function (this: HTMLDivElement, e: MouseEvent) {
      if (!e.shiftKey) return

      let x1, x2, y1, y2

      const mousexy = coords(
        e,
        this.getBoundingClientRect().x,
        this.getBoundingClientRect().y,
      )
      x1 = msx
      y1 = msy
      x2 = mousexy[0]
      y2 = mousexy[1]
      if (x1 === x2) {
        return
      }
      if (y1 === y2) {
        return
      }
      if (x1 > x2) {
        x1 = x1 + x2
        x2 = x1 - x2
        x1 = x1 - x2
      }
      if (y1 > y2) {
        y1 = y1 + y2
        y2 = y1 - y2
        y1 = y1 - y2
      }

      selectionFrame.current!.style.top = y1 + 'px'
      selectionFrame.current!.style.left = x1 + 'px'
      selectionFrame.current!.style.width = x2 - x1 + 'px'
      selectionFrame.current!.style.height = y2 - y1 + 'px'
      selectionFrame.current!.style.visibility = msdown ? 'visible' : 'hidden'
    }

    const mouseUpListener = function (this: HTMLDivElement, e: MouseEvent) {
      if (!e.shiftKey) return

      if (e.target instanceof SVGTextElement) {
        msdown = false
        selectionFrame.current!.style.visibility = 'hidden'
        return
      }
      const viewBox = svgRef.current!.getAttribute('viewBox')
      msdown = false
      const mousexy = coords(
        e,
        this.getBoundingClientRect().x,
        this.getBoundingClientRect().y,
      )

      doSelection({
        x1: msx,
        y1: msy,
        x2: mousexy[0],
        y2: mousexy[1],
        eventPageX1,
        eventPageY1,
        eventPageX2: mousexy[2],
        eventPageY2: mousexy[3],
      })
      selectionFrame.current!.style.visibility = msdown ? 'visible' : 'hidden'
    }

    selectionWrapperRef.current!.addEventListener(
      'mousedown',
      mouseDownListener,
    )
    selectionWrapperRef.current!.addEventListener(
      'mousemove',
      mouseMoveListener,
    )
    selectionWrapperRef.current!.addEventListener('mouseup', mouseUpListener)
    selectionWrapperRef.current!.addEventListener(
      'mouseleave',
      mouseLeaveListener,
    )

    const gEle = svgRef.current!.querySelector<SVGGElement>('g')
    if (gEle) {
      const seats = gEle.querySelectorAll('[data-element-type="seat"]')
      addListeners(seats, 'click', seatClickHandler)
      const rowLabels = gEle.getElementsByClassName(
        'row-label',
      ) as HTMLCollectionOf<SVGTextElement>
      addListeners(rowLabels, 'mousedown', moveRowLabel)
      const rects = gEle.getElementsByTagName('rect')
      addListeners(rects, 'click', rectClickHandler)

      for (let i = 0; i < rects.length; i++) {
        const rect = rects.item(i)

        if (rect) {
          containers.current.push(rect.id)

          const pointerEventsAttribute = rect.getAttribute('pointer-events')
          if (
            pointerEventsAttribute &&
            pointerEventsAttribute === 'visibleStroke'
          ) {
            rect.setAttribute('pointer-events', 'all')
          }
        }
      }
    }
  }

  const addListeners = (
    collection: HTMLCollectionOf<SVGElement> | NodeListOf<Element>,
    type: string,
    listener: (e: any) => void,
  ) => {
    for (let i = 0; i < collection.length; i++) {
      const item = collection.item(i) as HTMLElement
      if (item) {
        item.style.cursor = 'pointer'
        item.addEventListener(type, listener)
      }
    }
  }

  const getTextElementSizes = (
    text: string,
    fontSize = 12,
  ): { width: number; height: number } => {
    const tempWrapper = document.createElement('div')
    tempWrapper.style.position = 'absolute'
    tempWrapper.style.top = '-9999px'
    tempWrapper.style.left = '-9999px'
    document.body.appendChild(tempWrapper)

    const textElement = document.createElement('text')
    if (fontSize) {
      textElement.setAttribute('style', `font-size: ${fontSize}px`)
    }
    textElement.innerText = text

    tempWrapper.appendChild(textElement)
    const params = textElement.getBoundingClientRect()
    const width = Math.round(params.width)
    const height = Math.round(params.height)

    document.body.removeChild(tempWrapper)

    return {
      width,
      height,
    }
  }

  const addAttributesToSeat = (
    selectedPlace: string,
    seatNumber?: string,
    capacity?: number,
    rowNumber?: string,
  ) => {
    const ellipse = svgRef.current!.querySelector<SVGEllipseElement>(
      `#${selectedPlace}`,
    )
    const ellipseCoords = { x: 0, y: 0, rx: 0, ry: 0 }

    if (stage.current!.seats[selectedPlace] === undefined) {
      stage.current!.seats[selectedPlace] = {}
    }
    stage.current!.seats[selectedPlace].seatNumber = seatNumber
    stage.current!.seats[selectedPlace].rowNum = rowNumber
    stage.current!.seats[selectedPlace].capacity = capacity

    if (ellipse) {
      if (seatNumber) {
        ellipse.setAttribute('data-place', seatNumber)
      }
      if (capacity) {
        ellipse.setAttribute('data-capacity', String(capacity))
      }
      const cx = ellipse.getAttribute('cx')
      if (cx) {
        ellipseCoords.x = parseFloat(cx)
      }
      const cy = ellipse.getAttribute('cy')
      if (cy) {
        ellipseCoords.y = parseFloat(cy)
      }
      const rx = ellipse.getAttribute('rx')
      if (rx) {
        ellipseCoords.rx = parseFloat(rx)
      }
      const ry = ellipse.getAttribute('ry')
      if (ry) {
        ellipseCoords.ry = parseFloat(ry)
      }
    }

    if (seatNumber) {
      const existingTextEle = svgRef.current!.querySelector(
        `[data-to-seat='${selectedPlace}']`,
      )
      if (existingTextEle) {
        existingTextEle.remove()
      }

      const textEle = document.createElement('text')
      let textFontSize = ellipseCoords.ry * 0.8
      let sizes = getTextElementSizes(seatNumber, textFontSize)
      if (sizes.width > ellipseCoords.rx) {
        textFontSize =
          textFontSize * ((ellipseCoords.rx * 2) / sizes.width) * 0.8
        sizes = getTextElementSizes(seatNumber, textFontSize)
      }
      textEle.setAttribute('x', ellipseCoords.x - sizes.width / 2 + '')
      textEle.setAttribute(
        'y',
        ellipseCoords.y + sizes.height / 2 - sizes.height * 0.15 + '',
      )
      textEle.setAttribute('data-to-seat', selectedPlace)
      textEle.setAttribute('style', `font-size: ${textFontSize}px`)
      textEle.innerText = seatNumber

      const gEle = svgRef.current!.querySelector<SVGGElement>('g')
      if (stage.current!.seats[selectedPlace].seatNumber !== undefined) {
        if (gEle) {
          for (let i = 0; i < gEle.children.length; i++) {
            const child = gEle.children.item(i) as SVGElement
            if (child) {
              if (child.dataset.toSeat === selectedPlace) {
                gEle.removeChild(child)
              }
            }
          }
        }
      }

      if (gEle) {
        gEle.append(textEle)
      }
    }

    reAttachDefinition()
  }

  const seatClickHandler = (e: Event) => {
    e.stopPropagation()
    const ellipse = e.target
    if (ellipse instanceof SVGElement) {
      clearSelection(selectedSeats.current)
      const id = ellipse.id
      const idPosition = selectedSeats.current.indexOf(id)
      if (idPosition >= 0) {
        selectedSeats.current.splice(idPosition, 1)
      } else {
        selectedSeats.current.push(id)
      }
      setSelection(selectedSeats.current)
    }
    if (selectedSeats.current.length > 0) {
      setIsSeatsSelected(true)
    } else if (selectedSeats.current.length === 0) {
      setIsSeatsSelected(false)
    }
  }

  const rowLabelClickHandler = (e: Event) => {
    e.stopPropagation()
    const label = e.target
    if (label instanceof SVGElement) {
      const row = label.dataset.rowLabel
      const id = label.getAttribute('id')
      if (id) {
        setSelectedRow(id)
      }
      if (row) {
        modalForm.setFieldValue('row', row)
      }
    }
    toggleModal('row')
  }

  const rectClickHandler = (e: Event) => {
    const rect = e.target

    if (rect instanceof SVGRectElement) {
      let definitionValue: any = rect.dataset.definition
      const capacity = rect.dataset.capacity
      const rectID = rect.id
      if (definitionValue) {
        definitionValue = JSON.parse(decodeURIComponent(definitionValue))
        const values = { ...definitionValue.name }
        values.definition_list = []
        for (const key in definitionValue) {
          if (key !== 'name') {
            values.definition_list.push({
              key,
              ...definitionValue[key],
            })
          }
        }
        form.setFieldsValue(values)
      }
      if (rectID) {
        setSelectedRect(rectID)
      }
      if (capacity) {
        capacityInputHandler(parseInt(capacity))
      }

      const id = rect.id
      const idPosition = selectedSeats.current.indexOf(id)
      if (idPosition >= 0) {
        selectedSeats.current.splice(idPosition, 1)
        clearSelection([id])
      } else {
        selectedSeats.current.push(id)
      }
      setSelection(selectedSeats.current)
      if (selectedSeats.current.length > 0) {
        setIsSeatsSelected(true)
      } else if (selectedSeats.current.length === 0) {
        setIsSeatsSelected(false)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }, [isModalOpen])

  const onDefinitionFinish = async (values: any) => {
    const definition: { name: { [key: string]: any }; [key: string]: any } = {
      name: {},
    }
    const { definition_list } = values

    for (const key in values) {
      if (key !== 'definition_list') {
        definition.name[key] = values[key] ? values[key] : ''
      }
    }

    if (definition_list && definition_list.length > 0) {
      for (const item of definition_list) {
        definition[item.key] = { ...item }
        delete definition[item.key].key
        delete definition[item.key].value
      }
    }

    await handleRectModalOk(definition)
  }

  const removeSelection = () => {
    clearSelection(selectedSeats.current!)
    selectedSeats.current! = []
    setIsSeatsSelected(false)
  }

  const doSelection = ({
    x1,
    y1,
    x2,
    y2,
    eventPageX1,
    eventPageY1,
    eventPageX2,
    eventPageY2,
  }: {
    x1: number
    y1: number
    x2: number
    y2: number
    eventPageX1: number
    eventPageY1: number
    eventPageX2: number
    eventPageY2: number
  }) => {
    x1 = eventPageX1 - window.scrollX
    y1 = eventPageY1 - window.scrollY
    x2 = eventPageX2 - window.scrollX
    y2 = eventPageY2 - window.scrollY

    if (x1 === x2) {
      return
    }
    if (y1 === y2) {
      return
    }
    if (x1 > x2) {
      x1 = x1 + x2
      x2 = x1 - x2
      x1 = x1 - x2
    }
    if (y1 > y2) {
      y1 = y1 + y2
      y2 = y1 - y2
      y1 = y1 - y2
    }
    const selected: string[] = []

    const elements = definitionWrapperRef.current!.getElementsByTagName('*')
    for (let i = 0; i < elements.length; i++) {
      const item = elements.item(i) as SVGElement
      if (item.tagName === 'rect' || item.tagName === 'text') continue
      const itemRect = item.getBoundingClientRect()

      if (item instanceof SVGElement) {
        const id = item.getAttribute('id')
        if (!id) continue

        let x, y
        x = itemRect.left
        y = itemRect.top

        if (x && y && x >= x1 && x <= x2 && y >= y1 && y <= y2) {
          if (selectedSeats.current.indexOf(id!) >= 0) {
            continue
          }

          selectedSeats.current.push(id!)
          item.setAttribute('stroke', 'red')
          item.setAttribute('stroke-width', '3')
          item.dataset.selected = 'true'
        }
      }
    }

    selectedSeats.current = [...selectedSeats.current, ...selected]
    if (selectedSeats.current.length > 0) {
      setIsSeatsSelected(true)
    } else if (selectedSeats.current.length === 0) {
      setIsSeatsSelected(false)
    }
  }

  const changeElementsType = (type: string) => {
    if (selectedSeats.current.length > 0) {
      for (const seatID of selectedSeats.current) {
        const ele = svgRef.current!.querySelector<SVGElement>(`#${seatID}`)

        if (ele) {
          ele.dataset.elementType = type

          if (type === 'container') {
            // ele.style.pointerEvents = 'visibleStroke'
          }
        }
      }
    }
  }

  type ModalFormValues = {
    seat?: string
    capacity?: number
    row?: string
    seatToSelected?: string
    rowToSelected?: string
    containerToSelected?: string
    changeElementType?: string
  }
  const modalFormOnFinish = async function (values: ModalFormValues) {
    if (values.seatToSelected !== undefined) {
      // mass selection
      for (const seatID of selectedSeats.current!) {
        addAttributesToSeat(
          seatID,
          values.seatToSelected!,
          undefined,
          values.rowToSelected!,
        )
      }
    } else if (values.seat !== undefined) {
      // seat selection
      for (const seatID of selectedSeats.current!) {
        addAttributesToSeat(seatID, values.seat ?? '', values.capacity ?? 1)
      }
    } else if (values.capacity !== undefined) {
      // capacity
      for (const seatID of selectedSeats.current!) {
        addAttributesToSeat(seatID, undefined, values.capacity ?? 1)
      }
    } else if (values.row !== undefined) {
      changeRow(values.row)
    } else if (values.rowToSelected !== undefined) {
      let firstLeft, firstTop, x, y

      for (const seatID of selectedSeats.current!) {
        const element = svgRef.current!.querySelector<SVGElement>(`#${seatID}`)

        if (element) {
          if (element.tagName === 'ellipse') {
            let eleX: string | number = element.getAttribute('cx')!
            eleX = parseFloat(eleX)
            let eleRX: string | number = element.getAttribute('rx')!
            eleRX = parseFloat(eleRX)
            eleX -= eleRX
            eleX -= 20
            let eleY: string | number = element.getAttribute('cy')!
            eleY = parseFloat(eleY)
            let eleRY: string | number = element.getAttribute('ry')!
            eleRY = parseFloat(eleRY)
            const eleID = element.id

            if (x === undefined) {
              x = eleX
              firstLeft = eleID
            } else if (eleX < x) {
              x = eleX
              firstLeft = eleID
            }

            if (y === undefined) {
              y = eleY
              firstTop = eleID
            } else if (eleY < y) {
              y = eleY
              firstTop = eleID
            }
          }
        }
      }

      if (!x || !y || !firstLeft || !firstTop) {
        removeSelection()
        modalForm.resetFields()
        setSelectedRow('')
        toggleModal()
        message.error(t`SetDefinitions.elementNotAvailable`)
        return
      }

      const label = addRowLabel(x, y)
      const labelEle = svgRef.current!.querySelector<SVGTextElement>(
        `#${label.id}`,
      )

      if (labelEle) {
        labelEle.innerHTML = values.rowToSelected
        labelEle.dataset.rowLabel = values.rowToSelected

        if (stage.current.rows[values.rowToSelected] === undefined) {
          stage.current.rows[values.rowToSelected] = {
            labelIDs: [],
          }
        }
        stage.current.rows[values.rowToSelected].labelIDs!.push(label.id)
      }

      for (const seatID of selectedSeats.current!) {
        const element = svgRef.current!.querySelector<SVGElement>(`#${seatID}`)

        if (element) {
          element.setAttribute('rownum', values.rowToSelected)
          const lastIndex =
            stage.current.rows[values.rowToSelected].labelIDs!.length - 1
          element.dataset.rowLabelId =
            stage.current.rows[values.rowToSelected].labelIDs![lastIndex]
          stage.current.seats[seatID].rowNum = values.rowToSelected
        }
      }
    } else if (values.containerToSelected !== undefined) {
      for (const seatID of selectedSeats.current!) {
        const element = svgRef.current!.querySelector<SVGElement>(`#${seatID}`)

        if (element) {
          element.dataset.containerReferenceId = values.containerToSelected
        }
      }
    } else if (values.changeElementType !== undefined) {
      changeElementsType(values.changeElementType)
    }

    removeSelection()

    try {
      await putDefinition()
    } catch (e) {
      message.error('Error!')
      console.log('error \n', e)
    }

    modalForm.resetFields()
    setSelectedRow('')
    toggleModal()
  }

  const reAttachDefinition = () => {
    definitionWrapperRef.current!.innerHTML = svgRef.current!.outerHTML
    svgRef.current =
      definitionWrapperRef.current!.getElementsByTagName('svg')[0]

    const ellipses = svgRef.current.getElementsByTagName('ellipse')
    addListeners(ellipses, 'click', seatClickHandler)
    const rowLabels = svgRef.current.getElementsByClassName(
      'row-label',
    ) as HTMLCollectionOf<SVGTextElement>
    // addListeners(rowLabels, 'click', rowLabelClickHandler)
    addListeners(rowLabels, 'mousedown', moveRowLabel)
    addListeners(rowLabels, 'dragstart', () => false)
    const rects = svgRef.current.getElementsByTagName('rect')
    addListeners(rects, 'click', rectClickHandler)
  }

  const moveRowLabel = function (mouseDownEvent: MouseEvent) {
    mouseDownEvent.stopPropagation()

    const labelTarget: any = mouseDownEvent.target
    const CTM = labelTarget.getScreenCTM()
    const startCoord = getPosition(mouseDownEvent)
    const offset = getPosition(mouseDownEvent)
    offset.x -= parseFloat(labelTarget.getAttributeNS(null, 'x'))
    offset.y -= parseFloat(labelTarget.getAttributeNS(null, 'y'))

    function moveAt(moveAtX: number, moveAtY: number) {
      labelTarget!.setAttributeNS(null, 'x', String(moveAtX - offset.x))
      labelTarget!.setAttributeNS(null, 'y', String(moveAtY - offset.y))
    }

    function getPosition(event: MouseEvent): { x: number; y: number } {
      return {
        x: (event.clientX - CTM.e) / CTM.a,
        y: (event.clientY - CTM.f) / CTM.d,
      }
    }

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      const coord = getPosition(mouseMoveEvent)
      moveAt(coord.x, coord.y)
    }

    // @ts-ignore
    svgRef.current!.addEventListener('mousemove', onMouseMove)

    async function labelTargetOnMouseUp(mouseUpEvent: MouseEvent) {
      const coord = getPosition(mouseUpEvent)
      if (startCoord.x === coord.x && startCoord.y === coord.y) {
        rowLabelClickHandler(mouseUpEvent)
        // setSelectedRow(labelTarget.id)
        // toggleModal('row')
      }
      // @ts-ignore
      svgRef.current!.removeEventListener('mousemove', onMouseMove)
      labelTarget.onmouseup = null

      try {
        await putDefinition()
      } catch (e) {
        message.error('Error!')
        console.log('error \n', e)
      }
    }

    // @ts-ignore
    labelTarget.onmouseup = labelTargetOnMouseUp

    function windowOnMouseUp(e: MouseEvent) {
      labelTargetOnMouseUp(e)
      window.removeEventListener('mouseup', windowOnMouseUp)
    }

    function labelTargetOnMouseLeave() {
      window.addEventListener('mouseup', windowOnMouseUp)
      labelTarget.removeEventListener('mouseleave', labelTargetOnMouseLeave)
    }

    labelTarget.addEventListener('mouseleave', labelTargetOnMouseLeave)
  }

  const onContainerOptionEnter = (id: string) => {
    const container = svgRef.current!.querySelector<SVGElement>(`#${id}`)

    if (container) {
      container.setAttribute('stroke', 'limegreen')
    }
  }

  const onContainerOptionLeave = (id: string) => {
    const container = svgRef.current!.querySelector<SVGElement>(`#${id}`)

    if (container) {
      container.setAttribute('stroke', '#000000')
    }
  }

  const zoomIn = function () {
    wheelDeltaRef.current = 4
    definitionWrapperRef.current!.dispatchEvent(new Event('wheel'))
  }

  const zoomOut = function () {
    wheelDeltaRef.current = -4
    definitionWrapperRef.current!.dispatchEvent(new Event('wheel'))
  }

  return (
    <Card>
      <Modal
        open={isModalOpen.rect}
        footer={[]}
        onCancel={() => {
          form.resetFields()
          toggleModal('rect')
        }}
      >
        <Form
          form={form}
          style={{ marginTop: 20 }}
          name="definition_form"
          autoComplete="off"
          onFinish={onDefinitionFinish}
        >
          <Space
            key="default_inputs"
            style={{ display: 'flex', marginBottom: 8 }}
            align="baseline"
          >
            <Form.Item>
              <Input placeholder="name" disabled />
            </Form.Item>
            <MultiLanguageInputBase
              itemProps={{
                name: 'value',
                style: { marginBottom: 10 },
              }}
              inputProps={{ label: t('value') }}
              languages={castType<EventCreateResponseLanguagesEnum[]>(
                languages,
              )}
              form={form}
            />
          </Space>
          <Form.List name="definition_list">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[
                        {
                          required: true,
                          message:
                            t('SetDefinitions.keyInputRequiredMessage') || '',
                        },
                      ]}
                    >
                      <Input placeholder={t('key') || ''} />
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      {...restField}
                      name={[name, 'value']}
                    >
                      <MultiLanguageInputBase
                        itemProps={{
                          name: [name, 'value'],
                          style: { marginBottom: 10 },
                        }}
                        inputProps={{ label: t('value') }}
                        languages={castType<EventCreateResponseLanguagesEnum[]>(
                          languages,
                        )}
                        form={form}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('SetDefinitions.addField')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Row>
            <Space>
              <div>{t('SetDefinitions.setCapacity')}</div>
              <InputNumber
                placeholder={t('SetDefinitions.setCapacity') || ''}
                onChange={(value) => capacityInputHandler(value)}
                value={capacityInputValue}
                min={1}
              />
            </Space>
          </Row>
          <Row style={{ marginTop: 20 }} justify={'end'}>
            <Col>
              <Space>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button onClick={handleRectModalCancel}>{t('cancel')}</Button>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button type="primary" htmlType="submit">
                    {t('ok')}
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Row style={{ minHeight: '35px' }}>
        <Col span={1}>
          <Space>
            <Button onClick={zoomIn} icon={<ZoomInOutlined />} />
            <Button onClick={zoomOut} icon={<ZoomOutOutlined />} />
          </Space>
        </Col>
        <Col span={23}>
          <Space>
            <Button
              disabled={!isSeatsSelected}
              size="middle"
              onClick={removeSelection}
            >
              {t('StepSeatAssignment.removeSelectionButton')}
            </Button>
            <Button
              disabled={!(allSelectedIs === 'seat') || !isSeatsSelected}
              size="middle"
              onClick={() => toggleModal('seat')}
            >
              {t('SetDefinitions.setSeat')}
            </Button>
            <Button
              disabled={
                !(allSelectedIs === 'seat' || allSelectedIs === 'other') ||
                !isSeatsSelected
              }
              size="middle"
              onClick={() => toggleModal('capacity')}
            >
              {t('SetDefinitions.setCapacity')}
            </Button>
            <Button
              disabled={!(allSelectedIs === 'seat') || !isSeatsSelected}
              size="middle"
              onClick={() => toggleModal('setRowToSelected')}
            >
              {t('SetDefinitions.setRow')}
            </Button>
            <Button
              disabled={
                !(allSelectedIs === 'container' || allSelectedIs === 'other') ||
                !isSeatsSelected
              }
              size="middle"
              onClick={() => toggleModal('rect')}
            >
              {t('SetDefinitions.setDefinition')}
            </Button>
            <Button
              disabled={!isSeatsSelected}
              size="middle"
              onClick={() => toggleModal('setContainerToSelected')}
            >
              {t('SetDefinitions.setContainer')}
            </Button>
            <Button
              disabled={!isSeatsSelected}
              size="middle"
              onClick={() => toggleModal('changeElementType')}
            >
              {t('SetDefinitions.changeElementType')}
            </Button>
          </Space>
        </Col>
      </Row>
      <Row justify="center" style={{ position: 'relative' }}>
        <Col span={24}>
          <div
            ref={selectionWrapperRef}
            style={{ position: 'relative', userSelect: 'none' }}
          >
            <div id={styles.selectionFrame} ref={selectionFrame} />
            <div
              id="definition-wrapper"
              style={{ height: '60vh', padding: 30 }}
              ref={definitionWrapperRef}
            />
          </div>
          {modals.map((item, idx) => (
            <Modal
              key={'modal-' + idx}
              title={item.title}
              open={item.open}
              onCancel={() => item.onCancel(item.name)}
              width={300}
              footer={
                item.footer || [
                  <Row
                    key={'footer-modal-' + idx}
                    justify="end"
                    style={{ marginTop: 30 }}
                  >
                    <Col span={24}>
                      <Space size="small">
                        <Form.Item style={{ marginRight: 10, marginBottom: 0 }}>
                          <Button
                            onClick={() => item.onCancel(item.name)}
                          >{t`cancel`}</Button>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                            type="primary"
                            onClick={modalForm.submit}
                          >{t`ok`}</Button>
                        </Form.Item>
                      </Space>
                    </Col>
                  </Row>,
                ]
              }
            >
              <Form form={modalForm} onFinish={modalFormOnFinish}>
                <Space style={{ width: '100%' }} direction="vertical">
                  {item.inputs.map((input, index) => {
                    return (
                      <Row key={'form-item-' + index}>
                        <Col span={24}>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            name={input.name}
                            label={input.placeholder}
                            style={{ marginBottom: 0 }}
                          >
                            {
                              {
                                input: (
                                  <Input
                                    placeholder={input.placeholder}
                                    onPressEnter={input.onPressEnter}
                                  />
                                ),
                                number: (
                                  <InputNumber
                                    min={input.min}
                                    placeholder={input.placeholder}
                                    value={input.value}
                                    style={{ width: '100%' }}
                                    onPressEnter={input.onPressEnter}
                                  />
                                ),
                                containersSelect: (
                                  <Select>
                                    {containers.current.map((id, idx) => {
                                      return (
                                        <Select.Option
                                          key={'container-select-option-' + idx}
                                          value={id}
                                        >
                                          <div
                                            onMouseEnter={() =>
                                              onContainerOptionEnter(id)
                                            }
                                            onMouseLeave={() =>
                                              onContainerOptionLeave(id)
                                            }
                                          >{`${t('container')} ${
                                            idx + 1
                                          }`}</div>
                                        </Select.Option>
                                      )
                                    })}
                                  </Select>
                                ),
                                elementTypeSelect: (
                                  <Select options={input.items} />
                                ),
                              }[input.type!]
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  })}
                </Space>
              </Form>
            </Modal>
          ))}
        </Col>
      </Row>
    </Card>
  )
}
