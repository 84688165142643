import { useEffect } from 'react'
import { Form, DatePicker, Space } from 'antd'
import Page from 'components/Page'
import {
  AgenciesSalesStatisticsView,
  PageImplAgenciesSalesStatisticsView,
} from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import commonStyles from '../../commonStyles.module.less'
import { twoColumnsDateFormat } from '@pages/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { useAntdTable } from 'ahooks'
import { Params } from '@/types/Params'
import FormItem from '@components/FormItem'
import FloatInput from '@components/FloatInput'
import {
  DownloadReportButton,
  useLastParams,
} from '@pages/reports/shared/DownloadReportButton'
import { useExtraDatePickerFooter } from '@pages/reports/shared/useExtraDatePickerFooter'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'

const AgencyReport = () => {
  const [form] = Form.useForm()
  const { getSort, getQuery, setLastSort, setLastQuery } = useLastParams()
  const { t } = useTranslation()
  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<AgenciesSalesStatisticsView>
    },
    filters: {
      dates?: [Dayjs, Dayjs]
      agencyName?: string
      currencyCode?: string
    },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.dates?.[0] && filters.dates?.[1]) {
      query.push(
        `ticketSaleDate~DATE_AFTER~${filters.dates[0]?.format(DATE_FORMAT)}`,
      )
      query.push(
        `ticketSaleDate~DATE_BEFORE~${filters.dates?.[1]?.format(DATE_FORMAT)}`,
      )
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
      setLastSort({ sort: params.sort })
    } else {
      setLastSort(null)
    }
    if (filters.agencyName) {
      query.push(`agencyName~~${filters.agencyName}`)
    }
    if (filters.currencyCode) {
      query.push(`currencyCode~~${filters.currencyCode}`)
    }
    params.query = query.join(';')
    setLastQuery(query.join(';'))
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageImplAgenciesSalesStatisticsView>(
      'admin/statistics/agencies/view',
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    form,
    defaultParams: [
      {
        sorter: {
          columnKey: 'agencyName',
          order: 'ascend',
        },
        current: 1,
        pageSize: 10,
      },
      {},
    ],
  })

  const columns: ExtendedColumns<AgenciesSalesStatisticsView>[] = [
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Agency`}</div>
          <FormItem name="agencyName">
            <FloatInput label="" onChange={submit} />
          </FormItem>
        </div>
      ),
      shortTitle: t`labels.Agency`,
      dataIndex: 'agencyName',
      key: 'agencyName',
      sorter: true,
      width: '350px',
      defaultSortOrder: 'ascend',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Currency Code`}</div>
          <FormItem name="currencyCode">
            <FloatInput label="" onChange={submit} />
          </FormItem>
        </div>
      ),
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      sorter: true,
      shortTitle: t`labels.Currency Code`,
    },
    {
      title: t`labels.Total number of events`,
      dataIndex: 'eventsCount',
      key: 'eventsCount',
      sorter: true,
    },
    {
      title: t`labels.Sold Tickets`,
      dataIndex: 'soldTicketsCount',
      key: 'soldTicketsCount',
      sorter: true,
    },
    {
      title: t`labels.Sales Amount`,
      dataIndex: 'salesNet',
      key: 'salesNet',
      sorter: true,
    },
    {
      title: t`labels.Commission Rate`,
      dataIndex: 'agencyCommissionRate',
      key: 'agencyCommissionRate',
      sorter: true,
    },
    {
      title: t`labels.Commission`,
      dataIndex: 'totalCommission',
      key: 'totalCommission',
      sorter: true,
    },
    {
      title: t`labels.Sales Service Fee`,
      dataIndex: 'salesServiceFee',
      key: 'salesServiceFee',
      sorter: true,
    },
    {
      title: t`labels.Total earnings`,
      dataIndex: 'earnings',
      key: 'earnings',
      render: (_, record) =>
        (record.salesServiceFee || 0) + (record.totalCommission || 0),
    },
    {
      title: t`labels.Returns Vat`,
      dataIndex: 'returnsVat',
      key: 'returnsVat',
      sorter: true,
    },

    {
      title: t`labels.Sales Vat`,
      dataIndex: 'salesVat',
      key: 'salesVat',
      sorter: true,
    },
    {
      title: t`labels.Ticket Last Sale Date`,
      dataIndex: 'ticketLastSaleDate',
      key: 'ticketLastSaleDate',
      render: twoColumnsDateFormat,
    },
    {
      title: t`labels.Ticket First Sale Date`,
      dataIndex: 'ticketFirstSaleDate',
      key: 'ticketFirstSaleDate',
      render: twoColumnsDateFormat,
    },
    {
      title: t`labels.Returns Net`,
      dataIndex: 'returnsNet',
      key: 'returnsNet',
      sorter: true,
    },
    {
      title: t`labels.Billing Service Fee`,
      dataIndex: 'billingServiceFee',
      key: 'billingServiceFee',
      sorter: true,
    },
    {
      title: t`labels.Returns Service Fee`,
      dataIndex: 'returnsServiceFee',
      key: 'returnsServiceFee',
      sorter: true,
    },
  ]

  const extraFooter = useExtraDatePickerFooter(form, submit)

  useEffect(() => {
    form.setFieldValue('dates', [dayjs().startOf('month'), dayjs()])
  }, [])

  return (
    <Page inner title={t`labels.Agency report`}>
      <Form form={form}>
        <Space
          align="center"
          style={{ justifyContent: 'space-between', display: 'flex' }}
        >
          <FormItem name="dates">
            <DatePicker.RangePicker
              onChange={submit}
              allowClear={false}
              style={{ marginBottom: '20px' }}
              renderExtraFooter={() => extraFooter}
            />
          </FormItem>
          <DownloadReportButton
            entity="agencies"
            getQuery={getQuery}
            getParams={getSort}
          />
        </Space>
        <DragTable<AgenciesSalesStatisticsView>
          rowKey={(record) =>
            String(record.agencyName) + String(record.currencyCode)
          }
          bordered
          columns={columns}
          className={commonStyles.table}
          rowClassName={commonStyles.row}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default AgencyReport
