import { Button, Divider, Form, Modal, Select, SelectProps, Switch } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import FormItem from '@components/FormItem'
import { useParams } from 'react-router-dom'
import {
  ActorDto,
  CityDto,
  CountryDto,
  EventDto,
  LanguageDto,
  MailingListUpdateRequest,
  PageActorDto,
  PageCityDto,
  PageCountryDto,
  PageEventDto,
  PageStageDto,
  StageDto,
  EventTypeDto,
  ActorTypeDto,
  MarketingLinkCreateResponse,
  ConfirmationCodeRequestChannelEnum,
  MailingListCreateRequest,
  MailingListUpdateRequestChannelEnum,
  MailingListUpdateRequestLangEnum,
  MailingListDtoChannelEnum,
  MailingListFilters,
  MailingListCreateRequestTypeEnum,
} from '@/openapi'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import SmsCalculator from '@components/SegmentedCalculator'
import { channelOptions } from '@pages/users/manage/notificationLogs'
const { Option } = Select
import styles from './styles.module.less'
import { formatCountryOption } from '@pages/ticketControlPersons/tcsForm'
import { toast } from 'react-toastify'
import { prepareDataForSearch } from '@pages/event/manage/mailingList/helpers'
import { prepareOption } from '@components/AsyncSelect/types'
import FloatInput from '@components/FloatInput'
import MultiLanguageTextEditor from '@components/MultiLanguageTextEditor'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'
import FormSelect from '@components/formElements/FormSelect'

export type FormMode = 'view' | 'edit' | 'send' | 'create'

type MLFormProps = {
  onSuccess: () => void
  id?: number
  eventId?: string
  mode?: FormMode
}

type SearchTypes = {
  userCountryId?: string[]
  eventCountryId?: string[]
  eventCityId?: string[]
  eventStageId?: string[]
  eventId?: string[]
  actorId?: string[]
  eventTypeId?: string[]
  actorTypeId?: string[]
}

const MailingListForm = ({ onSuccess, id, mode }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const [isSearchParams, setIsSearchParams] = useState(false)
  const { id: eventId } = useParams<{ id: string }>()
  const [count, setCount] = useState<string | number>('-')
  const [formMode, setFormMode] = useState<FormMode>()
  const { t } = useTranslation()

  useEffect(() => {
    setFormMode(mode || 'create')
  }, [mode])

  const eventCustomersOnly = Form.useWatch('eventCustomersOnly', form)

  const disabled = useMemo(
    () => formMode === 'view' || formMode === 'send',
    [formMode],
  )

  const isCreate = useMemo(() => formMode === 'create', [formMode])

  const addLink = Form.useWatch('addLink', form)

  const text = useMemo(() => {
    switch (formMode) {
      case 'send':
        return t`labels.Send Mailing`
      case 'edit':
        return t`labels.Edit Mailing`
      case 'view':
        return t`labels.View mailing`
      default:
        return t`labels.Create Mailing`
    }
  }, [formMode, t])

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/mailing_lists/${id}`)
        .then(async ({ data }) => {
          const preparedData = { ...data }
          if (data.marketingLink) {
            preparedData.addLink = true
            preparedData.link = { ...data.marketingLink }
          }
          if (
            !!data.filters?.excludeCustomersByEventIds &&
            Array.isArray(data.filters.excludeCustomersByEventIds)
          ) {
            preparedData.excludeCustomersByEventIds = true
          }
          if (
            !!data.filters?.includeOnlyCustomersByEventIds &&
            Array.isArray(data.filters.includeOnlyCustomersByEventIds)
          ) {
            preparedData.eventCustomersOnly = true
          }
          if (data.filters?.searchParams) {
            try {
              const params = data.filters.searchParams as SearchTypes
              if (params.userCountryId) {
                const { data } = await axiosInstance.get<PageCountryDto>(
                  `admin/countries?query=id~in~${params.userCountryId.toString()}&size=${
                    params.userCountryId.length
                  }`,
                )
                preparedData.userCountryId =
                  data.content?.map(formatCountryOption)
              }
              if (params.eventCountryId) {
                const { data } = await axiosInstance.get<PageCountryDto>(
                  `admin/countries?query=id~in~${params.eventCountryId.toString()}&size=${
                    params.eventCountryId.length
                  }`,
                )
                preparedData.eventCountryId =
                  data.content?.map(formatCountryOption)
              }
              if (params.eventCityId) {
                const { data } = await axiosInstance.get<PageCityDto>(
                  `admin/cities?query=id~in~${params.eventCityId.toString()}&size=${
                    params.eventCityId.length
                  }`,
                )
                preparedData.eventCityId = data.content?.map((i) =>
                  prepareOption(i),
                )
              }
              if (params.eventStageId) {
                const { data } = await axiosInstance.get<PageStageDto>(
                  `admin/stages?query=id~in~${params.eventStageId.toString()}&size=${
                    params.eventStageId.length
                  }`,
                )
                preparedData.eventStageId = data.content?.map((i) =>
                  prepareOption(i),
                )
              }
              if (params.eventId) {
                const { data } = await axiosInstance.get<PageEventDto>(
                  `admin/events?query=id~in~${params.eventId.toString()}&size=${
                    params.eventId.length
                  }`,
                )
                preparedData.eventId = data.content?.map((i) =>
                  prepareOption(i),
                )
              }
              if (params.actorId) {
                const { data } = await axiosInstance.get<PageActorDto>(
                  `admin/actors?query=id~in~${params.actorId.toString()}&size=${
                    params.actorId.length
                  }`,
                )
                preparedData.actorId = data.content?.map((i) =>
                  prepareOption(i),
                )
              }
              if (params.eventTypeId) {
                const { data } = await axiosInstance.get<PageActorDto>(
                  `admin/event-types?query=id~in~${params.eventTypeId.toString()}&size=${
                    params.eventTypeId.length
                  }`,
                )
                preparedData.eventTypeId = data.content?.map((i) =>
                  prepareOption(i),
                )
              }
              if (params.actorTypeId) {
                const { data } = await axiosInstance.get<PageActorDto>(
                  `admin/actor-types?query=id~in~${params.actorTypeId.toString()}&size=${
                    params.actorTypeId.length
                  }`,
                )
                preparedData.actorTypeId = data.content?.map((i) =>
                  prepareOption(i),
                )
              }
            } catch (e) {
              toast.error(t`messages.cantParseSearchParams`)
            }
          }
          getUsersCount(preparedData)
          setFullItem(preparedData)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    setIsSearchParams(false)
    const searchParameters: { [key: string]: Array<number> } = {}
    const filters: MailingListFilters = {}
    if (!values.eventCustomersOnly) {
      if (values.userCountryId?.length) {
        searchParameters.userCountryId = values.userCountryId.map(
          ({ value }) => +value,
        )
      }
      if (values.eventCountryId?.length) {
        searchParameters.eventCountryId = values.eventCountryId.map(
          ({ value }) => +value,
        )
      }
      if (values.eventCityId?.length) {
        searchParameters.eventCityId = values.eventCityId.map(
          ({ value }) => +value,
        )
      }
      if (values.eventStageId?.length) {
        searchParameters.eventStageId = values.eventStageId.map(
          ({ value }) => +value,
        )
      }
      if (values.eventId?.length) {
        searchParameters.eventId = values.eventId.map(({ value }) => +value)
      }
      if (values.actorId?.length) {
        searchParameters.actorId = values.actorId.map(({ value }) => +value)
      }
      if (values.eventTypeId?.length) {
        searchParameters.eventTypeId = values.eventTypeId.map(
          ({ value }) => +value,
        )
      }
      if (values.actorTypeId?.length) {
        searchParameters.actorTypeId = values.actorTypeId.map(
          ({ value }) => +value,
        )
      }
      if (values.excludeCustomersByEventIds) {
        filters.excludeCustomersByEventIds = [+(eventId as string)]
      }
      filters.searchParams = searchParameters
    } else {
      searchParameters.eventId = [+(eventId as string)]
      filters.includeOnlyCustomersByEventIds = [+(eventId as string)]
    }

    const baseFields: Partial<MailingListUpdateRequest> = {
      channel: (values.channel?.value ||
        values.channel) as MailingListUpdateRequestChannelEnum,
      lang: castType<MailingListUpdateRequestLangEnum>(values.lang),
    }
    if (values.text?.length) {
      baseFields.text = values.text
    }
    if (values.subject) {
      baseFields.subject = values.subject
    }
    if (!Object.keys(searchParameters).length) {
      setIsSearchParams(true)
      return
    }
    try {
      if (id) {
        await axiosInstance.put(`admin/mailing_lists/${id}`, {
          ...baseFields,
          filters,
        })
      } else {
        let marketingLinkId: number | undefined
        if (values.addLink) {
          const link = {
            ...values.link,
            lang: values.lang,
            utmSource: 'mailing',
          }

          const { data } =
            await axiosInstance.post<MarketingLinkCreateResponse>(
              'admin/links',
              {
                ...link,
                eventId,
              },
            )
          marketingLinkId = data.id
        }

        await axiosInstance.post<MailingListCreateRequest>(
          'admin/mailing-lists',
          {
            ...baseFields,
            entityId: +(eventId as string),
            type: MailingListCreateRequestTypeEnum.EVENT,
            filters,
            ...(marketingLinkId ? { marketingLinkId } : {}),
          },
        )
      }
      onSuccess()
    } catch (e) {
      console.log(e)
    }
  }

  const onChange = async (changed: CustomFormData, values: CustomFormData) => {
    if (
      changed.addLink !== undefined ||
      changed.link ||
      changed.text ||
      changed.subject
    ) {
      return
    }
    getUsersCount(values)
  }

  const getUsersCount = useCallback(
    async (values: CustomFormData) => {
      if (!values.channel) return
      let body: any
      if (values.eventCustomersOnly) {
        body = { includeOnlyCustomersByEventIds: [eventId] }
      } else {
        body = { searchParams: prepareDataForSearch(values) }
        if (values.excludeCustomersByEventIds) {
          body.excludeCustomersByEventIds = [eventId as string]
        }
        if (
          !Object.entries(body.searchParams).filter(([_, val]) => !!val).length
        ) {
          setCount('-')
          return
        }
      }

      const { data } = await axiosInstance.post<number>(
        `admin/user-mailing-lists/count?channel=${
          values.channel?.value || values.channel
        }&eventCustomersOnly=${!!values.eventCustomersOnly}`,
        body,
        {
          apiVersion: 2,
        },
      )
      setCount(data)
    },
    [eventId],
  )

  const channel = Form.useWatch('channel', form) as any
  const isSms = useMemo(
    () =>
      channel === ConfirmationCodeRequestChannelEnum.SMS ||
      channel?.value === 'sms',
    [channel],
  )
  const isEmail = useMemo(
    () =>
      channel === ConfirmationCodeRequestChannelEnum.EMAIL ||
      channel?.value === 'email',
    [channel],
  )

  const handleSendMailing = useCallback(() => {
    if (!count) {
      Modal.error({
        title: t`error`,
        content: t`messages.noCountSendEmail`,
      })
      return
    }
    const filters = prepareDataForSearch(fullItem!)
    let hasFilters = false
    for (let key in filters) {
      const item = filters[key as keyof CustomFormData]
      if (Array.isArray(item) && item?.length) {
        hasFilters = true
      }
    }

    if (!hasFilters && !fullItem?.eventCustomersOnly) {
      Modal.error({
        title: t`error`,
        content: t`messages.Please fill at least one target audience filter`,
      })
      return
    }
    Modal.confirm({
      // @ts-ignore
      title: t('messages.shureToSendMailing', {
        count: count,
        channel: fullItem?.channel,
      }),
      content: (
        <div>
          <h3>{t`labels.Message Text`}</h3>
          {fullItem?.channel === MailingListDtoChannelEnum.EMAIL && (
            <>
              <h4>{fullItem.subject}</h4>
              <h5 dangerouslySetInnerHTML={{ __html: fullItem?.text || '' }} />
            </>
          )}
          {fullItem?.channel === MailingListDtoChannelEnum.SMS && (
            <h5>{fullItem?.text}</h5>
          )}
        </div>
      ),
      onOk: async () => {
        await axiosInstance.post(`admin/mailing_lists/${fullItem?.id}/start`)
        onSuccess()
      },
    })
  }, [fullItem, count, t])

  const handleAddLinkChange: SwitchChangeEventHandler = useCallback(
    (checked) => {
      let newValue: string = ''
      if (checked) {
        newValue = (form.getFieldValue('text') || '') + '{{link}}'
      } else {
        newValue = form.getFieldValue('text').replaceAll('{{link}}', '')
      }
      form.setFieldValue('text', newValue)
    },
    [],
  )

  const controlProps: Omit<SelectProps, 'onChange'> = useMemo(
    () => ({ mode: 'multiple', disabled: disabled || eventCustomersOnly }),
    [disabled, eventCustomersOnly],
  )

  useEffect(() => {
    if (eventCustomersOnly) {
      form.setFieldValue('excludeCustomersByEventIds', false)
    }
  }, [eventCustomersOnly])

  if (loading) return null

  return (
    <div>
      <h3>{text}</h3>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={fullItem}
        onValuesChange={onChange}
      >
        <div className={styles.formItems}>
          <GenericAsyncSelect<LanguageDto>
            name="lang"
            path="admin/languages"
            label={t`labels.Language`}
            withAll={false}
            searchField="name"
            rules={[{ required: true }]}
            customPrepareOption={({ code, name }) => ({
              value: code!,
              label: name!,
            })}
            required
            width="100%"
            controlProps={{ disabled }}
          />
          <FormSelect
            label={t`labels.Channel`}
            name="channel"
            disabled={disabled}
            formItemProps={{ rules: [{ required: true }] }}
          >
            {channelOptions.map(({ label, value }) => (
              <Option value={value} label={label} key={value}>
                {label}
              </Option>
            ))}
          </FormSelect>
        </div>
        <div className={styles.selectAudience}>
          {t`labels.Select target audience`}{' '}
        </div>
        <div className={styles.formItems}>
          <GenericAsyncSelect<CountryDto>
            path="admin/countries"
            label={t`labels.User Country`}
            name="userCountryId"
            withAll={false}
            customPrepareOption={formatCountryOption}
            width="100%"
            controlProps={controlProps}
          />
          <GenericAsyncSelect<CountryDto>
            path="admin/countries"
            label={t`labels.Event Country`}
            name="eventCountryId"
            withAll={false}
            customPrepareOption={formatCountryOption}
            disabled={disabled}
            width="100%"
            controlProps={controlProps}
          />
          <GenericAsyncSelect<CityDto>
            path="admin/cities"
            label={t`labels.Event City`}
            name="eventCityId"
            withAll={false}
            disabled={disabled}
            width="100%"
            controlProps={controlProps}
          />
          <GenericAsyncSelect<StageDto>
            path="admin/stages"
            label={t`labels.Event Stage`}
            name="eventStageId"
            withAll={false}
            disabled={disabled}
            width="100%"
            controlProps={controlProps}
          />
          <GenericAsyncSelect<EventDto>
            path="admin/events"
            label={t`labels.Event name`}
            name="eventId"
            withAll={false}
            disabled={disabled}
            width="100%"
            controlProps={controlProps}
          />
          <GenericAsyncSelect<ActorDto>
            path="admin/actors"
            label={t`labels.Actor Name`}
            name="actorId"
            withAll={false}
            disabled={disabled}
            width="100%"
            controlProps={controlProps}
          />
          <GenericAsyncSelect<EventTypeDto>
            path="admin/event-types"
            label={t`labels.Event Types`}
            name="eventTypeId"
            withAll={false}
            disabled={disabled}
            width="100%"
            controlProps={controlProps}
          />
          <GenericAsyncSelect<ActorTypeDto>
            path="admin/actor-types"
            label={t`labels.Actor Types`}
            name="actorTypeId"
            withAll={false}
            disabled={disabled}
            width="100%"
            controlProps={controlProps}
          />
          {isSearchParams && (
            <>
              <div
                style={{
                  color: '#ff4d4f',
                  marginBottom: '15px',
                  marginTop: '-15px',
                }}
              >
                {t`messages.Please fill at least one search param`}
              </div>
              <div />
            </>
          )}
          <FormItem
            label="Exclude from the event"
            valuePropName="checked"
            name="excludeCustomersByEventIds"
            disabled={eventCustomersOnly}
          >
            <Switch />
          </FormItem>
          <Divider style={{ gridColumn: 'span 2' }} />
          <FormItem
            label={t`labels.Event Customers Only`}
            valuePropName="checked"
            name="eventCustomersOnly"
            disabled={!isCreate}
          >
            <Switch />
          </FormItem>
        </div>
        <div className={styles.count}>
          {(isEmail || isSms) && (
            <div>
              {isSms
                ? t`messages.Number of unique phone numbers`
                : t`messages.Number of unique emails`}
              {' :'} <span>{count}</span>
            </div>
          )}
        </div>
        {isSms && (
          <SmsCalculator
            form={form}
            name="text"
            label={t`labels.SMS`}
            readOnly={disabled}
          />
        )}
        {isEmail && (
          <FormItem
            name="subject"
            rules={[{ required: true }]}
            hasFeedback
            disabled={disabled}
          >
            <FloatInput label={t`labels.Subject`} required />
          </FormItem>
        )}

        {isEmail && (
          <MultiLanguageTextEditor
            form={form}
            itemProps={{
              name: 'text',
              hasFeedback: true,
              rules: [
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue('addLink') &&
                      !value.includes('{{link}}')
                    ) {
                      return Promise.reject(
                        new Error('Text {{link}} is missed'),
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ],
            }}
            inputProps={{
              label: t`labels.Email text`,
              required: true,
            }}
            disabled={disabled}
            languageFields={[]}
          />
        )}

        {(isEmail || isSms) && (
          <FormItem
            label={t`labels.Add Marketing Link`}
            valuePropName="checked"
            name="addLink"
            disabled={!isCreate}
          >
            <Switch onChange={handleAddLinkChange} />
          </FormItem>
        )}
        {addLink && (
          <div className={styles.formItems}>
            <FormItem name={['link', 'device']} disabled={!isCreate}>
              <FloatInput label={t`labels.Device`} />
            </FormItem>
            <FormItem
              name={['link', 'utmCampaign']}
              rules={[{ required: true }]}
              disabled={!isCreate}
            >
              <FloatInput label="Utm Campaign" required disabled={!isCreate} />
            </FormItem>
            <FormItem
              name={['link', 'utmMedium']}
              rules={[{ required: true }]}
              disabled={!isCreate}
            >
              <FloatInput label="Utm Medium" required />
            </FormItem>
            <FormItem
              name={['link', 'utmSource']}
              rules={[{ required: true }]}
              disabled={!isCreate}
            >
              <FloatInput label="Utm Source" required />
            </FormItem>
            <FormItem name={['link', 'utmTerm']} disabled={!isCreate}>
              <FloatInput label="Utm Term" />
            </FormItem>
            <FormItem name={['link', 'utmContent']} disabled={!isCreate}>
              <FloatInput label="Utm Content" />
            </FormItem>
          </div>
        )}
        <Divider />
        <Form.Item
          style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
        >
          {formMode === 'create' && (
            <Button type="primary" htmlType="submit">
              {t`labels.Save as draft`}
            </Button>
          )}
          {formMode === 'edit' && (
            <Button type="primary" htmlType="submit">
              {t`labels.Update draft`}
            </Button>
          )}
        </Form.Item>
      </Form>
      {formMode === 'send' && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: '20px' }}
            onClick={() => setFormMode('edit')}
          >
            {t`labels.Go to Mailing Edit`}
          </Button>
          <Button type="primary" htmlType="submit" onClick={handleSendMailing}>
            {t`labels.Send Mailing`}
          </Button>
        </div>
      )}
    </div>
  )
}

export default MailingListForm
