import { Form } from 'antd'
import Page from 'components/Page'
import { PageUserSubscriptionDto, UserSubscriptionDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@/components/FloatInput'

import commonStyles from '@/pages/commonStyles.module.less'
import { useParams } from 'react-router-dom'
import { Params } from '@/types/Params'
import { twoColumnsDateFormat } from '@pages/helpers'
import { CountryAsyncSelect } from '@components/AsyncSelect/CountryAsyncSelect'
import CountryFlag from '@components/CountryFlag/CountryFlag'
import { useTranslation } from 'react-i18next'
import { DragTable, ExtendedColumns } from '@components/DragTable'
export type CustomFormData = UserSubscriptionDto

const UsersSubscriptions = () => {
  const [form] = Form.useForm()
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<UserSubscriptionDto>
    },
    filters: { [key in 'email' | 'phone' | 'country']: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [`subscriptionEntityId==${eventId}`, 'type==event']
    if (filters.email) {
      query.push(`user.email~~${filters.email}`)
    }
    if (filters.phone) {
      query.push(`user.phone~~${filters.phone}`)
    }
    if (filters.country && filters.country !== 'All') {
      query.push(`user.country.id==${filters.country}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageUserSubscriptionDto>(
      `admin/user-subscriptions`,
      {
        params,
      },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const columns: ExtendedColumns<UserSubscriptionDto>[] = [
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Email`}</div>
          <Form.Item name="email">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'email',
      shortTitle: t`labels.Email`,
      key: 'email',
      render: (_, record) => record.user?.email,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Phone`}</div>
          <Form.Item name="phone">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'phone',
      key: 'phone',
      shortTitle: t`labels.Phone`,
      render: (_, record) => record.user?.phone,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Country`}</div>
          <CountryAsyncSelect width="100%" submit={submit} noLabel />
        </div>
      ),
      dataIndex: 'country',
      key: 'country',
      shortTitle: t`labels.Country`,
      render: (_, record) => (
        <div>
          {`${record.user?.country?.name} (+${record.user?.country?.phoneCode})  `}
          <CountryFlag countryCode={record.user?.country?.code} />
        </div>
      ),
    },
    {
      title: t`labels.Created Date`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      defaultSortOrder: 'descend',
      render: twoColumnsDateFormat,
    },
  ]

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<UserSubscriptionDto>
          bordered
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default UsersSubscriptions
