import { useAuthContext } from '@/contexts/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'
import { PropsWithChildren } from 'react'

export const AdminOutlet = () => {
  const { isAdmin } = useAuthContext()
  return isAdmin ? <Outlet /> : <Navigate to="/app/dashboard" />
}

export const AdminWrapper = (props: PropsWithChildren) => {
  const { isAdmin } = useAuthContext()
  return isAdmin ? <>{props.children}</> : null
}
