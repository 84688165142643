import { Alert, Button, Card, Divider, Form, Modal, Select } from 'antd'
import { EventStep1Form, FirstStepProps } from './types'
import { axiosInstance } from 'api'
import { useCallback, useEffect } from 'react'
import { ActorAsyncSelect } from '@components/AsyncSelect/ActorAsyncSelect'
import { EventTypeAsyncSelect } from '@components/AsyncSelect/EventTypesAsyncSelect'
import styles from './styles.module.less'
import { useNavigate } from 'react-router-dom'
import {
  EventCreateResponseLanguagesEnum,
  TourDto,
  TourExtendedDto,
  TourExtendedDtoStatusEnum,
} from '@/openapi'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import CountryFlag from '@components/CountryFlag/CountryFlag'
import MultiLanguageInputBase from '../../components/MultiLanguageInputBase'
import { getValue } from './helpers'
import { toast } from 'react-toastify'
import cn from 'classnames'
import FormItem from 'components/FormItem'
import FloatSelect from '@components/FloatSelect'
import MultiLanguageTextEditorBase from '@components/MultiLanguageTextEditorBase'
import { SelectOption } from '@/types/Option'
import { AgencyAsyncSelect } from '@components/AsyncSelect/AgencyAsyncSelect'
import { useAuthContext } from '@/contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'

const { Option } = Select
const StepBasicInformation = ({
  onSuccess,
  item,
  setForm,
  isCreate,
  disabled,
  errors,
  canEdit,
}: FirstStepProps & { isCreate: boolean }) => {
  const [form] = Form.useForm<EventStep1Form>()
  useEffect(() => {
    setForm(form)
  }, [form])
  const languages = Form.useWatch('languages', form)
  const navigate = useNavigate()
  const { isAdmin, me } = useAuthContext()
  const { languagesFull } = useLanguageContext()
  const { t } = useTranslation()

  const onFinish = async (values: EventStep1Form) => {
    if (disabled && !canEdit) {
      onSuccess({})
      return
    }
    if (item?.id) {
      const dataToSend = {
        ...values,
        actorIds: values.actorIds?.map(
          (actor) => (actor as SelectOption).value,
        ),
        eventTypeId: getValue(values.eventTypeId),
        agencyId: isAdmin ? getValue(values.agencyId) : me?.agency?.id,
        ageCategory: getValue(values.ageCategory, false),
      }
      const { data } = await axiosInstance.put<TourExtendedDto>(
        `admin/tours/${item.id}`,
        dataToSend,
      )
      onSuccess(data)
    } else {
      const {
        data: { id },
      } = await axiosInstance.post<TourDto>('admin/tours', {
        ...values,
        ageCategory: getValue(values.ageCategory, false),
        actorIds: values.actorIds?.map(
          (actor) => (actor as SelectOption).value,
        ),
        agencyId: isAdmin ? values.agencyId : me?.agency?.id,
      })
      toast.success(t`messages.Changes in the tour were saved`)
      navigate(`/app/tour/${id}`, { state: 'new' })
    }
  }

  const cancelHandle = useCallback(() => {
    Modal.confirm({
      title: t`messages.cancelTourCreation`,
      onOk: () => navigate(-1),
    })
  }, [t])

  return (
    <Card style={{ marginTop: '15px' }}>
      {errors?.length && (
        <Alert
          message={
            <>
              {errors.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </>
          }
          type="error"
          showIcon
          className={cn(styles.fullWidth, styles.alert)}
        />
      )}
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={item}
        className={styles.form}
      >
        <ActorAsyncSelect
          width="100%"
          name="actorIds"
          withAll={false}
          rules={[{ required: true }]}
          disabled={
            !canEdit ||
            (!isCreate && item?.status === TourExtendedDtoStatusEnum.ANNOUNCED)
          }
          required
          mode="multiple"
        />
        <EventTypeAsyncSelect
          width="100%"
          name="eventTypeId"
          withAll={false}
          disabled={!canEdit}
          rules={[{ required: true }]}
          required
        />
        <FormItem
          disabled={!canEdit}
          name="languages"
          rules={[{ required: true }]}
          initialValue={['EN']}
          className={styles.fullWidth}
        >
          <Select mode="multiple">
            <Option value="EN" label={t`labels.English`} disabled>
              <CountryFlag countryCode="us" /> {t`labels.English`}
            </Option>
            {languagesFull
              .filter(({ code }) => code !== 'EN')
              .map(({ code, name }) => (
                <Option value={code} label={name} key={code}>
                  <CountryFlag countryCode={code!} /> {name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem disabled={!canEdit} name="ageCategory">
          <FloatSelect label={t`labels.Age Category`}>
            <Option value="0+" label="0+">
              {'0+'}
            </Option>
            <Option value="6+" label="6+">
              {'6+'}
            </Option>
            <Option value="12+" label="12+">
              {'12+'}
            </Option>
            <Option value="14+" label="14+">
              {'14+'}
            </Option>
            <Option value="16+" label="16+">
              {'16+'}
            </Option>
            <Option value="18+" label="18+">
              {'18+'}
            </Option>
          </FloatSelect>
        </FormItem>
        <MultiLanguageInputBase
          itemProps={{
            name: 'name',
            hasFeedback: true,
            rules: [{ required: true }],
            disabled: !canEdit,
          }}
          inputProps={{ label: t`labels.Tour name`, required: true }}
          languages={
            castType<EventCreateResponseLanguagesEnum[]>(languages) || []
          }
          form={form}
        />
        <MultiLanguageTextEditorBase
          itemProps={{
            name: 'description',
            hasFeedback: true,
            className: styles.fullWidth,
            rules: [{ required: true }],
            disabled: !canEdit,
          }}
          inputProps={{ label: t`labels.Description`, required: true }}
          languages={castType<EventCreateResponseLanguagesEnum[]>(languages)}
          disabled={!canEdit}
          form={form}
        />
        {isAdmin && (
          <AgencyAsyncSelect
            withAll={false}
            rules={[{ required: true }]}
            disabled={!canEdit}
            required
            name="agencyId"
          />
        )}
        <div className={styles.fullWidth}>
          <Divider />
          <div
            className={cn(styles.buttonBlock, !isCreate && styles.singleButton)}
          >
            {isCreate && (
              <Button type="primary" htmlType="button" onClick={cancelHandle}>
                {t`cancel`}
              </Button>
            )}
            <FormItem
              style={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Button type="primary" htmlType="submit">
                {t`labels.Next`}
              </Button>
            </FormItem>
          </div>
        </div>
      </Form>
    </Card>
  )
}

export default StepBasicInformation
