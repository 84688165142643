/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailingListCreateRequest } from '../models';
// @ts-ignore
import { MailingListCreateResponse } from '../models';
// @ts-ignore
import { MailingListDto } from '../models';
// @ts-ignore
import { MailingListUpdateRequest } from '../models';
// @ts-ignore
import { MailingListUpdateResponse } from '../models';
// @ts-ignore
import { PageAudit } from '../models';
// @ts-ignore
import { PageMailingListDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * MailingListsAdminControllerApi - axios parameter creator
 * @export
 */
export const MailingListsAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate20: async (mailingListCreateRequest: Array<MailingListCreateRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailingListCreateRequest' is not null or undefined
            assertParamExists('bulkCreate20', 'mailingListCreateRequest', mailingListCreateRequest)
            const localVarPath = `/v1/admin/mailing_lists/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingListCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate21: async (mailingListCreateRequest: Array<MailingListCreateRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailingListCreateRequest' is not null or undefined
            assertParamExists('bulkCreate21', 'mailingListCreateRequest', mailingListCreateRequest)
            const localVarPath = `/v1/admin/mailing-lists/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingListCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MailingListCreateRequest} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create24: async (mailingListCreateRequest: MailingListCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailingListCreateRequest' is not null or undefined
            assertParamExists('create24', 'mailingListCreateRequest', mailingListCreateRequest)
            const localVarPath = `/v1/admin/mailing_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingListCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MailingListCreateRequest} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create25: async (mailingListCreateRequest: MailingListCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailingListCreateRequest' is not null or undefined
            assertParamExists('create25', 'mailingListCreateRequest', mailingListCreateRequest)
            const localVarPath = `/v1/admin/mailing-lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingListCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById21: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById21', 'id', id)
            const localVarPath = `/v1/admin/mailing_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById22: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById22', 'id', id)
            const localVarPath = `/v1/admin/mailing-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find57: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find57', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/mailing_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find58: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find58', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/mailing-lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended20: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findExtended20', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/mailing_lists/extended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended21: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('findExtended21', 'pageRequest', pageRequest)
            const localVarPath = `/v1/admin/mailing-lists/extended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById42: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById42', 'id', id)
            const localVarPath = `/v1/admin/mailing_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById43: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById43', 'id', id)
            const localVarPath = `/v1/admin/mailing-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount47: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/mailing_lists/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount48: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/mailing-lists/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit20: async (pageable: Pageable, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getEntityAudit20', 'pageable', pageable)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit20', 'id', id)
            const localVarPath = `/v1/admin/mailing_lists/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit21: async (pageable: Pageable, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getEntityAudit21', 'pageable', pageable)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit21', 'id', id)
            const localVarPath = `/v1/admin/mailing-lists/audit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties20: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/mailing_lists/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties21: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/mailing-lists/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessTranslations20ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations20FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations20: async (toLanguage: ProcessTranslations20ToLanguageEnum, fromLanguage?: ProcessTranslations20FromLanguageEnum, forceTranslate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toLanguage' is not null or undefined
            assertParamExists('processTranslations20', 'toLanguage', toLanguage)
            const localVarPath = `/v1/admin/mailing_lists/i18n/from/{fromLanguage}/to/{toLanguage}`
                .replace(`{${"toLanguage"}}`, encodeURIComponent(String(toLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromLanguage !== undefined) {
                localVarQueryParameter['fromLanguage'] = fromLanguage;
            }

            if (forceTranslate !== undefined) {
                localVarQueryParameter['forceTranslate'] = forceTranslate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessTranslations21ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations21FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations21: async (toLanguage: ProcessTranslations21ToLanguageEnum, fromLanguage?: ProcessTranslations21FromLanguageEnum, forceTranslate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toLanguage' is not null or undefined
            assertParamExists('processTranslations21', 'toLanguage', toLanguage)
            const localVarPath = `/v1/admin/mailing-lists/i18n/from/{fromLanguage}/to/{toLanguage}`
                .replace(`{${"toLanguage"}}`, encodeURIComponent(String(toLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromLanguage !== undefined) {
                localVarQueryParameter['fromLanguage'] = fromLanguage;
            }

            if (forceTranslate !== undefined) {
                localVarQueryParameter['forceTranslate'] = forceTranslate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull20: async (id: number, properties: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPropertiesToNull20', 'id', id)
            // verify required parameter 'properties' is not null or undefined
            assertParamExists('setPropertiesToNull20', 'properties', properties)
            const localVarPath = `/v1/admin/mailing_lists/{id}/property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (properties) {
                localVarQueryParameter['properties'] = Array.from(properties);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull21: async (id: number, properties: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPropertiesToNull21', 'id', id)
            // verify required parameter 'properties' is not null or undefined
            assertParamExists('setPropertiesToNull21', 'properties', properties)
            const localVarPath = `/v1/admin/mailing-lists/{id}/property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (properties) {
                localVarQueryParameter['properties'] = Array.from(properties);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMailing: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startMailing', 'id', id)
            const localVarPath = `/v1/admin/mailing_lists/{id}/start`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMailing1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startMailing1', 'id', id)
            const localVarPath = `/v1/admin/mailing-lists/{id}/start`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MailingListUpdateRequest} mailingListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update20: async (id: number, mailingListUpdateRequest: MailingListUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update20', 'id', id)
            // verify required parameter 'mailingListUpdateRequest' is not null or undefined
            assertParamExists('update20', 'mailingListUpdateRequest', mailingListUpdateRequest)
            const localVarPath = `/v1/admin/mailing_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingListUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MailingListUpdateRequest} mailingListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update21: async (id: number, mailingListUpdateRequest: MailingListUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update21', 'id', id)
            // verify required parameter 'mailingListUpdateRequest' is not null or undefined
            assertParamExists('update21', 'mailingListUpdateRequest', mailingListUpdateRequest)
            const localVarPath = `/v1/admin/mailing-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingListUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailingListsAdminControllerApi - functional programming interface
 * @export
 */
export const MailingListsAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailingListsAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreate20(mailingListCreateRequest: Array<MailingListCreateRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MailingListCreateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreate20(mailingListCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreate21(mailingListCreateRequest: Array<MailingListCreateRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MailingListCreateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreate21(mailingListCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MailingListCreateRequest} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create24(mailingListCreateRequest: MailingListCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create24(mailingListCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MailingListCreateRequest} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create25(mailingListCreateRequest: MailingListCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create25(mailingListCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById21(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById21(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById22(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById22(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find57(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find57(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find58(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find58(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExtended20(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExtended20(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExtended21(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExtended21(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById42(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById42(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById43(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById43(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount47(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount47(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount48(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount48(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit20(pageable: Pageable, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit20(pageable, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit21(pageable: Pageable, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit21(pageable, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nSupportedProperties20(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nSupportedProperties20(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nSupportedProperties21(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getI18nSupportedProperties21(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessTranslations20ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations20FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTranslations20(toLanguage: ProcessTranslations20ToLanguageEnum, fromLanguage?: ProcessTranslations20FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTranslations20(toLanguage, fromLanguage, forceTranslate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessTranslations21ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations21FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTranslations21(toLanguage: ProcessTranslations21ToLanguageEnum, fromLanguage?: ProcessTranslations21FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTranslations21(toLanguage, fromLanguage, forceTranslate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPropertiesToNull20(id: number, properties: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPropertiesToNull20(id, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPropertiesToNull21(id: number, properties: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPropertiesToNull21(id, properties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startMailing(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startMailing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startMailing1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startMailing1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MailingListUpdateRequest} mailingListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update20(id: number, mailingListUpdateRequest: MailingListUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update20(id, mailingListUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MailingListUpdateRequest} mailingListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update21(id: number, mailingListUpdateRequest: MailingListUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update21(id, mailingListUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailingListsAdminControllerApi - factory interface
 * @export
 */
export const MailingListsAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailingListsAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate20(mailingListCreateRequest: Array<MailingListCreateRequest>, options?: any): AxiosPromise<Array<MailingListCreateResponse>> {
            return localVarFp.bulkCreate20(mailingListCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreate21(mailingListCreateRequest: Array<MailingListCreateRequest>, options?: any): AxiosPromise<Array<MailingListCreateResponse>> {
            return localVarFp.bulkCreate21(mailingListCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MailingListCreateRequest} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create24(mailingListCreateRequest: MailingListCreateRequest, options?: any): AxiosPromise<MailingListCreateResponse> {
            return localVarFp.create24(mailingListCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MailingListCreateRequest} mailingListCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create25(mailingListCreateRequest: MailingListCreateRequest, options?: any): AxiosPromise<MailingListCreateResponse> {
            return localVarFp.create25(mailingListCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById21(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById21(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById22(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteById22(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find57(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageMailingListDto> {
            return localVarFp.find57(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find58(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageMailingListDto> {
            return localVarFp.find58(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended20(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageMailingListDto> {
            return localVarFp.findExtended20(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExtended21(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageMailingListDto> {
            return localVarFp.findExtended21(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById42(id: number, options?: any): AxiosPromise<MailingListDto> {
            return localVarFp.getById42(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById43(id: number, options?: any): AxiosPromise<MailingListDto> {
            return localVarFp.getById43(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount47(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount47(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount48(query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount48(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit20(pageable: Pageable, id: number, options?: any): AxiosPromise<PageAudit> {
            return localVarFp.getEntityAudit20(pageable, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit21(pageable: Pageable, id: number, options?: any): AxiosPromise<PageAudit> {
            return localVarFp.getEntityAudit21(pageable, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties20(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getI18nSupportedProperties20(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nSupportedProperties21(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getI18nSupportedProperties21(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessTranslations20ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations20FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations20(toLanguage: ProcessTranslations20ToLanguageEnum, fromLanguage?: ProcessTranslations20FromLanguageEnum, forceTranslate?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processTranslations20(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessTranslations21ToLanguageEnum} toLanguage 
         * @param {ProcessTranslations21FromLanguageEnum} [fromLanguage] 
         * @param {boolean} [forceTranslate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTranslations21(toLanguage: ProcessTranslations21ToLanguageEnum, fromLanguage?: ProcessTranslations21FromLanguageEnum, forceTranslate?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processTranslations21(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull20(id: number, properties: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPropertiesToNull20(id, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} properties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPropertiesToNull21(id: number, properties: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPropertiesToNull21(id, properties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMailing(id: number, options?: any): AxiosPromise<MailingListDto> {
            return localVarFp.startMailing(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMailing1(id: number, options?: any): AxiosPromise<MailingListDto> {
            return localVarFp.startMailing1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MailingListUpdateRequest} mailingListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update20(id: number, mailingListUpdateRequest: MailingListUpdateRequest, options?: any): AxiosPromise<MailingListUpdateResponse> {
            return localVarFp.update20(id, mailingListUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MailingListUpdateRequest} mailingListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update21(id: number, mailingListUpdateRequest: MailingListUpdateRequest, options?: any): AxiosPromise<MailingListUpdateResponse> {
            return localVarFp.update21(id, mailingListUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailingListsAdminControllerApi - object-oriented interface
 * @export
 * @class MailingListsAdminControllerApi
 * @extends {BaseAPI}
 */
export class MailingListsAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public bulkCreate20(mailingListCreateRequest: Array<MailingListCreateRequest>, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).bulkCreate20(mailingListCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MailingListCreateRequest>} mailingListCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public bulkCreate21(mailingListCreateRequest: Array<MailingListCreateRequest>, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).bulkCreate21(mailingListCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingListCreateRequest} mailingListCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public create24(mailingListCreateRequest: MailingListCreateRequest, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).create24(mailingListCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingListCreateRequest} mailingListCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public create25(mailingListCreateRequest: MailingListCreateRequest, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).create25(mailingListCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public deleteById21(id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).deleteById21(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public deleteById22(id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).deleteById22(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public find57(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).find57(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public find58(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).find58(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public findExtended20(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).findExtended20(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public findExtended21(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).findExtended21(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getById42(id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getById42(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getById43(id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getById43(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getCount47(query?: string, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getCount47(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getCount48(query?: string, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getCount48(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getEntityAudit20(pageable: Pageable, id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getEntityAudit20(pageable, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getEntityAudit21(pageable: Pageable, id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getEntityAudit21(pageable, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getI18nSupportedProperties20(options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getI18nSupportedProperties20(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public getI18nSupportedProperties21(options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).getI18nSupportedProperties21(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessTranslations20ToLanguageEnum} toLanguage 
     * @param {ProcessTranslations20FromLanguageEnum} [fromLanguage] 
     * @param {boolean} [forceTranslate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public processTranslations20(toLanguage: ProcessTranslations20ToLanguageEnum, fromLanguage?: ProcessTranslations20FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).processTranslations20(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessTranslations21ToLanguageEnum} toLanguage 
     * @param {ProcessTranslations21FromLanguageEnum} [fromLanguage] 
     * @param {boolean} [forceTranslate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public processTranslations21(toLanguage: ProcessTranslations21ToLanguageEnum, fromLanguage?: ProcessTranslations21FromLanguageEnum, forceTranslate?: boolean, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).processTranslations21(toLanguage, fromLanguage, forceTranslate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} properties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public setPropertiesToNull20(id: number, properties: Array<string>, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).setPropertiesToNull20(id, properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} properties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public setPropertiesToNull21(id: number, properties: Array<string>, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).setPropertiesToNull21(id, properties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public startMailing(id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).startMailing(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public startMailing1(id: number, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).startMailing1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MailingListUpdateRequest} mailingListUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public update20(id: number, mailingListUpdateRequest: MailingListUpdateRequest, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).update20(id, mailingListUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MailingListUpdateRequest} mailingListUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingListsAdminControllerApi
     */
    public update21(id: number, mailingListUpdateRequest: MailingListUpdateRequest, options?: AxiosRequestConfig) {
        return MailingListsAdminControllerApiFp(this.configuration).update21(id, mailingListUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations20ToLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations20FromLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations21ToLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
/**
  * @export
  * @enum {string}
  */
export enum ProcessTranslations21FromLanguageEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}
