/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CurrencyExtendedDto } from './currency-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventBillingSystemExtendedDto } from './event-billing-system-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventPriceExtendedDto } from './event-price-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventSaleQuotaExtendedDto } from './event-sale-quota-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventSeatExtendedDto } from './event-seat-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PromoCode } from './promo-code';
// May contain unused imports in some cases
// @ts-ignore
import { PurchaseOrderExtendedDto } from './purchase-order-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { User } from './user';

/**
 * 
 * @export
 * @interface TicketExtendedDto
 */
export interface TicketExtendedDto {
    /**
     * 
     * @type {number}
     * @memberof TicketExtendedDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketExtendedDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketExtendedDto
     */
    'status'?: TicketExtendedDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketExtendedDto
     */
    'type'?: TicketExtendedDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketExtendedDto
     */
    'ticketId'?: string;
    /**
     * 
     * @type {EventSeatExtendedDto}
     * @memberof TicketExtendedDto
     */
    'eventSeat'?: EventSeatExtendedDto;
    /**
     * 
     * @type {EventPriceExtendedDto}
     * @memberof TicketExtendedDto
     */
    'eventPrice'?: EventPriceExtendedDto;
    /**
     * 
     * @type {number}
     * @memberof TicketExtendedDto
     */
    'vat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketExtendedDto
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {EventBillingSystemExtendedDto}
     * @memberof TicketExtendedDto
     */
    'eventAgencyBillingSystem'?: EventBillingSystemExtendedDto;
    /**
     * 
     * @type {CurrencyExtendedDto}
     * @memberof TicketExtendedDto
     */
    'currency'?: CurrencyExtendedDto;
    /**
     * 
     * @type {User}
     * @memberof TicketExtendedDto
     */
    'user'?: User;
    /**
     * 
     * @type {PromoCode}
     * @memberof TicketExtendedDto
     */
    'promoCode'?: PromoCode;
    /**
     * 
     * @type {PurchaseOrderExtendedDto}
     * @memberof TicketExtendedDto
     */
    'purchaseOrder'?: PurchaseOrderExtendedDto;
    /**
     * 
     * @type {EventSaleQuotaExtendedDto}
     * @memberof TicketExtendedDto
     */
    'eventSaleQuota'?: EventSaleQuotaExtendedDto;
    /**
     * 
     * @type {string}
     * @memberof TicketExtendedDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketExtendedDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketExtendedDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketExtendedDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TicketExtendedDtoStatusEnum {
    RESERVED = 'reserved',
    PURCHASED = 'purchased',
    RETURN_REQUESTED = 'return_requested',
    RETURNED = 'returned',
    EXPIRED = 'expired'
}
/**
    * @export
    * @enum {string}
    */
export enum TicketExtendedDtoTypeEnum {
    REGULAR = 'regular',
    GIFT = 'gift',
    EXTERNAL = 'external'
}


