import SideBar from './SideBar'
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Layout } from 'antd'
const { Content, Sider, Header } = Layout
import HeaderContent from './Header/HeaderContent'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import styles from './MailLayout.module.less'
import { ReactComponent as ArrowSvg } from '@/assets/arrow-square-up.svg'
import cn from 'classnames'

export const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false)
  const { appLang, fetchLanguages } = useLanguageContext()
  useEffect(() => {
    fetchLanguages()
  }, [])
  return (
    <Layout key={appLang} hasSider>
      <Sider
        width={280}
        collapsedWidth={60}
        breakpoint="lg"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: 'black',
        }}
      >
        <div
          className={cn(
            styles.sidebarTop,
            collapsed && styles.sidebarTopCollapsed,
          )}
        >
          <div className={cn(styles.text, collapsed && styles.textCollapsed)}>
            {'Relivent ticket system'}
          </div>
          <ArrowSvg
            className={cn(styles.collapseButton, collapsed && styles.collapsed)}
            onClick={() => setCollapsed((old) => !old)}
          />
        </div>
        <SideBar />
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? '60px' : '280px',
          transition: 'all 0.2s',
        }}
      >
        <Header
          style={{
            background: 'whitesmoke',
            display: 'flex',
            justifyContent: 'right',
            padding: '0 10px',
          }}
        >
          <HeaderContent />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}
