import { useCallback, useEffect, useState } from 'react'
import { FieldError } from 'rc-field-form/es/interface'
import { useFormContext } from '@/contexts/FormProvider'
import { generateName } from '@components/formElements/InputMultiLanguage'
import { EventCreateResponseLanguagesEnum } from '@/openapi'
import { FormInstance } from 'antd'

export const useHandleFormErrors = (
  languages: EventCreateResponseLanguagesEnum[],
  name: string,
  form: FormInstance,
) => {
  const [errors, setErrors] = useState<FieldError[]>([])
  const { onFormChangeCb, clearCb } = useFormContext()
  const calculateFieldErrors = useCallback(() => {
    setErrors(form.getFieldsError(languages.map((l) => generateName(name, l))))
  }, [languages, name, form])

  useEffect(() => {
    onFormChangeCb?.(calculateFieldErrors, name)

    return () => clearCb?.(name)
  }, [calculateFieldErrors, name])

  return errors
}
