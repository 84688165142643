/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CurrencyUserDto } from './currency-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventBillingSystemUserDto } from './event-billing-system-user-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventUserDto } from './event-user-dto';

/**
 * 
 * @export
 * @interface PurchaseOrderUserDto
 */
export interface PurchaseOrderUserDto {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderUserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderUserDto
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderUserDto
     */
    'status'?: PurchaseOrderUserDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderUserDto
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderUserDto
     */
    'ticketCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderUserDto
     */
    'vat'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderUserDto
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {CurrencyUserDto}
     * @memberof PurchaseOrderUserDto
     */
    'currency'?: CurrencyUserDto;
    /**
     * 
     * @type {EventUserDto}
     * @memberof PurchaseOrderUserDto
     */
    'event'?: EventUserDto;
    /**
     * 
     * @type {EventBillingSystemUserDto}
     * @memberof PurchaseOrderUserDto
     */
    'eventAgencyBillingSystem'?: EventBillingSystemUserDto;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderUserDto
     */
    'abandonTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderUserDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderUserDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderUserDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderUserDto
     */
    'updatedBy'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PurchaseOrderUserDtoStatusEnum {
    STARTED = 'started',
    PURCHASE_STARTED = 'purchase_started',
    PURCHASE_PENDING = 'purchase_pending',
    COMPLETED = 'completed',
    RETURN_REQUESTED = 'return_requested',
    RETURNED = 'returned',
    EXPIRED = 'expired',
    CANCELED = 'canceled',
    EVENT_CANCELED = 'event_canceled'
}


