/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorDto } from './actor-dto';
// May contain unused imports in some cases
// @ts-ignore
import { AgencyDto } from './agency-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CityDto } from './city-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CityUpdateResponseTimezone } from './city-update-response-timezone';
// May contain unused imports in some cases
// @ts-ignore
import { CurrencyDto } from './currency-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EventPriceSales } from './event-price-sales';
// May contain unused imports in some cases
// @ts-ignore
import { EventSales } from './event-sales';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeDto } from './event-type-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StageDto } from './stage-dto';
// May contain unused imports in some cases
// @ts-ignore
import { TourDto } from './tour-dto';

/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'admissionStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'admissionEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'saleStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'saleEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'pinCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'lockId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'eventStatus'?: EventDtoEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'filterType'?: EventDtoFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    'includeVat'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'vat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    'includeServiceFee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'commissionRate'?: number;
    /**
     * 
     * @type {AgencyDto}
     * @memberof EventDto
     */
    'agency'?: AgencyDto;
    /**
     * 
     * @type {Array<ActorDto>}
     * @memberof EventDto
     */
    'actors'?: Array<ActorDto>;
    /**
     * 
     * @type {EventTypeDto}
     * @memberof EventDto
     */
    'eventType'?: EventTypeDto;
    /**
     * 
     * @type {CityDto}
     * @memberof EventDto
     */
    'city'?: CityDto;
    /**
     * 
     * @type {StageDto}
     * @memberof EventDto
     */
    'stage'?: StageDto;
    /**
     * 
     * @type {CurrencyDto}
     * @memberof EventDto
     */
    'currency'?: CurrencyDto;
    /**
     * 
     * @type {TourDto}
     * @memberof EventDto
     */
    'tour'?: TourDto;
    /**
     * 
     * @type {EventSales}
     * @memberof EventDto
     */
    'eventSales'?: EventSales;
    /**
     * 
     * @type {Array<EventPriceSales>}
     * @memberof EventDto
     */
    'eventPriceSales'?: Array<EventPriceSales>;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'minPriceSeatsLeft'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    'soldOut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {CityUpdateResponseTimezone}
     * @memberof EventDto
     */
    'timezone'?: CityUpdateResponseTimezone;
}

/**
    * @export
    * @enum {string}
    */
export enum EventDtoEventStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum EventDtoFilterTypeEnum {
    REGULAR = 'regular',
    PROMOTION = 'promotion',
    HIDDEN = 'hidden'
}


