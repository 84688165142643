/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CurrencyCreateResponse } from './currency-create-response';
// May contain unused imports in some cases
// @ts-ignore
import { EventBillingSystemCreateResponse } from './event-billing-system-create-response';
// May contain unused imports in some cases
// @ts-ignore
import { EventCreateResponse } from './event-create-response';
// May contain unused imports in some cases
// @ts-ignore
import { UserCreateResponse } from './user-create-response';

/**
 * 
 * @export
 * @interface PurchaseOrderCreateResponse
 */
export interface PurchaseOrderCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateResponse
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateResponse
     */
    'externalOrderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateResponse
     */
    'status'?: PurchaseOrderCreateResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateResponse
     */
    'total'?: number;
    /**
     * 
     * @type {CurrencyCreateResponse}
     * @memberof PurchaseOrderCreateResponse
     */
    'currency'?: CurrencyCreateResponse;
    /**
     * 
     * @type {EventCreateResponse}
     * @memberof PurchaseOrderCreateResponse
     */
    'event'?: EventCreateResponse;
    /**
     * 
     * @type {EventBillingSystemCreateResponse}
     * @memberof PurchaseOrderCreateResponse
     */
    'eventAgencyBillingSystem'?: EventBillingSystemCreateResponse;
    /**
     * 
     * @type {UserCreateResponse}
     * @memberof PurchaseOrderCreateResponse
     */
    'user'?: UserCreateResponse;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateResponse
     */
    'updatedDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PurchaseOrderCreateResponseStatusEnum {
    STARTED = 'started',
    PURCHASE_STARTED = 'purchase_started',
    PURCHASE_PENDING = 'purchase_pending',
    COMPLETED = 'completed',
    RETURN_REQUESTED = 'return_requested',
    RETURNED = 'returned',
    EXPIRED = 'expired',
    CANCELED = 'canceled',
    EVENT_CANCELED = 'event_canceled'
}


