import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { MLFormProps } from '../types'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { prepareOption } from '@components/AsyncSelect/types'
import FloatInput from '@components/FloatInput'
import FormItem from '@components/FormItem'
import { AgencyAsyncSelect } from '@components/AsyncSelect/AgencyAsyncSelect'
import { LoginCredentialsDtoRolesEnum } from '@/openapi'
import { useTranslation } from 'react-i18next'

const LoginCredentialsForm = ({ onSuccess, id }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { appLang } = useLanguageContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/login-credentials/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            ...(data.agency
              ? { agencyId: prepareOption(data.agency, appLang) }
              : {}),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    if (id) {
      await axiosInstance.put(`admin/login-credentials/${id}`, {
        password: values.password,
      })
    } else {
      const valuesCopy = {
        ...values,
        roles: [LoginCredentialsDtoRolesEnum.PARTNER],
      }
      await axiosInstance.post('admin/login-credentials', valuesCopy)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={fullItem}
    >
      <FormItem
        name="username"
        hasFeedback
        rules={[{ required: true }]}
        disabled={!!id}
      >
        <FloatInput label={t('labels.User name')} required />
      </FormItem>
      <Form.Item name="password" hasFeedback rules={[{ required: !id }]}>
        <FloatInput label={t`Password`} type="password" required />
      </Form.Item>
      <AgencyAsyncSelect
        rules={[{ required: true }]}
        name="agencyId"
        withAll={false}
        disabled={!!id}
      />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t`Submit`}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginCredentialsForm
