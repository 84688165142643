import { useCallback, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import Page from 'components/Page'
import { PageTicketOperatorDto, TicketOperatorDto } from '@/openapi'
import { SorterResult } from 'antd/es/table/interface'
import DropOption from '@components/DropOption/DropOption'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@components/FloatInput'

import { Params } from '@/types/Params'
import commonStyles from '../commonStyles.module.less'
import { twoColumnsDateFormat } from '@pages/helpers'
import { useTranslation } from 'react-i18next'
import TicketOperatorForm from '@pages/ticketOperators/ticketOperatorForm'
import { DragTable, ExtendedColumns } from '@components/DragTable'

const TicketOperators = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<TicketOperatorDto>()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<TicketOperatorDto>
    },
    filters: { [key in keyof TicketOperatorDto]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = []
    if (filters.name) {
      query.push(`name~~${filters.name}`)
    }
    if (filters.publicUrl) {
      query.push(`publicUrl~~${filters.publicUrl}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageTicketOperatorDto>(
      'admin/ticket-operators',
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const handleMenuClick = useCallback((record: TicketOperatorDto, e: any) => {
    if (e.key === '1') {
      setActiveItem(record)
      setIsOpenModal(true)
    }
  }, [])

  const columns: ExtendedColumns<TicketOperatorDto>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
      fixed: 'left',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`name`}</div>
          <Form.Item name="name">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      shortTitle: t`name`,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`link`}</div>
          <Form.Item name="publicUrl">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'publicUrl',
      key: 'publicUrl',
      sorter: true,
      shortTitle: t`link`,
    },
    {
      title: t`createdDate`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      render: twoColumnsDateFormat,
    },
    {
      title: t`updatedDate`,
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      sorter: true,
      render: twoColumnsDateFormat,
    },
    {
      title: t`actions`,
      key: 'operation',
      noDrag: true,
      noTooltip: true,
      width: '90px',
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[{ key: '1', name: t`update` }]}
          />
        )
      },
    },
  ]

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refresh()
    setActiveItem(undefined)
  }, [])

  return (
    <Page inner>
      <Form style={{ marginTop: 10 }} form={form}>
        <DragTable<TicketOperatorDto>
          bordered
          actions={
            <Button onClick={() => setIsOpenModal(true)}>{t`add`}</Button>
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
      <Modal
        open={isOpenModal}
        title={
          activeItem
            ? `${t`update`} ${activeItem.name}`
            : t`TicketOperators.addOperator`
        }
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <TicketOperatorForm onSuccess={handleSuccess} id={activeItem?.id} />
      </Modal>
    </Page>
  )
}

export default TicketOperators
