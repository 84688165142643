import { useCallback, useEffect, useState } from 'react'
import { Modal, Form, Button, Select, Space } from 'antd'
import Page from 'components/Page'
import {
  PageImplTicketsSalesStatisticsView,
  TicketsNotificationsProjection,
  TicketsSalesStatisticsView,
  TicketsSalesStatisticsViewTicketStatusEnum,
} from '@/openapi'
import { SorterResult, ColumnsType } from 'antd/es/table/interface'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import FloatInput from '@/components/FloatInput'

import commonStyles from '@/pages/commonStyles.module.less'
import { useParams, useSearchParams } from 'react-router-dom'
import { Params } from '@/types/Params'
import { twoColumnsDateFormat } from '@pages/helpers'
import { useAuthContext } from '@/contexts/AuthContext'
import DropOption from '@components/DropOption/DropOption'
import { LinkOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'
import { DragTable } from '@components/DragTable'
import { useAdminColumns } from '@/hooks/adminColumns'
export type CustomFormData = TicketsSalesStatisticsView
const { Option } = Select

export const ticketsStatusOptions = [
  {
    value: TicketsSalesStatisticsViewTicketStatusEnum.PURCHASED,
    label: 'Purchased',
  },
  {
    value: TicketsSalesStatisticsViewTicketStatusEnum.RESERVED,
    label: 'Reserved',
  },
  {
    value: TicketsSalesStatisticsViewTicketStatusEnum.RETURNED,
    label: 'Returned',
  },
  {
    value: TicketsSalesStatisticsViewTicketStatusEnum.RETURN_REQUESTED,
    label: 'Return Requested',
  },
]

const selectOptions = [
  ['purchased', 'Purchased'],
  ['return_requested', 'Return Requested'],
  ['returned', 'Returned'],
]

const SoldTickets = () => {
  const [form] = Form.useForm()
  const { id: eventId } = useParams<{ id: string }>()
  const { isAdmin, isPartner } = useAuthContext()
  const [_, setSearch] = useSearchParams()
  const [notProcessedTickets, setNotProcessedTickets] = useState(0)
  const [processing, setProcessing] = useState(false)
  const { t } = useTranslation()
  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<TicketsSalesStatisticsView>
    },
    filters: { [key in keyof CustomFormData]: string },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query: string[] = [`eventId==${eventId}`]
    if (filters.ticketStatus) {
      query.push(`ticketStatus==${filters.ticketStatus}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    if (filters.phone) {
      query.push(`phone~~${filters.phone}`)
    }
    if (filters.email) {
      query.push(`email~~${filters.email}`)
    }
    params.query = query.join(';')
    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageImplTicketsSalesStatisticsView>(
      `admin/statistics`,
      { params },
    )
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const getPaymentLink = useCallback(async (id: number) => {
    const { data } = await axiosInstance.get<string>(
      `/admin/tickets/${id}/payment-link`,
    )
    window.open(data, '_blank')
  }, [])

  const downloadTicket = useCallback(
    async (record: TicketsSalesStatisticsView) => {
      const { data } = await axiosInstance.get<Blob>(
        `/admin/tickets/${record.id}/download`,
        {
          responseType: 'blob',
        },
      )
      const fileURL = window.URL.createObjectURL(data)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = `Ticket-${record.id}-${record.phone}`
      alink.click()
    },
    [],
  )

  const handleMenuClick = useCallback(
    (record: TicketsSalesStatisticsView, e: any) => {
      if (e.key === '1') {
        downloadTicket(record)
      }
    },
    [],
  )

  const columns: ColumnsType<TicketsSalesStatisticsView> = useAdminColumns(
    [
      {
        title: 'ID',
        fixed: 'left',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        width: '70px',
      },
      {
        title: t`labels.Created Date`,
        dataIndex: 'createdDate',
        key: 'createdDate',
        sorter: true,
        defaultSortOrder: 'descend',
        render: twoColumnsDateFormat,
      },
      {
        title: t`labels.Price`,
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        render: (_, ticket) => ticket.price + ' ' + ticket.currency,
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`Phone`}</div>
            <Form.Item name="phone">
              <FloatInput label="" onChange={submit} />
            </Form.Item>
          </div>
        ),
        dataIndex: 'phone',
        key: 'phone',
        shortTitle: t`Phone`,
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`labels.Email`}</div>
            <Form.Item name="email">
              <FloatInput label="" onChange={submit} />
            </Form.Item>
          </div>
        ),
        dataIndex: 'email',
        key: 'email',
        shortTitle: t`labels.Email`,
      },
      {
        title: (
          <div className={commonStyles.headerCell}>
            <div>{t`labels.Status`}</div>
            <Form.Item name="ticketStatus">
              <Select onClick={(e) => e.stopPropagation()} onChange={submit}>
                {ticketsStatusOptions.map(({ label, value }) => (
                  <Option value={value} label={label} key={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        ),
        noTooltip: true,
        dataIndex: 'ticketStatus',
        key: 'ticketStatus',
        shortTitle: t`labels.Status`,
        sorter: true,
        render: (_, ticket) => (
          <div>
            <div>
              {
                ticketsStatusOptions.find(
                  ({ value }) =>
                    value ===
                    castType<TicketsSalesStatisticsViewTicketStatusEnum>(
                      ticket.ticketStatus,
                    ),
                )?.label
              }
            </div>
            {isAdmin && (
              <div>
                <Select
                  value=""
                  onChange={(value) => {
                    Modal.confirm({
                      title: t(`messages.changeTicketStatus`, {
                        from: ticket.ticketStatus,
                        to: value,
                      }),
                      onOk: async () => {
                        const castedValue =
                          castType<TicketsSalesStatisticsViewTicketStatusEnum>(
                            value,
                          )
                        if (
                          castedValue ===
                          TicketsSalesStatisticsViewTicketStatusEnum.RETURNED
                        ) {
                          await axiosInstance.post(
                            '/admin/tickets/' + ticket.id + '/refund-ticket',
                          )
                        } else {
                          await axiosInstance.put(
                            '/admin/tickets/' + ticket.id,
                            {
                              id: ticket.id,
                              status: value,
                            },
                          )
                        }

                        refresh()
                      },
                    })
                  }}
                  style={{ width: '100%' }}
                >
                  {selectOptions
                    .filter(
                      ([value]) =>
                        castType<TicketsSalesStatisticsViewTicketStatusEnum>(
                          value,
                        ) !== ticket.ticketStatus,
                    )
                    .map(([value, label]) => (
                      <Option value={value} label={label} key={value}>
                        {'->'} {label}
                      </Option>
                    ))}
                </Select>
              </div>
            )}
          </div>
        ),
      },
      {
        title: t`labels.Payment link`,
        dataIndex: 'paymentLink',
        key: 'paymentLink',
        width: '130px',
        render: (_, ticket) => {
          if (
            ticket.ticketStatus ===
              TicketsSalesStatisticsViewTicketStatusEnum.PURCHASED ||
            ticket.ticketStatus ===
              TicketsSalesStatisticsViewTicketStatusEnum.RETURNED ||
            ticket.ticketStatus ===
              TicketsSalesStatisticsViewTicketStatusEnum.RETURN_REQUESTED
          ) {
            return (
              <Button
                type="primary"
                icon={<LinkOutlined />}
                onClick={() => getPaymentLink(ticket.id!)}
              >
                {t`open`}
              </Button>
            )
          }
          return null
        },
      },
      {
        title: t`actions`,
        key: 'operation',
        noDrag: true,
        noTooltip: true,
        width: '90px',
        render: (text, record) => {
          return (
            <DropOption
              onMenuClick={(e) => handleMenuClick(record, e)}
              menuOptions={[{ key: '1', name: `Download ticket` }]}
            />
          )
        },
      },
    ],
    ['operation', 'paymentLink', 'phone', 'email'],
  )

  const downloadFile = useCallback(async () => {
    const { data } = await axiosInstance.get<Blob>(
      `/admin/tickets/export/${eventId}`,
      {
        responseType: 'blob',
      },
    )
    const fileURL = window.URL.createObjectURL(data)
    let alink = document.createElement('a')
    alink.href = fileURL
    alink.download = `ticket-ids-${eventId}.txt`
    alink.click()
  }, [eventId])

  const calcTicketsToReprocess = useCallback(async () => {
    if (isPartner) return
    const { data } = await axiosInstance.get<TicketsNotificationsProjection>(
      `admin/notifications/events/${eventId}/purchase-orders`,
    )
    const delta = (data.purchaseOrdersCount || 0) - (data.smsDelivered || 0)
    if (delta > 0) {
      setNotProcessedTickets(delta)
    }
  }, [eventId])

  useEffect(() => {
    calcTicketsToReprocess()
  }, [eventId])

  const reprocessTickets = useCallback(async () => {
    setProcessing(true)
    try {
      await axiosInstance.post(
        `admin/notifications/reprocess/purchase-orders?eventId=${eventId}`,
      )
    } finally {
      await calcTicketsToReprocess()
      setProcessing(false)
    }
  }, [eventId])

  return (
    <Page inner>
      <div style={{ display: 'flex', gap: '20px' }}>
        <Button className={commonStyles.addButton} onClick={downloadFile}>
          {t`labels.Download Ticket ids in TXT`}
        </Button>
        <Button
          style={{ marginBottom: '10px' }}
          onClick={() => setSearch('tab=new-gift')}
        >
          {t`labels.New Gift Ticket`}
        </Button>
      </div>

      <Form form={form}>
        <DragTable<TicketsSalesStatisticsView>
          bordered
          actionsMobile
          actions={
            notProcessedTickets > 0 && (
              <Space
                style={{
                  display: 'flex',
                  gap: '20px',
                  marginBottom: '20px',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ margin: 0 }}>
                  {t`labels.Count of not processed tickets`}
                  {notProcessedTickets}
                </h3>
                <Button
                  loading={processing}
                  disabled={processing}
                  onClick={reprocessTickets}
                >
                  {t`labels.Reprocess tickets`}
                </Button>
              </Space>
            )
          }
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default SoldTickets
