import dayjs from 'dayjs'
import { TicketDto } from '@/openapi'
import { t } from 'i18next'

export const twoColumnsDateFormat = (date?: string) => {
  if (!date) {
    return (
      <>
        <div>{'—'}</div>
      </>
    )
  } else {
    return (
      <>
        <div>{dayjs(date).format('YYYY-MM-DD')}</div>
        <div style={{ fontSize: '12px' }}>{dayjs(date).format('HH:mm:ss')}</div>
      </>
    )
  }
}

export const renderRowPlace = (event?: TicketDto) => {
  if (!event?.eventSeat) {
    return (
      <>
        <div>{'—'}</div>
      </>
    )
  } else {
    return (
      <>
        <div>{`${t`labels.Row`} ${event.eventSeat.rowNum || '—'}`}</div>
        <div style={{ fontSize: '12px' }}>
          {`${t`labels.Place`} ${event.eventSeat.place || '—'}`}
        </div>
      </>
    )
  }
}

export const renderUser = (event?: TicketDto) => {
  if (!event?.user) {
    return (
      <>
        <div>{'—'}</div>
      </>
    )
  } else {
    return (
      <>
        <div>
          {`${t`Phone`} (${event.user.country?.phoneCode})${event.user.phone}`}
        </div>
        <div style={{ fontSize: '12px' }}>
          {t`labels.Email`} {event.user.email || '—'}
        </div>
      </>
    )
  }
}

export const valueOrDash = (v?: string | number, postfix = '') =>
  v ? String(`${v} ${postfix}`) : '—'

export const generateDefaultSort = (
  columnKey: string,
  order: 'descend' | 'ascend',
): any => [
  {
    sorter: { columnKey, order },
    pageSize: 10,
    current: 1,
  },
  {},
]

export const alpabeticalSort = (a: string, b: string) => {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

export const dateSort = (a: string, b: string) => {
  const dateA = dayjs(a)
  const dateB = dayjs(b)
  if (dateA.isSame(dateB)) return 0
  if (dateA.isAfter(dateB)) return 1
  return -1
}

export const noop = () => {}

export const roundToNearestFiveCents = (number?: number) => {
  if (!number) return '—'
  return Math.ceil(number * 20) / 20
}

export const percentFormat = (number?: number) => {
  if (!number) return '—'
  return roundToNearestFiveCents(number) + ' %'
}
