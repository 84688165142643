import { useCallback, useState } from 'react'
import styles from './styles.module.less'
import useAsyncGet from '@/hooks/useAsyncGet'
import {
  ActorSocialMediaDto,
  PageActorSocialMediaDto,
  PageSocialMediaDto,
  SocialMediaDto,
} from '@/openapi'
import { ReactComponent as TickCircle } from '@/assets/tick-circle.svg'
import { Button, Modal } from 'antd'
import ManageSocialMediaForm from '@pages/actors/SocialLinks/manageSocialMediaForm'
import { useTranslation } from 'react-i18next'

type Props = {
  id?: number
}

const ActorSocialLinks = ({ id }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [activeItem, setActiveItem] = useState<ActorSocialMediaDto>()
  const [activeSocialMedia, setActiveSocialMedia] = useState<SocialMediaDto>()
  const { t } = useTranslation()
  const { data } = useAsyncGet<PageSocialMediaDto>(
    `admin/social-medias?size=1000`,
  )

  const { data: actorsSocials, refetch } = useAsyncGet<PageActorSocialMediaDto>(
    `admin/actor-social-media?size=1000&query=actor.id==${id}`,
  )

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setActiveItem(undefined)
    setActiveSocialMedia(undefined)
  }, [])

  const handleSuccess = useCallback(() => {
    setIsOpenModal(false)
    refetch()
    setActiveItem(undefined)
    setActiveSocialMedia(undefined)
  }, [refetch])

  return (
    <div className={styles.wrapper}>
      <h3>{t`labels.Social media links`}</h3>
      <div className={styles.socialsWrapper}>
        {data?.content?.map((sm) => (
          <div key={sm.id} className={styles.socialItem}>
            <div className={styles.leftPart}>{sm.name}</div>
            <div className={styles.rightPart}>
              {actorsSocials?.content?.some(
                (as) => as.socialMedia?.id === sm.id,
              ) ? (
                <Button
                  type="text"
                  className={styles.connected}
                  onClick={() => {
                    setActiveSocialMedia(sm)
                    setActiveItem(
                      actorsSocials?.content?.find(
                        (as) => as.socialMedia?.id === sm.id,
                      ),
                    )
                    setIsOpenModal(true)
                  }}
                >
                  <TickCircle />
                  <span>{t`labels.Connected`}</span>
                </Button>
              ) : (
                <Button
                  type="text"
                  className={styles.add}
                  onClick={() => {
                    setActiveSocialMedia(sm)
                    setIsOpenModal(true)
                  }}
                >
                  {t`add`}
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      <Modal
        open={isOpenModal}
        title={
          activeItem
            ? t('labels.updateLinkFor', { item: activeSocialMedia?.name })
            : t('labels.addLinkFor', { item: activeSocialMedia?.name })
        }
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        width="50%"
      >
        <ManageSocialMediaForm
          onSuccess={handleSuccess}
          actorId={id!}
          socialMedia={activeSocialMedia}
          item={activeItem}
        />
      </Modal>
    </div>
  )
}

export default ActorSocialLinks
