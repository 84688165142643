/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Currency } from './currency';
// May contain unused imports in some cases
// @ts-ignore
import { MailingList } from './mailing-list';

/**
 * 
 * @export
 * @interface NotificationLog
 */
export interface NotificationLog {
    /**
     * 
     * @type {number}
     * @memberof NotificationLog
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'channel'?: NotificationLogChannelEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'destination'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'type'?: NotificationLogTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'status'?: NotificationLogStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'messageSid'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationLog
     */
    'eventId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'failureReason'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationLog
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationLog
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationLog
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationLog
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationLog
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {MailingList}
     * @memberof NotificationLog
     */
    'mailingList'?: MailingList;
    /**
     * 
     * @type {number}
     * @memberof NotificationLog
     */
    'smsPrice'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof NotificationLog
     */
    'smsPriceCurrency'?: Currency;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationLogChannelEnum {
    EMAIL = 'email',
    SMS = 'sms'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationLogTypeEnum {
    REGISTRATION = 'registration',
    PURCHASE_COMPLETED = 'purchase_completed',
    GIFT = 'gift',
    MARKETING = 'marketing',
    NOTIFICATION = 'notification',
    ALERT = 'alert'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationLogStatusEnum {
    IN_PROGRESS = 'in_progress',
    SENT = 'sent',
    DELIVERED = 'delivered',
    FAILED = 'failed',
    BOUNCE = 'bounce',
    UNDELIVERED = 'undelivered'
}


