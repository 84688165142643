import { Button, Divider, Form } from 'antd'
import FloatInput from '../../components/FloatInput'
import { CountryAsyncSelect } from '@components/AsyncSelect/CountryAsyncSelect'
import { CustomFormData } from './index'
import { axiosInstance } from 'api'
import { useTranslation } from 'react-i18next'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

type CityFormProps = {
  onSuccess: () => void
  item?: CustomFormData
}

const UserForm = ({ onSuccess, item }: CityFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const { t } = useTranslation()

  const onFinish = async (values: CustomFormData) => {
    if (item?.id) {
      await axiosInstance.put(`admin/users/${item.id}`, {
        ...values,
        countryId: values.countryId.value,
      })
    } else {
      await axiosInstance.post('admin/users', values)
    }
    onSuccess()
  }

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={item}
    >
      <Form.Item name="username" hasFeedback rules={[{ required: true }]}>
        <FloatInput label={t('labels.User name')} required />
      </Form.Item>
      <Form.Item name="phone" hasFeedback rules={[{ required: true }]}>
        <FloatInput label={t('labels.Phone')} required />
      </Form.Item>
      <Form.Item name="email" hasFeedback rules={[{ required: true }]}>
        <FloatInput label={t`labels.Email`} required />
      </Form.Item>
      <Form.Item name="userType" hasFeedback rules={[{ required: true }]}>
        <FloatInput label={t`labels.Type`} required />
      </Form.Item>
      <CountryAsyncSelect
        name="countryId"
        rules={[{ required: true }]}
        withAll={false}
      />
      <Divider />
      <Form.Item
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
      >
        <Button type="primary" htmlType="submit">
          {t('Submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserForm
