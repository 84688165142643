/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserPreferencesRequest
 */
export interface UserPreferencesRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPreferencesRequest
     */
    'lang'?: UserPreferencesRequestLangEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPreferencesRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferencesRequest
     */
    'username'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserPreferencesRequestLangEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}


