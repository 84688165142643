/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ActorUpdateResponse } from './actor-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { CityUpdateResponse } from './city-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { CurrencyUpdateResponse } from './currency-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { EventTypeUpdateResponse } from './event-type-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { StageUpdateResponse } from './stage-update-response';
// May contain unused imports in some cases
// @ts-ignore
import { TourUpdateResponse } from './tour-update-response';

/**
 * 
 * @export
 * @interface EventUpdateResponse
 */
export interface EventUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof EventUpdateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'nameBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'descriptionBY'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'admissionStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'admissionEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'saleStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'saleEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'eventStatus'?: EventUpdateResponseEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateResponse
     */
    'filterType'?: EventUpdateResponseFilterTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventUpdateResponse
     */
    'languages'?: Array<EventUpdateResponseLanguagesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof EventUpdateResponse
     */
    'includeVat'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateResponse
     */
    'vat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventUpdateResponse
     */
    'includeServiceFee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateResponse
     */
    'serviceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateResponse
     */
    'commissionRate'?: number;
    /**
     * 
     * @type {Array<ActorUpdateResponse>}
     * @memberof EventUpdateResponse
     */
    'actors'?: Array<ActorUpdateResponse>;
    /**
     * 
     * @type {EventTypeUpdateResponse}
     * @memberof EventUpdateResponse
     */
    'eventType'?: EventTypeUpdateResponse;
    /**
     * 
     * @type {CityUpdateResponse}
     * @memberof EventUpdateResponse
     */
    'city'?: CityUpdateResponse;
    /**
     * 
     * @type {StageUpdateResponse}
     * @memberof EventUpdateResponse
     */
    'stage'?: StageUpdateResponse;
    /**
     * 
     * @type {CurrencyUpdateResponse}
     * @memberof EventUpdateResponse
     */
    'currency'?: CurrencyUpdateResponse;
    /**
     * 
     * @type {TourUpdateResponse}
     * @memberof EventUpdateResponse
     */
    'tour'?: TourUpdateResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum EventUpdateResponseEventStatusEnum {
    UNPUBLISHED = 'unpublished',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    ANNOUNCED = 'announced',
    CANCELED = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum EventUpdateResponseFilterTypeEnum {
    REGULAR = 'regular',
    PROMOTION = 'promotion',
    HIDDEN = 'hidden'
}
/**
    * @export
    * @enum {string}
    */
export enum EventUpdateResponseLanguagesEnum {
    EN = 'EN',
    PL = 'PL',
    RU = 'RU',
    BY = 'BY',
    UA = 'UA'
}


