import { useAuthContext } from '@/contexts/AuthContext'
import { useMemo } from 'react'
import { ExtendedColumns } from '@components/DragTable'

export const useAdminColumns = <T>(
  columns: ExtendedColumns<T>[],
  filter: string[] = ['operation'],
) => {
  const { isAdmin } = useAuthContext()

  const filteredColumns = useMemo(
    () =>
      columns.filter(({ key }) => isAdmin || !filter.includes(key as string)),
    [isAdmin],
  )

  return filteredColumns
}
