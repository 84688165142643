import React, { useCallback, useContext, useMemo, useState } from 'react'
import { axiosInstance } from 'api'
import { EventCreateRequestLanguagesEnum, LanguageDto } from '@/openapi'
import { useLocalStorageState } from 'ahooks'
import LanguagesEnum = EventCreateRequestLanguagesEnum
import i18n from '../i18n'

export interface IAuthContext {
  languages: LanguagesEnum[]
  languagesFull: LanguageDto[]
  appLang: LanguagesEnum
  setApplicationLanguage: (lang: LanguagesEnum) => void
  fetchLanguages: () => void
  getLocalizedKey: <T extends { [key: string]: any }>(
    key: string,
    entity?: T,
  ) => any
}

const LanguageContext = React.createContext<IAuthContext | undefined>(undefined)

const LanguageProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [languagesFull, setFullLanguages] = useState<LanguageDto[]>([])
  const [lsLang, setLsLang] = useLocalStorageState<LanguagesEnum>('lang')
  const [appLang, setAppLang] = useState<LanguagesEnum>(
    lsLang || LanguagesEnum.EN,
  )

  const setApplicationLanguage = useCallback((l: LanguagesEnum) => {
    setLsLang(l)
    setAppLang(l)
    i18n.changeLanguage(l as never as string)
  }, [])

  const fetchLanguages = useCallback(() => {
    axiosInstance
      .get('admin/languages')
      .then(({ data: { content } }) => setFullLanguages(content || []))
  }, [])

  const languages = useMemo(
    () => languagesFull.map(({ code }) => code as never as LanguagesEnum),
    [languagesFull],
  )

  const getLocalizedKey = useCallback(
    <T extends { [key: string]: any }>(key: string, entity?: any) =>
      entity ? entity[key + appLang] : undefined,
    [appLang],
  )

  let value = {
    languages,
    languagesFull,
    appLang,
    setApplicationLanguage,
    fetchLanguages,
    getLocalizedKey,
  }

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export function useLanguageContext() {
  const context = useContext(LanguageContext)

  if (typeof context === 'undefined') {
    throw new Error('useLanguageContext must be used within an AuthProvider')
  }

  return context
}

export default LanguageProvider
