import { Fragment, useCallback } from 'react'
import { Button, Row, Form } from 'antd'
import Ticket from 'assets/ticket-icon.png'

import styles from './index.module.less'
import FloatInput from '../../components/FloatInput'
import { useAuthContext } from '@/contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleOk = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      const result = await authContext.login(username, password)
      if (result) {
        const path = localStorage.getItem('path')
        if (path) {
          localStorage.setItem('path', '')
          navigate(path)
        } else {
          navigate('/app/events_published')
        }
      }
    },
    [],
  )

  return (
    <Fragment>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img alt="logo" src={Ticket} />
          <span>{'RELIVENT'}</span>
        </div>
        <Form onFinish={handleOk}>
          <Form.Item name="username" rules={[{ required: true }]} hasFeedback>
            <FloatInput label={t`labels.Username`} alwaysFloat />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]} hasFeedback>
            <FloatInput label={t`Password`} alwaysFloat type="password" />
          </Form.Item>
          <Row>
            <Button type="primary" htmlType="submit">
              {t`Sign in`}
            </Button>
          </Row>
        </Form>
      </div>
    </Fragment>
  )
}

export default Login
