import { Button, Menu, Space, Tag } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { EventExtendedDto } from '@/openapi'
import { axiosInstance } from '@/api'
import dayjs from 'dayjs'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import TicketControlActivity from '@pages/event/manage/ticketControlActivities'
import PromoCodes from '@pages/event/manage/promoCodes'
import SoldTickets from '@pages/event/manage/soldTickets'
import GiftTickets from '@pages/event/manage/gift'
import NewGiftTickets from './newGift'
import { LinkOutlined } from '@ant-design/icons'
import DenominationReport from '@pages/event/manage/denominationReport'
import { useAuthContext } from '@/contexts/AuthContext'
import VisitsHistory from '@pages/users/manage/visitsHistory'
import { MenuProps } from 'antd/lib/menu'
import UsersSubscriptions from '@pages/event/manage/usersSubscriptions'
import MailingList from '@pages/event/manage/mailingList'
import UsersSubscriptionsPartner from '@pages/event/manage/usersSubscriptions/userSubscriptionsPartner'
import GenericReportByChannels from '@/pages/reports/shared/GenericReportByChannels'
import MarketingReport from '@pages/event/manage/marketingReport'
import SalesByDates from '@pages/reports/salesByDates'
import NotificationLogs from '@pages/users/manage/notificationLogs'
import { useTranslation } from 'react-i18next'
import MarketingLinks from '@/components/pages/marketingLinks'
import {
  isAnnouncedEvent,
  isArchivedEvent,
  isPublishedEvent,
} from '@/utils/eventsStatuses'
const ManageEvent = () => {
  const [search, setSearch] = useSearchParams()
  const { isAdmin } = useAuthContext()
  const { id } = useParams<{ id: string }>()
  const [event, setEvent] = useState<EventExtendedDto>()
  const { getLocalizedKey } = useLanguageContext()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    axiosInstance
      .get<EventExtendedDto>(`admin/events/${id}`)
      .then(({ data }) => {
        setEvent(data)
      })
  }, [id])

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: t`tabs.Ticket Control Activities`,
        key: 'ticket-control-activities',
      },
      {
        label: t`tabs.Promo Codes`,
        key: 'promo-codes',
      },
      {
        label: t`labels.Sold Tickets`,
        key: 'sold-tickets',
      },
      {
        label: t`tabs.Gift tickets`,
        key: 'gift-tickets',
      },
      {
        label: t`tabs.Notification Logs`,
        key: 'notification-logs',
      },
      {
        label: t`tabs.Reports`,
        key: 'reports',
        children: [
          ...(isPublishedEvent(event) || isArchivedEvent(event)
            ? [
                {
                  label: t`tabs.Denomination Report`,
                  key: 'denomination-report',
                } as any,
                {
                  label: t`tabs.Marketing Report`,
                  key: 'marketing-report',
                } as any,
              ]
            : []),
          {
            label: t`tabs.Sales by Dates`,
            key: 'sales-by-dates',
          },
          {
            label: t`tabs.Channels report`,
            key: 'channels-report',
          },
        ],
      },

      ...(search.get('tab') === 'new-gift'
        ? [
            {
              label: t`tabs.Add Gift tickets`,
              key: 'new-gift',
            } as any,
          ]
        : []),

      {
        label: t`tabs.Marketing`,
        key: 'marketing',
        children: [
          ...(isAdmin
            ? [
                {
                  label: t`tabs.User Visit History`,
                  key: 'user-visit-history',
                },
              ]
            : []),
          {
            label: t`tabs.Marketing Links`,
            key: 'marketing-links',
          },
          {
            label: t`tabs.Users Subscriptions`,
            key: 'users-subscriptions',
          },
          ...(isPublishedEvent(event) ||
          isAnnouncedEvent(event) ||
          isArchivedEvent(event)
            ? [
                {
                  label: t`tabs.Mailing list`,
                  key: 'mailing-list',
                },
              ]
            : []),
        ],
      },
    ],
    [event, search, id, t],
  )

  const subtitle = useMemo(
    () => (
      <Space wrap>
        {getLocalizedKey('name', event)}
        {event?.dateStart
          ? `  (${dayjs(event?.dateStart).format('YYYY-MM-DD HH:mm:ss')})`
          : ''}
        <Tag color="#108ee9">{event?.eventStatus}</Tag>
      </Space>
    ),
    [event],
  )

  return (
    <>
      <PageHeader
        title={t`labels.Manage Event`}
        subTitle={subtitle}
        extra={
          <Space wrap>
            <Button
              type="primary"
              icon={<LinkOutlined />}
              onClick={() => navigate(`/app/event/${id}`)}
            >
              {t`labels.View Edit event`}
            </Button>
          </Space>
        }
      />
      <Menu
        onClick={(tab) => setSearch({ tab: tab.key })}
        selectedKeys={[search.get('tab') || 'ticket-control-activities']}
        mode="horizontal"
        items={items}
      />
      {(search.get('tab') === 'ticket-control-activities' ||
        !search.get('tab')) && <TicketControlActivity event={event} />}
      {search.get('tab') === 'promo-codes' && <PromoCodes event={event} />}
      {search.get('tab') === 'notification-logs' && (
        <NotificationLogs baseQuery={`eventId==${id}`} />
      )}
      {search.get('tab') === 'sold-tickets' && <SoldTickets />}
      {search.get('tab') === 'new-gift' && <NewGiftTickets event={event} />}
      {search.get('tab') === 'denomination-report' && (
        <DenominationReport event={event} />
      )}
      {search.get('tab') === 'marketing-report' && <MarketingReport />}
      {search.get('tab') === 'gift-tickets' && <GiftTickets />}
      {search.get('tab') === 'sales-by-dates' && (
        <SalesByDates externalEventId={id} />
      )}
      {search.get('tab') === 'user-visit-history' && (
        <VisitsHistory filter={`eventId==${id}`} showUser />
      )}
      {search.get('tab') === 'marketing-links' && (
        <MarketingLinks showAdd={!isArchivedEvent(event)} />
      )}
      {search.get('tab') === 'users-subscriptions' && isAdmin && (
        <UsersSubscriptions />
      )}
      {search.get('tab') === 'users-subscriptions' && !isAdmin && (
        <UsersSubscriptionsPartner />
      )}
      {search.get('tab') === 'channels-report' && (
        <GenericReportByChannels externalEventId={id} />
      )}
      {search.get('tab') === 'mailing-list' && <MailingList />}
    </>
  )
}

export default ManageEvent
