/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventPriceUserDto } from '../models';
// @ts-ignore
import { PageEventPriceUserDto } from '../models';
// @ts-ignore
import { Pageable } from '../models';
/**
 * EventPricesControllerApi - axios parameter creator
 * @export
 */
export const EventPricesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find15: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('find15', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/event-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById10: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById10', 'id', id)
            const localVarPath = `/v1/events/event-prices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount10: async (pageRequest: Pageable, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageRequest' is not null or undefined
            assertParamExists('getCount10', 'pageRequest', pageRequest)
            const localVarPath = `/v1/events/event-prices/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageRequest !== undefined) {
                localVarQueryParameter['pageRequest'] = pageRequest;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventPricesControllerApi - functional programming interface
 * @export
 */
export const EventPricesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventPricesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find15(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEventPriceUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find15(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById10(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventPriceUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById10(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCount10(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCount10(pageRequest, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventPricesControllerApi - factory interface
 * @export
 */
export const EventPricesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventPricesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find15(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<PageEventPriceUserDto> {
            return localVarFp.find15(pageRequest, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById10(id: number, options?: any): AxiosPromise<EventPriceUserDto> {
            return localVarFp.getById10(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageRequest 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCount10(pageRequest: Pageable, query?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCount10(pageRequest, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventPricesControllerApi - object-oriented interface
 * @export
 * @class EventPricesControllerApi
 * @extends {BaseAPI}
 */
export class EventPricesControllerApi extends BaseAPI {
    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventPricesControllerApi
     */
    public find15(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return EventPricesControllerApiFp(this.configuration).find15(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventPricesControllerApi
     */
    public getById10(id: number, options?: AxiosRequestConfig) {
        return EventPricesControllerApiFp(this.configuration).getById10(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageRequest 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventPricesControllerApi
     */
    public getCount10(pageRequest: Pageable, query?: string, options?: AxiosRequestConfig) {
        return EventPricesControllerApiFp(this.configuration).getCount10(pageRequest, query, options).then((request) => request(this.axios, this.basePath));
    }
}

