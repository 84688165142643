/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserSubscriptionRequest
 */
export interface UserSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionRequest
     */
    'subscriptionEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionRequest
     */
    'type'?: UserSubscriptionRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionRequest
     */
    'byEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionRequest
     */
    'bySms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionRequest
     */
    'email'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSubscriptionRequestTypeEnum {
    EVENT = 'event',
    ACTOR = 'actor',
    STAGE = 'stage',
    CITY = 'city',
    ACTOR_TYPE = 'actor_type',
    EVENT_TYPE = 'event_type'
}


