import { axiosInstance } from '@/api'
import {
  CountryDto,
  EventExtendedDto,
  EventPriceDto,
  EventSeatPriceDto,
  EventSeatUserDto,
  PageEventPriceDto,
  PageEventStageDefinitionDto,
  StageDefinitionDto,
  TicketsBookedDto,
} from '@/openapi'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Card, Col, Form, Row, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { ColumnsType } from 'antd/es/table'
import styles from '@pages/event/styles.module.less'
import { FullscreenOutlined } from '@ant-design/icons'
import useAsyncGet from '@/hooks/useAsyncGet'
import { SeatTag } from '@pages/event/manage/newGift/generateSeatTag'
import { GenericAsyncSelect } from '@components/AsyncSelect/GenericAsyncSelect'
import { SelectOption } from '@/types/Option'
import FloatInput from '@components/FloatInput'
import { useSearchParams } from 'react-router-dom'
import FloatNumberInput from '@components/FloatNumberInput'
const COLORS = [
  '#220597',
  '#8032FF',
  '#E372FF',
  '#FFA53B',
  '#FFD645',
  '#748AFF',
  '#1EC9FF',
  '#56D1C2',
  '#00EADC',
]

export const BOOKED_COLOR = '#E91D41'

export const PURCHASED_COLOR = '#d2d2d2'
export type Props = {
  eventId: string
  event: EventExtendedDto
  stageDefinition?: StageDefinitionDto
}

export const GiftWithSD = ({ eventId }: Props) => {
  const definitionWrapperRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<Element>()
  const selectionFrame = useRef<HTMLDivElement | null>(null)
  const [form] = Form.useForm<any>()
  const { t } = useTranslation()
  const [_, setSearch] = useSearchParams()

  const [seatPrices, setSeatPrices] = useState<EventSeatPriceDto[] | null>(null)
  const [eventSeats, setEventSeats] = useState<EventSeatUserDto[] | null>(null)
  const [eventPrices, setEventPrices] = useState<EventPriceDto[] | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState(false)
  const [filled, setFilled] = useState(false)
  const [selectedSeats, setSelectedSeats] = useState<string[]>([])
  const { appLang } = useLanguageContext()
  const svgString = useRef('')
  const { data: ticketsBooked, loaded: ticketsBookedLoaded } =
    useAsyncGet<TicketsBookedDto>(`admin/tickets/sold/${eventId}`)

  const defaultTicketCategory = {
    name: t('StepSeatAssignment.defaultTicketCategoryName'),
    description: t('StepSeatAssignment.defaultTicketCategoryName'),
    event: {},
    id: 0,
    price: 0,
    quantity: 0,
  }

  const ellipseClickHandler = useCallback((e: Event) => {
    e.stopPropagation()
    const target = e.target
    let ellipse

    if (target instanceof SVGTextElement) {
      const toSeat = target.dataset.toSeat || target.getAttribute('to')
      if (toSeat) {
        ellipse = document.getElementById(toSeat)
      }
    } else {
      ellipse = target
    }

    if (ellipse instanceof SVGElement && ellipse.tagName === 'ellipse') {
      const id = ellipse.getAttribute('id')
      const selected = ellipse.dataset.selected

      if (!selected || selected === 'false') {
        if (id) {
          setSelectedSeats((old) => [...old, id])
        }
        ellipse.setAttribute('stroke', 'red')
        ellipse.setAttribute('stroke-width', '3')
        ellipse.dataset.selected = 'true'
      } else {
        if (id) {
          setSelectedSeats((old) => old.filter((d) => d !== id))
        }

        ellipse.setAttribute('stroke', '#000000')
        ellipse.setAttribute('stroke-width', '1')
        ellipse.dataset.selected = 'false'
      }
    } else if (ellipse instanceof SVGElement && ellipse.tagName === 'rect') {
      const id = ellipse.getAttribute('id')
      if (id) {
        setSelectedSeats((old) => [...old, id])
      }
    }
  }, [])

  const clearSelection = (ids: string[]): void => {
    for (const id of ids) {
      const element = document.getElementById(id)
      if (element) {
        element.setAttribute('stroke', '#000000')
        element.setAttribute('stroke-width', '1')
        element.dataset.selected = 'false'
      }
    }
  }

  const removeSelection = () => {
    clearSelection(selectedSeats)
    setSelectedSeats([])
  }

  const fillSeatsByPrice = (
    eventPrices: EventPriceDto[],
    eventSeatPrices: EventSeatPriceDto[],
  ) => {
    if (eventPrices && eventPrices.length > 0) {
      for (let i = 1; i < eventPrices.length; i++) {
        const eventPrice = eventPrices[i]
        for (const eventSeatPrice of eventSeatPrices) {
          if (eventSeatPrice.eventSeat?.seatId) {
            if (eventSeatPrice.eventPrice?.id === eventPrice.id) {
              const ele = document.getElementById(
                eventSeatPrice.eventSeat.seatId,
              )
              if (ele) {
                ele.setAttribute('fill', COLORS[i])
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    const getData = async () => {
      const {
        data: { content },
      } = await axiosInstance.get<PageEventStageDefinitionDto>(
        `admin/event-stage-definitions?query=event.id==${eventId}`,
      )

      if (content && content[0]?.definition) {
        svgString.current! = content[0].definition
        const temp = document.createElement('template')
        temp.innerHTML = content[0].definition
        if (temp.content.firstChild) {
          definitionWrapperRef.current!.appendChild(temp.content.firstChild)
        }

        const svg = definitionWrapperRef.current!.children[1]
        svgRef.current = svg

        svg.removeAttribute('width')
        svg.removeAttribute('height')

        const svgElements =
          svgRef.current!.querySelectorAll('[data-definition]')
        if (svgElements.length > 0) {
          for (let i = 0; i < svgElements.length; i++) {
            const ele = svgElements.item(i) as SVGElement
            const defData = ele.dataset.definition
            if (defData) {
              const definition = JSON.parse(decodeURIComponent(defData))

              const id = ele.getAttribute('id')
              if (id) {
                const linkedEle = svgRef.current!.querySelector(`[to="${id}"]`)
                if (linkedEle) {
                  linkedEle.innerHTML = definition.name[`value${appLang}`]
                }
              }
            }
          }
        }
      }

      await getAndSetEventSeatPrices()

      const {
        data: { content: eventSeats },
      } = await axiosInstance.get(
        `admin/event-seats?size=50000&query=event.id==${eventId}`,
      )
      setEventSeats(eventSeats)

      const seats: { [key: string]: { id: number } } = {}

      for (const eventSeat of eventSeats) {
        seats[eventSeat.seatId] = {
          id: eventSeat.id,
        }

        const ele = document.getElementById(eventSeat.seatId)
        if (ele) {
          ele.addEventListener('click', ellipseClickHandler)
        }
        const textEle =
          document.querySelector(`[data-to-seat="${eventSeat.seatId}"]`) ||
          document.querySelector(`[to="${eventSeat.seatId}"]`)
        if (textEle) {
          textEle.addEventListener('click', ellipseClickHandler)
        }
      }

      const {
        data: { content: eventPrices },
      } = await axiosInstance.get<PageEventPriceDto>(
        `admin/event-prices?size=50000&query=event.id==${eventId}`,
      )
      setEventPrices(eventPrices)

      if (eventPrices) {
        setEventPrices(() => [defaultTicketCategory, ...eventPrices])
      }
    }

    setLoading(true)
    getData().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (eventPrices && seatPrices) {
      fillSeatsByPrice(eventPrices, seatPrices)
      setFilled(true)
    }
  }, [seatPrices, eventPrices])

  useEffect(() => {
    const tempSeats: {
      [key: string]: { id: number; eventSeatPriceID?: number }
    } = {}
    if (eventSeats) {
      for (const eventSeat of eventSeats) {
        if (eventSeat.seatId) {
          tempSeats[eventSeat.seatId] = {
            id: eventSeat.id!,
          }
        }
      }
    }
    if (seatPrices) {
      for (const seatPrice of seatPrices) {
        if (seatPrice.eventSeat?.seatId) {
          if (tempSeats[seatPrice.eventSeat?.seatId] === undefined) {
            tempSeats[seatPrice.eventSeat?.seatId] = {
              id: seatPrice.eventSeat?.id!,
            }
          }
          tempSeats[seatPrice.eventSeat?.seatId].eventSeatPriceID = seatPrice.id
        }
      }
    }
  }, [eventSeats, seatPrices])

  const getAndSetEventSeatPrices = async () => {
    const {
      data: { content: eventSeatPrices },
    } = await axiosInstance.get(
      `admin/event-seat-prices?size=50000&query=eventPrice.event.id==${eventId};eventSeat.event.id==${eventId}`,
    )
    setSeatPrices(eventSeatPrices)

    return eventSeatPrices
  }

  const columns: ColumnsType<EventPriceDto> = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any, index: number) => {
        return (
          <Space>
            <span
              style={{
                display: 'block',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '1px solid #d9d9d9',
                backgroundColor: index === 0 ? '#ffffff' : COLORS[index],
              }}
            />
            <span style={index === 0 ? {} : { cursor: 'pointer' }}>
              {record.name}
            </span>
          </Space>
        )
      },
    },
  ]

  const saveData = useCallback(
    async (values: any) => {
      await axiosInstance.post(
        'admin/tickets/gift/bulk',
        selectedSeats.map((seatId) => ({
          ...values,
          eventSeatId: eventSeats?.find((i) => i.seatId === seatId)?.id,
          eventPriceId: seatPrices?.find((i) => i.eventSeat?.seatId === seatId)
            ?.eventPrice?.id,
        })),
      )
      toast.success(t`messages.giftCreated`)
      setSearch('tab=gift-tickets')
    },
    [selectedSeats, eventSeats, seatPrices, t],
  )

  const fullScreen = () => {
    definitionWrapperRef.current!.style.position = 'fixed'
    definitionWrapperRef.current!.style.top = '50%'
    definitionWrapperRef.current!.style.left = '0'
    definitionWrapperRef.current!.style.transform = 'translate(0, -50%)'
    definitionWrapperRef.current!.style.width = '100%'
    definitionWrapperRef.current!.style.zIndex = '1001'

    const mask = document.createElement('div')
    mask.style.position = 'fixed'
    mask.style.width = '100%'
    mask.style.height = '100%'
    mask.style.top = '0'
    mask.style.bottom = '0'
    mask.style.left = '0'
    mask.style.background = 'rgba(0, 0, 0, 0.45)'
    mask.style.zIndex = '1000'
    definitionWrapperRef.current!.parentElement!.prepend(mask)
    mask.addEventListener('click', function () {
      definitionWrapperRef.current!.style.position = 'relative'
      definitionWrapperRef.current!.style.top = ''
      definitionWrapperRef.current!.style.left = ''
      definitionWrapperRef.current!.style.transform = ''
      definitionWrapperRef.current!.style.width = ''
      definitionWrapperRef.current!.style.zIndex = 'auto'

      this.remove()
    })
  }

  useEffect(() => {
    if (!ticketsBookedLoaded || !filled) return

    const booked: string[] = ticketsBooked?.bookedSeatIds || []
    const purchased: string[] = ticketsBooked?.purchasedSeatIds || []

    for (const bookedID of booked) {
      const ele = svgRef.current?.querySelector<SVGElement>(`#${bookedID}`)
      const toElement = svgRef.current!.querySelector<SVGTextElement>(
        `[data-to-seat="${bookedID}"]`,
      )

      if (
        ele &&
        !seatPrices?.find(({ eventSeat }) => eventSeat === bookedID)?.eventSeat!
          .capacityLeft
      ) {
        ele.style.cursor = 'pointer'
        ele.setAttribute('fill', BOOKED_COLOR)
        ele.dataset.booked = 'booked'

        if (ele.tagName === 'rect') {
          if (ellipseClickHandler) {
            ele.style.cursor = 'default'
            ele.removeEventListener('click', ellipseClickHandler)
            toElement?.removeEventListener('click', ellipseClickHandler)
          }
        }
        if (ele.tagName === 'ellipse') {
          if (ellipseClickHandler) {
            ele.style.cursor = 'default'
            ele.removeEventListener('click', ellipseClickHandler)
            if (toElement) {
              toElement.removeEventListener('click', ellipseClickHandler)
              toElement.style.cursor = 'default'
            }
          }
        }
      }
      if (toElement) {
      }
    }

    for (const purchasedID of purchased) {
      const ele = svgRef.current?.querySelector<SVGElement>(`#${purchasedID}`)
      const toElement = svgRef.current!.querySelector<SVGTextElement>(
        `[data-to-seat="${purchasedID}"]`,
      )
      if (
        ele &&
        !seatPrices?.find(({ eventSeat }) => eventSeat!.seatId === purchasedID)
          ?.eventSeat!.capacityLeft
      ) {
        ele.setAttribute('fill', PURCHASED_COLOR)
        ele.dataset.purchased = 'purchased'
        if (ele.tagName === 'ellipse') {
          if (ellipseClickHandler) {
            ele.style.cursor = 'default'
            if (toElement) {
              toElement.removeEventListener('click', ellipseClickHandler)
              toElement.style.cursor = 'default'
            }
            ele.removeEventListener('click', ellipseClickHandler)
          }
        }
      }
    }
  }, [ticketsBookedLoaded, ticketsBooked, filled])

  return (
    <Card style={{ marginTop: '15px' }}>
      <Row>
        <Col span={24}>
          <Form form={form} onFinish={saveData}>
            <Card
              style={{ marginBottom: '10px' }}
              title={t`labels.Please Enter person data`}
              extra={
                <Button
                  disabled={!selectedSeats.length}
                  type="primary"
                  htmlType="submit"
                >
                  {t`labels.Send gift`}
                </Button>
              }
            >
              <Row>
                <Col span={12}>
                  {selectedSeats.map((seatId, i) => {
                    const eventSeat = seatPrices?.find(
                      ({ eventSeat }) => eventSeat!.seatId === seatId,
                    )?.eventSeat!

                    return <SeatTag eventSeat={eventSeat} key={seatId + i} />
                  })}
                </Col>
                <Col span={12}>
                  <Space>
                    <GenericAsyncSelect<CountryDto>
                      path="admin/countries"
                      label={t`phoneCode`}
                      name="countryPhoneCode"
                      withAll={false}
                      customPrepareOption={({
                        phoneCode,
                        name,
                      }: CountryDto): SelectOption => ({
                        value: String(phoneCode),
                        label: `${name} (+${phoneCode})`,
                      })}
                      rules={[{ required: true }]}
                    />
                    <Form.Item
                      name="phone"
                      hasFeedback
                      rules={[{ required: true }]}
                    >
                      <FloatNumberInput
                        controls={false}
                        maxLength={12}
                        label={t('labels.Phone')}
                        required
                      />
                    </Form.Item>
                    <Form.Item name="email" hasFeedback>
                      <FloatInput label={t`labels.Email`} />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={12} xs={24} sm={24} md={12} xxl={14}>
          <Card
            style={{ height: '100%' }}
            extra={
              <Button onClick={fullScreen} icon={<FullscreenOutlined />} />
            }
            headStyle={{ textAlign: 'center' }}
            title={t('StepSeatAssignment.mapTitle')}
          >
            {!!selectedSeats.length && (
              <Button
                size="small"
                style={{
                  position: 'absolute',
                  top: '62px',
                  right: '10px',
                  zIndex: '1',
                }}
                onClick={removeSelection}
              >
                {t('StepSeatAssignment.removeSelectionButton')}
              </Button>
            )}
            <div
              ref={definitionWrapperRef}
              style={{ position: 'relative', userSelect: 'none' }}
            >
              <div id={styles.selectionFrame} ref={selectionFrame} />
            </div>
          </Card>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} xxl={10}>
          <Card
            headStyle={{ textAlign: 'center' }}
            title={t('StepSeatAssignment.ticketCategoriesTitle')}
          >
            <Table
              scroll={{ x: 400 }}
              loading={loading}
              showHeader={false}
              pagination={false}
              dataSource={eventPrices}
              columns={columns}
              rowClassName={styles.rowHover}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  )
}
