import { useCallback } from 'react'
import { Form, Select, Button, Modal } from 'antd'
import Page from 'components/Page'
import {
  UserDto,
  PageUserDto,
  TicketDto,
  PurchaseOrderDtoStatusEnum,
  TicketsSalesStatisticsViewTicketStatusEnum,
} from '@/openapi'
import { axiosInstance } from 'api'
import { useAntdTable } from 'ahooks'
import { Params } from 'types/Params'
import { SelectOption } from 'types/Option'
import commonStyles from '@pages/commonStyles.module.less'
import { SorterResult } from 'antd/es/table/interface'
import { useParams } from 'react-router-dom'
import { twoColumnsDateFormat } from '@pages/helpers'
import { ticketsStatusOptions } from '@pages/event/manage/soldTickets'
import { LinkOutlined, ArrowDownOutlined } from '@ant-design/icons'
import FloatInput from '@components/FloatInput'
import { useTranslation } from 'react-i18next'
import { castType } from '@/utils/cast'
import { DragTable, ExtendedColumns } from '@components/DragTable'
const { Option } = Select

export type CustomFormData = UserDto & { countryId: SelectOption }

const selectOptions = [
  ['purchased', 'Purchased'],
  ['return_requested', 'Return Requested'],
  ['returned', 'Returned'],
]

type Props = {
  user?: UserDto
}

const UserTickets = ({}: Props) => {
  const [form] = Form.useForm()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const getData = async (
    {
      current,
      pageSize,
      sorter,
    }: {
      current: number
      pageSize: number
      sorter: SorterResult<TicketDto>
    },
    filters: { [key in keyof TicketDto]: string } & {
      eventName: string
      stage: string
      city: string
    },
  ) => {
    const params: Params = { page: current - 1, size: pageSize }
    const query = [`user.id==${id}`]
    if (filters.eventName) {
      query.push(`eventPrice.event.name~~${filters.eventName}`)
    }
    if (filters.stage) {
      query.push(`eventPrice.event.stage.name~~${filters.stage}`)
    }
    if (filters.city) {
      query.push(`eventPrice.event.stage.city.name~~${filters.city}`)
    }
    if (filters.status) {
      query.push(`status==${filters.status}`)
    }
    if (sorter?.columnKey) {
      params.sort = `${sorter.columnKey},${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`
    }
    params.query = query.join(';')

    const {
      data: { content, totalElements },
    } = await axiosInstance.get<PageUserDto>('admin/tickets', { params })
    return {
      list: content || [],
      total: totalElements!,
    }
  }

  const getPaymentLink = useCallback(async (id: number) => {
    const { data } = await axiosInstance.get<string>(
      `/admin/tickets/${id}/payment-link`,
    )
    window.open(data, '_blank')
  }, [])

  const {
    tableProps,
    refresh,
    search: { submit },
  } = useAntdTable(getData, {
    defaultPageSize: 10,
    form,
  })

  const downloadTicket = useCallback(async (record: TicketDto) => {
    const { data } = await axiosInstance.get<Blob>(
      `/admin/tickets/${record.id}/download`,
      {
        responseType: 'blob',
      },
    )
    const fileURL = window.URL.createObjectURL(data)
    let alink = document.createElement('a')
    alink.href = fileURL
    alink.download = `Ticket-${record.id}-${record.user?.phone}`
    alink.click()
  }, [])

  const columns: ExtendedColumns<TicketDto>[] = [
    {
      title: 'ID',
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '70px',
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Event name`}</div>
          <Form.Item name="eventName">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'eventName',
      key: 'eventName',
      shortTitle: t`labels.Event name`,
      render: (_, t) => t.eventPrice?.event?.name,
    },
    {
      title: t`labels.Purchase Date`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: twoColumnsDateFormat,
      sorter: true,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Stage`}</div>
          <Form.Item name="stage">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'stage',
      key: 'stage',
      shortTitle: t`labels.Stage`,
      render: (_, t) => t.eventPrice?.event?.stage?.name,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.City`}</div>
          <Form.Item name="city">
            <FloatInput label="" onChange={submit} />
          </Form.Item>
        </div>
      ),
      dataIndex: 'stage',
      key: 'stage',
      shortTitle: t`labels.City`,
      render: (_, t) => t.eventPrice?.event?.stage?.city?.name,
    },
    {
      title: t`labels.Category`,
      dataIndex: 'category',
      key: 'category',
      render: (_, t) => t.eventPrice?.name,
    },
    {
      title: t`labels.Row`,
      dataIndex: 'row',
      key: 'row',
      render: (_, t) => t.eventSeat?.rowNum,
      width: '70px',
    },
    {
      title: t`labels.Place`,
      dataIndex: 'place',
      key: 'place',
      render: (_, t) => t.eventSeat?.place,
      width: '70px',
    },
    {
      title: t`labels.Price`,
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (_, t) => t.price + ' ' + t.currency?.code,
    },
    {
      title: (
        <div className={commonStyles.headerCell}>
          <div>{t`labels.Status`}</div>
          <Form.Item name="status">
            <Select onClick={(e) => e.stopPropagation()} onChange={submit}>
              {ticketsStatusOptions.map(({ label, value }) => (
                <Option value={value} label={label} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ),
      shortTitle: t`labels.Status`,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: '230px',
      noTooltip: true,
      render: (_, ticket) => (
        <div>
          <div>
            {
              ticketsStatusOptions.find(
                ({ value }) =>
                  value ===
                  castType<TicketsSalesStatisticsViewTicketStatusEnum>(
                    ticket.status,
                  ),
              )?.label
            }
          </div>
          <div>
            <Select
              value={ticket.status}
              onChange={(value) => {
                Modal.confirm({
                  title: t('messages.changeTicketStatus', {
                    from: ticket.status,
                    to: value,
                  }),
                  onOk: async () => {
                    if (
                      value ===
                      TicketsSalesStatisticsViewTicketStatusEnum.RETURNED
                    ) {
                      await axiosInstance.post(
                        '/admin/tickets/' + ticket.id + '/refund-ticket',
                      )
                    } else {
                      await axiosInstance.put('/admin/tickets/' + ticket.id, {
                        id: ticket.id,
                        status: value,
                      })
                    }
                    refresh()
                  },
                })
              }}
              style={{ width: '100%' }}
            >
              {selectOptions
                .filter(([value]) => value !== ticket.status)
                .map(([value, label]) => (
                  <Option value={value} label={label} key={value}>
                    {'->'} {label}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      ),
    },
    {
      title: t`labels.Payment link`,
      dataIndex: 'paymentLink',
      key: 'paymentLink',
      noTooltip: true,
      render: (_, ticket) => {
        if (
          ticket.type !== 'gift' &&
          ticket.purchaseOrder &&
          (ticket.purchaseOrder.status ===
            PurchaseOrderDtoStatusEnum.PURCHASE_STARTED ||
            ticket.purchaseOrder.status ===
              PurchaseOrderDtoStatusEnum.PURCHASE_PENDING ||
            ticket.purchaseOrder.status ===
              PurchaseOrderDtoStatusEnum.COMPLETED)
        ) {
          return (
            <Button
              type="primary"
              icon={<LinkOutlined />}
              onClick={() => getPaymentLink(ticket.id!)}
            >
              {t`open`}
            </Button>
          )
        }
        return null
      },
    },
    {
      title: '',
      dataIndex: 'download',
      key: 'download',
      noTooltip: true,
      render: (_, t) => (
        <Button
          type="primary"
          icon={<ArrowDownOutlined />}
          onClick={() => downloadTicket(t)}
        />
      ),
    },
  ]

  return (
    <Page inner>
      <Form form={form}>
        <DragTable<UserDto>
          bordered
          columns={columns}
          rowKey={(record) => String(record.id)}
          className={commonStyles.table}
          {...tableProps}
        />
      </Form>
    </Page>
  )
}

export default UserTickets
