import { Alert, Button, Form } from 'antd'
import { EventStep3Form, StepProps } from './types'
import { axiosInstance } from 'api'
import { useCallback, useEffect, useRef } from 'react'
import { CityAsyncSelect } from '@components/AsyncSelect/CityAsyncSelect'
import { StageAsyncSelect } from '@components/AsyncSelect/StageAsyncSelect'
import styles from './styles.module.less'
import cn from 'classnames'
import { getValue } from './helpers'
import { StageDefinition } from './StageDefinition/StageDefinition'
import { EventExtendedDto } from '@/openapi'
import { useParams } from 'react-router-dom'
import { FILTER_OPERATORS } from '@components/AsyncSelect/GenericAsyncSelect'
import { useTranslation } from 'react-i18next'

const StepStage = ({
  onSuccess,
  item,
  setForm,
  disabled,
  errors,
  refetchItem,
}: StepProps) => {
  const [form] = Form.useForm<EventStep3Form>()
  const goForward = useRef(true)
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  useEffect(() => {
    setForm(form)
  }, [form])
  const cityId = Form.useWatch('cityId', form)
  const stageId = Form.useWatch('stageId', form)
  const onFinish = async (values: EventStep3Form) => {
    if (disabled) {
      onSuccess({}, !goForward.current)
      return
    }
    const dataToSend = {
      stageId: getValue(values.stageId, true),
      cityId: getValue(values.cityId),
    }
    await axiosInstance.put<EventExtendedDto>(
      `admin/events/step3/${id}`,
      dataToSend,
    )
    await refetchItem?.()
    onSuccess(undefined, !goForward.current)
  }

  const handleBackClick = useCallback(() => {
    goForward.current = false
    form.submit()
  }, [form])

  return (
    <div style={{ marginTop: '15px' }}>
      {errors?.length && (
        <Alert
          message={
            <>
              {errors.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </>
          }
          type="error"
          showIcon
          className={cn(styles.fullWidth, styles.alert)}
        />
      )}
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={item}
        className={cn(styles.form, styles.formQuarter)}
      >
        <CityAsyncSelect
          name="cityId"
          withAll={false}
          rules={[{ required: true }]}
          required
          fullWidth
          allowClear
          disabled={disabled}
          submit={() => form.setFieldValue('stageId', undefined)}
        />

        <StageAsyncSelect
          name="stageId"
          withAll={false}
          required
          fullWidth
          controlProps={{ allowClear: true, disabled: !cityId || disabled }}
          baseFilters={
            cityId
              ? {
                  'city.id': {
                    operator: FILTER_OPERATORS.EQUAL,
                    value: cityId.value ? cityId.value : String(cityId),
                  },
                }
              : undefined
          }
        />
        <StageDefinition
          stageId={getValue(stageId, false)}
          eventId={Number(id)}
          disabled={disabled}
        />
        <div className={cn(styles.buttonBlock, styles.fullWidth)}>
          <Button htmlType="button" onClick={() => form.resetFields()}>
            {t`cancel`}
          </Button>
          <div className={styles.buttonBlock}>
            <Button htmlType="button" onClick={handleBackClick}>
              {t`labels.Back`}
            </Button>
            <Form.Item
              style={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'right',
                marginTop: 0,
              }}
            >
              <Button type="primary" htmlType="submit">
                {t`labels.Next`}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default StepStage
