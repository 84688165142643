/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PurchaseOrderCreateRequest
 */
export interface PurchaseOrderCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateRequest
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateRequest
     */
    'externalOrderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateRequest
     */
    'status'?: PurchaseOrderCreateRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateRequest
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateRequest
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateRequest
     */
    'eventId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateRequest
     */
    'userId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PurchaseOrderCreateRequestStatusEnum {
    STARTED = 'started',
    PURCHASE_STARTED = 'purchase_started',
    PURCHASE_PENDING = 'purchase_pending',
    COMPLETED = 'completed',
    RETURN_REQUESTED = 'return_requested',
    RETURNED = 'returned',
    EXPIRED = 'expired',
    CANCELED = 'canceled',
    EVENT_CANCELED = 'event_canceled'
}


